import React from 'react';
import {connect} from 'react-redux';
import Chats from '../EventMenuDropdowns/Chats/Chats';
import Contacts from '../EventMenuDropdowns/Contacts';
import Notifications from '../EventMenuDropdowns/Notifications';
// import Program from '../EventMenuDropdowns/AdvancedProgramComponents/Programme/Program';
import {Link, withRouter} from 'react-router-dom';
// import MyEvents from '../EventMenuDropdowns/MyEvents';
import MyAccount from '../EventMenuDropdowns/MyAccount';
import Rooms from './Rooms/Rooms';
// import ProgramSubmenu from './Program/ProgramSubmenu';
import colors from '../../CSS/_variables.module.scss';
import '../../CSS/headerMenu.scss';
import '../../CSS/headerMenuMobile.scss';
import DetectOutsideClick from '../../HOC/DetectOutsideClick';
import {ReactComponent as AccountIcon} from '../../Images/svg/account.svg';
import * as actions from '../../store/actions';
// import {ReactComponent as MenuUpIcon} from '../../Images/svg/arrow_up.svg';
// import {ReactComponent as MenuDownIcon} from '../../Images/svg/arrow_down.svg';
import {isIOS} from 'react-device-detect';
import {ReactComponent as ChatsIcon} from '../../Images/svg/chat.svg';
import {ReactComponent as ProgramIcon} from '../../Images/svg/checklist.svg';
import {ReactComponent as LobbyIcon} from '../../Images/svg/lobby-logo.svg';
import {ReactComponent as LogoImage} from '../../Images/svg/logo.svg';
import {ReactComponent as LogoBlack} from '../../Images/svg/myonvent-black.svg';
import {ReactComponent as ContactsIcon} from '../../Images/svg/phone-book.svg';
import {ReactComponent as NotificationsIcon} from '../../Images/svg/ring.svg';
import {ReactComponent as RoomsIcon} from '../../Images/svg/rooms-mobile.svg';
import {checkIfEventHasEnded, preventDefaultDrag} from '../../Utils/utils';
import AdvancedProgram from '../EventMenuDropdowns/AdvancedProgramComponents/AdvancedProgram';

class EventMenuMobile extends React.Component {
    componentDidMount() {
        this._addOverlayWhenMyEventsIsOpened();
        document.addEventListener('mousedown', this._addOverlayWhenMyEventsIsOpened);
    }

    componentDidUpdate(prevProps) {
        const {event, eventArchive, seeRooms, topNavigation} = this.props;
        if (
            prevProps.event.hideAuditoriumDoor !== event.hideAuditoriumDoor &&
            event.hideAuditoriumDoor &&
            event.hideShowfloorDoor &&
            eventArchive &&
            !eventArchive.length > 0 &&
            topNavigation.seeRooms
        ) {
            seeRooms();
            this._addOverlayWhenMyEventsIsOpened();
        }

        if (
            prevProps.event.hideShowfloorDoor !== event.hideShowfloorDoor &&
            event.hideAuditoriumDoor &&
            event.hideShowfloorDoor &&
            eventArchive &&
            !eventArchive.length > 0 &&
            topNavigation.seeRooms
        ) {
            seeRooms();
            this._addOverlayWhenMyEventsIsOpened();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this._addOverlayWhenMyEventsIsOpened);
    }

    _addOverlayWhenMyEventsIsOpened = () => {
        setTimeout(function () {
            if (
                document.getElementById('account-drop-mobile') ||
                document.querySelector('.notifications-mobile') ||
                document.querySelector('.my-account-mobile') ||
                document.getElementById('rooms-dropdown') ||
                document.getElementById('submenu-dropdown')
            ) {
                if (document.querySelector('.position-background')) {
                    document.querySelector('.position-background').classList.add('overlay-grey');
                } else if (document.getElementById('my-account-mobile')) {
                    document.getElementById('my-account-mobile').classList.add('overlay-grey');
                } else if (document.querySelector('.showfloor-page')) {
                    document.querySelector('.showfloor-page').classList.add('overlay-grey');
                }
            } else {
                if (document.querySelector('.position-background')) {
                    document.querySelector('.position-background').classList.remove('overlay-grey');
                } else if (document.getElementById('my-account-mobile')) {
                    document.getElementById('my-account-mobile').classList.remove('overlay-grey');
                } else if (document.querySelector('.showfloor-page')) {
                    document.querySelector('.showfloor-page').classList.remove('overlay-grey');
                }
            }
        }, 150);
    };

    redirectToLobby = () => {
        const {eventSlug} = this.props;
        this.props.onCloseTopNavMobile();
        this.props.history.push(`/event/${eventSlug}/lobby`);
    };

    checkIfEventHasEnded = () => {
        // check if event has ended
        // if yes, redirect to the video archive page
        const {event} = this.props;
        if (event) {
            return checkIfEventHasEnded(event, event.timezoneValue);
        }
    };

    stopTooltipTimeout = () => {
        this.props.onShowSmartTooltip(false);
    };

    startTooltipTimeout = () => {
        setTimeout(() => {
            this.props.onShowSmartTooltip(true);
        }, 3000);
    };

    stopDisplayingSmartTooltip = () => {
        const {eventSlug, user} = this.props;
        let dataToStorage = [];
        const setData = {
            event: eventSlug,
            userId: user?._id,
            smartTooltipDisplayed: true,
        };
        const smartTooltipData = localStorage.getItem('smartTooltipData');
        if (smartTooltipData !== null && JSON.parse(smartTooltipData).length > 0) {
            let spreadArray = JSON.parse(smartTooltipData);
            if (
                spreadArray.filter((tooltipData) => tooltipData.event === eventSlug && tooltipData.userId === user?._id)
                    ?.length === 0
            ) {
                spreadArray.push(setData);
            }
            dataToStorage = spreadArray;
        } else {
            dataToStorage.push(setData);
        }
        localStorage.setItem('smartTooltipData', JSON.stringify(dataToStorage));

        this.stopTooltipTimeout();
    };

    render() {
        const {
            refreshPage,
            notificationsNotRead,
            activeMyAccount,
            activeHome,
            topNavigation,
            translation,
            event,
            eventSlug,
            serverDown,
            sessionExpired,
            seeMyAccountMobile,
            notReadChatMessages,
            seeNotificationsMobile,
            seeRooms,
            seeContactsMobile,
            seeChatsMobile,
            seeProgramMobile,
            eventArchive,
            defaultTranslation,
        } = this.props;

        // fix for removing green lobby when one overlay is opened;
        let activeLobby =
            activeHome &&
            !topNavigation.seeRooms &&
            !topNavigation.seeContactsMobile &&
            !topNavigation.seeChatsMobile &&
            !topNavigation.seeProgramMobile;

        return (
            <DetectOutsideClick>
                <div onDragStart={preventDefaultDrag} className="top-container">
                    {/* {serverDown && (
                        <div onDragStart={preventDefaultDrag} className="server-down-info-container">
                            <p>
                                {translation?.eventMenu.connectionProblemTextFirst}
                                <span onDragStart={preventDefaultDrag} draggable="false" onClick={refreshPage}>
                                    {translation?.eventMenu.connectionProblemButton}
                                </span>
                                {translation?.eventMenu.connectionProblemTextSecond}
                            </p>
                        </div>
                    )}*/}
                    {sessionExpired && (
                        <div onDragStart={preventDefaultDrag} className="server-down-info-container">
                            <p>
                                {translation?.eventMenu.sessionProblemTextFirst}
                                <span onDragStart={preventDefaultDrag} draggable="false" onClick={refreshPage}>
                                    {translation?.eventMenu.sessionProblemButton}
                                </span>
                                {translation?.eventMenu.sessionProblemTextSecond}
                            </p>
                        </div>
                    )}
                    <header id="header-mobile" className="header">
                        <div onDragStart={preventDefaultDrag} className="main-menu-container">
                            <div onDragStart={preventDefaultDrag} className="actions-container">
                                <div onDragStart={preventDefaultDrag} className="left-header-wrapper">
                                    <div onDragStart={preventDefaultDrag} id="logo-mobile">
                                        <Link tabIndex="0" to={{pathname: `/event/${eventSlug}/lobby`}}>
                                            <LogoImage />
                                        </Link>
                                        <a href="https://myonvent.com/" target="_blank" className="powered-by">
                                            <span>Powered by</span>
                                            <LogoBlack />
                                        </a>
                                    </div>
                                </div>
                                <div onDragStart={preventDefaultDrag} className="right-header-wrapper">
                                    <div
                                        onDragStart={preventDefaultDrag}
                                        id="notifications"
                                        onClick={seeNotificationsMobile}
                                    >
                                        <NotificationsIcon
                                            fill={`${
                                                topNavigation.seeNotificationsMobile ? colors.primary : colors.grey
                                            }`}
                                        />
                                        {notificationsNotRead && (
                                            <div
                                                className={`not-read-notifications flex-center ${
                                                    notificationsNotRead.toString().length >= 3 ? 'fs9' : 'fs10'
                                                }`}
                                            >
                                                <span>
                                                    {notificationsNotRead.toString().length >= 3
                                                        ? '99+'
                                                        : notificationsNotRead}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        id="myaccount"
                                        onClick={seeMyAccountMobile}
                                        className={topNavigation.seeMyAccountMobile || activeMyAccount ? 'active' : ''}
                                    >
                                        <AccountIcon
                                            fill={`${
                                                topNavigation.seeMyAccountMobile || activeMyAccount
                                                    ? colors.primary
                                                    : colors.grey
                                            }`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>

                    {topNavigation.seeContactsMobile && <Contacts mobile />}
                    {topNavigation.seeProgramMobile && (
                        <AdvancedProgram
                            mobile
                            startTooltipTimeout={this.startTooltipTimeout}
                            stopDisplayingSmartTooltip={this.stopDisplayingSmartTooltip}
                            stopTimeout={this.stopTooltipTimeout}
                        />
                    )}
                    {topNavigation.seeChatsMobile && <Chats />}
                    {topNavigation.seeNotificationsMobile && <Notifications mobile />}
                    {/*{topNavigation.seeMyEventsMobile && <MyEvents mobile />}*/}
                    {topNavigation.seeMyAccountMobile && <MyAccount mobile />}
                </div>
                <div
                    onDragStart={preventDefaultDrag}
                    id="menu-bottom"
                    className={isIOS ? 'menu-bottom is-ios' : 'menu-bottom'}
                >
                    <div onDragStart={preventDefaultDrag} className="menu-bottom-wrapper">
                        <div onDragStart={preventDefaultDrag} className="main-menu-container">
                            <div onDragStart={preventDefaultDrag} className="main-header-wrapper">
                                <div
                                    className={'home-button ' + (activeLobby ? 'active' : '')}
                                    onClick={this.redirectToLobby}
                                >
                                    <LobbyIcon fill={`${activeLobby ? colors.primary : colors.grey}`} />
                                    <p>
                                        {translation?.eventMenu.homeButton || defaultTranslation?.eventMenu.homeButton}
                                    </p>
                                </div>
                                {event.hideAuditoriumDoor &&
                                event.hideShowfloorDoor &&
                                eventArchive &&
                                !eventArchive.length > 0 ? null : (
                                    <div
                                        onDragStart={preventDefaultDrag}
                                        id="rooms"
                                        onClick={seeRooms}
                                        className={topNavigation.seeRooms ? 'active' : ''}
                                    >
                                        <RoomsIcon
                                            className={`${topNavigation.seeRooms ? 'rotate-90deg' : ''}`}
                                            fill={`${topNavigation.seeRooms ? colors.primary : colors.grey}`}
                                        />
                                        <p>{translation?.eventMenu.roomsButton}</p>
                                    </div>
                                )}
                                <div
                                    id="contacts"
                                    onClick={seeContactsMobile}
                                    className={topNavigation.seeContactsMobile ? 'active' : ''}
                                >
                                    <ContactsIcon
                                        stroke={`${topNavigation.seeContactsMobile ? colors.primary : colors.greyDark}`}
                                        fill={`${topNavigation.seeContactsMobile ? colors.primary : colors.greyDark}`}
                                    />
                                    <p>{translation?.eventMenu.contactsButton}</p>
                                </div>
                                <div
                                    id="chats"
                                    onClick={seeChatsMobile}
                                    className={topNavigation.seeChatsMobile ? 'active' : ''}
                                >
                                    {topNavigation.seeChatsMobile ? (
                                        <span>
                                            <ChatsIcon fill={colors.primary} />
                                            {notReadChatMessages && (
                                                <div
                                                    className={`flex-center ${
                                                        notReadChatMessages.toString().length >= 3
                                                            ? 'fs9'
                                                            : notReadChatMessages.toString().length >= 2
                                                            ? 'fs10'
                                                            : 'fs12'
                                                    }`}
                                                >
                                                    <span>
                                                        {notReadChatMessages.toString().length >= 3
                                                            ? '99+'
                                                            : notReadChatMessages}
                                                    </span>
                                                </div>
                                            )}
                                        </span>
                                    ) : (
                                        <span>
                                            <ChatsIcon fill={colors.grey} />
                                            {notReadChatMessages && (
                                                <div
                                                    className={`flex-center ${
                                                        notReadChatMessages.toString().length >= 3
                                                            ? 'fs9'
                                                            : notReadChatMessages.toString().length >= 2
                                                            ? 'fs10'
                                                            : 'fs12'
                                                    }`}
                                                >
                                                    <span>
                                                        {notReadChatMessages.toString().length >= 3
                                                            ? '99+'
                                                            : notReadChatMessages}
                                                    </span>
                                                </div>
                                            )}
                                        </span>
                                    )}

                                    <p>{translation?.eventMenu.chatsButton}</p>
                                </div>
                                <div
                                    id="program"
                                    onClick={seeProgramMobile}
                                    className={topNavigation.seeProgramMobile ? 'active' : ''}
                                >
                                    <ProgramIcon
                                        fill={`${topNavigation.seeProgramMobile ? colors.primary : colors.grey}`}
                                    />
                                    <p>{translation?.eventMenu.programButton}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {topNavigation.seeRooms && <Rooms />}
                </div>
            </DetectOutsideClick>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        event: state.event.data,
        eventArchive: state.eventArchive.data,
        user: state.user.data,
        eventRoles: state.user.eventRoles,
        resourcesAccess: state.user.resourcesAccess,
        loggedIn: state.user.loggedIn,
        notReadChatMessages: state.user.notReadChatMessages,
        topNavigation: state.user.topNavigation,
        serverDown: state.user.serverDown,
        sessionExpired: state.user.sessionExpired,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        hasOnDemandVideos: state.onDemandVideos.hasOnDemandVideos,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        seeContactsMobile: () => dispatch(actions.topNavSeeContactsMobile()),
        seeProgramMobile: () => dispatch(actions.topNavSeeProgramMobile()),
        seeChatsMobile: () => dispatch(actions.topNavSeeChatsMobile()),
        seeMyEventsMobile: () => dispatch(actions.topNavSeeMyEventsMobile()),
        seeNotificationsMobile: () => dispatch(actions.topNavSeeNotificationsMobile()),
        seeMyAccountMobile: () => dispatch(actions.topNavSeeMyAccountMobile()),
        onSetNotReadChatMessages: (numberOfNotReadMessages) =>
            dispatch(actions.setNotReadChatMessages(numberOfNotReadMessages)),
        onSetEventAccess: (resourcesAccess) => dispatch(actions.setEventAccess(resourcesAccess)),
        seeRooms: () => dispatch(actions.bottomSeeRooms()),
        onCloseTopNavMobile: () => dispatch(actions.topNavCloseMobile()),
        onShowSmartTooltip: (show) => dispatch(actions.showSmartTooltip(show)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventMenuMobile));

import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions';
import Slider from 'react-slick';
import './FeaturedExhibitorsSlider.scss';
import colors from '../../../CSS/_variables.module.scss';
import {ReactComponent as ButtonArrow} from './arrow_image.svg';
import {ReactComponent as Arrow} from '../../../Images/svg/arrow_featured_slider.svg';
import {preventDefaultDrag} from '../../../Utils/utils';
import cloneDeep from 'lodash/cloneDeep';

function SampleNextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div onDragStart={preventDefaultDrag} className={className} style={{...style}} onClick={onClick}>
            <Arrow />
        </div>
    );
}

function SamplePrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div onDragStart={preventDefaultDrag} className={className} style={{...style}} onClick={onClick}>
            <Arrow />
        </div>
    );
}

class FeaturedExhibitorsSlider extends React.Component {
    state = {
        sliderContainerStyles: {
            maxWidth: ((window.innerHeight - 269) * 132.7) / 100,
        },
        showAllExhibitorsOverlay: false,
    };

    componentDidMount() {
        this.calculateSliderMaxWidth();
        window.addEventListener('resize', this.calculateSliderMaxWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calculateSliderMaxWidth);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activeExhibitorId !== this.props.activeExhibitorId) {
            let activeElementIndex = this.props.featuredExhibitors.findIndex(
                (exhibitor) => exhibitor._id === this.props.activeExhibitorId
            );
            if (activeElementIndex !== -1) {
                this.slider.slickGoTo(activeElementIndex, true);
            }
        }
    }

    calculateSliderMaxWidth = () => {
        let realHeight = window.innerHeight - 269;

        if (window.innerHeight < 700) {
            realHeight = window.innerHeight - 210;
        }

        let sliderContainerStyles = {
            maxWidth: (realHeight * 165) / 100,
        };

        if (window.innerHeight < 700) {
            sliderContainerStyles = {
                maxWidth: (realHeight * 165) / 100,
            };
        }
        this.setState({sliderContainerStyles: sliderContainerStyles});
    };

    getSliderSettings = () => {
        const {featuredExhibitors} = this.props;
        return {
            dots: false,
            arrows: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            infinite: featuredExhibitors.length > 4,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            className: 'center',
            swipeToSlide: true,
            centerMode: true,
            centerPadding: 0,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 320,
                    settings: {slidesToShow: 1, infinite: featuredExhibitors.length > 0},
                },
                {
                    breakpoint: 768,
                    settings: {slidesToShow: 3, infinite: featuredExhibitors.length > 2},
                },
                {
                    breakpoint: 1366,
                    settings: {slidesToShow: 4, infinite: featuredExhibitors.length > 3},
                },
            ],
        };
    };

    getCurrentShowfloor = () => {
        const {event, activeShowfloorId} = this.props;
        const showfloor = event.showfloors.find((showfloor) => showfloor._id === activeShowfloorId);
        return showfloor;
    };

    sortExhibitors = () => {
        const {featuredExhibitors} = this.props;
        let sortFeaturedExhibitors = cloneDeep(featuredExhibitors);

        if (sortFeaturedExhibitors) {
            const sorted = sortFeaturedExhibitors.sort(function (a, b) {
                return a.booth?.position - b.booth?.position;
            });
            return sorted;
            // }
        }
    };

    getAllExhibitButtonName = () => {
        const {event, translation, defaultTranslation} = this.props;
        let buttonName = '';
        if (event?.showfloors?.length > 1) {
            buttonName =
                translation?.showfloor?.showShowfloorsButton || defaultTranslation?.showfloor?.showShowfloorsButton;
        } else {
            const {activeShowfloorId} = this.props;
            const showfloor = event.showfloors.find((showfloor) => showfloor._id === activeShowfloorId);
            if (showfloor?.type === 'posters') {
                buttonName =
                    translation?.showfloor?.showShowfloorsPosterButton ||
                    defaultTranslation?.showfloor?.showShowfloorsPosterButton;
            } else {
                buttonName =
                    translation?.showfloor?.showShowfloorsExhibitorButton ||
                    defaultTranslation?.showfloor?.showShowfloorsExhibitorButton;
            }
        }
        return buttonName;
    };

    handleSetActiveExhibitor = (exhibitorId) => (e) => {
        this.props.onSetActiveExhibitorId(exhibitorId);
    };

    render() {
        const {featuredExhibitors, noExhibitors, translation} = this.props;
        const sliderSettings = this.getSliderSettings();
        const showfloor = this.getCurrentShowfloor();
        const allExhibitButtonName = this.getAllExhibitButtonName();

        return (
            <div
                className={`exhibitors-slider ${noExhibitors ? 'no-exhibitors' : ''}`}
                style={this.state.sliderContainerStyles}
            >
                <div
                    onDragStart={preventDefaultDrag}
                    className="showfloor-menu-button"
                    onClick={this.props.showExhibitorsOverlay}
                >
                    <div>
                        {featuredExhibitors?.length > 0 && (
                            <div className="exhibitors-number">
                                <span>{featuredExhibitors?.length}</span>
                            </div>
                        )}
                        <ButtonArrow />
                        <div onDragStart={preventDefaultDrag} className="text-container">
                            <p onDragStart={preventDefaultDrag} className="text">
                                {allExhibitButtonName}
                            </p>
                        </div>
                    </div>
                </div>
                <div onDragStart={preventDefaultDrag} className="title-container">
                    <div onDragStart={preventDefaultDrag} className="">
                        <p onDragStart={preventDefaultDrag} className="title">
                            {showfloor?.name}
                        </p>
                        <p onDragStart={preventDefaultDrag} className="subtitle">
                            {translation?.showfloor.featuredExhibitorsText}
                        </p>
                    </div>
                </div>
                <div onDragStart={preventDefaultDrag} className="slider-container">
                    <Slider {...sliderSettings} ref={(c) => (this.slider = c)}>
                        {featuredExhibitors &&
                            this.sortExhibitors().map((exhibitor) => {
                                const isActive = this.props.activeExhibitorId === exhibitor._id;
                                return (
                                    <div
                                        className={`featured-exhibitors-container ${isActive ? 'active' : ''}`}
                                        key={exhibitor._id}
                                        onClick={this.handleSetActiveExhibitor(exhibitor._id)}
                                    >
                                        <div onDragStart={preventDefaultDrag} className="exhibitor-container">
                                            {exhibitor.booth.logo ? (
                                                <div onDragStart={preventDefaultDrag} className="logo-container">
                                                    <img
                                                        draggable="false"
                                                        onDragStart={preventDefaultDrag}
                                                        src={exhibitor.filesUrl + exhibitor.booth.logo}
                                                        alt={exhibitor.company}
                                                    />
                                                </div>
                                            ) : (
                                                <p>{exhibitor.company}</p>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                    </Slider>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        user: state.user.data,
        featuredExhibitors: state.exhibitors.featuredExhibitors,
        exhibitors: state.exhibitors.approvedExhibitors,
        activeExhibitorId: state.exhibitors.activeExhibitorId,
        languages: state.languages,
        activeShowfloorId: state.showfloors.activeShowfloorId,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        showfloors: state.showfloors.showfloors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetUser: () => dispatch(actions.refreshUserData()),
        onSetActiveExhibitorId: (exhibitorId) => dispatch(actions.setActiveExhibitorId(exhibitorId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedExhibitorsSlider);

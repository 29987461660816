const en = {
    generalText: {
        upload: 'Upload',
        save: 'Save',
        add: 'Add',
        // select: 'Select', // not used
        remove: 'Remove',
        close: 'Close',
        update: 'Update',
        cancel: 'Cancel',
        confirm: 'Confirm',
        download: 'Download',
        refresh: 'Refresh',
        // manage: 'Manage', // not used
        back: 'Back',
        submit: 'Submit',
        send: 'Send',
        // eventMO: 'onvent', // not used
        // eventETX: 'event', // not used
        // eventsMO: 'onvents', // not used
        // eventsETX: 'events', // not used
        // eventUpperMO: 'Onvent', // not used
        // eventUpperETX: 'Event', // not used
        leave: 'Leave',
        // hide: 'HIDE', // not used
        step1: 'Step 1',
        step2: 'Step 2',
        or: 'or',
        delete: 'Delete',
        please: 'Please',
        before: 'before',
        approve: 'Approve', // new translation
        decline: 'Decline', // new translation
        start: 'Start', // new translation
        stop: 'Stop', // new translation
        resetFilter: 'Reset Filter', // new translation
        apply: 'Apply', // new translation
        filters: 'Filters', // new translation
        unsavedChangesTitle: 'Unsaved changes', // new translation
        unsavedChangesText: 'You have unsaved changes. Please save them to continue.', // new translation
        selectAll: 'Select All',
        unselectAll: 'Unselect All',
    },
    time: {
        justNow: 'just now',
        minute: 'minute',
        minutes: 'minutes',
        hour: 'hour',
        hours: 'hours',
        day: 'day',
        days: 'days',
        month: 'month',
        months: 'months',
        yesterday: 'yesterday',
        today: 'today',
        tomorrow: 'tomorrow',
        monday: 'monday',
        tuesday: 'tuesday',
        wednesday: 'wednesday',
        thursday: 'thursday',
        friday: 'friday',
        saturday: 'saturday',
        sunday: 'sunday',
        // mondayShort: 'mon', // not used
        // tuesdayShort: 'tue', // not used
        // wednesdayShort: 'wed', // not used
        // thursdayShort: 'thu', // not used
        // fridayShort: 'fri', // not used
        // saturdayShort: 'sat', // not used
        // sundayShort: 'sun', // not used
        january: 'january',
        february: 'february',
        march: 'march',
        april: 'april',
        may: 'may',
        june: 'june',
        july: 'july',
        august: 'august',
        september: 'september',
        october: 'october',
        november: 'november',
        december: 'december',
        januaryShort: 'jan',
        februaryShort: 'feb',
        marchShort: 'mar',
        aprilShort: 'apr',
        mayShort: 'may',
        juneShort: 'jun',
        julyShort: 'jul',
        augustShort: 'aug',
        septemberShort: 'sep',
        octoberShort: 'oct',
        novemberShort: 'nov',
        decemberShort: 'dec',
    },
    errors: {
        noFile: 'No file chosen',
        required: 'Field is required',
        unique: 'Field must be unique',
        onlyEmptySpaces: 'Only empty spaces not allowed',
        emailNotValid: 'Email not valid',
        passwordMinLength: 'Password must have min 8 characters',
        passwordMismatch: 'Password mismatch',
        passwordIsSame: 'Your new password must be different',
        // fileToLarge: 'File too large. 2Mb max file size.', // not used
        // fileToLargeTextFirst: 'File too large.', // not used
        // fileToLargeTextSecond: 'max file size.', // not used
        // agreeToGDPR: 'Please agree to privacy policy', // not used
        noInvitationCode: 'Please enter your invitation code',
        // invitationCodeInvalid: 'Invitation code is invalid.', // not used
        imageFileNotSupported: 'File type not supported. Please use one of the following:',
        phoneInvalid: 'Please enter a valid phone number. E.g.: +447517663928',
        linkInvalid: 'Please enter a valid link (https://site.com)',
        facebookLinkInvalid: 'Please enter a valid link (https://facebook.com/)',
        linkedinLinkInvalid: 'Please enter a valid link (https://linkedin.com/)',
        twitterLinkInvalid: 'Please enter a valid link (https://twitter.com/)',
        youtubeLinkInvalid: 'Please enter a valid link (https://www.youtube.com/)',
        googleScholarLinkInvalid: 'Please enter a valid link (https://scholar.google.com/)',
        researchGateLinkInvalid: 'Please enter a valid link (https://www.researchgate.net/)',
        connectionProblemTextFirst: 'There seems to be a connection problem. Please',
        connectionProblemButton: 'CLICK',
        connectionProblemTextSecond: 'or refresh the page.',
        fileTooLarge: 'File too large. 2Mb max file size.',
        fileTooLargeTextFirst: 'File too large.',
        fileTooLargeTextSecond: 'max file size.',
        maxWallInputLength: 'You reached the maximum number of 1200 characters.',
        noCommasAllowed: 'Commas are not allowed.', // new translation
        genericTooShort: 'Field value is too short. Minimum length: ',
        genericTooLongErrorFirst: 'You have reached the maximum limit of characters allowed (',
        genericTooLongErrorSecond: ' characters)',
    },
    footer: {
        cookiesText:
            'This website is using essential cookies, which are necessary for its functioning. It also uses third-party cookies, which are necessary for the functioning of some features of our website. If you want to learn more or withdraw your consent to some cookies, please refer to our',
        cookiesLink: 'cookies policy',
        cookiesButton: 'ACCEPT',
        privacyPolicy: 'Privacy policy',
        cookies: 'Cookies',
        termsAndConditions: 'Terms and conditions',
        socialLinksTitle: 'Follow us:',
    },
    menu: {
        logIn: 'Log in',
        logOut: 'Log out',
        scholarRegistration: 'Join as poster/study',
        exhibitorRegistration: 'Join as exhibitor',
        participantRegistration: 'Join as participant',
        createEvent: 'Organize event',
        joinNetwork: 'Join network', // new translation aifg
        // launchText: 'Launch September 2020 - open for early access', // not used
    },
    homepage: {
        organizedBy: 'ORGANIZED BY',
        date: 'DATE',
        location: 'DOMAIN & LOCATION',
        price: 'PRICE',
        tabIntro: 'INTRO',
        // tabProgramIntroButton: 'INTRO', // not used
        tabProgram: 'PROGRAM',
        // tabIntroProgramButton: 'PROGRAM', // not used
        tabSponsors: 'SPONSORS',
        tabPartners: 'PARTNERS',
        tabExhibitors: 'EXHIBITORS',
        previousEventsSectionTitle: 'PREVIOUS EVENTS',
        // tabSocialMedia: 'SOCIAL MEDIA', // not used
    },
    socialButtons: {
        linkedinButton: 'Log in with Linkedin',
        loginTabLinkedinButton: 'with Linkedin', // to be removed
        loginLinkedinButton: 'Linkedin',
        facebookButton: 'Log in with Facebook',
        loginTabFacebookButton: 'with Facebook', // to be removed
        loginFacebookButton: 'Facebook',
        googleButton: 'Log in with Google',
        loginTabGoogleButton: 'with Google', // to be removed
        loginGoogleButton: 'Google',
        twitterButton: 'Log in with Twitter',
        loginTabTwitterButton: 'with Twitter', // to be removed
        loginTwitterButton: 'Twitter',
        socialButtonsLogInTitle: 'Join with your social media profile',
        socialButtonsSignUpTitle: 'Sign up with your social media profile',
    },
    login: {
        // orText: 'or', // not used
        inputEmail: 'Email',
        inputPassword: 'Password',
        rememberMeButton: 'Remember\n me',
        forgotPasswordButton: 'Forgot\n password?',
        loginButton: 'Log in',
        loginTitleLowerCase: 'log in',
        // noAccountText: "Don't have an account?", // not used
        // registerButton: 'Register here', // not used
        recoverPasswordDialogTitle: 'RECOVER PASSWORD',
        // recoverPasswordDialogInputEmail: 'Email', // not used
        // recoverPasswordDialogCloseButton: 'CLOSE', // not used
        recoverPasswordDialogRecoverButton: 'RECOVER',
        // errorNotRegistered: 'Not Registered', // not used
        // errorUnableToLogin: 'Unable to login', // not used
        errorIncorrectDates: 'Incorrect email or password',
        orLogInWithEmail: 'or login with email',
        logInAsParticipantSecondText: 'as participant',
        logInAsExhibitorSecondText: 'as exhibitor',
        logInAsPosterSecondText: 'as poster/study',
        errorAccountNotFound: 'We couldn’t find your Neural Network account',
        // errorAccountNotFoundSecond: 'Please sign up.', // not used
        loginTitle: 'Log in to the Neural Network', // new translation aifg
    },
    register: {
        // formTitle: 'Or create your onvent profile',
        inputFirstName: 'First name',
        inputLastName: 'Last name',
        inputEmail: 'Email',
        inputPassword: 'Password',
        inputConfirmPassword: 'Confirm password',
        // uploadImageText: 'Upload Profile Image (2MB):', // not used
        // editImageText: 'Edit Profile Image (2MB):', // not used
        gdprTextFirst: 'By proceeding, you agree to our',
        gdprTextTerms: 'Terms of Use',
        gdprTextSecond: 'and confirm you have read our',
        gdprTextCookies: 'Cookie',
        gdprTextThird: 'and', // not used
        gdprTextPrivacy: 'Privacy Policy',
        gdprTextAgree: 'Please agree to privacy policy',
        registerButton: 'Sign up',
        signUpTitleLowerCase: 'sign up',
        // errorAlreadyHaveAccount: 'Already registered.', // not used
        // formTitleTextFirst: 'Or create your', // not used
        // formTitleTextSecond: 'profile', // not used
        invitationCode: 'Invitation code',
        registerNotAllowed: 'Registration closed',
        logInTitleFirst: 'Join',
        logInTitleSecond: 'event',
        signUpTitleTextFirst: 'First time at', // to be removed
        signUpTitleText: 'First time at Neural Network?', // new translation aifg
        signUpTitleTextSecond: 'Neural Network', // to be removed
        orFillInTheForm: 'or fill in the form below',
        uploadProfilePicture: 'Upload profile picture',
        editProfilePicture: 'Edit profile picture',
        errorAlreadyHaveMOAccount: 'You already have an account on Neural Network',
        step1From2Text: '1/2 steps', // to be removed
        step2From2Text: '2/2 steps', // to be removed
        oneStep: '1', // new translation aifg
        twoStep: '2', // new translation aifg
        steps: 'steps', // new translation aifg
        metaTitleScholarRegistration: 'Poster/Study registration',
        loginTitleForm: 'Already have an account?', // new translation aifg
        unregisterInProgressError: 'Cannot register while account is still being deleted.', // new translation
    },
    participantRegistration: {
        metaTitle: 'Participant registration',
        choosePackageTitle: 'CHOOSE THE ACCESS PACKAGE',
        noPackageText: 'Please select a package',
        gdprTextFirst: 'By proceeding, you agree to our',
        gdprTextPrivacy: 'Event Privacy Policy',
        gdprTextSecond: 'and',
        gdprTextTerms: 'Event Terms and Conditions',
        gdprTextAgree: 'Please agree to privacy policy',
        registerButtonFree: 'Complete Registration',
        registerButtonPay: 'Proceed to payment',
    },
    exhibitorRegistration: {
        metaTitle: 'Exhibitor registration',
        choosePackageTitle: 'CHOOSE THE ACCESS PACKAGE',
        gdprTextFirst: 'By proceeding, you agree to our',
        gdprTextPrivacy: 'Event Privacy Policy',
        gdprTextSecond: 'and',
        gdprTextTerms: 'Event Terms and Conditions',
        gdprTextAgree: 'Please agree to privacy policy',
        registerButtonFree: 'Complete Registration',
        registerButtonPay: 'Proceed to payment',
    },
    stripePayment: {
        cardNumber: 'Card Number',
        expirationDate: 'Expiration Date (MM/YY)',
        cvc: 'CVC',
        disclaimerText:
            'This payment operation is encrypted end-to-end. Security & privacy are guaranteed by Stripe Services.',
        disclaimerTextLink: 'Click here to learn more.',
        cancelButton: 'Cancel',
        payButton: 'Pay',
    },
    recoverPassword: {
        // recoverTitle: 'Recover password', // not used
        // cancelButton: 'Close', // not used
        // recoverButton: 'Recover', // not used
        recoverTextSend: 'Password recovery email sent successfully.',
    },
    unsubscribe: {
        unsubscribeTitle: 'Unsubscribe?',
        unsubscribeHomepage: 'Go to homepage',
    },
    notAvailable: {
        title: 'The event is unavailable',
        description:
            'The event you are looking for is unavailable. It has either been closed by the organizer or disabled by an admin',
        // notAvailableTitle: 'Onvent is unavailable', // not used
        // notAvailableSubtitle:
        //     'The onvent you are looking for is not available. It has either been closed by the organizer or disabled by an admin', // not used
        // notAvailableTitleTextSecond: 'is unavailable', // not used
        // notAvailableSubtitleTextFirst: 'The', // not used
        // notAvailableSubtitleTextSecond: // not used
        // 'you are looking for is not available. It has either been closed by the organizer or disabled by an admin', // not used
        // closedTitle: 'closed now', // not used
        // closedSubtitleTextFirst: 'You have successfully registered for this', // not used
        // closedSubtitleTextSecond: 'is currently closed', // not used
        fullOverlayEvents: 'Check out your other events',
        fullOverlayNoEvents: 'No other events found',
    },
    notFound: {
        notFoundDescription: 'Oops... No event to be found here!',
        notFoundHomepage: 'Go to homepage',
    },
    eventMenu: {
        organizerButton: 'ORGANIZER',
        coOrganizerButton: 'MANAGE',
        exhibitorButton: 'EXHIBITOR',
        posterStudyButton: 'POSTER / STUDY',
        boothButton: 'BOOTH',
        boothsButton: 'BOOTHS',
        homeButton: 'HOME',
        contactsButton: 'CONTACTS',
        meetingsButton: 'MEETINGS',
        chatsButton: 'CHATS',
        programButton: 'PROGRAM',
        archiveButton: 'ARCHIVE',
        myAccountButton: 'MY ACCOUNT',
        accountButton: 'Account', // new translation aifg
        lobbyButton: 'LOBBY',
        roomsButton: 'ROOMS',
        connectionProblemTextFirst: 'New updates are available. Please click',
        connectionProblemButton: 'HERE',
        connectionProblemTextSecond: 'or refresh the page.',
        sessionProblemTextFirst: 'Your session has expired. Please click',
        sessionProblemButton: 'HERE',
        sessionProblemTextSecond: 'to login again.',
        // backButton: 'BACK', // not used
        // hubButton: 'HUB', // not used
        help: 'Help', // new translation aifg
    },
    myEventsDropdown: {
        title: 'MY EVENTS',
        // createButtonDesktop: 'Create event', // not used
        createButtonMobile: 'Create',
        myEventsList: 'My events list', // new translation aifg
        eventName: 'Event name', // new translation aifg
        eventDate: 'Event date', // new translation aifg
        open: 'Open', // new translation aifg
        yourCurrentEvent: 'Your current event', // new translation aifg
        noEvents: "We couldn't find any other events you registered for", // new translation aifg
    },
    myBoothsDropdown: {
        title: 'MY BOOTHS',
        emptyStudyTitle: 'Poster/Study booth',
    },
    contactsDropdown: {
        title: 'YOUR CONTACTS',
        requestsTitle: 'Contact Requests',
        rejectButton: 'Reject',
        acceptButton: 'Accept',
        pendingButton: 'Pending',
        addButton: 'Add',
        noContacts:
            "You don't have any contacts yet. Please visit the networking section to start building your list of contacts.",
    },
    meetingsDropdown: {
        meetingWith: 'Meeting with',
        pending: 'Pending',
        accepted: 'Accepted',
        canceled: 'Canceled',
        noMeetings: 'No meetings scheduled yet',
        finished: 'Finished',
        confirmed: 'Confirmed', // new translation for AIFG
    },
    programDropdown: {
        // timeZone: 'Time zone:', // not used
        with: 'with',
        noProgram: 'No program entries available for the time being.',
    },
    notificationsDropdown: {
        title: 'NOTIFICATIONS',
        contactRequestReceived: 'sent you a contact request. Check it out!',
        contactRequestAccepted: 'accepted your contact request. Start to chat!',
        meetingRequestReceived: 'sent you a meeting request. Check it out!',
        meetingRequestAccepted: 'accepted your meeting request. Check it out!',
        meetingRequestCanceled: 'canceled a meeting request. Check it out!',
        meetingStarting: 'A meeting will start soon. Check it out!',
        newBoothPost: 'has posted in your booth wall. Check it out!',
        noNotifications: "You don't have any notifications yet",
        seeAll: 'SEE ALL',
        hideAll: 'HIDE ALL',
        // agendaItemUpdated: 'Saved program item was updated by event organizer', // not used
        // agendaItemDelted: 'Saved program item was deleted by event organizer', // not used
        // newMeetingRequest: 'You have a new meeting request', // not used
        // meetingAccepted: 'Your meeting request was accepted', // not used
        // meetingCancelled: 'Your meeting was cancelled', // not used
        meetingWillStartTextFirst: 'A meeting with',
        meetingWillStartTextSecond: 'will start soon. Check it out!',
    },
    chatsDropdown: {
        title: 'CHATS',
        // newChatButton: 'New chat', // not used
        // newChatStart: 'Start a new chat', // not used
        newChatProvideText: 'Please provide a name for your chat. You will be able to edit the name afterwards:',
        noChats: 'Create at least one chat to talk to event participants',
        // member: 'member', // not used
        members: 'members',
        noOpenedChatText: 'Click on a chat to continue the conversation.',
        noMessagesInChatTextFirst: 'Break the ice.',
        noMessagesInChatTextSecond: 'Be the one to start this conversation.',
        newChatNameInput: 'Chat name (optional)',
        newChatAddMembers: 'Add contacts and other participants to this chat:',
        editChatAddMembers: 'Please search users from this event you want to add to group chat.',
        newChatSearchInput: 'Search users',
        newChatNoUsersMessage: 'No users found',
        // newChatAddUserButton: 'ADD', // not used
        newChatRemoveUserButton: 'REMOVE',
        newChatCancelButton: 'CLOSE',
        newChatCreateButton: 'CREATE',
        membersLeaveButton: 'LEAVE',
        membersUpdateButton: 'UPDATE',
        membersChatButton: 'CHAT',
        chatSettingsButton: 'SETTINGS',
        newMessageInput: 'Type message here',
        newVideoCallTooltipText: 'Click here to start a video call',
        // fullscreenButton: 'Open full screen', // not used
        // minimizeButton: 'Minimize screen', // not used
        leaveVideoMeetingTitle: 'Leave video meeting',
        leaveVideoMeetingDescription: 'You are about to leave the video meeting. Are you sure you want to proceed?',
    },
    myAccountDropdown: {
        title: 'MY ACCOUNT',
        viewProfileButton: 'View Profile',
        logoutButton: 'Log out',
    },
    myAccountPage: {
        // platformProfileSectionTitle: 'Neural Network profile', // not used
        changePasswordButton: 'CHANGE PASSWORD',
        deleteAccountButton: 'DELETE MY ACCOUNT',
        deleteAccount: 'Delete account', // new translation aifg
        platformLanguage: 'Platform language:',
        // reviewPlatformGdprText: 'Review our Onvent conditions:', // not used
        privacyPolicyLink: 'Privacy notice / policy',
        cookiesLink: 'Cookies',
        termsAndConditionsLink: 'Terms and Conditions',
        eventProfileSectionTitle: 'Event profile',
        yourInformation: 'Your information', // new translation aifg
        eventPackage: 'Event Package',
        // reviewEventGdprText: 'Review our Event conditions:', // not used
        unregisterFromEventButton: 'UNREGISTER FROM EVENT',
        unregisterCurrentEventButton: 'Unregister from current event',
        unregisterCurrentEventConfirmation: 'Please confirm that you want to unregister from event',
        enableParticipantProfile: 'Enable Participant Profile',
        editUserDialogTitle: 'Edit User Details',
        editExhibitorDialogTitle: 'Edit Company Contact Person Details',
        editScholarDialogTitle: 'Edit Poster / Study Contact Person Details',
        editParticipantDialogTitle: 'Edit Participant Details',
        firstName: 'First Name',
        lastName: 'Last Name',
        uploadImage: 'Upload image',
        removeImage: 'Remove image',
        deleteTitle: 'Delete my account',
        deleteConfirm: 'Confirm delete my account',
        deleteError:
            "You can't delete your account. Please delete all the events created to be able to delete  your account.",
        deleteSuccess: 'Your account has been deleted.',
        // email: 'e-mail', // not used
        reviewEventPrivacyPolicy: 'Review our Event Privacy Policy:',
        title: 'Title',
        company: 'Company',
        phone: 'Phone',
        platformProfileSectionTitleTextSecond: 'profile',
        // reviewPlatformGdprTextFirst: 'Review our', // not used
        // reviewPlatformGdprTextSecond: 'conditions', // not used
        createParticipantProfile: 'Create Participant Profile', // new translation
        reviewPlatformConditions: 'Review our platform conditions',
        reviewEventConditions: 'Review our event conditions',
        accountDetails: 'Account details', // new translation aifg
        accountTab: 'Account', // new translation aifg
        termsAndConditionsTab: 'Terms & conditions', // new translation aifg
        matchingForm: 'Matching form', // new translation aifg
        manageNotifications: 'Manage Notifications',
    },
    changePassword: {
        title: 'Change password',
        oldPassword: 'Old Password',
        oldPasswordError: 'Old password does not match!',
        inputPassword: 'New Password',
        inputConfirmPassword: 'Confirm Password',
        // changePasswordButton: 'Change password', // not used
    },
    roomsDropdown: {
        title: 'ROOMS MENU', // not used
        auditoriumButton: 'AUDITORIUM',
        archiveButton: 'ARCHIVE',
        onDemandButton: 'ON-DEMAND',
        stages: 'Stages', // new translation aifg
    },
    sideMenu: {
        liveWall: 'LIVE WALL',
        networking: 'NETWORKING',
        matchMaking: 'Matchmaking', // new translation aifg
        audience: 'AUDIENCE',
        info: 'INFO',
        videoWall: 'VIDEO WALL',
        booth: 'BOOTH',
        boothWall: 'BOOTH WALL',
        // resources: 'RESOURCES', // not used
        networkingTooltip: "You don't have access to all the networking functionalities.",
        // polls: 'POLLS', // not used
        attendees: 'Attendees',
    },
    wall: {
        sortBy: 'Sort by:',
        popularity: 'POPULARITY',
        time: 'TIME',
        emptyWallText: 'Be the first to add a post here.\nJust tell what’s on your mind or briefly introduce yourself',
        newPostInput: "What's on your mind?",
        newCommentInput: 'Comment',
        resourcesVideoDescription: 'VIDEO DESCRIPTION',
        resourcesLinks: 'Links',
        resourcesFiles: 'Files',
        // whatIsOnYourMind: "What's on your mind?", // not used
        pinTitle: 'Pin',
        unPinTitle: 'Unpin',
        deleteDialogTitle: 'Delete post?',
        pinDialogDescription:
            'There is already a pinned post. Only one post can be pinned at a time. By proceeding, the previous post will be automatically unpinned.',
        edited: 'Edited',
        escapeToCancel: 'escape to cancel',
        enterToSave: 'enter to save',
        editingState: 'Editing message',
        bannerModeration: 'Posts and comments on this wall are being moderated to ensure quality content',
        pendingApproval: 'Pending approval',
        approvePost: 'Approve post',
        approvePostComment: 'Approve comment',
        commentsPendingApproval: 'Comments pending approval',
    },
    networking: {
        regenerateMatches: 'regenerate matches',
        searchInput: 'Search by user',
        // at: 'at', // not used
        emptyNetworkingSearchResults: 'The person you are looking for has not registered for this online event',
        emptyAuditoriumNetworkingSearchResults: 'The person you are looking for has not attended yet this presentation',
        alphabetical: 'abc',
        online: 'online',
        matching: 'matches',
        matchingAlgo: 'Matching algorithm in action. Give us a minute while we are curating your best matches!',
        searchFilters: 'Search Filters',
        applyFilterTooltip: 'In order to apply filters select at least 1 option',
        chat: 'Chat',
        acceptHighMatches: 'This user is accepting chat messages only from High Matches', // new translation for aifg
        acceptContacts: 'This user is accepting chat messages only from Contacts', // new translation aifg
        acceptContactsOrHighMatches: 'This user is accepting chat messages only from Contacts or High Matches', // new translation aifg
        denyEverybody: 'This user is not accepting chat messages', // new translation aifg
    },
    closedAuditoriumDialog: {
        title: 'Auditorium Information',
        // content: 'Access to the Auditorium has been restricted.', // not used
        contentPackage: "Your access package doesn't have access to any auditoriums.",
        // button: 'OK', // not used
    },
    closedShowfloorDialog: {
        title: 'Exhibit Hall Information',
        // content: 'Access to the Exhibit Hall has been restricted.',  // not used
        contentPackage: "Your access package doesn't have access to any Exhibit Halls.",
        button: 'OK',
    },
    auditorium: {
        willStart: 'Next session will start in...',
        noAccess: "Your access package doesn't have access to this auditorium.",
        noVideos: 'No videos are scheduled to play in this Auditorium.',
        stageTab: 'STAGE',
        // stagesButton: 'STAGES', // not used
        videoWallButton: 'VIDEO WALL',
        resourcesButton: 'RESOURCES',
        slotEndsTextFirst: 'This program slot ends in',
        // slotEndsTextSecond: 'The presentation will be available on demand at the end of the program.', // not used
        slotEndsTextSecondUpdated: 'The presentation will be available on-demand.',
        slotTitleWith: 'with',
        // allRooms: 'All rooms', // not used
        allAuditoriums: 'All Auditoriums',
        onDemandButton: 'ON-DEMAND',
        hide: 'Hide',
        leavePopupTitle: 'Leave stage?', // new translation
        leavePopupDescription: 'By leaving the auditorium, you will be removed from the session',
        leaveSession: 'Leave stage', // new translation
        cancelDescription: 'By leaving, you will no longer be a speaker. Do you want to proceed?', // new translation
        settings: 'Settings', // new translation
        minimize: 'Minimize', // new translation
        microphone: 'Microphone', // new translation
        camera: 'Camera', // new translation
        liveButton: 'Live', // new translation aifg
        upcomingButton: 'Upcoming', // new translation aifg
        pastEvent: 'Past event', // new translation aifg
        videoOnDemand: 'video on-demand', // new translation aifg
        videosOnDemand: 'videos on-demand', // new translation aifg
        allStages: 'All stages', // new translation aifg
    },
    auditoriumArchive: {
        title: 'Auditorium Archive',
        titleEvent: 'Event Archive',
        with: 'with',
        // stage: 'STAGE', // not used
        // videoWall: 'VIDEO WALL', // not used
        // resources: 'RESOURCES', // not used
        // tooltipMinimize: 'Minimize', // not used
        // tooltipFullscreen: 'Fullscreen', // not used
        // tooltipPause: 'Pause', // not used
        // tooltipPlay: 'Play', // not used
        // tooltipUnmute: 'Unmute', // not used
        // tooltipMute: 'Mute', // not used
        noVideosAvailable: 'All sessions finished',
    },
    videoPlayer: {
        videoNotPlayingError: 'Having trouble with the Video Stream? Please Reload.',
        videoEndedTextFirst: 'Video has ended. Thank you for watching!',
        videoEndedTextSecond: 'The video wall is open for Q&A.',
        videoEndedPleaseRefresh:
            'Please refresh your browser to continue viewing the session stream and if the session is finished please feel free to network with other participants on the video wall', // new translation
        tooltipPlay: 'Play',
        tooltipPause: 'Pause',
        tooltipMinimize: 'Minimize',
        tooltipFullscreen: 'Fullscreen',
        tooltipMute: 'Mute',
        tooltipUnmute: 'Unmute',
        tooltipTheaterModeStart: 'Theatre mode', // new translation
        tooltipTheaterModeEnd: 'Default view', // new translation
        videoStreamNotStarted: 'Going live soon. Stay tuned!',
    },
    showfloor: {
        allExhibitorsButton: 'ALL EXHIBITORS',
        featuredExhibitorsText: 'FEATURED EXHIBITORS',
        boothLinksDialogTitle: 'BOOTH LINKS',
        // boothLinksDialogOtherLinksTitle: 'Other:', // not used
        boothFilesDialogTitle: 'BOOTH FILES',
        boothVideoDialogTitle: 'VIDEO',
        mobileTabChat: 'CHAT',
        mobileTabPoster: 'POSTER',
        mobileTabFiles: 'FILES',
        mobileTabLinks: 'LINKS',
        mobileTabVideo: 'VIDEO',
        noExhibitorsTextFirst: 'THE EXHIBITORS ARE WORKING ON THEIR BOOTHS',
        noPresentersTextFirst: 'The presenters are working on their booths',
        noExhibitorsTextSecond: 'STAY TUNED',
        // filterBy: 'Filter by', // not used
        // searchByTheUser: 'Search by the user', // not used
        showShowfloorsButton: 'ALL EXHIBIT HALLS',
        showShowfloorsPosterButton: 'ALL POSTERS/STUDIES',
        showShowfloorsExhibitorButton: 'ALL EXHIBITORS',
        searchExhibitors: 'Search exhibitors',
        searchPosters: "Search by title or author's name",
        searchExhibitorsMultiple: 'Search exhibitors in this exhibit hall',
        searchPostersMultiple: "Search by title or author's name in this exhibit hall",
        showfloorsTitle: 'Exhibit Halls',
        showfloorPlural: 'Showfloors',
        collapseList: 'Collapse list',
        seeFullList: 'See full list',
        emptyExhibitorsResults: "The searched booth is not present.\n But we'll invite them next time",
        emptyPostersResults: "The searched poster/study is not present.\n But we'll invite them next time",
        posters: 'posters',
        allPostersStudiesTitle: 'All Posters/Studies',
        authors: 'authors',
        titleNotAvailable: 'Title not available',
        abstractNotAvailable: 'Abstract not available',
        authorsNotAvailable: 'Authors not available',
        openPosterFile: 'Open poster file',
        openExhibitorFile: 'Open PDF file', //new translation
        fileIsNotAvailable: 'File is not available',
        posterBooth: {
            study: 'Poster/Study',
            authors: 'Authors',
            highlights: 'Abstract',
            scrollInfoText: 'SCROLL DOWN TO READ THE FULL ABSTRACT',
        },
        exhibitBooth: 'Exhibitor Booth', // new translation
        exhibits: 'Exhibits', // new translation aifg
        totalStands: 'Total stands', // new translation aifg
    },
    eventArchive: {
        title: 'Video Archive',
        searchText: 'Tap to search',
        // noVideosText: 'No videos found in this event.', // not used
        noArchivedEvents: 'There are currently no archived events.',
    },
    exhibitorDashboard: {
        event: 'Event',
        title: 'Manage Your Booth',
        visitorsButton: 'Booth Visitors',
        // goToBoothButton: 'Take me to booth', // not used
        goToBoothPreview: 'Booth Preview',
        previewButton: 'Preview',
        // goToBoothButtonDisabled: 'Your booth is not available for preview yet.', // not used
        tabStyle: 'Booth Style',
        tabLogo: 'Logo',
        tabStudyDetails: 'Poster / Study',
        tabFiles: 'Files',
        tabStudyFile: 'File',
        tabLinks: 'Links',
        tabAvatars: 'Avatars',
        tabStudyAuthors: 'Authors',
        tabVideo: 'Video',
        tabPreviewFile: 'Preview File', //new translation
        boothStyleTab: {
            optionOne: 'Option 1 - Scandinavian',
            optionOne1: 'Option 1 - Silver', // new translation aifg
            optionTwo: 'Option 2 - Modern',
            optionTwo2: 'Option 2 - Gold', // new translation aifg
            optionThree: 'Option 3 - Dynamic',
            optionThree3: 'Option 3 - Diamond', // new translation aifg
            optionFour: 'Option 4 - Custom',
            optionCustom: 'Custom booth', // new translation aifg
            optionScholarOne: 'Option 1 - Einstein',
            optionScholarOne1: 'Poster/Study Booth', // new translation aifg
            optionScholarTwo: 'Option 2 - Curie',
            optionScholarThree: 'Option 3 - da Vinci',
        },
        logoTab: {
            // title: 'Logo', // not used
            fileUploadButton: 'Click here to upload',
            noFile: 'No file chosen',
        },
        posterStudyTab: {
            // title: 'Poster / Study:', // not used
            posterStudyTitle: 'Title',
            // noAuthorsAdded: 'No authors added', // not used
            studyTitleInputLabel: 'Poster / Study Title',
            highlightAbstractTitle: 'Highlight/Abstract',
            studyHighlightsInputLabel: 'Research highlights / abstract',
            highlightAbstractError: 'You have reached the maximum limit of characters allowed! (500 characters)',
            authorNameLabel: 'Author name',
            authorAffiliationLabel: 'Affiliation',
            // authorsTitle: 'Authors', // not used
        },
        filesTab: {
            title: 'Documents:',
            fileNameInput: 'Document name (visible to all participants)',
            chooseFileButton: 'Choose file',
            noDocAdded: 'No documents added',
            fileError: 'Please add a document name',
        },
        fileStudyTab: {
            title: 'Upload pdf file',
            description: 'Upload poster: paper or presentation to be available in your booth (20 Mb. file limit)',
            exhibitorDescription: 'Upload a PDF file to be available in your booth (20 Mb. file limit)', //new translation
            uploadPdfInputLabel: 'Upload PDF file',
            uploadNewPdfInputLabel: 'Upload new PDF file',
            uploadedFile: 'Uploaded',
            dragContainerTitle: 'Simply drag the file here',
            dragContainerNewTitle: 'Simply drag the new file here',
        },
        linksTab: {
            social: {
                title: 'Social media links',
                subtitle: '(visible to all participants)',
                facebookInput: 'Insert your Facebook link',
                linkedinInput: 'Insert your Linkedin link',
                twitterInput: 'Insert your Twitter link',
                googleScholarInput: 'Insert your Google Scholar link',
                researchGateInput: 'Insert your Research Gate link',
            },
            other: {
                // title: 'Other links', // not used
                // subtitle: '(Homepage or other links)', // not used
                linkInput: 'Link URL',
                linkLabel: 'Link label',
            },
        },
        linksStudyTab: {
            authorProfiles: 'Author profiles',
            additionalLinks: 'Additional links',
        },
        avatarsTab: {
            mainRepresentatives: {
                title: 'Booth Representatives',
                //subtitle: '(only one representative mandatory)', // not used
                leftRepresentative: 'Left representative',
                rightRepresentative: 'Right representative',
                addDialog: {
                    title: 'Add Avatar',
                    titleScholar: 'Add author',
                    editAvatarsTitle: 'Edit avatar',
                    editAuthorTitle: 'Edit author',
                    contentLine1: 'Choose an avatar from the below gallery',
                    contentLine2: 'Upload your own personalized avatar.',
                    contentLine3: 'Need help? Get in touch with',
                    // contentSecond: 'Need design help? Get in touch:', // not used
                    // female: 'Female', // not used
                    // male: 'Male', // not used
                    // uploadSectionTitle: 'Upload your personal avatar', // not used
                    //uploadButton: 'Choose file:',
                    noFile: 'Choose file:', // not used
                    assignButtonText: 'Assign',
                    assignText: 'Assign an event participant to be associated with the above representative.',
                    assignErrorBooth: 'User is already added as Booth representative',
                    assignErrorAdditional: 'User is already added as Additional representative',
                    input: 'Search by name',
                    avatarLabels: {
                        // femaleA: 'female A', // not used
                        // femaleB: 'female B', // not used
                        // femaleC: 'female C', // not used
                        // maleA: 'male A', // not used
                        // maleB: 'male B', // not used
                        // maleC: 'male C', // not used
                    },
                },
            },
            additionalRepresentatives: {
                title: 'Additional representatives',
                subtitle: '(optional, will be displayed with their profile picture)',
                addDialog: {
                    title: 'Choose Additional Representative',
                    titleScholar: 'Choose Co-Author',
                    assignText: 'Please start searching for an event participant to add him/her as a representative.',
                    assignError: 'User is already added as Booth representative!',
                    // chooseAvatarTextFirst:
                    // 'Choose an avatar from the below gallery, or upload your own personalized avatar.', // not used
                    // chooseAvatarTextSecond: 'Need design help? Get in touch:', // not used
                    // uploadText: 'Upload your personal avatar', // not used
                    // chooseFileText: 'Choose file:', // not used
                    // noFile: 'No file chosen', // not used
                },
            },
            author: {
                title: 'Presenting Author',
                subtitle: 'Add only an author that registered to the event. You can add 1 author.',
                mainRepresentative: 'Main representative',
            },
            coAuthors: {
                title: 'Co-Authors',
                singularTitle: 'Co-Author',
                subtitle: 'Add only co-authors that registered to the event. You can add up to 4 co-authors.',
            },
        },
        videoTab: {
            video: {
                title: 'Booth Video',
                subtitle: '(Youtube video link only)',
                input: 'Enter Youtube URL',
            },
            image: {
                // title: 'Image', // not used
                subtitle:
                    "(Image is displayed in your Booth in case you haven't added a video link in the above section)",
                // uploadButton: 'Click here to upload', // not used
            },
        },
    },
    boothDashboard: {
        event: 'Event',
        title: 'Your Booth',
        manage: 'Manage',
        // goToBoothButton: 'Take me to Booth', // not used
        // previewNotAvailable: 'Your booth is not available for preview yet.', // not used
        statistics: 'Statistics',
        // usersNow: 'Attendants now', // not used
        // usersAllTime: 'Attendants all time', // not used
        // linkClicks: 'Link clicks', // not used
        // documentViews: 'Document Views', // not used
        // videoViews: 'Video Presentation seen', // not used
        // visitorsSectionTitle: 'Booth Visitors', // not used
        // noVisitors: "There aren't any visitors in your booth yet.", // not used
        // visitorsSearch: 'Search users', // not used
        // actionType: 'ACTION TYPE', // not used
        // showUsers: 'SHOW USERS', // not used
        // ofUsers: '# OF USERS', // not used
    },
    polls: {
        polls: 'POLLS',
        // poll: 'POLL', // not used
        noPolls: 'No polls were added yet.',
        pollProgress: 'Poll in progress',
        pollClosed: 'Poll closed',
        pollVoted: 'participants voted',
    },
    onDemandVideos: {
        sectionTitle: 'ON-DEMAND',
        noOnDemandVideos: 'No on demand videos were added yet.',
    },
    meetings: {
        meeting: 'MEETING',
        // meetings: 'MEETINGS', // not used
        createDialog: {
            createTitle: 'Request meeting with',
            inputDate: 'Date',
            inputStart: 'Starts',
            inputEnd: 'Ends',
            inputTimezone: 'Timezone',
            inputNote: 'Note: Only one meeting can be scheduled at a time with the same person.',
            meetingError: 'Invalid meeting date or time. Please recheck!',
            buttonDisabled: 'You can schedule a meeting after you start the conversation!',
        },
        banner: {
            pending: 'Meeting request',
            accepted: 'Meeting scheduled for',
            progress: 'Meeting in progress',
            cancelTitle: 'Cancel meeting?',
            cancelDescription: 'Are you sure you want to cancel this meeting?',
            startCall: 'START CALL',
            joinCall: 'JOIN',
            pendingOwner: 'Pending meeting request for',
            videoCallStarted: 'Video meeting started',
            videoCallStartedInfo: 'Video meetings work best for up to 10 people',
        },
        // newMeeting: 'NEW MEETING', // not used
        // requestMeeting: 'Request meeting with', // not used
        // startDate: 'Start date', // not used
        // starts: 'Starts', // not used
        // ends: 'Ends', // not used
        // addMessageHere: 'Add your message here', // not used
        // note:  // not used
        // 'Note: you can only request one meeting with a particular person. After meeting is finished (or cancelled), you can reschedule the next meeting again',
        // create: 'CREATE', // not used
        // requested: 'Requested', // not used
        // confirmed: 'Confirmed', // not used
        title: 'Your meetings',
        meetingsInfo:
            'Meetings can be scheduled through the Chat feature. Please start a chat with the user you want to schedule a meeting with.',
    },
    landingPage: {
        // animatedSectionButton: 'START JOURNEY', // not used
        // topSectionHeaderPrimary: 'Next generation', // not used
        // topSectionHeaderSecondary: 'online events', // not used
        // carouselPresentationText: 'Check out', // not used
        // carouselPresentationTextAccent: 'NEW Neural Network', // not used
        // videoPresentationTextPrimary: 'Neural Network - online events that your', // not used
        // videoPresentationTextAccent: 'participants will love.', // not used
        // videoPresentationTextSecondary: 'Here is why:', // not used
        // testimonialsTextPrimary: 'Online events since 2015 -', // not used
        // testimonialsTextAccent: "it's all about people", // not used
        // testimonialsTextSecondary: '...', // not used
        // cardsTextPrimary: 'Neural Network brings', // not used
        // cardsTextAccent: 'the WOW', // not used
        // cardsTextSecondary: 'to...', // not used
        // contactTextPrimary: 'Interested in organizing an online event?', // not used
        // contactTextAccent: 'Get in touch', // not used
        // contactTextSecondary: '', // not used
        // contactInputName: 'Name', // not used
        // contactInputEmail: 'Email', // not used
        // contactInputPhone: 'Mobile phone number (optional)', // not used
        // contactInputMessage: 'Message', // not used
        // contactButton: 'SEND', // not used
        // carouselPresentationTextAccentTextFirst: 'NEW', // not used
        // videoPresentationTextPrimaryTextSecond: 'online events that your', // not used
        // cardsTextPrimaryTextSecond: 'brings', // not used
    },
    loginPlatform: {
        // title: 'LOG IN', // not used
        // description: // not used
        // 'After log in you will get redirected to the last onvent you registered to. You can easily switch between onvents using the My Events option in your menu.',
        // descriptionTextFirst: 'After log in you will get redirected to the last', // not used
        // descriptionTextSecond: 'you registered to. You can easily switch between', // not used
        // descriptionTextThird: 'using the My Events option in your menu.', // not used
    },
    socialMediaShare: {
        event: 'event',
        shareLabel: 'Share',
        dialogTitleGeneral: 'Share with your network',
        dialogTitlePost: 'Share your post',
        linkedinLabel: 'LinkedIn',
        facebookLabel: 'Facebook',
        twitterLabel: 'Twitter',
        emailLabel: 'Email',
        clipboardLabel: 'Clipboard',
        clipboardSnackbarMessage: 'Link copied to clipboard',
        linkedinButtonTitle: 'Share on LinkedIn',
        facebookButtonTitle: 'Share on Facebook',
        twitterButtonTitle: 'Share on Twitter',
        emailButtonTitle: 'Share by Email',
        clipboardButtonTitle: 'Copy to Clipboard',
        imAttending: "I'm attending the",
        interestedClick: 'Interested in attending? Click here:',
    },
    skipped: {
        // startVideoChat: "I've set up a Video Meeting. Please click here to join.", // not used
    },
    hubDropdownTitle: 'HUB MENU', // not used
    marketplace: {
        title: {
            default: 'Marketplace', // not used
            uppercased: 'MARKETPLACE', // not used
        },
        label: 'PRODUCTS', // not used
        addNew: 'ADD NEW', // not used
        addNewProduct: 'Add new product', // not used
        editProduct: 'Edit product', // not used
        noProducts: 'No products yet', // not used
        publish: 'PUBLISH', // not used
        productTitle: 'Product title', // not used
        productDescription: 'Product description', // not used
        productLink: 'Product link', // not used
        productImage: 'PRODUCT IMAGE', // not used
        // not used
        uploadHint:
            'Upload an image that will be displayed as the product preview. Suggested 600*600 px resolution for better image quality',
        descriptionHint: 'Description is limited up to 300 characters', // not used
        uploadImage: 'UPLOAD IMAGE TO INCREASE THE PRODUCT’S VALUE', // not used
        uploaded: 'Uploaded', // not used
    },
    personalAgenda: {
        // agenda: 'AGENDA', // not used
        // myProgram: 'MY PROGRAM', // not used
        // myMeetings: 'MY MEETINGS', // not used
        addNewTask: {
            // default: 'Add new task', // not used
            // uppercased: 'ADD NEW TASK', // not used
        },
        // noProgram: 'No program entries available for the time being', // not used
        // noMeetings: 'No meetings available for the time being', // not used
        // title: 'Title', // not used
        // startDate: 'Start date', // not used
        // starts: 'Starts', // not used
        // ends: 'Ends', // not used
        // create: 'CREATE', // not used
        // schedule: 'SCHEDULE', // not used
        // personalAgenda: 'PERSONAL AGENDA', // not used
        // timetableMenu: 'TIMETABLE MENU', // not used
        // gmt: 'GMT', // not used
    },
    program: {
        // toAgenda: 'TO AGENDA', // not used
        // attending: 'ATTENDING', // not used
        smartListTitle: 'The most interesting sessions for you', // new aifg translation
        sponsored: 'Sponsored', // new translation aifg
        added: 'Added', // new translation aifg
        addToCalendar: 'Add to calendar', // new translation aifg
        scheduledSessions: 'Scheduled sessions', // new translation aifg
        showPastSessions: 'Show past sessions', // new translation aifg
        pastSessions: 'Past sessions', // new translation aifg
        emptySessions: "We couldn't find any sessions you might be interested in", // new translation aifg
        emptyAgenda: 'Your Calendar is currently empty', // new translation aifg
        removeItemFromAgenda: 'Remove item from Calendar', // new translation aifg
        removeItemDescription: 'Are you sure you would like to remove this item from your Calendar?', // new translation aifg
        smartTooltip: 'Remind yourself to attend this session: add it to your calendar', // new translation aifg
        searchSessions: 'Search sessions', // new translation aifg
        search: 'Search', // new translation aifg
        searchWrapperTitle: 'Search through all sessions', // new translation aifg
        emptyProgramme: "We couldn't find any upcoming sessions. Please check back later", // new translation aifg
        emptySearch: 'No results matching your request. Try another combination', // new translation aifg
        reset: 'Reset', // new translation aifg
    },
    businessCard: {
        businessCard: {
            // default: 'Business card', // not used
            // uppercased: 'BUSINESS CARD', // not used
        },
        // editText: 'EDIT TEXT', // not used
        // colors: 'COLORS', // not used
        // branding: 'BRANDING', // not used
        // cardPreview: 'CARD PREVIEW', // not used
        // onceSaved: // not used
        //     'Once saved, Business Card will always be connected to your profile. It’ll let you get access to Networking, Chat and another important functionality. The card will be deleted automatically when you delete your profile',
        // required: 'required', // not used
        // designation: 'Designation', // not used
        // Mobile: 'Mobile', // not used
        // Phone: 'Phone', // not used
        // Website: 'Website', // not used
        // companyName: 'Company name', // not used
        // honoric: 'Add your preferrable honorific such as Mr, Mrs, Ser, Sheikh, etc', // not used
        // fillUpData: 'FILL UP DATA FROM MY ACCOUNT', // not used
        // primaryColor: 'PRIMARY COLOR', // not used
        // primaryColorDescription: 'This color is the accent color. Use your brand color here', // not used
        // backgroundColor: 'BACKGROUND COLOR', // not used
        // backgroundColorDescription: // not used
        //     'This color is the additional color. Add it as a background. By default, it can be white or black',
        // backgroundTextColor: 'BACKGROUND TEXT & ICONS COLOR', // not used
        // backgroundTextColorDescription: // not used
        //     'This color for the phones, email and website details, and it have to be contrast to Background color. Go to Card preview to be sure the card is readable and has enough contrast level',
        // companyLogo: 'COMPANY LOGO', // not used
        // uploadMessage: // not used
        //     'Upload an image that will be displayed as the company logo. Suggested 320*200 px resolution for better image quality. Use logo with transperent background (.png file format is preferrable)',
        // uploadImage: 'UPLOAD IMAGE', // not used
        // request: 'REQUEST', // not used
        // open: 'OPEN', // not used
        // pending: 'PENDING', // not used
    },
    businessWallet: {
        // short: 'B WALLET', // not used
        // full: 'BUSINESS WALLET', // not used
        // myContacts: 'MY CONTACTS', // not used
        // requests: 'REQUESTS', // not used
        // pendings: 'PENDINGS', // not used
        // searchByName: 'Search by name', // not used
        // chat: 'Chat', // not used
        // openCard: 'OPEN CARD', // not used
        // empty: 'No one here yet', // not used
        // cancelRequest: 'CANCEL REQUEST', // not used
        // approve: 'APPROVE', // not used
        // reject: 'REJECT', // not used
        // myNotes: 'MY NOTES', // not used
        // addNewNote: 'Add new note', // not used
        // delete: 'DELETE', // not used
        // saveThoughts: 'Save your thoughts organized at one single space', // not used
    },
    videoConferences: {
        // message: "I've set up a Video Meeting room. Please click here to join it", // not used
        // clickToStart: 'Click here to start a video call', // not used
        fullScreen: 'OPEN FULL SCREEN',
        minimizeScreen: 'MINIMIZE SCREEN',
        startedVideoChat: 'started a video call',
        timeAgo: 'ago',
        videoCallStarted: 'Video Call started...',
    },
    sessions: {
        // sessionList: 'SESSIONS LIST',  // not used
        createNewSession: {
            // uppercased: 'CREATE NEW SESSION',  // not used
            // default: 'Create new session',  // not used
        },
        sessionName: {
            // uppercased: 'SESSION NAME',  // not used
            // default: 'Session name',  // not used
        },
        // sessionLink: 'SESSION LINK',  // not used
        // sessionType: 'SESSION TYPE',  // not used
        // manage: 'MANAGE',  // not used
        edit: 'EDIT',
        // delete: 'DELETE',  // not used
        live: {
            // uppercased: 'LIVE',  // not used
            // lowercased: 'live',  // not used
            // default: 'Live',  // not used
        },
        recorded: {
            // uppercased: 'RECORDED',  // not used
            // lowercased: 'recorded',  // not used
            // default: 'Recorded',  // not used
        },
        // editSession: 'Edit session', // not used
        // uploadVideo: 'UPLOAD VIDEO', // not used
        // create: 'CREATE', // not used
        // discard: 'DISCARD', // not used
        speakers: 'SPEAKERS',
        speakerSingular: 'speaker', // new translation
        moderators: 'MODERATORS',
        moderatorSingular: 'moderator', // new translation
        // speakerName: 'SPEAKER NAME', // not used
        // moderatorName: 'MODERATOR NAME', // not used
        // kick: 'KICK', // not used
        // screenSharing: 'SCREEN SHARING', // not used
        // audio: 'AUDIO', // not used
        video: 'VIDEO',
        startStream: 'START STREAM',
        stopStream: 'STOP STREAM',
        // startRecording: 'START RECORDING', // not used
        // endRecording: 'END RECORDING', // not used
        sessionChat: 'SESSION CHAT',
        // typeMessageHere: 'Type message here', // not used
        // sessionLinks: 'SESSION LINKS', // not used
        speakerUrl: 'Speaker URL',
        moderatorUrl: 'Moderator URL',
        copyUrl: 'copy URL',
        // organizer: 'ORGANIZER', // not used
        // moderator: 'MODERATOR', // not used
        // speaker: 'SPEAKER', // not used
        // joinAsSpeaker: 'Join as a speaker', // not used
        // invitedAsSpeaker: // not used
        //     'You’ve been invited as a speaker to AIM Sessions demo. Input your name (it will be visible to session’s participants)', // not used
        // yourName: 'Your name', // not used
        // enter: 'ENTER', // not used
        // waitForApproval: 'Please wait for approva', // not used
        // deny: 'DENY', // not used
        // admit: 'ADMIT', // not used
        // joinAsModeraotr: 'Join as a moderator', // not used
        // invitedAsModerator: // not used
        //     'You’ve been invited as a moderator to AIM Sessions demo. Input your name (it will be visible to session’s participants)', // not used
        // declined: 'Your join request has been declined!', // not used
        noSpeakersJoined: 'No Speakers joined yet', // new translation
        allFilter: 'all', // new translation
        waitingRoom: 'Waiting room', // new translation
        isReady: 'Is Ready', // new translation
        invitationAccepted: 'Accepted the invitation', // new translation
        requestPending: 'Request pending', // new translation
        gettingReady: 'Getting ready', // new translation
        inviteToSpeak: 'Invite to speak', // new translation
        fromAuditorium: 'from Auditorium', // new translation
        mic: 'Mic', // new translation
        screen: 'Screen', // new translation
        rec: 'rec', // new translation
        sessionHasEnded: 'The session has ended', // new translation
        sessionLinks: 'SESSION LINKS', // new translation
        sessionSettings: 'Settings', // new translation
        noAvailableChat: 'No chat available. To see the chat please allocate this session to a timeslot.', // new translation
        startLiveSessionStreamTitle: 'Start Live Session Stream', // new translation
        startLiveSessionDescription: 'The Recording of the session will automatically start when you start the stream', // new translation
        stopLiveSessionStreamTitle: 'Stop Live Session Stream', // new translation
        stopLiveSessionStreamDescription:
            "By stopping the Live Session Stream, the recording will also stop automatically. This Live Session Stream can't be started again.", // new translation
        thumbnailImage: 'Thumbnail Image', // new translation
        uploadThumbnail: 'Upload Thumbnail', // new translation
        clickToUpload: 'Click here to upload', // new translation
        requestMic: 'REQUEST MICROPHONE', // new translation
        acceptInvitationTitle: "You've got the invitation", // new translation
        acceptInvitationSubTitle: 'Moderator has invited you to be a speaker. What do you say?', // new translation
        acceptInvitationButton: 'Accept Invitation', // new translation
        requestPendingTitle: 'Request is pending...', // new translation
        requestPendingSubTitle: 'Please wait until the moderator will let you enter to the stream', // new translation
        cancelRequest: 'Cancel request', // new translation
        approvedTitle: 'Please check your microphone and video and click I’m Ready.', // new translation
        approvedSubTitle:
            'This session is being recorded. By entering this session you agree on the processing of your personal data.', // new translation
        connectedToTheBackstage: 'You are now streaming live and are connected to the backstage.', // new translation
        removedFromTheSession: 'You have been kicked from the session, please contact a moderator.', // new translation
        imReady: "I'm ready", // new translation
        isTryingToRegister: 'is trying to register as a', // new translation
        hasLeftTheSession: 'has left the session', // new translation
        stopVideo: 'Stop video', // new translation
        startVideo: 'Start video', // new translation
        disabledScreenSharing: 'Turn off your screen share before starting your video', // new translation
        disabledByModerator: 'Disabled by Moderator', // new translation
        disabledUntilReady: 'Disabled until Ready', // new translation
        stopShareScreen: 'Stop share screen', // new translation
        shareScreen: 'Share screen', // new translation
        disabledVideoSharing: 'Turn off your video before sharing your screen', // new translation
        anotherUserIsScreenSharing: 'Someone else is already sharing the screen', // new translation
        removeRoleDescription: 'Are you sure you want to remove', // new translation
        declinedInvitation: 'declined the invitation to be a speaker', // new translation
    },
    breadcrumbsNavigation: {
        // mainLobby: 'Main Lobby',  // not used
        // village: 'Village',  // not used
    },
    village: {
        // clickToOpen: 'clickToOpen',  // not used
    },
    gdpr: {
        disclaimerTextFirst:
            'This document was translated by artificial intelligence. In case of inconsistency, please refer to the English version of the document',
        disclaimerTextSecond: 'HERE',
        metaTitlePrivacyPolicy: 'Privacy Policy',
        metaTitleCookiesPolicy: 'Cookie Policy',
        metaTitleTerms: 'Terms and Conditions',
        updateCookieConsent: 'Update cookie consent', // new translation
        changeYourConsent: 'Change your consent', // new translation
        withdrawYourConsent: 'Withdraw your consent', // new translation
        withdrawSnackbarText: 'Cookie consent successfully withdrawn', // new translation
    },
    infoMessages: {
        imageExplain420x200: 'Recommended image ratio 1.8:1 (landscape-oriented, e.g. 420 x 200 pixels)',
        imageExplainTB230x320:
            'Recommended image ratio 1:1.4 (portrait-oriented, e.g. 230 x 320 pixels) with transparent background',
        imageExplainTB230x875:
            'Recommended image ratio 1:3.8 (portrait-oriented, e.g. 230 x 875 pixels) with transparent background',
    },
    matching: {
        matchingQuestionnaireTitle: 'Matching questionnaire', // new translation
        matchingQuestionnaireIntro:
            "Let's help you get connected to the most relevant people for you! Amazing things happen when the right minds meet, complete the questionnaire to get started", // new translation
        matchingQuestionnaireIntroSkip:
            "By skipping the questionnaire, we will not be able to match you with the rest of the attendees at today's event. You can always complete the questionnaire later in the Networking section. All answers are editable from My Account.", // new translation
        doItLaterBtn: 'DO IT LATER', // new translation
        nextBtn: 'Next', // new translation
        backBtn: 'Back', // new translation
        finishBtn: 'Finish', // new translation
        seeMatchesBtn: 'SEE ALL MATCHES', // new translation
        tryMatchingBtn: 'TRY SMART MATCHING', // new translation
        optionalQuestion: 'Optional', // new translation
        requiredQuestion: 'Required', // new translation
        singleSelect: 'Single select', // new translation
        multiSelect: ' Multi select', // new translation
        mediumMatch: 'Medium Match', // new translation
        highMatch: 'High Match', // new translation
        matchLabel: 'Match', // new translation
        matchesLabel: 'Matches', // new translation
        infoTooltipJump: 'Jump here to see your matches', // new translation
        infoTooltipClickToSee1: 'Click on', // new translation
        infoTooltipClickToSee2: 'to see who you should connect with!', // new translation
        loadingAlgoText: 'Matching algorithm in action. Give us a minute while we are curating your best matches!', // new translation
        matchingDetailsFormTitle: 'Smart matchng details', // new translation
        tryMatchingBanner: 'Make a meaningful connection, try our Smart Matching feature!', // new translation
        matchingBannerClosed: 'You can always fill the Smart Matching form in Networking under Matches', // new translation
        resultsTitle: 'Here are your top matches', // new translation
        resultsNoteLabel: 'NOTE:', // new translation
        resultsNoteText: 'You can find all your matches in the Networking section.', // new translation
        resultsNoteMyAccount: 'All answers are editable in My Account menu.', // new translation
        btwResultsResponsive: 'By the way, you can find all your matches in the NETWORKING', // new translation
        noMoreMatches:
            'Oh snap! We can’t find more matches for you at the moment. Please check back again when more participants have joined', // new translation
        noResultsTitle: 'Congrats, you made it! We are searching for your best matches! ', // new translation
        noResults: `Thanks for trying the Smart Matching function! We are searching for your best matches and will pair you up as soon soon as more participants join the event. Please check the "Matches" tab in the "Networking" section`, // new translation
        clickStartChat: 'Click chat to start a conversation', // new translation
        closeMatchingTitle: 'Close matching form?', // new translation
        closeMatchingTextA:
            'If you close the form before completion, we will not store any details and the Smart Matching results will not be available to you. ', // new translation
        closeMatchingTextB:
            'Your profile will not part of the Smart Matching process and no one will be matched with you. ', // new translation
        matchingTooltip: 'Click on Matches to see who you should connect with!', // new translation
        myAccountMatchingSectionTitle: 'Smart matching details', // new translation
        matchingRequiredError: 'You have to complete all Required questions', // new translation
        rejectMatchingTitle: 'Reject Matching participation?', // new translation
        rejectMatchingText:
            'Your answers will be erased and you will not be matched with other users. You can retake the Matching questionnaire anytime.', // new translation
        rejectMatchingBtn: 'REJECT PARTICIPATION', // new translation
        editMatchingAnswers: 'Edit matching answers', // new translation aifg
    },
    demoMessages: {
        clickToOpen: 'click to open', // new translation aifg
    },
    welcomeScreen: {
        goToEvent: 'Go to event', // new translation
    },
    emailCodeVerification: {
        codeSent: 'We’ve sent a verification code to your email:', // new translation
        continueRegistration: 'To continue the registration,', // new translation
        enterCodeBelow: 'enter the verification code below:', // new translation
        codeWillExpire: 'This code will expire in', // new translation
        codeExpired: 'The code expired', // new translation
        codeNotReceived: 'Didn’t receive the code? Have you checked the Spam folder?', // new translation
        resendTimer: 'Resend in:', // new translation
        resendLabel: 'Resend', // new translation
        resendCodeLabel: 'Resend the code', // new translation
        codeError: 'Entered code is incorrect.',
        codeErrorFix: 'Re-enter the correct code from your email or resend a new code',
        congrats: 'Congrats!',
        emailConfirmed: 'Your email confirmed:',
    },
    addToCalendarDialog: {
        titleSession: 'Add session to calendar',
        titleMeeting: 'Add meeting to calendar',
        confirmationTextSession: 'The session has been added to your Neural Network Calendar.',
        confirmationTextMeeting: 'The meeting has been added to your Neural Network Calendar.',
        addToOtherCalendarsTextSession: 'Add the session to one of your own calendars:',
        addToOtherCalendarsTextMeeting: 'Add the meeting to one of your own calendars:',
    },
    notificationsTextsUserEnd: {
        headline: 'Notifications',
        subHeadline: 'Manage notifications for:',
        headlinePlatformNotifications: 'Platform Notifications',
        headlineEmailotifications: 'Email Notifications',
        platformNotifications: {
            upComingSession: 'Upcoming Sessions',
        },
        emailNotifications: {
            upComingSession: 'Upcoming Sessions',
            emailCampaigns: 'Email campaigns',
            privacyPolicy: 'Platform Privacy Policy and Terms & Conditions updates',
        },
    },
    notificationsTextsDashboard: {
        headline: 'Notifications',
        headlinePlatformNotifications: 'Platform Notifications',
        subHeadlinePlatformNotifications: 'Send platform notifications to registered users about a Session',
        headlineEmailotifications: 'Email Notifications',
        subHeadlineEmailotifications: 'Send email notifications about a Session',

        emailNotifications: {
            whenIsCreated: 'When the session is created',
            oneWeekBefore: '1 week before the Session',
            oneDayBefore: '1 day before the Session',
            oneHourBefore: '1 hour before the Session',
            whenAddedByURL: 'When they add the session to their calendar using the session-specific URL',
            whenNotAttended: 'When they did not attend the session added to their calendar',
        },
        platformNotifications: {
            whenIsCreated: 'When the session is created',
            oneWeekBefore: '1 week before the Session',
            oneDayBefore: '1 day before the Session',
            oneHourBefore: '1 hour before the Session',
            whenAddedByURL: 'When they add the session to their calendar using the session-specific URL',
            whenOnAvailableOnDemand: 'When the session is available on-demand',
        },
    },
};

export default en;

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactPlayer from 'react-player';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import FastForwardIcon from '@material-ui/icons/FastForward';
import {connect} from 'react-redux';
import {isMobile} from 'react-device-detect';
import {getVideoIdFromYoutubeUrl, preventDefaultDrag} from '../../Utils/utils';
import moment from 'moment';
import Slider from '@material-ui/core/Slider';

class BoothVideo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullscreen: false,
            muteVideo: isMobile,
            loaded: false,
            connectTimestamp: null,
            isVideoPlaying: true,
            progressTime: 0,
            thumbnailImage: '',
        };
        this.youtubePlayer = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false);
        this.setState({loaded: true});
        this.setThumbnailImage();
    }

    componentDidUpdate(prevProps) {
        // update thumbnail everytime the exhibitor changes the videoUrl
        if (prevProps.videoUrl !== this.props.videoUrl) {
            this.setState(
                {
                    progressTime: 0,
                },
                () => {
                    this.setThumbnailImage();
                    this.youtubePlayer.current && this.youtubePlayer.current.seekTo(0, 'seconds');
                }
            );
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction, false);
        // booth statistics
        const {onVideoExit} = this.props;
        const {connectTimestamp} = this.state;
        if (onVideoExit && connectTimestamp) {
            this.props.onVideoExit(connectTimestamp);
            this.setState({connectTimestamp: null});
        }
    }

    handleVideoReady = () => {
        this.youtubePlayer.current.getInternalPlayer().setLoop(true);
        this.setState({connectTimestamp: moment.utc()});
    };

    escFunction = (e) => {
        if (e.key === 'Escape') {
            this.setState({fullscreen: false});
        }
    };

    handleProgressChange = (e, value) => {
        e.preventDefault();
        this.youtubePlayer.current.seekTo(value / 100.5, 'fraction');
    };

    handleProgressBar = (progress) => {
        this.setState({
            progressTime: parseFloat((progress?.played * 100).toFixed(1)),
        });
    };

    setThumbnailImage = () => {
        let videoId = getVideoIdFromYoutubeUrl(this.props.videoUrl || '');
        this.setState({
            thumbnailImage: `https://img.youtube.com/vi/${videoId}/0.jpg`,
        });
    };

    toggleMute = () => this.setState({muteVideo: !this.state.muteVideo});
    toggleFullscreen = () => this.setState({fullscreen: !this.state.fullscreen});

    toggleRewind = () =>
        this.youtubePlayer.current.seekTo(Math.floor(this.youtubePlayer.current.getCurrentTime()) - 30, 'seconds');

    toggleForward = () =>
        this.youtubePlayer.current.seekTo(Math.floor(this.youtubePlayer.current.getCurrentTime()) + 30, 'seconds');

    togglePause = () =>
        this.setState({isVideoPlaying: !this.state.isVideoPlaying}, () => {
            if (this.state.isVideoPlaying) {
                this.youtubePlayer.current.getInternalPlayer().playVideo();
            } else {
                this.youtubePlayer.current.getInternalPlayer().pauseVideo();
            }
        });

    render() {
        const {fullscreen, loaded, muteVideo, isVideoPlaying, thumbnailImage, progressTime} = this.state;
        const {opened, closeDialog, classes, videoUrl, translation} = this.props;
        return (
            <Dialog
                open={opened}
                onClose={closeDialog}
                fullScreen={fullscreen}
                aria-labelledby="form-dialog-title"
                disableEscapeKeyDown={true}
                className={`booth-video-dialog-container booth-dialog ${fullscreen ? 'fullscreen' : ''} ${
                    classes !== undefined ? classes : ''
                }`}
            >
                {loaded ? (
                    <DialogContent className="booth-dialog-content booth-video-dialog-container">
                        <DialogTitle id="form-dialog-title">{translation?.showfloor.boothVideoDialogTitle}</DialogTitle>
                        <div onDragStart={preventDefaultDrag} className="booth-video-container">
                            <img
                                src={thumbnailImage}
                                alt="Video thumbnail"
                                className={`booth-thumbnail ${!isVideoPlaying && 'show'}`}
                            />
                            <ReactPlayer
                                ref={this.youtubePlayer}
                                url={videoUrl}
                                onReady={this.handleVideoReady}
                                onProgress={this.handleProgressBar}
                                playing
                                loop
                                playsinline
                                playIcon={<></>}
                                controls={false}
                                width="100%"
                                height="100%"
                                volume={muteVideo ? 0 : 1}
                                className="react-player"
                            />
                            <Slider
                                classes={{root: `progress-bar ${!isVideoPlaying && 'hide'}`}}
                                onChange={this.handleProgressChange}
                                value={progressTime}
                                color="secondary"
                            />
                            <div onDragStart={preventDefaultDrag} className="auditoriumOverlayPlayer"></div>
                        </div>
                        <div onDragStart={preventDefaultDrag} className="booth-video-actions-container">
                            <button onClick={this.toggleFullscreen} className="fullscreen-button">
                                {fullscreen ? (
                                    <FullscreenExitIcon fontSize="inherit" />
                                ) : (
                                    <FullscreenIcon fontSize="inherit" />
                                )}
                            </button>
                            <button onClick={this.toggleRewind} className="fullscreen-button mute-button">
                                <FastRewindIcon fontSize="inherit" />
                            </button>
                            <button onClick={this.togglePause} className="fullscreen-button mute-button">
                                {isVideoPlaying ? (
                                    <PauseIcon fontSize="inherit" />
                                ) : (
                                    <PlayArrowIcon fontSize="inherit" />
                                )}
                            </button>
                            <button onClick={this.toggleForward} className="fullscreen-button mute-button">
                                <FastForwardIcon fontSize="inherit" />
                            </button>
                            <button onClick={this.toggleMute} className="fullscreen-button mute-button">
                                {muteVideo ? <VolumeOffIcon fontSize="inherit" /> : <VolumeUpIcon fontSize="inherit" />}
                            </button>
                        </div>
                    </DialogContent>
                ) : null}

                <DialogActions>
                    <Button type="button" classes={{label: 'booth-cancel-button'}} onClick={closeDialog}>
                        {translation?.generalText.close}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps)(BoothVideo);

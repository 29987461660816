import React from 'react';
import {Link} from 'react-router-dom';
import SideMenu from '../../Components/SideMenu';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import NotificationPresentationStarts from '../../Components/NotificationPresentationStarts';
import NoVideoImage from '../../assets/no-video.png';
import Slider from 'react-slick';
import FullOverlay from '../../SmallLayoutComponents/FullOverlay';
import BoothVideo from '../../Dialogs/ExhibitorBooth/BoothVideo';
import '../../CSS/lobby.scss';
import {ReactComponent as PlayIcon} from '../../Images/svg/play-black.svg';
import {checkIfEventHasEnded, getVideoIdFromYoutubeUrl, getPrivateChatId, preventDefaultDrag} from '../../Utils/utils';
import {disconnectFromNotifications} from '../../Api/socketApi';
import {getLobbyBackground} from '../../Utils/getBackgrounds';
import {getLobbyBackgroundUploaded} from '../../Utils/getBackgroundsUploaded';
import AuditoriumLink from '../../Components/EventMenu/Rooms/AuditoriumLink';
import ShowfloorLink from '../../Components/EventMenu/Rooms/ShowfloorLink';
import {Helmet} from 'react-helmet';
import LeftSideMenu from '../../Components/LeftSideMenu';
import axios from '../../store/axios-instance';
import MatchingDialog from '../../Components/EventManagement/Matching/MatchingDialog';
import MatchingBanners from '../../Components/EventManagement/Matching/MatchingBanners';
import MatchingDialogConfirm from '../../Components/EventManagement/Matching/MatchingDialogConfirm';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

class Lobby extends React.Component {
    state = {
        showVideo: false,
        showNoShowfloorDialog: false,
        showNoShowfloorAccessDialog: false,
        showNoAuditoriumDialog: false,
        showNoAuditoriumAccessDialog: false,
        brandingTranslation: null,
        alreadyShowed: false,
        openMatchingCloseDialog: false,
        bannerDisableOnFinish: false,
    };

    componentDidMount() {
        const {eventId, onGetQuestionnaires} = this.props;

        this.props.onSetEventProtectedMenu();
        // this.handleSeeLiveWall();
        this.handleSeeNetworking();
        this.props.setHasVideoWall(false);
        this.setLanguagesData();
        this.refreshExhibitorData();
        this.getMatchingBannerShowed();

        onGetQuestionnaires(eventId);

        this.checkIfRegisteringToAnOldEventWithExternalLink();
    }

    componentDidUpdate(prevProps) {
        const {event} = this.props;
        if (prevProps.event._id !== event._id) {
            // this.handleSeeLiveWall();
            this.handleSeeNetworking();
            this.setLanguagesData();
            this.refreshExhibitorData();
            this.getMatchingBannerShowed();
        }
    }

    componentWillUnmount() {
        // reset wall data to null
        const wallData = {
            wallId: null,
            highlightUsers: [],
        };
        this.props.setActiveWall(wallData);
    }

    findCurrentTimeslot = (timeslotId) => {
        let allAuditoriumsProgram = [];
        this.props.event.auditoriums.forEach((auditorium) => {
            auditorium.dailyProgram.forEach((dayProgram) => {
                allAuditoriumsProgram = allAuditoriumsProgram.concat(dayProgram.program);
            });
        });

        let timeSlot = allAuditoriumsProgram.find((timeSlot) => timeSlot._id === timeslotId);
        return timeSlot;
    };

    addTimeSlotToAgenda = (auditoriumId, timeslotId) => {
        const {eventId} = this.props;
        let data = {};
        data.date = moment(this.findCurrentTimeslot(timeslotId).startTimestamp).format('YYYY-MM-DD');
        data.auditoriumId = auditoriumId;
        data.programTimeslotId = timeslotId;
        axios({
            method: 'post',
            url: `/event/${eventId}/add-timeslot-to-agenda`,
            data: data,
        })
            .then((response) => {
                this.props.addSessionAgenda(timeslotId);
            })
            .catch((error) => {});
    };

    checkIfRegisteringToAnOldEventWithExternalLink = async () => {
        const {eventId, history, eventSlug, user} = this.props;

        const userRole = user.eventRoles[0]?.roles;
        const externalId = localStorage.getItem('externalId');
        const externalIdsArray = externalId ? externalId.split(',') : [];
        for (const id of externalIdsArray) {
            const {data} = await axios.get(`/event/${eventId}/check-external-id/${id}`);
            if (data?.success && userRole.includes('participant') && userRole.length === 1) {
                if (data?.data?.success) {
                    localStorage.removeItem('externalId');
                    this.addTimeSlotToAgenda(data?.data?.auditoriumId, data?.data?.programId);
                } else {
                    return history.push(`/event/${eventSlug}/lobby`);
                }
            } else {
                localStorage.removeItem('externalId');
            }
        }
        const {data} = await axios.get(`/event/${eventId}/check-external-id/${externalIdsArray[0]}`);
        if (data?.success && userRole.includes('participant') && userRole.length === 1) {
            if (data?.data?.success) {
                return history.push(
                    `/event/${eventSlug}/auditorium-archive/${data?.data?.auditoriumId}/timeslot/${data?.data?.programId}`
                );
            } else {
                return history.push(`/event/${eventSlug}/lobby`);
            }
        } else {
            localStorage.removeItem('externalId');
        }
    };

    handleOpenLobbyVideo = () => this.setState({showVideo: true});
    handleOpenNoShowfloorDialog = () => this.setState({showNoShowfloorDialog: true});
    handleOpenNoShowfloorAccess = () => this.setState({showNoShowfloorAccessDialog: true});
    handleOpenNoAuditoriumDialog = () => this.setState({showNoAuditoriumDialog: true});
    handleOpenNoAuditoriumAccess = () => this.setState({showNoAuditoriumAccessDialog: true});
    handleRequestCloseMatchingBanner = () => {
        this.setState({bannerDisableOnFinish: true});
        this.props.onRequestCloseMatchingBanner(true);
    };

    openMatchingCloseDialog = () => this.setState({openMatchingCloseDialog: !this.state.openMatchingCloseDialog});
    closeMatchingModal = () => {
        this.setState({openMatchingCloseDialog: false});
        this.props.onToggleMatchingDialog(false);
    };
    openMatchingModal = () => {
        const {eventId, onGetQuestionnaires, onToggleMatchingDialog} = this.props;
        onGetQuestionnaires(eventId);
        onToggleMatchingDialog(true);
    };

    getMatchingBannerShowed = () => {
        const {eventSlug, user} = this.props;
        const matchingBannerData = localStorage.getItem('matchingBannerData');
        if (matchingBannerData !== null) {
            const matchingBannerDataArray = JSON.parse(matchingBannerData);
            let data = matchingBannerDataArray?.filter((bannerData) => bannerData?.event === eventSlug)?.shift();
            if (data !== undefined && data['userId'] === user?._id) {
                this.setState({alreadyShowed: data['alreadyShowed']});
            }
        }
    };

    checkIfEventHasEnded = () => {
        const {event} = this.props;
        return checkIfEventHasEnded(event, event.timezoneValue);
    };

    refreshExhibitorData = () => {
        const {eventSlug, user} = this.props;
        const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === eventSlug);
        const exhibitorId = userRolesInEvent?.exhibitor?._id;
        if (exhibitorId) {
            this.props.onGetExhibitor(exhibitorId);
            this.props.onSetActiveExhibitorId(exhibitorId);
        }
    };

    closeDialog = () =>
        this.setState({
            showVideo: false,
            showNoShowfloorDialog: false,
            showNoShowfloorAccessDialog: false,
            showNoAuditoriumDialog: false,
            showNoAuditoriumAccessDialog: false,
        });

    handleLogoutUser = () => {
        const {user} = this.props;
        // if user logged out we should also disconnect him from notifications
        disconnectFromNotifications(user._id);

        this.props.onLogoutUser();
    };

    setLanguagesData = () => {
        const brandingTranslation = this.props.event.brandingTranslations.find(
            (translation) => translation.language === this.props.languages.eventLanguage
        );
        this.setState({
            brandingTranslation: brandingTranslation,
        });
    };

    handleSeeLiveWall = () => {
        const {event} = this.props;
        const wallData = {
            wallId: event.liveWallId,
            highlightUsers: [],
        };
        this.props.setActiveWall(wallData);
        this.props.seeLiveWall();
    };

    handleSeeNetworking = () => {
        this.props.seeNetworking();
    };

    checkExhibitorsCanLogin = () => {
        const {exhibitorData, event} = this.props;
        let canLogin = true;
        if (exhibitorData?.type === 'company') {
            canLogin = event.exhibitorsLogin;
        }
        return canLogin;
    };

    checkScholarCanLogin = () => {
        const {exhibitorData, event} = this.props;
        let canLogin = true;
        if (exhibitorData?.type === 'scholar') {
            canLogin = event.scholarsLogin;
        }
        return canLogin;
    };

    checkIsScholar = () => {
        const {exhibitorData} = this.props;
        let isScholar = false;
        if (exhibitorData?.type === 'scholar') {
            isScholar = true;
        }
        return isScholar;
    };

    handleOpenPrivateChatWithRepresentative = () => {
        const {eventId, eventRoles, user} = this.props;
        let userPrivateChats = user.privateChats;
        // we check if we have allready talked with an organizer representative
        let representativeChatId = null;
        this.props.event.representatives.forEach((representative) => {
            let privateChatId = getPrivateChatId(userPrivateChats, representative.user._id);
            if (privateChatId) {
                representativeChatId = privateChatId;
            }
        });
        // if we allready talked with one, we open the same private chat with him
        if (representativeChatId) {
            this.props.onOpenPrivateChat(representativeChatId);
        } else {
            // if we didn't talk with a representative, we make an API call to the server
            // to alocate us a random representative
            let data;
            if (eventRoles.isExhibitor) {
                data = {exhibitorId: eventRoles.exhibitorId};
            } else {
                data = {participantId: eventRoles.participantId};
            }

            if (!this.state.loading) {
                axios({method: 'post', url: `/event/${eventId}/chat-with-organizer-representative`, data: data})
                    .then((response) => {
                        const newOrganizerChatId = response.data.privateChatId;
                        this.props.getPrivateChatsAndOpenPrivateChat(newOrganizerChatId);
                    })
                    .catch(() => {});
            }
        }
    };

    checkHasAccessToAuditoriums = () => {
        const {event, eventRoles, resourcesAccess} = this.props;
        let hasAccessToAuditoriums = true;
        const userIsCompanyExhibitor = eventRoles.isExhibitor && eventRoles?.exhibitorType === 'company';
        const userIsScholarExhibitor = eventRoles.isExhibitor && eventRoles?.exhibitorType === 'scholar';

        // participant
        if (event.hasAccessManagement && eventRoles.isParticipant && resourcesAccess) {
            if (!resourcesAccess.auditoriums.length) {
                hasAccessToAuditoriums = false;
            }
        }
        // company exhibitor
        if (event.exhibitorHasAccessManagement && userIsCompanyExhibitor && resourcesAccess) {
            if (!resourcesAccess.auditoriums.length) {
                hasAccessToAuditoriums = false;
            }
        }
        // scholar exhibitor
        if (event.scholarHasAccessManagement && userIsScholarExhibitor && resourcesAccess) {
            if (!resourcesAccess.auditoriums.length) {
                hasAccessToAuditoriums = false;
            }
        }

        return hasAccessToAuditoriums;
    };

    checkHasAccessToShowfloors = () => {
        const {event, eventRoles, resourcesAccess} = this.props;
        let hasAccessToShowfloor = true;

        const userIsCompanyExhibitor = eventRoles.isExhibitor && eventRoles?.exhibitorType === 'company';
        const userIsScholarExhibitor = eventRoles.isExhibitor && eventRoles?.exhibitorType === 'scholar';

        // participant
        if (event.hasAccessManagement && eventRoles.isParticipant && resourcesAccess) {
            if (!resourcesAccess.showfloors?.length) {
                hasAccessToShowfloor = false;
            }
        }
        // company exhibitor
        if (event.exhibitorHasAccessManagement && userIsCompanyExhibitor && resourcesAccess) {
            if (!resourcesAccess.showfloors?.length) {
                hasAccessToShowfloor = false;
            }
        }
        // scholar exhibitor
        if (event.scholarHasAccessManagement && userIsScholarExhibitor && resourcesAccess) {
            if (!resourcesAccess.showfloors?.length) {
                hasAccessToShowfloor = false;
            }
        }

        return hasAccessToShowfloor;
    };

    handleEnterKey = (tab) => (e) => {
        if (e.key === 'Enter') {
            if (tab === 'lobbyVideo') return this.setState({showVideo: true});
        }
    };
    logoSorting = () => {
        const {event} = this.props;

        let lobbyLogos = cloneDeep(event.brandingData?.lobbyLogos);

        lobbyLogos.sort(function (a, b) {
            return a.position - b.position;
        });

        return lobbyLogos;
    };

    render() {
        const {
            showNoShowfloorDialog,
            showNoShowfloorAccessDialog,
            showNoAuditoriumDialog,
            showNoAuditoriumAccessDialog,
            showVideo,
            brandingTranslation,
            alreadyShowed,
            openMatchingCloseDialog,
            bannerDisableOnFinish,
            // splashScreenState,
        } = this.state;
        const {event, userEventProfile, eventSlug, eventRoles, isLargeScreen, translation, defaultTranslation} =
            this.props;
        const eventHasEnded = this.checkIfEventHasEnded();
        const exhibitorCanLogin = this.checkExhibitorsCanLogin();
        const scholarCanLogin = this.checkScholarCanLogin();
        const isScholar = this.checkIsScholar();

        let sliderSettings = {
            dots: false,
            arrows: false,
            autoplay: true,
            draggable: false,
            pauseOnHover: false,
            swipe: false,
            infinite: true,
            speed: 1300,
            slidesToShow: 1,
            slidesToScroll: 1,
            // if we use fade, we need to force ".slick-slide {float: left}" on RTL languages (see lobby.scss line 232)
            fade: true,
            autoplaySpeed: 4000,
        };

        let lobbyVideoId = null;
        if (event.brandingData.lobbyVideoUrl) {
            lobbyVideoId = getVideoIdFromYoutubeUrl(event.brandingData.lobbyVideoUrl);
        }
        let lobbyVideoImage = '';
        if ('lobbyVideoImage' in event.brandingData && event.brandingData.lobbyVideoImage) {
            lobbyVideoImage = event.brandingData.filesUrl + event.brandingData.lobbyVideoImage;
        }

        let EventLobyImage;
        if (event.brandingData && event.brandingData.lobbyRenderType === 'custom') {
            // Custom uploaded
            EventLobyImage = getLobbyBackgroundUploaded(event.brandingData);
        } else if (event.brandingData.lobbyRenderType === 'v2') {
            // International
            EventLobyImage = getLobbyBackgroundUploaded(event.brandingData, event.brandingData.lobbyRenderType);
        } else if (event.brandingData.lobbyRenderType === 'v3') {
            // Academic
            EventLobyImage = getLobbyBackgroundUploaded(event.brandingData, event.brandingData.lobbyRenderType);
        } else if (event.brandingData.lobbyRenderType === 'v4') {
            // Green
            EventLobyImage = getLobbyBackgroundUploaded(event.brandingData, event.brandingData.lobbyRenderType);
        } else {
            // Default
            EventLobyImage = getLobbyBackground();
        }

        let hasAccessToAuditoriums = this.checkHasAccessToAuditoriums();
        let hasAccessToShowfloor = this.checkHasAccessToShowfloors();

        const hasUserSeenMatchingForm = userEventProfile?.matchingFormSeen;

        return (
            <>
                <Helmet>
                    <title>{`${process.env.REACT_APP_PLATFORM_NAME} - ${
                        brandingTranslation?.homepageEventName ? brandingTranslation?.homepageEventName : event.title
                    }`}</title>
                    <meta property="og:image" content={`${EventLobyImage}`} />
                    <meta
                        property="og:title"
                        content={`${process.env.REACT_APP_PLATFORM_NAME} - ${brandingTranslation?.homepageEventName}`}
                    />
                </Helmet>
                <div
                    onDragStart={preventDefaultDrag}
                    className={`position-background lobby-screen ${!isLargeScreen ? 'lobby-mobile' : ''}`}
                >
                    {' '}
                    {!hasUserSeenMatchingForm && !alreadyShowed && (
                        <MatchingBanners
                            bannerDisableOnFinish={bannerDisableOnFinish}
                            requestCloseBanner={event?.requestCloseBanner}
                            openMatchingModal={this.openMatchingModal}
                        />
                    )}
                    <LeftSideMenu />
                    <SideMenu openMatchingDialog={this.openMatchingModal} />
                    <div
                        className="lobby-page"
                        // style={{
                        //     backgroundImage: `url('${EventLobyImage}')`,
                        //     backgroundPosition: 'center',
                        //     backgroundRepeat: 'no-repeat',
                        //     backgroundSize: 'cover',
                        // }}
                    >
                        <div className="lobby-wrapper">
                            <div className="doors-wrapper">
                                <div
                                    onDragStart={preventDefaultDrag}
                                    className={`${event.hideAuditoriumDoor ? 'disable-lobby-door' : ''}`}
                                    id="auditorium"
                                >
                                    {event.hasVillage ? (
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className={`${event.hideAuditoriumDoor ? 'disable-lobby-door' : ''}`}
                                        >
                                            <Link to={{pathname: `/event/${eventSlug}/village/`}} />
                                        </div>
                                    ) : (
                                        !event.hideAuditoriumDoor && (
                                            <AuditoriumLink
                                                lobby
                                                translation={translation}
                                                defaultTranslation={defaultTranslation}
                                                eventHasEnded={eventHasEnded}
                                                hasAccessToAuditoriums={hasAccessToAuditoriums}
                                                showNoAuditoriumDialog={showNoAuditoriumDialog}
                                                handleOpenNoAuditoriumDialog={this.handleOpenNoAuditoriumDialog}
                                                showNoAuditoriumAccessDialog={showNoAuditoriumAccessDialog}
                                                handleOpenNoAuditoriumAccess={this.handleOpenNoAuditoriumAccess}
                                                closeDialog={this.closeDialog}
                                            />
                                        )
                                    )}
                                </div>
                                <div
                                    onDragStart={preventDefaultDrag}
                                    className={`${event.hideShowfloorDoor ? 'disable-lobby-door' : ''}`}
                                    id="showfloor"
                                >
                                    {event.hasVillage ? (
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className={`${event.hideShowfloorDoor ? 'disable-lobby-door' : ''}`}
                                        >
                                            <Link to={{pathname: `/event/${eventSlug}/village/`}} />
                                        </div>
                                    ) : (
                                        !event.hideShowfloorDoor && (
                                            <ShowfloorLink
                                                lobby
                                                translation={translation}
                                                defaultTranslation={defaultTranslation}
                                                hasAccessToShowfloor={hasAccessToShowfloor}
                                                showNoShowfloorDialog={showNoShowfloorDialog}
                                                handleOpenNoShowfloorDialog={this.handleOpenNoShowfloorDialog}
                                                showNoShowfloorAccessDialog={showNoShowfloorAccessDialog}
                                                handleOpenNoShowfloorAccess={this.handleOpenNoShowfloorAccess}
                                                closeDialog={this.closeDialog}
                                            />
                                        )
                                    )}
                                </div>
                            </div>
                            <div
                                tabIndex={event.brandingData.lobbyVideoUrl ? '0' : '-1'}
                                onDragStart={preventDefaultDrag}
                                className={`${!event.brandingData.lobbyVideoUrl && 'no-hover'}`}
                                id="show-lobby-video"
                                onKeyDown={this.handleEnterKey('lobbyVideo')}
                            >
                                {event.brandingData.lobbyVideoUrl ? (
                                    <div
                                        className="image-preview-video"
                                        style={{
                                            backgroundImage: `url('${
                                                'https://img.youtube.com/vi/' + lobbyVideoId + '/0.jpg'
                                            }')`,
                                        }}
                                        onClick={this.handleOpenLobbyVideo}
                                    >
                                        <div onDragStart={preventDefaultDrag} className="play-button">
                                            <PlayIcon />
                                        </div>
                                    </div>
                                ) : lobbyVideoImage.length ? (
                                    <div
                                        className="image-preview-video no-video"
                                        style={{backgroundImage: `url('${lobbyVideoImage}')`}}
                                    />
                                ) : (
                                    <div
                                        className="image-preview-video no-video"
                                        style={{backgroundImage: `url('${NoVideoImage}')`}}
                                    />
                                )}
                            </div>
                        </div>
                        <BoothVideo
                            opened={showVideo}
                            closeDialog={this.closeDialog}
                            videoUrl={event.brandingData.lobbyVideoUrl}
                        />
                        <div onDragStart={preventDefaultDrag} id="slider-logos">
                            {event.brandingData.lobbyLogos?.length > 0 ? (
                                <Slider {...sliderSettings}>
                                    {this.logoSorting().map((lobbyLogo) => {
                                        return (
                                            <div onDragStart={preventDefaultDrag} key={lobbyLogo._id}>
                                                <img
                                                    draggable="false"
                                                    onDragStart={preventDefaultDrag}
                                                    src={event.brandingData.filesUrl + lobbyLogo.image}
                                                    alt="Logo"
                                                />
                                            </div>
                                        );
                                    })}
                                </Slider>
                            ) : event.brandingData?.lobbyBanner ? (
                                <img
                                    draggable="false"
                                    onDragStart={preventDefaultDrag}
                                    src={event.brandingData.filesUrl + event.brandingData.lobbyBanner}
                                    alt="Logo"
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
                {event?.matchingDialog && (
                    <MatchingDialog
                        noOpenNetworking
                        open={event?.matchingDialog}
                        close={this.openMatchingCloseDialog}
                        closeNoPrompt={this.closeMatchingModal}
                        closeBannerOnFinish={this.handleRequestCloseMatchingBanner}
                    />
                )}
                {openMatchingCloseDialog && (
                    <MatchingDialogConfirm
                        hasCloseButton
                        open={openMatchingCloseDialog}
                        dialogTitle={
                            translation?.matching?.closeMatchingTitle ||
                            defaultTranslation?.matching?.closeMatchingTitle
                        }
                        dialogDescription={`${
                            translation?.matching?.closeMatchingTextA ||
                            defaultTranslation?.matching?.closeMatchingTextA
                        } <br><br> ${
                            translation?.matching?.closeMatchingTextB ||
                            defaultTranslation?.matching?.closeMatchingTextB
                        }`}
                        cancelBtnLabel={translation?.generalText?.cancel || defaultTranslation?.generalText?.cancel}
                        confirmBtnLabel={translation?.generalText?.close || defaultTranslation?.generalText?.close}
                        closeConfirm={this.openMatchingCloseDialog}
                        handleConfirm={this.closeMatchingModal}
                    />
                )}
                {!event.participantsLogin &&
                    !eventRoles.isOrganizer &&
                    !eventRoles.isExhibitor &&
                    !eventRoles.isCoOrganizer && (
                        <FullOverlay
                            title={event.brandingData.participantsClosedEventTitle}
                            text={event.brandingData.participantsClosedEventInfo}
                            buttonText={translation?.myAccountDropdown.logoutButton}
                            handleClick={this.handleLogoutUser}
                        />
                    )}
                {/* if exhibitor or poster login is closed then display the logout dialog
                on lobby for mobile and tablets */}
                {eventRoles.isExhibitor && !eventRoles.isOrganizer && !exhibitorCanLogin && (
                    <FullOverlay
                        title={event.brandingData.exhibitorsClosedEventTitle}
                        text={event.brandingData.exhibitorsClosedEventInfo}
                        buttonText={`${translation?.myAccountDropdown.logoutButton}`}
                        handleClick={this.handleLogoutUser}
                    />
                )}
                {isScholar && !eventRoles.isOrganizer && !scholarCanLogin && (
                    <FullOverlay
                        title={event.brandingData.scholarsClosedEventTitle}
                        text={event.brandingData.scholarsClosedEventInfo}
                        buttonText={`${translation?.myAccountDropdown.logoutButton}`}
                        handleClick={this.handleLogoutUser}
                    />
                )}
                <NotificationPresentationStarts />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        loadingEvent: state.event.loading,
        event: state.event.data,
        user: state.user.data,
        eventMatching: state.organizer.eventMatching,
        sideNavigation: state.user.sideNavigation,
        eventRoles: state.user.eventRoles,
        exhibitorData: state.exhibitor.data,
        resourcesAccess: state.user.resourcesAccess,
        isLargeScreen: state.layout.isLargeScreen,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        myAgenda: state.event.myAgenda,
        userEventProfile: state.userEventProfile.data,
        // pastSessions: state.event.pastSessions,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setHasVideoWall: (booleanValue) => dispatch(actions.sideNavHasVideoWall(booleanValue)),
        onLogoutUser: () => dispatch(actions.logoutUser()),
        setActiveWall: (wallData) => dispatch(actions.setActiveWall(wallData)),
        seeNetworking: () => dispatch(actions.sideNavSeeNetworking()),
        onSetEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
        onGetExhibitor: (exhibitorId) => dispatch(actions.getExhibitor(exhibitorId)),
        onSetActiveExhibitorId: (exhibitorId) => dispatch(actions.setActiveExhibitorId(exhibitorId)),
        getPrivateChatsAndOpenPrivateChat: (privateChatId) => dispatch(actions.getPrivateChats(privateChatId)),
        onOpenPrivateChat: (privateChatId) => dispatch(actions.openPrivateChat(privateChatId)),
        onGetQuestionnaires: (eventId) => dispatch(actions.getQuestionnaires(eventId)),
        onToggleMatchingDialog: (bool) => dispatch(actions.toggleMatchingDialog(bool)),
        onRequestCloseMatchingBanner: (bool) => dispatch(actions.requestCloseMatchingBanner(bool)),
        addSessionAgenda: (sessionId) => dispatch(actions.addSessionToAgenda(sessionId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Lobby);

import React from 'react';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import Spinner from '../../SmallLayoutComponents/Spinner';
import {countries, getCurrencySymbol, getBackgroundImageUrl, preventDefaultDrag} from '../../Utils/utils';
import BackgroundImage from '../../Images/background.jpg';
import StripePaymentDialog from '../../Dialogs/StripePaymentDialog/StripePaymentDialog';
import TextFieldInfoPoint from '../../Components/InputFields/TextFieldInfoPoint';
import SelectCountry from '../../Components/InputFields/SelectCountry';
import RadioField from '../../Components/InputFields/RadioField';
import SelectField from '../../Components/InputFields/SelectField';
import CheckboxField from '../../Components/InputFields/CheckboxField';
import NotFound from '../NotFound';
import RegisterMenuLinks from '../../Components/RegisterMenuLinks';
import AccessPackage from './AccessPackage';
import {Helmet} from 'react-helmet';
import CustomSDGFields from '../../Components/InputFields/CustomSDGFields';
import cloneDeep from 'lodash/cloneDeep';
import {EXHIBITOR_TYPES} from '../../Utils/constants/shared';
import {
    isLinkRule,
    isFacebookLinkRule,
    isLinkedinLinkRule,
    isTwitterLinkRule,
    isGoogleScholarLinkRule,
    isResearchGateLinkRule,
} from 'Utils/validationRules';

class RegisterAsExhibitor extends React.Component {
    state = {
        user: {
            fields: [
                {
                    isEnabled: true,
                    name: 'company',
                    type: 'text',
                    value: '',
                    label: 'Company',
                    multiline: 0,
                    validators: [],
                    errorMessages: [],
                },
                {
                    isEnabled: true,
                    name: 'title',
                    type: 'text',
                    value: '',
                    label: 'Title',
                    multiline: 0,
                    validators: [],
                    errorMessages: [],
                },
                {
                    isEnabled: true,
                    name: 'phone',
                    type: 'tel',
                    value: '',
                    label: 'Mobile',
                    multiline: 0,
                    validators: ['matchRegexp:^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$'],
                    errorMessages: [
                        `${this.props.languages.translations[this.props.languages.eventLanguage].errors.phoneInvalid}`,
                    ],
                },
                {
                    isEnabled: true,
                    name: 'facebook',
                    type: 'text',
                    value: '',
                    label: 'Facebook profile',
                    multiline: 0,
                    validators: ['isFacebookLink'],
                    errorMessages: [
                        `${
                            this.props.languages.translations[this.props.languages.eventLanguage].errors
                                .facebookLinkInvalid
                        }`,
                    ],
                },
                {
                    isEnabled: true,
                    name: 'linkedin',
                    type: 'text',
                    value: '',
                    label: 'Your Linkedin profile',
                    multiline: 0,
                    validators: ['isLinkedinLink'],
                    errorMessages: [
                        `${
                            this.props.languages.translations[this.props.languages.eventLanguage].errors
                                .linkedinLinkInvalid
                        }`,
                    ],
                },
                {
                    isEnabled: true,
                    name: 'twitter',
                    type: 'text',
                    value: '',
                    label: 'Your Twitter profile',
                    multiline: 0,
                    validators: ['isLTwitterLink'],
                    errorMessages: [
                        `${
                            this.props.languages.translations[this.props.languages.eventLanguage].errors
                                .twitterLinkInvalid
                        }`,
                    ],
                },
                {
                    isEnabled: true,
                    name: 'country',
                    type: 'text',
                    value: '',
                    label: 'Country',
                    multiline: 0,
                    validators: [],
                    errorMessages: [],
                },
                {
                    isEnabled: true,
                    name: 'website',
                    type: 'url',
                    value: '',
                    label: 'Website',
                    multiline: 0,
                    validators: ['isLink'],
                    errorMessages: [
                        `${this.props.languages.translations[this.props.languages.eventLanguage].errors.linkInvalid}`,
                    ],
                },
                {
                    isEnabled: true,
                    name: 'sdgs',
                    type: 'text',
                    value: [],
                    label: 'Which SDGs are you interested in?',
                    multiline: 0,
                    validators: [],
                    errorMessages: [],
                },
            ],
            invitationCode: '',
            country: '',
        },
        disabled: false,
        showCodeInvalidErrorMessage: false,
        errorMessage: '',
        countriesList: countries,
        openPaymentDialog: false,
        selectedPackageId: null,
        errorAccessPackageNotSelected: false,
        brandingTranslation: null,
        unregisterInProgressError: false,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.onSetEventPublicMenu();
        this.addValidationRules();

        const {eventInfo, preselectedAccessPackageId} = this.props;

        // if the event has access management, select a package
        if (eventInfo.exhibitorHasAccessManagement) {
            const accessPackages = eventInfo.exhibitorAccessPackages.filter(
                (accessPackage) => accessPackage.type === 'company'
            );
            if (preselectedAccessPackageId) {
                // check if the package exists and if it is active
                // if it is not active, just reload the page without the packageId in the URL
                // this way we can display all the packages again and also clear the URL
                const selectedPackage = accessPackages.find(
                    (accessPackage) => accessPackage._id === preselectedAccessPackageId
                );
                if (selectedPackage && selectedPackage.isActive) {
                    this.setState({
                        selectedPackageId: preselectedAccessPackageId,
                    });
                } else {
                    let redirectLink = `${window.location.origin}${window.location.pathname}`;
                    window.location.replace(redirectLink);
                }
            } else {
                //select the first active && visible package
                const activeVisiblePackages = accessPackages.filter(
                    (accessPackage) => accessPackage.showOnRegistration && accessPackage.isActive
                );
                if (activeVisiblePackages.length > 0) {
                    const firstPackageId = activeVisiblePackages[0]._id;
                    this.setState({
                        selectedPackageId: firstPackageId,
                    });
                }
            }
        }

        // update the required fields with the settings from the current event
        this.setRegistrationFields();
        this.setLanguagesData();
        this.props.seeSignUpForm();
        document.addEventListener(
            'invalid',
            (function () {
                return function (e) {
                    //prevent the browser from showing default error bubble / hint
                    e.preventDefault();
                    // optionally fire off some custom validation handler
                    // myValidation();
                };
            })(),
            true
        );
    }

    componentDidUpdate(prevProps) {
        const {stripeSecret, languages} = this.props;
        if (prevProps.stripeSecret === '' && stripeSecret !== '') {
            // user created the paymentIntent
            this.setState({openPaymentDialog: true});
        }
        if (prevProps.stripeSecret !== '' && stripeSecret === '') {
            // user closed the striPayment dialog
            this.setState({openPaymentDialog: false});
        }
        if (prevProps.languages.eventLanguage !== languages.eventLanguage) {
            this.setLanguagesData();
            this.updateFieldsErrorsLanguage();
        }
    }

    componentWillUnmount() {
        // remove rule when it is not needed
        this.removeValidationRules();
        document.removeEventListener(
            'invalid',
            (function () {
                return function (e) {
                    //prevent the browser from showing default error bubble / hint
                    e.preventDefault();
                    // optionally fire off some custom validation handler
                    // myValidation();
                };
            })(),
            true
        );
    }

    updateFieldsErrorsLanguage = () => {
        const {fields} = this.state.user;
        const {languages} = this.props;
        const translation = languages.translations[languages.eventLanguage];

        let updatedFields = fields;
        updatedFields.forEach((field) => {
            field.validators.forEach((validator, index) => {
                if (validator === 'required') {
                    field.errorMessages[index] = translation?.errors.required;
                }
                if (validator === 'isFacebookLink') {
                    field.errorMessages[index] = translation?.errors.facebookLinkInvalid;
                }
                if (validator === 'isLinkedinLink') {
                    field.errorMessages[index] = translation?.errors.linkedinLinkInvalid;
                }
                if (validator === 'isLTwitterLink') {
                    field.errorMessages[index] = translation?.errors.twitterLinkInvalid;
                }
                if (validator === 'isLink') {
                    field.errorMessages[index] = translation?.errors.linkInvalid;
                }
                if (validator === 'noCommasAllowed') {
                    field.errorMessages[index] = translation?.errors.noCommasAllowed;
                }
            });
        });

        this.setState({fields: updatedFields});
    };

    setLanguagesData = () => {
        const brandingTranslation = this.props.eventInfo.brandingTranslations.find(
            (translation) => translation.language === this.props.languages.eventLanguage
        );
        this.setState({
            brandingTranslation: brandingTranslation,
        });
    };

    setRegistrationFields = () => {
        // update the required fields with the settings from the current event
        const {user} = this.state;
        const {fields} = this.state.user;
        const {eventInfo, languages} = this.props;
        let updatedFields = [...fields];
        updatedFields.forEach((field) => {
            let eventInfoField = eventInfo.exhibitorPredefinedRegistrationFields[field.name];
            field.label = eventInfoField.label;
            field.isEnabled = eventInfoField.isEnabled;
            if (eventInfoField.isRequired) {
                field.validators.push('required');
                field.errorMessages.push(languages.translations[languages.eventLanguage].errors.required);
            }
        });

        eventInfo.exhibitorRegistrationFields.forEach((extraField) => {
            const field = {
                isEnabled: true,
                name: extraField._id,
                type: extraField.type,
                value: '',
                label: extraField.label,
                multiline: 0,
                validators: extraField.isRequired ? ['required'] : [],
                errorMessages: extraField.isRequired
                    ? [languages.translations[languages.eventLanguage].errors.required]
                    : [],
                options: extraField.options,
            };

            updatedFields.push(field);
        });

        let enabledUpdatedFields = updatedFields.filter((field) => field.isEnabled);

        this.setState({
            user: {...user, fields: enabledUpdatedFields},
        }, this.addNoCommasValidation);
    };

    addNoCommasValidation = () => {
        const updatedUser = cloneDeep(this.state.user);
        const {translation, defaultTranslation} = this.props;
        updatedUser.fields.forEach(field => {
            // for the sdg field we send as values an array
            // an array contains ',' and we couldn't pass the validation
            // by adding !Array.isArray(field.value) we fixed this bug
            if(field.type === "text" && !Array.isArray(field.value)){
                field.validators.push('noCommasAllowed');
                const errorMessage = translation.errors.noCommasAllowed || defaultTranslation.errors.noCommasAllowed;
                field.errorMessages.push(errorMessage);
            }
        })
        this.setState({
            user: updatedUser
        })
    }

    handleFieldChange = (fieldName, fieldValue) => {
        const {user} = this.state;
        const {fields} = this.state.user;
        let updatedFields = [...fields];

        let updatedFieldIndex = updatedFields.findIndex((field) => field.name === fieldName);

        updatedFields[updatedFieldIndex].value = fieldValue;
        this.setState({user: {...user, fields: updatedFields}}, () => {
            this.refs.form.isFormValid().then((isValid) => {
                this.setState({disabled: !isValid});
            });
        });
    };

    handleChangeInvitationCode = (e) => {
        const {user} = this.state;
        this.setState(
            {
                user: {
                    ...user,
                    [e.target.name]: e.target.value,
                },
            },
            () => {
                this.refs.form.isFormValid().then((isValid) => {
                    this.setState({disabled: !isValid});
                });
            }
        );
    };

    addValidationRules = () => {
        ValidatorForm.addValidationRule('isFacebookLink', (value) => {
            let rule = isFacebookLinkRule;
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            return match;
        });
        ValidatorForm.addValidationRule('isLinkedinLink', (value) => {
            let rule = isLinkedinLinkRule;
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            return match;
        });
        ValidatorForm.addValidationRule('isLTwitterLink', (value) => {
            let rule = isTwitterLinkRule;
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            return match;
        });
        ValidatorForm.addValidationRule('isLink', (value) => {
            let rule = isLinkRule;
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                this.setState({newLinkError: true});
                return false;
            }
            this.setState({newLinkError: false});
            return true;
        });
        ValidatorForm.addValidationRule('noCommasAllowed', (value) => {
            let rule = new RegExp('^[^,]+$', 'i');
            let match = rule.test(value);

            if (value === undefined || value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
    };

    removeValidationRules = () => {
        ValidatorForm.removeValidationRule('isFacebookLink');
        ValidatorForm.removeValidationRule('isLinkedinLink');
        ValidatorForm.removeValidationRule('isLTwitterLink');
        ValidatorForm.removeValidationRule('isLink');
        ValidatorForm.removeValidationRule('noCommasAllowed');
    };

    handleRegister = () => {
        const {user} = this.state;
        let {eventInfo, eventId} = this.props;
        if (eventInfo && eventInfo.closedEvent) {
            this.props.onCheckInvitationCode(eventId, user.invitationCode.trim()).then((response) => {
                if (response.isCodeValid === false) {
                    this.setState({
                        showCodeInvalidErrorMessage: true,
                        errorMessage: response.message,
                    });
                } else {
                    this.setState({
                        showCodeInvalidErrorMessage: false,
                        errorMessage: '',
                    });
                    this.registerUser();
                }
            });
        } else {
            this.registerUser();
        }
    };

    handleOnError = () => {
        const inputElementsWithErrors = document.getElementsByClassName('Mui-error');
        if (inputElementsWithErrors.length > 0) {
            const errorElement = inputElementsWithErrors[0];
            let scrollTop = errorElement.getBoundingClientRect().top + document.documentElement.scrollTop;
            if (errorElement.parentElement.className.includes('sdg-label')) {
                scrollTop = document.getElementsByClassName('sdgs-wrapper')[0].getBoundingClientRect().top + document.documentElement.scrollTop;
            }
            if (window.innerWidth > 767) {
                scrollTop = scrollTop - 110;
            } else {
                scrollTop = scrollTop - 30;
            }

            window.scrollTo({
                top: scrollTop,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    registerUser = () => {
        const {selectedPackageId} = this.state;
        const {eventId, eventInfo} = this.props;

        // create user data Object to send with the Api Call
        let user = {};
        user.type = EXHIBITOR_TYPES.company;

        this.state.user.fields.forEach((field) => {
            let fieldValue = field.value;
            if(typeof field.value === 'string'){
                fieldValue = field.value?.trim();
            }
            user[field.name] = fieldValue;
        });
        user.invitationCode = this.state.user.invitationCode.trim();

        // this.props.onRegisterExhibitor(eventId, user).then(() => this.props.refreshUserData());
        if (eventInfo.exhibitorHasAccessManagement) {
            if (selectedPackageId) {
                const paymentData = {
                    userId: this.props.user._id,
                    eventId: eventId,
                    packageId: selectedPackageId,
                };

                // fullData is an object containing the user data and payment data
                const fullData = {...paymentData, ...user};

                // if the selectedPackage is Free, we should directly register the user
                const selectedPackageData = eventInfo.exhibitorAccessPackages.find(
                    (accessPackage) => accessPackage._id === selectedPackageId
                );
                if (selectedPackageData.isFree) {
                    // automatically register the user
                    user.accessPackage = selectedPackageId;
                    this.props.onRegisterExhibitor(eventId, user)
                        .then(() => this.props.refreshUserData())
                        .catch((error) => this.handleRegisterApiError(error));
                } else {
                    this.props.onCreateExhibitorPaymentIntent(fullData);
                }
            } else {
                this.setState({
                    errorAccessPackageNotSelected: true,
                });
            }
        } else {
            this.props.onRegisterExhibitor(eventId, user)
                .then(() => this.props.refreshUserData())
                .catch((error) => this.handleRegisterApiError(error));
        }
    };

    handleRegisterApiError = (error) => {
        const errorMessage = error.response.data.error;
        if (errorMessage === 'cannot register while account is still being deleted.') {
            this.setState({
                unregisterInProgressError: true,
            });
        }
    };

    handleChangeCountry = (e) => {
        const {user} = this.state;
        this.setState({
            user: {...user, country: e.target.value},
        });
    };

    cancelPaymentIntent = () => {
        this.props.onCancelPaymentIntent();
    };

    stripeResponse = (response) => {
        this.setState({loading: true});
    };

    selectPackage = (packageId) => (e) => {
        this.setState({selectedPackageId: packageId, errorAccessPackageNotSelected: false});
    };

    getAllFields = () => {
        const {fields} = this.state.user;
        const {eventInfo, isLargeScreen} = this.props;
        return fields?.map((field) => {
            if (field.name === 'country') {
                return (
                    <SelectCountry
                        field={field}
                        handleChange={this.handleFieldChange}
                        key={field.name}
                    />
                );
            }
            if (field.type === 'select') {
                return (
                    <SelectField
                        field={field}
                        handleChange={this.handleFieldChange}
                        key={field.name}
                    />
                );
            }
            if (field.type === 'radio') {
                return (
                    <RadioField
                        field={field}
                        handleChange={this.handleFieldChange}
                        key={field.name}
                    />
                );
            }
            if (field.type === 'checkbox') {
                return (
                    <CheckboxField
                        field={field}
                        handleChange={this.handleFieldChange}
                        key={field.name}
                        fieldValues={field.value}
                    />
                );
            }
            if (field.name === 'sdgs') {
                return (
                    <CustomSDGFields
                        field={field}
                        key={field.name}
                        handleChange={this.handleFieldChange}
                        eventInfo={eventInfo}
                        fieldValues={field.value}
                    />
                )
            }
            return (
                <TextFieldInfoPoint
                    field={field}
                    handleChange={this.handleFieldChange}
                    key={field.name}
                    isLargeScreen={isLargeScreen}
                />
            );
        })
    };

    render() {
        const {invitationCode} = this.state.user;
        const {
            eventInfo,
            loadingUser,
            loadingEvent,
            preselectedAccessPackageId,
            stripeSecret,
            translation,
            defaultTranslation,
            isMobile,
        } = this.props;
        const {
            selectedPackageId,
            showCodeInvalidErrorMessage,
            errorMessage,
            errorAccessPackageNotSelected,
            openPaymentDialog,
            brandingTranslation,
            unregisterInProgressError
        } = this.state;
        let overlayColor = {};
        if (eventInfo.brandingData.overlayColorExhibitorReg) {
            overlayColor.background = eventInfo.brandingData.overlayColorExhibitorReg;
        }
        let selectedPackageData = null;
        const accessPackages = eventInfo.exhibitorAccessPackages.filter(
            (accessPackage) => accessPackage.type === 'company'
        );
        if (eventInfo.exhibitorHasAccessManagement) {
            if (selectedPackageId) {
                selectedPackageData = accessPackages.find((accessPackage) => accessPackage._id === selectedPackageId);
            }
        }

        // if the event doesn't support scholars, return NotFound page
        if (!eventInfo.companyExhibitorsType) {
            return <NotFound />;
        }

        return (
            <>
                <div onDragStart={preventDefaultDrag} className="register-participant-step-2">
                    <Helmet>
                        <title>{`${process.env.REACT_APP_PLATFORM_NAME} - ${
                            brandingTranslation?.homepageEventName
                                ? brandingTranslation?.homepageEventName
                                : eventInfo.title
                        }: ${
                            translation?.exhibitorRegistration.metaTitle ||
                            defaultTranslation?.exhibitorRegistration.metaTitle
                        }`}</title>
                    </Helmet>
                    <div onDragStart={preventDefaultDrag} className="full-background-container p-relative d-flex">
                        {eventInfo.brandingData && eventInfo.brandingData.exhibitorsRegBackground ? (
                            <img
                                src={getBackgroundImageUrl(eventInfo, 'exhibitorsRegBackground')}
                                className="full-background-img img-cover p-absolute w-100 h-100"
                                alt="background"
                            />
                        ) : (
                            <img
                                src={BackgroundImage}
                                className="full-background-img img-cover p-absolute w-100 h-100"
                                alt="background"
                            />
                        )}
                        <div
                            onDragStart={preventDefaultDrag}
                            className="full-background-overlay p-absolute w-100 h-100"
                            style={overlayColor}
                        />
                        <div onDragStart={preventDefaultDrag} className="header-spacing-container">
                            <div
                                onDragStart={preventDefaultDrag}
                                className="w-100 d-flex justify-content-center align-items-center"
                            >
                                {eventInfo ? (
                                    <div onDragStart={preventDefaultDrag} className="form-container d-flex">
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className="form-header left-log-in-description is-sign-up"
                                        >
                                            <h1 className="form-title banner-text">
                                                <div>
                                                    <span>
                                                        {translation?.register.registerButton ||
                                                            defaultTranslation?.register.registerButton}
                                                        {' ' +
                                                        (translation?.login.logInAsExhibitorSecondText ||
                                                            defaultTranslation?.login.logInAsExhibitorSecondText)}
                                                    </span>
                                                </div>
                                            </h1>
                                            <div onDragStart={preventDefaultDrag} draggable="false" className="steps-text">
                                                <div>
                                                    <div>
                                                        {translation?.register.oneStep ||
                                                        defaultTranslation?.register.oneStep}
                                                    </div>
                                                    <div className="active">
                                                        {translation?.register.twoStep ||
                                                        defaultTranslation?.register.twoStep}
                                                    </div>
                                                </div>
                                                <span>
                                                    {translation?.register.steps ||
                                                    defaultTranslation?.register.steps}
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <div onDragStart={preventDefaultDrag} className="left-form">
                                                <div>
                                                    <p>
                                                        {brandingTranslation?.exhibitorsRegDescription
                                                            .split('\n')
                                                            .map((item, key) => {
                                                                return (
                                                                    <span
                                                                        onDragStart={preventDefaultDrag}
                                                                        draggable="false"
                                                                        key={key}
                                                                    >
                                                                        {item}
                                                                        <br />
                                                                    </span>
                                                                );
                                                            })}
                                                    </p>
                                                </div>
                                            </div>
                                            <div onDragStart={preventDefaultDrag} className="right-form">
                                                <div onDragStart={preventDefaultDrag} className="register-form-wrapper">
                                                    <ValidatorForm
                                                        ref="form"
                                                        onSubmit={this.handleRegister}
                                                        onError={this.handleOnError}
                                                    >
                                                        {this.getAllFields()}

                                                        {eventInfo && eventInfo.closedEvent && (
                                                            <div
                                                                onDragStart={preventDefaultDrag}
                                                                className="invitation-code-wrapper"
                                                            >
                                                                <TextValidator
                                                                    placeholder={
                                                                        translation?.register.invitationCode ||
                                                                        defaultTranslation?.register.invitationCode
                                                                    }
                                                                    onChange={this.handleChangeInvitationCode}
                                                                    name="invitationCode"
                                                                    value={invitationCode}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    className="field-container invitation-code-input"
                                                                    validators={['required']}
                                                                    errorMessages={[
                                                                        `${translation?.errors.noInvitationCode}`,
                                                                    ]}
                                                                    onFocus={this.handleInputFocus}
                                                                    onBlur={this.handleInputBlur}
                                                                />
                                                                {showCodeInvalidErrorMessage && (
                                                                    <p
                                                                        onDragStart={preventDefaultDrag}
                                                                        className="error-message"
                                                                    >
                                                                        {errorMessage}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        )}
                                                        <AccessPackage
                                                            translation={translation}
                                                            eventInfo={eventInfo}
                                                            accessPackages={accessPackages}
                                                            hasAccessManagement={'exhibitorHasAccessManagement'}
                                                            paidEvent={'exhibitorPaidEvent'}
                                                            preselectedAccessPackageId={preselectedAccessPackageId}
                                                            selectedPackageId={selectedPackageId}
                                                            selectPackage={this.selectPackage}
                                                            errorAccessPackageNotSelected={
                                                                errorAccessPackageNotSelected
                                                            }
                                                        />
                                                        <div
                                                            onDragStart={preventDefaultDrag}
                                                            className="buttons-wrapper"
                                                        >
                                                            <button
                                                                type="submit"
                                                                disabled={
                                                                    this.props.loadingInvitationCode ||
                                                                    eventInfo.restrictExhibitorRegistration
                                                                }
                                                            >
                                                                {eventInfo.exhibitorHasAccessManagement ? (
                                                                    <>
                                                                        {selectedPackageData &&
                                                                        selectedPackageData.isFree
                                                                            ? translation?.exhibitorRegistration
                                                                                  .registerButtonFree
                                                                            : translation?.exhibitorRegistration
                                                                                  .registerButtonPay}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {eventInfo.restrictExhibitorRegistration
                                                                            ? translation?.register
                                                                                  .registerNotAllowed ||
                                                                              defaultTranslation?.register
                                                                                  .registerNotAllowed
                                                                            : translation?.exhibitorRegistration
                                                                                  .registerButtonFree}
                                                                    </>
                                                                )}
                                                            </button>
                                                        </div>
                                                        {unregisterInProgressError && (
                                                            <p
                                                                onDragStart={preventDefaultDrag}
                                                                className="error-text unregister-in-progress-error"
                                                            >
                                                                {translation?.register.unregisterInProgressError ||
                                                                    defaultTranslation.register
                                                                        .unregisterInProgressError}
                                                            </p>
                                                        )}
                                                    </ValidatorForm>
                                                </div>
                                                {(loadingUser || (loadingEvent && eventInfo)) && <Spinner />}
                                                {isMobile && <RegisterMenuLinks />}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {openPaymentDialog && (
                            <StripePaymentDialog
                                open={openPaymentDialog}
                                onClose={this.cancelPaymentIntent}
                                stripeSecret={stripeSecret}
                                onStripeResponse={this.stripeResponse}
                                organizerStripeAccountId={eventInfo.owner.stripeConnect.connectedAccountId}
                                selectedPackageData={selectedPackageData}
                                currency={getCurrencySymbol(eventInfo.currency)}
                            />
                        )}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        loadingEvent: state.event.loading,
        eventInfo: state.event.eventInfo,
        loggedIn: state.user.loggedIn,
        loadingUser: state.user.loading,
        user: state.user.data,
        preselectedAccessPackageId: state.user.preselectedAccessPackageId,
        stripeSecret: state.user.stripeSecret,
        isInvitationCodeValid: state.invitations.isInvitationCodeValid,
        loadingInvitationCode: state.invitations.loading,
        isMobile: state.layout.isMobile,
        isLargeScreen: state.layout.isLargeScreen,
        languages: state.languages,
        translation: state.languages.translations[state.languages.eventLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRegisterExhibitor: (eventId, user) => dispatch(actions.registerExhibitor(eventId, user)),
        onCheckInvitationCode: (eventId, invitationCode) =>
            dispatch(actions.checkInvitationCode(eventId, invitationCode)),
        onCreateExhibitorPaymentIntent: (paymentData) => dispatch(actions.createExhibitorPaymentIntent(paymentData)),
        onCancelPaymentIntent: () => dispatch(actions.cancelPaymentIntent()),
        refreshUserData: () => dispatch(actions.autoLoginWithToken()),
        onSetEventPublicMenu: () => dispatch(actions.setEventPublicMenu()),
        seeSignUpForm: () => dispatch(actions.seeSignUpForm()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterAsExhibitor);

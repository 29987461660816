import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import userReducer from './reducers/userReducer';
import eventReducer from './reducers/eventReducer';
import eventArchiveReducer from './reducers/eventArchiveReducer';
import exhibitorsReducer from './reducers/exhibitorsReducer';
import exhibitorReducer from './reducers/exhibitorReducer';
import invitationsReducer from './reducers/invitationsReducer';
import adminReducer from './reducers/adminReducer';
import layoutReducer from './reducers/layoutReducer';
import languagesReducer from './reducers/languagesReducer';
import videoConferenceReducer from './reducers/videoConferenceReducer';
import organizerReducer from './reducers/organizerReducer';
import buildingReducer from './reducers/buildingReducer';
import onDemandVideosReducer from './reducers/onDemandVideosReducer';
import showfloorsReducer from './reducers/showfloorsReducer';
import eventUsersReducer from './reducers/eventUsersReducer';
import newsletterReducer from './reducers/newsletterReducer';
import sessionsReducer from './reducers/sessionReducer';
import interpretation from './reducers/interpretation';
import liveSessionReducer from './reducers/liveSessionReducer';
import auditoriumReducer from './reducers/auditoriumReducer';
import {eventListReducer, downloadExcelReducer} from './reducers/legalPageReducer';
import {importProgramReducer} from './reducers/importProgramReducer';
import {importAuditoriumProgramReducer} from './reducers/importAuditoriumProgramReducer';
import {evenSpeakertListReducer} from './reducers/eventSpeakersReducers';
import userMediaStream from './reducers/userMediaStreamReducer';
import eventWelcomeScreenReducer from './reducers/eventWelcomeScreenReducer';
import userEventProfileReducer from './reducers/userEventProfileReducer';
import onlineUsersReducer from './reducers/onlineUsersReducer';
import wallReducer from './reducers/wallReducer';
import eventUsersWithAdditionalDataReducer from './reducers/eventUsersWithAdditionalDataReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    admin: adminReducer,
    auditorium: auditoriumReducer,
    building: buildingReducer,
    downloadAsExcel: downloadExcelReducer,
    event: eventReducer,
    eventArchive: eventArchiveReducer,
    eventUsers: eventUsersReducer,
    eventUsersWithAdditionalData: eventUsersWithAdditionalDataReducer,
    eventWelcomeScreen: eventWelcomeScreenReducer,
    exhibitor: exhibitorReducer,
    exhibitors: exhibitorsReducer,
    interpretation,
    invitations: invitationsReducer,
    languages: languagesReducer,
    layout: layoutReducer,
    legalPages: eventListReducer,
    liveSession: liveSessionReducer,
    newsletter: newsletterReducer,
    onDemandVideos: onDemandVideosReducer,
    organizer: organizerReducer,
    sessions: sessionsReducer,
    showfloors: showfloorsReducer,
    speakers: evenSpeakertListReducer,
    user: userReducer,
    userMediaStream: userMediaStream,
    importedProgram: importProgramReducer,
    importedAuditoriumProgram: importAuditoriumProgramReducer,
    videoConference: videoConferenceReducer,
    userEventProfile: userEventProfileReducer,
    onlineUsers: onlineUsersReducer,
    wall: wallReducer,

});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;

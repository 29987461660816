import React, {PureComponent} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import InformDialog from '../../../Dialogs/InformDialog';
import colors from '../../../CSS/_variables.module.scss';
import {ReactComponent as AuditoriumIcon} from '../../../Images/svg/auditorium_icon.svg';
import {
    checkIfEventHasLiveTimeslot,
    checkIfEventHasUpcomingTimeslot,
    formatDecimalHoursToHoursAndMinutes,
    numberOfTotalVideos,
    preventDefaultDrag,
} from '../../../Utils/utils';
import {ReactComponent as HoverHand} from '../../../Images/svg/hover-hand.svg';
import LiveStatus from '../../EventMenuDropdowns/AdvancedProgramComponents/LiveStatus';
import UpComingStatus from '../../EventMenuDropdowns/AdvancedProgramComponents/UpComingStatus';
import moment from 'moment';

class AuditoriumLink extends PureComponent {
    getAuditoriumName = (liveTimeSlot) => {
        const {event} = this.props;
        let auditoriumName = '';
        event.auditoriums.forEach((auditorium) => {
            auditorium.dailyProgram.forEach((auditoriumDayProgram) => {
                auditoriumDayProgram.program.forEach((timeSlot) => {
                    if (timeSlot._id === liveTimeSlot[0]._id) {
                        auditoriumName = auditorium.name;
                    }
                });
            });
        });

        return auditoriumName;
    };

    render() {
        const {
            classes,
            event,
            eventSlug,
            translation,
            defaultTranslation,
            hasAccessToAuditoriums,
            eventHasEnded,
            activeAuditoriumArchive,
            location,
            showNoAuditoriumAccessDialog,
            handleOpenNoAuditoriumDialog,
            handleOpenNoAuditoriumAccess,
            showNoAuditoriumDialog,
            closeDialog,
            lobby,
        } = this.props;

        let activeAuditorium = location.pathname.indexOf(`/${eventSlug}/auditorium`) > -1;
        activeAuditorium = activeAuditorium && !activeAuditoriumArchive;

        let numberOfVideos = numberOfTotalVideos(event).length;

        let liveTimeSlot = checkIfEventHasLiveTimeslot(event, event.timezoneValue);

        let auditoriumTitleLive;
        if (liveTimeSlot.length > 0) {
            auditoriumTitleLive = this.getAuditoriumName(liveTimeSlot);
        }

        let upcomingTimeSlot = checkIfEventHasUpcomingTimeslot(event);
        let auditoriumTitleUpcoming;
        if (upcomingTimeSlot.length > 0) {
            auditoriumTitleUpcoming = this.getAuditoriumName(upcomingTimeSlot);
        }


        return (
            <>
                <div onDragStart={preventDefaultDrag} className={classes}>
                    {event.restrictAuditoriumAccess ? (
                        <>
                            {lobby ? (
                                <div
                                    onDragStart={preventDefaultDrag}
                                    onClick={handleOpenNoAuditoriumDialog}
                                    className="show-info-dialog-button"
                                >
                                    <div className="door-text-wrapper">
                                        <HoverHand className="d-none"/>
                                        <div>
                                            {event.brandingData.auditoriumDoorText && (
                                                <p
                                                    style={{
                                                        color: event.brandingData.lobbyDoorsTextColor
                                                            ? event.brandingData.lobbyDoorsTextColor
                                                            : colors.doorColor,
                                                    }}
                                                >
                                                    {event.brandingData.auditoriumDoorText}
                                                </p>
                                            )}
                                            <span className="d-none">
                                                {translation?.demoMessages?.clickToOpen ||
                                                defaultTranslation?.demoMessages?.clickToOpen}
                                            </span>
                                        </div>
                                        <div className="bottom-wrapper">
                                            { numberOfVideos === 1
                                                ?
                                                <p className="number-videos">
                                                    {numberOfVideos + ' ' + (translation?.auditorium.videoOnDemand ||
                                                        defaultTranslation?.auditorium.videoOnDemand)}
                                                </p>
                                                :
                                                <p className="number-videos">
                                                    {numberOfVideos + ' ' + (translation?.auditorium.videosOnDemand ||
                                                        defaultTranslation?.auditorium.videosOnDemand)}
                                                </p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <AuditoriumIcon fill={colors.greyVariant} />
                                    <p onDragStart={preventDefaultDrag} onClick={handleOpenNoAuditoriumDialog}>
                                        {event.brandingData.auditoriumDoorText
                                            ? event.brandingData.auditoriumDoorText
                                            : translation?.roomsDropdown.auditoriumButton}
                                    </p>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {!hasAccessToAuditoriums ? (
                                <>
                                    {lobby ? (
                                        <div onClick={handleOpenNoAuditoriumAccess} className="show-info-dialog-button">
                                            <div className="door-text-wrapper">
                                                <HoverHand className="d-none"/>
                                                <div>
                                                    {event.brandingData.auditoriumDoorText && (
                                                        <p
                                                            style={{
                                                                color: event.brandingData.lobbyDoorsTextColor
                                                                    ? event.brandingData.lobbyDoorsTextColor
                                                                    : colors.doorColor,
                                                            }}
                                                        >
                                                            {event.brandingData.auditoriumDoorText}
                                                        </p>
                                                    )}
                                                    <span className="d-none">
                                                        {translation?.demoMessages?.clickToOpen ||
                                                        defaultTranslation?.demoMessages?.clickToOpen}
                                                    </span>
                                                </div>
                                                <div className="bottom-wrapper">
                                                    { numberOfVideos === 1
                                                        ?
                                                        <p className="number-videos">
                                                            {numberOfVideos + ' ' + (translation?.auditorium.videoOnDemand ||
                                                                defaultTranslation?.auditorium.videoOnDemand)}
                                                        </p>
                                                        :
                                                        <p className="number-videos">
                                                            {numberOfVideos + ' ' + (translation?.auditorium.videosOnDemand ||
                                                                defaultTranslation?.auditorium.videosOnDemand)}
                                                        </p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <AuditoriumIcon fill={colors.greyVariant} />
                                            <p onDragStart={preventDefaultDrag} onClick={handleOpenNoAuditoriumAccess}>
                                                {event.brandingData.auditoriumDoorText
                                                    ? event.brandingData.auditoriumDoorText
                                                    : translation?.roomsDropdown.auditoriumButton}
                                            </p>
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {eventHasEnded ? (
                                        <Link
                                            tabIndex="0"
                                            className={`${activeAuditoriumArchive ? 'active' : ''}`}
                                            to={{pathname: `/event/${eventSlug}/auditorium-archive/`}}
                                        >
                                            <div className="door-text-wrapper">
                                                <HoverHand className="d-none"/>
                                                <div>
                                                    {event.brandingData.auditoriumDoorText && (
                                                        <p
                                                            style={{
                                                                color: event.brandingData.lobbyDoorsTextColor
                                                                    ? event.brandingData.lobbyDoorsTextColor
                                                                    : colors.doorColor,
                                                            }}
                                                        >
                                                            {event.brandingData.auditoriumDoorText}
                                                        </p>
                                                    )}
                                                    <span className="d-none">
                                                        {translation?.demoMessages?.clickToOpen ||
                                                        defaultTranslation?.demoMessages?.clickToOpen}
                                                    </span>
                                                </div>
                                                <div className="bottom-wrapper">
                                                    { numberOfVideos === 1
                                                        ?
                                                        <p className="number-videos">
                                                            {numberOfVideos + ' ' + (translation?.auditorium.videoOnDemand ||
                                                                defaultTranslation?.auditorium.videoOnDemand)}
                                                        </p>
                                                        :
                                                        <p className="number-videos">
                                                            {numberOfVideos + ' ' + (translation?.auditorium.videosOnDemand ||
                                                                defaultTranslation?.auditorium.videosOnDemand)}
                                                        </p>
                                                    }
                                                </div>
                                            </div>
                                            {!lobby && (
                                                <>
                                                    <AuditoriumIcon fill={colors.greyVariant} />
                                                    <p>
                                                        {event.brandingData.auditoriumDoorText
                                                            ? event.brandingData.auditoriumDoorText
                                                            : translation?.roomsDropdown.auditoriumButton}
                                                    </p>
                                                </>
                                            )}
                                        </Link>
                                    ) : (
                                        <Link
                                            tabIndex="0"
                                            className={`${activeAuditorium ? 'active' : ''}`}
                                            to={{pathname: `/event/${eventSlug}/auditorium/`}}
                                        >
                                            <div className="door-text-wrapper">
                                                <HoverHand className="d-none"/>
                                                <div>
                                                    {event.brandingData.auditoriumDoorText && (
                                                        <p
                                                            style={{
                                                                color: event.brandingData.lobbyDoorsTextColor
                                                                    ? event.brandingData.lobbyDoorsTextColor
                                                                    : colors.doorColor,
                                                            }}
                                                        >
                                                            {event.brandingData.auditoriumDoorText}
                                                        </p>
                                                    )}
                                                    <span className="d-none">
                                                        {translation?.demoMessages?.clickToOpen ||
                                                        defaultTranslation?.demoMessages?.clickToOpen}
                                                    </span>
                                                </div>
                                                <div className="bottom-wrapper">
                                                    {liveTimeSlot.length > 0
                                                        ?
                                                        <div className="status-timeslot">
                                                            <div className="status-timeslot-content">
                                                                <p>{liveTimeSlot[0].title}</p>
                                                                <div>
                                                                    <span>
                                                                        {liveTimeSlot[0].start + ' - ' + liveTimeSlot[0].end + ' '}
                                                                        {event.showTimezone && (
                                                                            <span>
                                                                                (GMT{' '}
                                                                                {event.timezoneValue >= 0 ? '+' : ''}
                                                                                {formatDecimalHoursToHoursAndMinutes(event.timezoneValue)})
                                                                            </span>
                                                                        )}
                                                                    </span>
                                                                </div>
                                                                <span>{auditoriumTitleLive}</span>
                                                            </div>
                                                            <LiveStatus
                                                                translation={translation}
                                                                defaultTranslation={defaultTranslation}
                                                            />
                                                        </div>
                                                        :
                                                        <>
                                                            {upcomingTimeSlot.length > 0
                                                                ?
                                                                <div className="status-timeslot">
                                                                    <div className="status-timeslot-content">
                                                                        <p>{upcomingTimeSlot[0].title}</p>
                                                                        <div>
                                                                            <span>
                                                                                {upcomingTimeSlot[0].start + ' - ' + upcomingTimeSlot[0].end + ' '}
                                                                                {event.showTimezone && (
                                                                                    <span>
                                                                                        (GMT{' '}
                                                                                        {event.timezoneValue >= 0 ? '+' : ''}
                                                                                        {formatDecimalHoursToHoursAndMinutes(event.timezoneValue)}),
                                                                                        {' ' + moment(upcomingTimeSlot[0].startTimestamp).format('DD MMM YYYY')}
                                                                                    </span>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        <span>{auditoriumTitleUpcoming}</span>
                                                                    </div>
                                                                    <UpComingStatus
                                                                        translation={translation}
                                                                        defaultTranslation={defaultTranslation}
                                                                    />
                                                                </div>
                                                                :
                                                                <>
                                                                    { numberOfVideos === 1
                                                                        ?
                                                                        <p className="number-videos">{numberOfVideos + ' video on-demand'}</p>
                                                                        :
                                                                        <p className="number-videos">{numberOfVideos + ' videos on-demand'}</p>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            {!lobby && (
                                                <>
                                                    <AuditoriumIcon fill={colors.greyVariant} />
                                                    <p>
                                                        {event.brandingData.auditoriumDoorText
                                                            ? event.brandingData.auditoriumDoorText
                                                            : translation?.roomsDropdown.auditoriumButton}
                                                    </p>
                                                </>
                                            )}
                                        </Link>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
                {showNoAuditoriumDialog && (
                    <InformDialog
                        open={showNoAuditoriumDialog}
                        onClose={closeDialog}
                        dialogClasses={'closed-doors'}
                        dialogTitle={event.brandingData.closedAuditoriumTitle}
                        dialogDescription={event.brandingData.closedAuditoriumText}
                        dialogButtonLabel={`${translation?.closedShowfloorDialog.button}`}
                    />
                )}
                {showNoAuditoriumAccessDialog && (
                    <InformDialog
                        open={showNoAuditoriumAccessDialog}
                        onClose={closeDialog}
                        dialogTitle={`${translation?.closedAuditoriumDialog.title}`}
                        dialogDescription={`${translation?.closedAuditoriumDialog.contentPackage}`}
                        dialogButtonLabel={`${translation?.closedShowfloorDialog.button}`}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventSlug: state.event.eventSlug,
    };
};

export default withRouter(connect(mapStateToProps)(AuditoriumLink));

import React, {Component, PureComponent} from 'react';
import {connect} from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class AccountNavigation extends PureComponent {
    getTabsList = () => {
        const {tabsList, selectedTab, hasMatchingEnabled, matchingFormSeen, matchingDetailsPrecompleted, hasParticipantExhibitorProfile} = this.props;
        return tabsList.map((tab) => {
            if (tab.name === 'matchingForm') {
                if (!hasParticipantExhibitorProfile || !(hasMatchingEnabled && matchingFormSeen && matchingDetailsPrecompleted)) {
                    return null;
                }
            }
            return (
                <Accordion
                    expanded={selectedTab === tab.name}
                    onChange={this.props.selectTab(tab.name)}
                    className={'accordion ' + (selectedTab === tab.name ? 'active' : '')}
                    key={tab.name}
                >
                    <AccordionSummary
                        className="summary"
                    >
                        <p>{tab.label}</p>
                    </AccordionSummary>
                </Accordion>
            );
        })
    };
    render() {
        return (
            <div className="account-navigation">
                {this.getTabsList()}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps)(AccountNavigation);

const zh = {
    generalText: {
        upload: '上传',
        save: '保存',
        add: '添加',
        select: '选取',
        remove: '移除',
        close: '关闭',
        update: '更新',
        cancel: '取消',
        confirm: '确认',
        download: '下载',
        refresh: '刷新',
        manage: '管理',
        back: '返回',
        submit: '提交',
        send: '发送',
        eventMO: 'onvent',
        eventETX: '活动',
        eventsMO: 'onvents',
        eventsETX: '活动',
        eventUpperMO: 'Onvent',
        eventUpperETX: '活动',
        hide: 'HIDE', //new translation: HIDE
    },
    time: {
        justNow: '刚才',
        minute: '分钟',
        minutes: '分钟',
        hour: '小时',
        hours: '小时',
        day: '天',
        days: '天',
        month: '月',
        months: '月',
        yesterday: '昨天',
        today: '今天',
        tomorrow: '明天',
        monday: '星期一',
        tuesday: '星期二',
        wednesday: '星期三',
        thursday: '星期四',
        friday: '星期五',
        saturday: '星期六',
        sunday: '星期日',
        mondayShort: '周一',
        tuesdayShort: '周二',
        wednesdayShort: '周三',
        thursdayShort: '周四',
        fridayShort: '周五',
        saturdayShort: '周六',
        sundayShort: '周日',
        january: '一月',
        february: '二月',
        march: '三月',
        april: '四月',
        may: '五月',
        june: '六月',
        july: '七月',
        august: '八月',
        september: '九月',
        october: '十月',
        november: '十一月',
        december: '十二月',
        januaryShort: '一月',
        februaryShort: '二月',
        marchShort: '三月',
        aprilShort: '四月',
        mayShort: '五月',
        juneShort: '六月',
        julyShort: '七月',
        augustShort: '八月',
        septemberShort: '九月',
        octoberShort: '十月',
        novemberShort: '十一月',
        decemberShort: '十二月',
    },
    errors: {
        noFile: '没有选中任何文件',
        required: '必填项',
        emailNotValid: '电子邮件无效',
        passwordMinLength: '密码必须至少8个字符',
        passwordMismatch: '密码不匹配',
        passwordIsSame: 'Your new password must be different', //new translation
        fileToLarge: '文件过大。 文件大小上限为2Mb。',
        fileToLargeTextFirst: '文件过大。',
        fileToLargeTextSecond: '文件最大上限',
        agreeToGDPR: '请同意隐私政策',
        noInvitationCode: '请输入您的邀请码',
        invitationCodeInvalid: '邀请码无效。',
        imageFileNotSupported: '不支持的文件类型。 请使用以下之一：',
        phoneInvalid: '请输入一个有效的电话号码。 例如：+447517663928',
        linkInvalid: '请输入一个有效的链接 (https://site.com/)',
        facebookLinkInvalid: '请输入一个有效链接(https://facebook.com/)',
        linkedinLinkInvalid: '请输入一个有效链接(https://linkedin.com/)',
        twitterLinkInvalid: '请输入一个有效链接(https://twitter.com/)',
        youtubeLinkInvalid: '请输入有效的链接（https://www.youtube.com/）',
        connectionProblemTextFirst: '似乎存在连接问题。 请',
        connectionProblemButton: '点击',
        connectionProblemTextSecond: '或刷新页面。',
        fileTooLarge: '文件过大。 档案大小上限为2Mb。',
        fileTooLargeTextFirst: '文件过大。',
        fileTooLargeTextSecond: '最大文件大小。',
    },
    footer: {
        cookiesText:
            '本网站使用必要的信息记录程序，这是网站运作所必需的。本网站也使用第三方信息记录程序，这对于我们网站的某些功能的运作是必要的。如果您想了解更多信息或撤销您对某些信息记录程序的同意，请参考我们的',
        cookiesLink: '信息记录程序的政策。',
        cookiesButton: '接收',
        privacyPolicy: '私隐政策',
        cookies: '信息记录程序',
        termsAndConditions: '条款和条件',
        socialLinksTitle: '关注我们：',
    },
    menu: {
        logIn: '登录',
        logOut: '登出',
        scholarRegistration: 'Poster / Study Registration', // new translation
        exhibitorRegistration: '成为参展商', // new translation modification (Be Exhibitor => Exhibitor Registration)
        participantRegistration: '注册', // new translation modification (Register => Participant Registration)
        createEvent: '举办活动',
        launchText: '2020年9月发布 - 提前开放准入通道',
    },
    homepage: {
        organizedBy: '举办方',
        date: '日期',
        location: '域和位置',
        price: '价格',
        tabIntro: '介绍',
        tabIntroProgramButton: '活动内容',
        tabProgram: '活动内容',
        tabProgramIntroButton: '介绍',
        tabPartners: '合作伙伴',
        tabExhibitors: '参展商',
        previousEventsSectionTitle: '历届活动',
        tabSocialMedia: '社交媒体 ',
    },
    socialButtons: {
        linkedinButton: '通过领英登录',
        facebookButton: '通过脸书登录',
        googleButton: '通过谷歌登录',
        twitterButton: '通过推特登录',
    },
    login: {
        orText: '或者',
        inputEmail: '邮箱地址',
        inputPassword: '密码',
        rememberMeButton: '记住我',
        forgotPasswordButton: '忘记密码？',
        loginButton: '登录',
        noAccountText: '还没有帐号？',
        registerButton: '在这里注册',
        recoverPasswordDialogTitle: '请同意隐私政策',
        recoverPasswordDialogInputEmail: '邮箱地址',
        recoverPasswordDialogCloseButton: '关闭',
        recoverPasswordDialogRecoverButton: '恢复',
        errorNotRegistered: '未注册',
        errorUnableToLogin: '无法登入',
    },
    register: {
        formTitle: '或创建您的onvent个人资料',
        inputFirstName: '名字',
        inputLastName: '姓氏',
        inputEmail: '邮箱地址',
        inputPassword: '密码',
        inputConfirmPassword: '确认密码',
        uploadImageText: '上传个人资料图片（2MB)：',
        editImageText: '编辑个人资料图片（2MB)：',
        gdprTextFirst: '继续进行，即表示您同意我们的',
        gdprTextTerms: '使用条款',
        gdprTextSecond: '并确认您已阅读我们的',
        gdprTextCookies: '信息记录程序',
        gdprTextThird: '和',
        gdprTextPrivacy: '私隐政策',
        gdprTextAgree: '请同意隐私政策',
        registerButton: '注册',
        haveAccountText: '已经有帐号了？',
        haveAccountLoginButton: '在此登录',
        errorAlreadyHaveAccount: '已经注册。',
        formTitleTextFirst: '或者创建您的',
        formTitleTextSecond: '个人资料',
        invitationCode: '邀请码',
    },
    participantRegistration: {
        choosePackageTitle: '选择访问套餐',
        noPackageText: '请选择一个套餐',
        gdprTextFirst: '继续进行，即表示您同意我们的',
        gdprTextPrivacy: '私隐政策',
        gdprTextSecond: '和',
        gdprTextTerms: '活动条款和条件',
        gdprTextAgree: '请同意隐私政策',
        registerButtonFree: '完成注册',
        registerButtonPay: '继续付款',
    },
    exhibitorRegistration: {
        choosePackageTitle: '选择访问套餐',
        gdprTextFirst: '继续进行，即表示您同意我们的',
        gdprTextPrivacy: '活动隐私权政策',
        gdprTextSecond: '和',
        gdprTextTerms: '活动条款和条件',
        gdprTextAgree: '请同意隐私政策',
        registerButtonFree: '完成注册',
        registerButtonPay: '继续付款',
    },
    stripePayment: {
        cardNumber: '卡号',
        expirationDate: '到期日期（月/日）',
        cvc: '安全码',
        disclaimerText: '此支付操作是全程加密的。 Stripe 服务公司保证安全和隐私。',
        disclaimerTextLink: '点击这里了解更多。',
        cancelButton: '取消',
        payButton: '付款',
    },
    recoverPassword: {
        recoverTitle: '请同意隐私政策',
        cancelButton: '关闭',
        recoverButton: '恢复',
        recoverTextSend: '恢复密码的邮件已成功发送',
    },
    unsubscribe: {
        unsubscribeTitle: '取消订阅？',
        unsubscribeHomepage: '去主页',
    },
    notAvailable: {
        notAvailableTitle: '无法使用Onvent',
        notAvailableSubtitle: '您所寻找的Onvent无法使用。 它已被组织者关闭或被管理员禁用',
        notAvailableTitleTextSecond: '无法获得',
        notAvailableSubtitleTextFirst: '这个',
        notAvailableSubtitleTextSecond: '您要寻找的无法获得。 它已被组织者关闭或被管理员禁用',
        closedTitle: '现在关闭',
        closedSubtitleTextFirst: '您已成功注册',
        closedSubtitleTextSecond: '目前关闭',
    },
    notFound: {
        notFoundDescription: '糟糕...在这里找不到活动！',
        notFoundHomepage: '去主页',
    },
    eventMenu: {
        organizerButton: '组织者',
        exhibitorButton: '参展商',
        boothButton: '展位',
        boothsButton: '展位',
        homeButton: '主页',
        contactsButton: '联系人',
        meetingsButton: '会议',
        chatsButton: '聊天',
        programButton: '活动内容',
        archiveButton: '存档',
        myAccountButton: '我的帐户',
        lobbyButton: '大堂',
        roomsButton: '房间',
        connectionProblemTextFirst: '有新的更新可用。请点击',
        connectionProblemButton: '这里',
        connectionProblemTextSecond: '或刷新页面。',
        sessionProblemTextFirst: '您的会议已过期。 请点击',
        sessionProblemButton: '这里',
        sessionProblemTextSecond: '重新登录。',
        backButton: '返回',
        hubButton: '中心',
    },
    myEventsDropdown: {
        title: '我的活动',
        createButtonDesktop: '建立活动',
        createButtonMobile: '创建',
    },
    myBoothsDropdown: {
        title: '我的展位',
    },
    contactsDropdown: {
        title: '您的联系人',
        requestsTitle: '联络邀请',
        rejectButton: '拒绝',
        acceptButton: '接收',
        pendingButton: '待定',
        addButton: '添加',
        noContacts: '您还没有任何联系人。 请访问交流沟通区域以开始建立您的联系人列表。',
    },
    meetingsDropdown: {
        meetingWith: '和…进行会议',
        pending: '待定',
        accepted: '接收',
        canceled: '取消',
        noMeetings: '尚未安排会议',
        finished: '完成',
    },
    programDropdown: {
        timeZone: '时区：',
        with: '与',
        noProgram: '没有活动',
    },
    notificationsDropdown: {
        title: '消息通知',
        contactRequestReceived: '向您发送了联系请求。 查看一下！',
        contactRequestAccepted: '接受了您的联系请求。 开始聊天！',
        meetingRequestReceived: '向您发送了会议请求。 请查看',
        meetingRequestAccepted: '接受了您的会议请求。 请查看',
        meetingRequestCanceled: '取消了会议请求。 请查看',
        meetingStarting: '会议即将开始。 请查看',
        newBoothPost: '已公布在您的展位墙上。 查看一下！',
        noNotifications: '您还没有任何通知。',
        seeAll: '查看全部',
        hideAll: '隐藏全部',
        agendaItemUpdated: '已保存的活动项目已由活动组织者更新',
        agendaItemDelted: '活动组织者删除了已保存的活动项目',
        newMeetingRequest: '您有新的会议要求',
        meetingAccepted: '您的会议要求已被接受',
        meetingCancelled: '您的会议已取消',
        meetingWillStartTextFirst: '进行会议和',
        meetingWillStartTextSecond: '即将开始。 一探究竟！',
    },
    chatsDropdown: {
        title: '聊天',
        newChatButton: '新聊天',
        newChatStart: '开始新的聊天',
        newChatProvideText: '请提供您的聊天名称。 之后，您可以编辑名称：',
        noChats: '创建至少一个聊天室来和活动参与者交谈',
        member: '成员',
        members: '成员',
        noOpenedChatText: '单击聊天继续对话。',
        noMessagesInChatTextFirst: '打破僵局。',
        noMessagesInChatTextSecond: '成为发起对话的人。',
        newChatNameInput: '聊天名称（可选）',
        newChatAddMembers: '将联系人和其他参会者添加到此聊天中：',
        editChatAddMembers: '请搜索您在这个活动中要添加到群聊中的用户。',
        newChatSearchInput: '搜索用户',
        newChatNoUsersMessage: '没有搜索到用户',
        newChatAddUserButton: '添加',
        newChatRemoveUserButton: '移除',
        newChatCancelButton: '关闭',
        newChatCreateButton: '创建',
        membersLeaveButton: '离开',
        membersUpdateButton: '更新',
        membersChatButton: '聊天',
        chatSettingsButton: '设置',
        newMessageInput: '在此处输入消息',
        newVideoCallTooltipText: '点击此处开始视频聊天',
        fullscreenButton: '开启全屏',
        minimizeButton: '最小化屏幕',
    },
    myAccountDropdown: {
        title: '我的帐户',
        viewProfileButton: '查看资料',
        logoutButton: '登出',
    },
    myAccountPage: {
        platformProfileSectionTitle: 'Neural Network个人资料',
        changePasswordButton: '更改密码',
        deleteAccountButton: '删除我的账户',
        platformLanguage: '平台语言：',
        reviewPlatformGdprText: '查看我们的Onvent条件：',
        privacyPolicyLink: '隐私权声明/政策',
        cookiesLink: '信息记录程序',
        termsAndConditionsLink: '条款和条件',
        eventProfileSectionTitle: '活动简介',
        eventPackage: '活动套餐',
        reviewEventGdprText: '查看我们的活动条件：',
        unregisterFromEventButton: '从活动取消注册',
        unregisterCurrentEventButton: '从当前活动中注销',
        unregisterCurrentEventConfirmation: '请确认您要注销活动',
        enableParticipantProfile: '启用参与者资料',
        editUserDialogTitle: '编辑用户详细信息',
        editExhibitorDialogTitle: '编辑公司联系人详细信息',
        editParticipantDialogTitle: '编辑参加者详细信息',
        firstName: '名字',
        lastName: '姓氏',
        uploadImage: '上传图像',
        removeImage: '移除图像',
        deleteTitle: '删除我的账户',
        deleteConfirm: '确认删除我的帐户',
        deleteError: '您无法删除您的帐户。 请删除所有创建的活动，以便能够删除您的帐户。',
        deleteSuccess: '您的帐户已被删除。',
        email: '邮箱地址',
        reviewEventPrivacyPolicy: '查看我们的活动隐私政策：',
        title: '职称',
        company: '公司',
        phone: '电话号码',
        platformProfileSectionTitleTextSecond: '个人资料',
        reviewPlatformGdprTextFirst: '查看我们的',
        reviewPlatformGdprTextSecond: '条件',
    },
    changePassword: {
        title: '更改密码',
        oldPassword: '旧密码',
        oldPasswordError: '旧密码不匹配！',
        inputPassword: '新密码',
        inputConfirmPassword: '确认密码',
        changePasswordButton: '更改密码',
    },
    roomsDropdown: {
        title: '房间菜单',
        auditoriumButton: '会议礼堂',
        showfloorButton: '展示厅',
        archiveButton: '存档',
        onDemandButton: 'ON-DEMAND', // new translation
    },
    sideMenu: {
        liveWall: '直播墙',
        networking: '沟通交流',
        info: '信息',
        videoWall: '视频墙',
        booth: '展位',
        boothWall: '展位墙',
        resources: '资源',
        networkingTooltip: '您无法访问所有沟通交流功能。',
        polls: '投票',
    },
    wall: {
        sortBy: '排序方式：',
        popularity: '热门度',
        time: '时间',
        emptyWallText: '成为第一个在这里添加帖子的人',
        newPostInput: '您在想什么？',
        newCommentInput: '评论',
        resourcesVideoDescription: '视频描述',
        resourcesLinks: '链接',
        resourcesFiles: '文件',
        whatIsOnYourMind: '您在想什么？',
    },
    networking: {
        searchInput: '按用户搜索',
        at: '在',
        regenerateMatches: '重新生成匹配項',
    },
    closedAuditoriumDialog: {
        title: '会议礼堂信息',
        content: '会议礼堂的访问受到限制。',
        contentPackage: '您的访问套餐无权访问任何会议礼堂。',
        button: '好',
    },
    closedShowfloorDialog: {
        title: '展厅信息',
        content: '进入展厅的操作受到了限制。',
        contentPackage: '您的访问权限套餐无权访问展厅。', //New translation: Your access package doesn't have access to any showfloors.
        button: '好',
    },
    auditorium: {
        willStart: '下一场会议将在...',
        noAccess: '您的访问套餐无权访问此会议礼堂。',
        noVideos: '没有视频在此会议礼堂播放。',
        stageTab: '舞台',
        stagesButton: '舞台',
        videoWallButton: '视频墙',
        resourcesButton: '资源',
        slotEndsTextFirst: '该活动将结束在',
        slotEndsTextSecond: '演讲将在活动结束时提供。',
        slotTitleWith: '与',
        allRooms: '所有房间',
        onDemandButton: 'ON-DEMAND', // new translation
    },
    auditoriumArchive: {
        title: '会议礼堂存档',
        titleEvent: '活动存档',
        with: '与',
        stage: '舞台',
        videoWall: '视频墙',
        resources: '资源',
        tooltipMinimize: '最小化',
        tooltipFullscreen: '全屏',
        tooltipPause: '暂停',
        tooltipPlay: '播放',
        tooltipUnmute: '取消静音',
        tooltipMute: '静音',
        noVideosAvailable: 'All sessions finished', // new translation
    },
    videoPlayer: {
        videoNotPlayingError: '视频播放有问题？ 请重新加载。',
        videoEndedTextFirst: '视频已结束。 感谢您的收看！',
        videoEndedTextSecond: '视频墙开放供问答环节。',
        tooltipPlay: '播放',
        tooltipPause: '暂停',
        tooltipMinimize: '最小化',
        tooltipFullscreen: '全屏',
        tooltipMute: '静音',
        tooltipUnmute: '取消静音',
    },
    showfloor: {
        allExhibitorsButton: '所有参展商', //new translation: Showfloor menu
        featuredExhibitorsText: '特别参展商',
        boothLinksDialogTitle: '展位链接',
        boothLinksDialogOtherLinksTitle: '其他：',
        boothFilesDialogTitle: '展位文件',
        boothVideoDialogTitle: '视频',
        mobileTabChat: '聊天',
        mobileTabFiles: '文件',
        mobileTabLinks: '链接',
        mobileTabVideo: '视频',
        noExhibitorsTextFirst: '参展商正在规划他们的展位',
        noExhibitorsTextSecond: '敬请关注',
        filterBy: '筛洗',
        searchByTheUser: '用户搜索',
        showShowfloorsButton: 'ALL EXHIBIT HALLS', //new translation
        searchExhibitors: 'Search exhibitors', // //new translation: Search exhibitors
        showfloorsTitle: 'Exhibit Halls', //new translation: Showfloors
    },
    eventArchive: {
        title: '视频存档',
        searchText: '点击即可搜索',
        noVideosText: '在此活动中找不到视频。',
        noArchivedEvents: '当前没有存档的活动。',
    },
    exhibitorDashboard: {
        event: '活动',
        title: '管理展位',
        visitorsButton: '展位参观者',
        goToBoothButton: '带我去展位',
        goToBoothButtonDisabled: '您的展位尚无法预览。',
        tabStyle: '展位风格',
        tabLogo: '商标/商徽',
        tabFiles: '文件',
        tabLinks: '链接',
        tabAvatars: '头像',
        tabVideo: '视频',
        boothStyleTab: {
            optionOne: '选项1-北欧风格',
            optionTwo: '选项2-现代风格',
            optionThree: '选项3-动态风格',
            optionFour: '选项4-自定义',
        },
        logoTab: {
            title: '商标/商徽',
            fileUploadButton: '点击这里上传',
            noFile: '没有选中任何文件',
        },
        filesTab: {
            title: '文档：',
            fileNameInput: '文档名称（所有参会者均可见）',
            chooseFileButton: '选择文件',
            noDocAdded: '没有文件添加',
            fileError: '请添加文件名',
        },
        linksTab: {
            social: {
                title: '社交媒体的链接',
                subtitle: '（所有参与者可见）',
                facebookInput: '添加您的脸书链接',
                linkedinInput: '添加您的领英链接',
                twitterInput: '添加您的推特链接',
            },
            other: {
                title: '其他链接',
                subtitle: '（主页或者其他链接）',
                linkInput: '添加新的链接',
                linkLabel: '插入新的链接标签',
            },
        },
        avatarsTab: {
            mainRepresentatives: {
                title: '展位代表',
                subtitle: '（仅一名代表必填）',
                leftRepresentative: '左边代表',
                rightRepresentative: '右边代表',
                addDialog: {
                    title: '添加头像',
                    contentFirst: '从下面的画廊中选择一个头像，或上传您自己的个性化头像。',
                    contentSecond: '需要设计帮助吗？ 保持联系：',
                    female: '女',
                    male: '男',
                    uploadSectionTitle: '上传个人头像',
                    uploadButton: '选择文件',
                    noFile: '选择文件',
                    assignText: '请指派活动参会者与上述代表关联',
                    input: '按名字搜索',
                    avatarLabels: {
                        femaleA: '女士A',
                        femaleB: '女士B',
                        femaleC: '女士C',
                        maleA: '男士A',
                        maleB: '男士B',
                        maleC: '男士C',
                    },
                },
            },
            additionalRepresentatives: {
                title: '额外代表',
                subtitle: '（选择性，将与他们的个人资料图片一起显示）',
                addDialog: {
                    title: '选择其他代表',
                    titleScholar: 'Choose Co-Author', //new translation
                    assignText: '请开始搜索活动参与者以将其添加为代表。',
                    assignError: '用户已被添加为展位代表！',
                    chooseAvatarTextFirst: '从下面的画廊中选择一个头像，或上传您自己的个性化头像。',
                    chooseAvatarTextSecond: '需要设计帮助吗？ 保持联系：',
                    uploadText: '上传个人头像',
                    chooseFileText: '选择文件',
                    noFile: '没有选中任何文件',
                },
            },
            author: {
                title: 'Presenting Author', // new translation
                subtitle: 'Add only an author that registered to the event. You can add 1 author.', // new translation
                mainRepresentative: 'Main representative', // new translation
            },
            coAuthors: {
                title: 'Co-Authors', // new translation
                subtitle: 'Add only co-authors that registered to the event. You can add up to 4 co-authors.', // new translation
            },
        },
        videoTab: {
            video: {
                title: '展位视频',
                subtitle: '（仅限YouTube视频链接）',
                input: '输入YouTube URL链接',
            },
            image: {
                title: '图片',
                subtitle: '（如果您没有在上面添加视频链接，则图像会显示在您的展位中）',
                uploadButton: '点击这里上传',
            },
        },
    },
    boothDashboard: {
        event: '活动',
        title: '您的展位',
        manage: '管理',
        goToBoothButton: '带我去展位',
        previewNotAvailable: '您的展位尚无法预览。',
        statistics: '数据',
        usersNow: '现在的参会者',
        usersAllTime: '所有参会者',
        linkClicks: '链接点击数',
        documentViews: '文件查看数',
        videoViews: '观看了视频演讲',
        visitorsSectionTitle: '展位参观者',
        noVisitors: '您的展位还没有访客',
        visitorsSearch: '搜索用户',
        actionType: '动作类型',
        showUsers: '显示用户',
        ofUsers: '用户数',
    },
    polls: {
        polls: '问卷投票',
        poll: '问卷投票',
        noPolls: '尚未添加问卷投票',
        pollProgress: '进行中的问卷投票',
        pollClosed: '投票关闭',
        pollVoted: '参与者投票',
    },
    onDemandVideos: {
        sectionTitle: 'ON-DEMAND', // new translation
        noOnDemandVideos: 'No on demand videos were added yet.', // new translation
    },
    meetings: {
        meeting: '会议',
        meetings: '会议',
        createDialog: {
            createTitle: '要求与…会面',
            inputDate: '日期',
            inputStart: '开始',
            inputEnd: '结束',
            inputTimezone: '时区',
            inputNote: '注意：同一人一次只能安排一次会议。',
            meetingError: '会议日期或时间无效。 请重新检查！',
            buttonDisabled: '您可以在开始对话后安排会议！',
        },
        banner: {
            pending: '会议要求',
            accepted: '会议定于',
            progress: '会议进行中',
            cancelTitle: '取消会议？',
            cancelDescription: '您确定要取消此会议吗？',
            startCall: '开始通话',
            joinCall: '加入',
            pendingOwner: '等待会议要求',
            videoCallStarted: '视频会议已经开始',
        },
        newMeeting: '新会议',
        requestMeeting: '要求进行会议',
        startDate: '开始日期',
        starts: '开始',
        ends: '完结',
        addMessageHere: '在此处添加您的消息',
        note: '注意：您只能请求与特定人员开会。 会议结束（或取消）后，您可以重新安排下一次会议',
        create: '创建',
        requested: '已要求',
        confirmed: '已确认',
        title: '您的会议',
        meetingsInfo: '可以通过聊天安排会议。 请与您要安排会议的用户开始聊天。',
    },
    landingPage: {
        animatedSectionButton: '开始旅程',
        topSectionHeaderPrimary: '下一代',
        topSectionHeaderSecondary: '在线活动',
        carouselPresentationText: '退出',
        carouselPresentationTextAccent: '新的Neural Network',
        videoPresentationTextPrimary: 'Neural Network-您的在线活动',
        videoPresentationTextAccent: '参与者会喜欢。',
        videoPresentationTextSecondary: '原因如下：',
        testimonialsTextPrimary: '自2015年以来的在线活动-',
        testimonialsTextAccent: '都是关于人的',
        testimonialsTextSecondary: '...',
        cardsTextPrimary: 'Neural Network带来',
        cardsTextAccent: '赞叹',
        cardsTextSecondary: '到…',
        contactTextPrimary: '有兴趣组织在线活动吗？',
        contactTextAccent: '保持联系',
        contactTextSecondary: '',
        contactInputName: '名称',
        contactInputEmail: '邮箱地址',
        contactInputPhone: '手机号码（选择性）',
        contactInputMessage: '信息',
        contactButton: '发送',
        carouselPresentationTextAccentTextFirst: '更新',
        videoPresentationTextPrimaryTextSecond: '您的在线活动',
        cardsTextPrimaryTextSecond: '带来',
    },
    loginPlatform: {
        title: '登录',
        description:
            '登录后，您将被重新定向到您注册的上一个活动。 您可以使用菜单中的“我的活动”选项轻松地在活动之间切换。',
        descriptionTextFirst: '登录后，您将被重定向到最后一个',
        descriptionTextSecond: '您注册到。 您可以轻松地转换在',
        descriptionTextThird: '使用菜单中的“我的活动”选项。',
    },
    skipped: {
        startVideoChat: '我已经组建了视频会议。 请点击这里加入。',
    },
    hubDropdownTitle: '中心菜单栏',
    marketplace: {
        title: {
            default: '市场',
            uppercased: '市场',
        },
        label: '产品展示',
        addNew: '添新',
        addNewProduct: '新增产品',
        editProduct: '编辑产品',
        noProducts: '暂无产品',
        publish: '发布',
        productTitle: '产品名称',
        productDescription: '产品描述',
        productLink: '产品链接',
        productImage: '产品图片',
        uploadHint: '上载将显示为产品预览的图像。 建议的600 * 600 px分辨率，以获得更好的图像质量',
        descriptionHint: '说明最多可包含300个符号',
        uploadImage: '上载图像以增加产品价值',
        uploaded: '已上传',
    },
    personalAgenda: {
        agenda: '议程',
        myProgram: '我的活动',
        myMeetings: '我的会议',
        addNewTask: {
            default: '添加新任务',
            uppercased: '添加新任务',
        },
        noProgram: '暂时没有活动项目',
        noMeetings: '暂时没有会议',
        title: '标题',
        startDate: '开始日期',
        starts: '开始',
        ends: '完结',
        create: '创建',
        schedule: '时间表',
        personalAgenda: '个人议程',
        timetableMenu: '时间表菜单',
        gmt: '格林威治标准时间',
    },
    program: {
        toAgenda: '议程',
        attending: '参加',
    },
    businessCard: {
        businessCard: {
            default: '名片',
            uppercased: '名片',
        },
        editText: '编辑文字',
        colors: '颜色',
        branding: '品牌推广',
        cardPreview: '卡预览',
        onceSaved:
            '保存后，名片将始终连接到您的个人资料。 通过它，您可以访问网络，聊天和其他重要功能。 删除个人资料时，该卡将自动删除',
        required: '要求',
        designation: '职称',
        Mobile: '手机',
        Phone: '电话',
        Website: '网站',
        companyName: '公司名',
        honoric: '添加您喜欢的前置称呼，例如先生，女士，塞尔，谢赫等',
        fillUpData: '从我的帐户中填写数据',
        primaryColor: '初始颜色',
        primaryColorDescription: '该颜色是强调色。在这里使用您的品牌颜色',
        backgroundColor: '背景颜色',
        backgroundColorDescription: '此颜色是附加颜色。将其添加为背景。默认情况下，可以是白色或黑色',
        backgroundTextColor: '背景文字和图标颜色',
        backgroundTextColorDescription:
            '该颜色用于电话，电子邮件和网站详细信息，并且必须与背景颜色形成对比。转到卡片预览，以确保卡片可读且对比度足够',
        companyLogo: '公司标志',
        uploadMessage:
            '上载将显示为公司徽标的图像。建议的320 * 200像素分辨率，以获得更好的图像质量。使用具有透明背景的徽标（首选.png文件格式）',
        uploadImage: '上传图片',
        request: '请求',
        open: '打开',
        pending: '待处理',
    },
    businessWallet: {
        short: 'B钱包',
        full: '商业钱包',
        myContacts: '我的联系方式',
        requests: '要求',
        pendings: '待办事项',
        searchByName: '依名称搜寻',
        chat: '聊天室',
        openCard: '开卡',
        empty: '这里没有人',
        cancelRequest: '取消请求',
        approve: '批准',
        reject: '拒绝',
        myNotes: '我的笔记',
        addNewNote: '新增笔记',
        delete: '删除',
        saveThoughts: '将您的想法整理成一个单独的空间',
    },
    videoConferences: {
        message: '我已经建立了一个视频会议室。请点击这里加入',
        clickToStart: '单击此处开始视频聊天',
        fullScreen: '全屏显示',
        minimizeScreen: '最小化屏幕',
    },
    sessions: {
        sessionList: '会议清单',
        createNewSession: {
            uppercased: '创建新会议',
            default: '建立新工作阶段',
        },
        sessionName: {
            uppercased: '会议名称',
            default: '会议名称',
        },
        sessionLink: '会议链接',
        sessionType: '会议类型',
        manage: '管理',
        edit: '编辑',
        delete: '删除',
        live: {
            uppercased: '直播',
            lowercased: '直播',
            default: '直播',
        },
        recorded: {
            uppercased: '录像',
            lowercased: '录像',
            default: '录像',
        },
        editSession: '编辑会议',
        uploadVideo: '上载视频',
        create: '创建',
        discard: '丢弃',
        speakers: '演讲嘉宾',
        moderators: '主持人',
        speakerName: '演讲嘉宾姓名',
        moderatorName: '主持人姓名',
        kick: '去除',
        screenSharing: '屏幕共享',
        audio: '音频',
        video: '视频',
        startStream: '开始播放',
        stopStream: '停止播放',
        startRecording: '开始录像',
        endRecording: '结束录像',
        sessionChat: '会话聊天',
        typeMessageHere: '在此处输入消息',
        sessionLinks: '会议链接',
        speakerUrl: '演讲嘉宾链接',
        moderatorUrl: '主持人链接',
        copyUrl: '复制网址链接',
        organizer: '组织者',
        moderator: '主持人',
        speaker: '扬声器',
        joinAsSpeaker: '以演讲嘉宾身份加入',
        invitedAsSpeaker: '您已受邀作为演讲嘉宾参加AIM会话演示。输入您的姓名（会话的参与者可以看到您的姓名）',
        yourName: '您的名字',
        enter: '输入',
        waitForApproval: '请等待批准',
        deny: '拒绝',
        admit: '准入',
        joinAsModeraotr: '以主持人身份加入',
        invitedAsModerator: '您已被邀请作为主持人参加AIM会话演示。输入您的姓名（会话的参与者可以看到您的姓名）',
        declined: '对不起，您被拒绝的原因',
    },
    breadcrumbsNavigation: {
        mainLobby: '主大堂',
        village: '会场园区',
    },
    village: {
        clickToOpen: '点击打开',
    },
    gdpr: {},
};

export default zh;

import React, {Component} from 'react';
import axios from '../../../../store/axios-instance';
import {connect} from 'react-redux';
import './downloadRecord.scss';

class DownloadRecords extends Component {
    state = {
        recordUrl: '',
    };

    componentDidMount() {
        this.setDownloadRecordUrl();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.session?._id !== this.props.session?._id) {
            this.setDownloadRecordUrl();
        }
    }

    setDownloadRecordUrl = () => {
        const sessionId = this.props.session?._id;
        if (sessionId) {
            this.loadSessionRecords(sessionId).then((records) => {
                // in our Zoom Session case, we will only have 1 session recording
                if (records.length) {
                    const recordUrl = records[0];
                    this.setState({recordUrl: recordUrl});
                }
            });
        }
    };

    loadSessionRecords = async (sessionId) => {
        const result = await axios.get(`/sessions/${sessionId}/records`);
        const records = result.data.recordings.map((r) => r.url);
        return records;
    };

    render() {
        const {recordUrl} = this.state;
        return (
            <div className={'download-record'}>
                <a href={recordUrl} target={'_blank'}>
                    Download Record
                </a>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        session: state.sessions.zoomSessionDashboard.session,
    };
};

export default connect(mapStateToProps)(DownloadRecords);

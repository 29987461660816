const ar = {
    generalText: {
        upload: 'تحميل',
        save: 'حفظ',
        add: 'إضافة',
        select: 'اختيار',
        remove: 'حذف',
        close: 'غلق',
        update: 'تحديث',
        cancel: 'إلغاء',
        confirm: 'تأكيد',
        download: 'تحميل',
        refresh: 'تحديث',
        manage: 'إدارة',
        back: 'عودة إلى الخلف',
        submit: 'إرسال',
        send: 'إرسال',
        eventMO: 'أونفينت',
        eventETX: 'فعالية',
        eventsMO: 'أونفينت',
        eventsETX: 'فعاليات',
        eventUpperMO: 'أونفينت',
        eventUpperETX: 'فعالية',
        hide: 'HIDE', //new translation: HIDE
    },
    time: {
        justNow: 'الآن',
        minute: 'دقيقة',
        minutes: 'دقائق',
        hour: 'ساعة',
        hours: 'ساعات',
        day: 'يوم',
        days: 'الأيام',
        month: 'الشهر',
        months: 'الشهور',
        yesterday: 'الأمس',
        today: 'اليوم',
        tomorrow: 'غداً',
        monday: 'الإثنين',
        tuesday: 'الثلاثاء',
        wednesday: 'الأربعاء',
        thursday: 'الخميس',
        friday: 'الجمعة',
        saturday: 'السبت',
        sunday: 'الأحد',
        mondayShort: 'الإثنين',
        tuesdayShort: 'الثلاثاء',
        wednesdayShort: 'الأربعاء',
        thursdayShort: 'الخميس',
        fridayShort: 'الجمعة',
        saturdayShort: 'السبت',
        sundayShort: 'الأحد',
        january: 'يناير',
        february: 'فبراير',
        march: 'مارس',
        april: 'أبريل',
        may: 'مايو',
        june: 'يونيو',
        july: 'يوليو',
        august: 'أغسطس',
        september: 'سبتمبر',
        october: 'أكتوبر',
        november: 'نوفمبر',
        december: 'ديسمبر',
        januaryShort: 'يناير',
        februaryShort: 'فبراير',
        marchShort: 'مارس',
        aprilShort: 'أبريل',
        mayShort: 'مايو',
        juneShort: 'يونيو',
        julyShort: 'يوليو',
        augustShort: 'أغسطس',
        septemberShort: 'سبتمبر',
        octoberShort: 'أكتوبر',
        novemberShort: 'نوفمبر',
        decemberShort: 'ديسمبر',
    },
    errors: {
        noFile: 'لم يتم اختيار ملف',
        required: 'الحقل إلزامي',
        emailNotValid: 'البريد الإلكتروني غير صحيح',
        passwordMinLength: 'يجب ألا تقل كلمة المرور عن 8 أحرف',
        passwordMismatch: 'كلمة السّر غير متطابقة',
        passwordIsSame: 'Your new password must be different', //new translation
        fileToLarge: 'الملف كبير جداً. أقصى حجم للملف 2 ميجابايت',
        fileToLargeTextFirst: 'الملف كبير جداً.',
        fileToLargeTextSecond: 'أقصى حجم للملف.',
        agreeToGDPR: 'يرجى الموافقة على سياسة الخصوصية',
        noInvitationCode: 'يرجى إدخال رمز الدعوة الخاص بك',
        invitationCodeInvalid: 'رمز الدعوة غير صالح.',
        imageFileNotSupported: 'نوع  الملف غير مدعوم. الرجاء استخدام أي مما يلي:',
        phoneInvalid: 'يرجى إدخال رقم هاتف صالح. على سبيل المثال: +447517663928',
        linkInvalid: 'يرجى إدخال رابط صالح (https://site.com)',
        facebookLinkInvalid: 'يرجى إدخال رابط صالح (https://facebook.com/)',
        linkedinLinkInvalid: 'يرجى إدخال رابط صالح (https://linkedin.com/)',
        twitterLinkInvalid: 'يرجى إدخال رابط صالح (https://twitter.com/)',
        youtubeLinkInvalid: 'يرجى إدخال رابط صالح (https://www.youtube.com/)',
        connectionProblemTextFirst: 'يبدو أن هناك مشكلة في الاتصال. الرجاء',
        connectionProblemButton: 'النقر',
        connectionProblemTextSecond: 'أو قم بتحديث الصفحة.',
        fileTooLarge: 'الملف كبير جداً، الحد الأقصى لحجم الملف هو 2 ميغا بايت ',
        fileTooLargeTextFirst: 'الملف كبير جداً',
        fileTooLargeTextSecond: 'الحد الأقصى لحجم الملف.',
    },
    footer: {
        cookiesText:
            'يستخدم هذا الموقع ملفات تعريف الارتباط الأساسية بصورة ضرورية ليعمل الموقع بطريقة صحيحة. كما أنه يستخدم ملفات تعريف الارتباط الخاصة بالطرف الثالث، والتي تُعد ضرورية لتشغيل بعض ميزات موقعنا الإلكتروني. إذا كنت ترغب بمعرفة المزيد أو سحب موافقتك على بعض ملفات تعريف الارتباط، فيرجى العودة إلى',
        cookiesLink: 'سياسة ملفات تعريف الارتباط',
        cookiesButton: 'قبول',
        privacyPolicy: 'سياسة الخصوصية',
        cookies: 'ملفات تعريف الارتباط',
        termsAndConditions: 'الشروط والأحكام',
        socialLinksTitle: 'تابعنا عبر:',
    },
    menu: {
        logIn: 'تسجيل الدخول',
        logOut: 'تسجيل الخروج',
        scholarRegistration: 'Poster / Study Registration', // new translation
        exhibitorRegistration: 'كن عارضاً', // new translation modification (Be Exhibitor => Exhibitor Registration)
        participantRegistration: 'التسجيل', // new translation modification (Register => Participant Registration)
        createEvent: 'نظم حدثاً:',
        launchText: 'الإطلاق في سبتمبر 2020 - مفتوح للمستخدمين ذوي الباقة المبكرة',
    },
    homepage: {
        organizedBy: 'بتنظيم من',
        date: 'التاريخ',
        location: 'المجال والموقع',
        price: 'السعر',
        tabIntro: 'المقدمة',
        tabIntroProgramButton: 'البرنامج',
        tabProgram: 'البرنامج',
        tabProgramIntroButton: 'المقدمة',
        tabPartners: 'الشركاء',
        tabExhibitors: 'العارضون:',
        previousEventsSectionTitle: 'فعاليات سابقة',
        tabSocialMedia: 'وسائل التواصل الاجتماعي',
    },
    socialButtons: {
        linkedinButton: 'تسجيل الدخول عبر لينكد إن',
        facebookButton: 'تسجيل الدخول عبر فيسبوك',
        googleButton: 'تسجيل الدخول عبر جوجل',
        twitterButton: 'تسجيل الدخول عبر تويتر',
    },
    login: {
        orText: 'أو',
        inputEmail: 'البريد الإلكتروني',
        inputPassword: 'كلمة المرور',
        rememberMeButton: 'تذكرني',
        forgotPasswordButton: 'هل نسيت كلمة السر؟',
        loginButton: 'تسجيل الدخول',
        noAccountText: 'ليس لديك حساب؟',
        registerButton: 'سجل هنا',
        recoverPasswordDialogTitle: 'إستعادة كلمة المرور',
        recoverPasswordDialogInputEmail: 'البريد الإلكتروني',
        recoverPasswordDialogCloseButton: 'غلق',
        recoverPasswordDialogRecoverButton: 'استعادة',
        errorNotRegistered: 'غير مسجل',
        errorUnableToLogin: 'غير قادر على تسجيل الدخول',
    },
    register: {
        formTitle: 'أو قم بإنشاء حساب شخصي على أونفينت',
        inputFirstName: 'الاسم الأول',
        inputLastName: 'الاسم الأخير',
        inputEmail: 'البريد الإلكتروني',
        inputPassword: 'كلمة المرور',
        inputConfirmPassword: 'تأكيد كلمة المرور',
        uploadImageText: 'رفع صورة للملف الشخصي',
        editImageText: 'تحرير صورة الملف الشخصي (2 ميغا بايت):',
        gdprTextFirst: 'بالمتابعة، فإنك توافق على',
        gdprTextTerms: 'تعليمات الاستخدام',
        gdprTextSecond: 'وتؤكد أنك قد قرأت',
        gdprTextCookies: 'ملفات تعريف الارتباط',
        gdprTextThird: 'و',
        gdprTextPrivacy: 'سياسة الخصوصية',
        gdprTextAgree: 'يرجى الموافقة على سياسة الخصوصية',
        registerButton: 'سجّل',
        haveAccountText: 'هل لديك حساب؟',
        haveAccountLoginButton: 'تسجيل الدخول من هنا',
        errorAlreadyHaveAccount: 'مسجل بالفعل.',
        formTitleTextFirst: 'أو قم بإنشاء ',
        formTitleTextSecond: 'ملف',
        invitationCode: 'رمز الدعوة',
    },
    participantRegistration: {
        choosePackageTitle: 'اختر الباقة ',
        noPackageText: 'الرجاء تحديد الباقة.',
        gdprTextFirst: 'بالمتابعة، فإنك توافق على',
        gdprTextPrivacy: 'سياسة الخصوصية',
        gdprTextSecond: 'و',
        gdprTextTerms: 'شروط وأحكام الفعالية',
        gdprTextAgree: 'يرجى الموافقة على سياسة الخصوصية',
        registerButtonFree: 'إكمال التسجيل',
        registerButtonPay: 'المتابعة إلى عملية الدفع',
    },
    exhibitorRegistration: {
        choosePackageTitle: 'اختر الباقة ',
        gdprTextFirst: 'بالمتابعة، فإنك توافق على',
        gdprTextPrivacy: 'سياسة خصوصية الفعالية',
        gdprTextSecond: 'و',
        gdprTextTerms: 'شروط وأحكام الفعالية',
        gdprTextAgree: 'يرجى الموافقة على سياسة الخصوصية',
        registerButtonFree: 'إكمال التسجيل',
        registerButtonPay: 'المتابعة إلى عملية الدفع',
    },
    stripePayment: {
        cardNumber: 'رقم البطاقة',
        expirationDate: 'تاريخ انتهاء الصلاحية الشهر / السنة)',
        cvc: 'رمز التحقق من البطاقة',
        disclaimerText: 'عملية الدفع هذه مشفرة من طرف إلى طرف. تضمن شركة سترايب الأمان والخصوصية في خدماتها.',
        disclaimerTextLink: 'انقر هنا لمعرفة المزيد.',
        cancelButton: 'إلغاء',
        payButton: 'الدفع',
    },
    recoverPassword: {
        recoverTitle: 'إستعادة كلمة المرور',
        cancelButton: 'أغلق',
        recoverButton: 'استعادة',
        recoverTextSend: 'تم إرسال البريد الإلكتروني لاستعادة كلمة المرور بنجاح.',
    },
    unsubscribe: {
        unsubscribeTitle: 'إلغاء الاشتراك؟',
        unsubscribeHomepage: 'اذهب إلى الصفحة الرئيسية',
    },
    notAvailable: {
        notAvailableTitle: 'اونفينت غير متوفر',
        notAvailableSubtitle:
            'الحدث الذي تبحث عنه غير متوفر. إما أنه تم إغلاقه من قبل المنظم أو تم تعطيله من قبل المسؤول',
        notAvailableTitleTextSecond: 'غير متوفر',
        notAvailableSubtitleTextFirst: 'ال',
        notAvailableSubtitleTextSecond:
            'الذي تبحث عنه غير متوفر. إما أنه تم إغلاقه من قبل المنظم أو تم تعطيله من قبل المسؤول',
        closedTitle: 'مغلق الآن',
        closedSubtitleTextFirst: 'لقد سجلت بنجاح لهذا',
        closedSubtitleTextSecond: 'مغلق حالياً',
    },
    notFound: {
        notFoundDescription: 'عفواً... لم يتم العثور على أي حدث هنا!',
        notFoundHomepage: 'اذهب إلى الصفحة الرئيسية',
    },
    eventMenu: {
        organizerButton: 'المنظم',
        exhibitorButton: 'عارِض',
        boothButton: 'منصة عرض',
        boothsButton: 'منصات عرض',
        homeButton: 'الصفحة الرئيسية',
        contactsButton: 'اتصل بنا',
        meetingsButton: 'الاجتماعات',
        chatsButton: 'الدردشات',
        programButton: 'البرنامج',
        archiveButton: 'الأرشيف',
        myAccountButton: 'حسابي',
        lobbyButton: 'القاعة',
        roomsButton: 'الغرف',
        connectionProblemTextFirst: 'هناك تحديثات جديدة متاحة. يرجى النقر',
        connectionProblemButton: 'هنا',
        connectionProblemTextSecond: 'أو قم بتحديث الصفحة.',
        sessionProblemTextFirst: 'انتهت صلاحية جلسة عملك من فضلك اضغط',
        sessionProblemButton: 'هنا',
        sessionProblemTextSecond: 'لتسجيل الدخول مرة أخرى.',
        backButton: 'عودة إلى الخلف',
        hubButton: 'منصة',
    },
    myEventsDropdown: {
        title: 'فعالياتي',
        createButtonDesktop: 'إنشاء حدث',
        createButtonMobile: 'إنشاء',
    },
    myBoothsDropdown: {
        title: 'منصات العرض الخاصة بي',
    },
    contactsDropdown: {
        title: 'جهات الاتصال الخاصة بك',
        requestsTitle: 'طلبات الاتصال',
        rejectButton: 'رفض',
        acceptButton: 'قبول',
        pendingButton: 'قيد الانتظار',
        addButton: 'إضافة',
        noContacts: 'ليس لديك أي جهات اتصال حتى الآن. يرجى زيارة قسم التواصل لبدء بناء قائمة جهات الاتصال الخاصة بك.',
    },
    meetingsDropdown: {
        meetingWith: 'اجتماع مع',
        pending: 'قيد الانتظار',
        accepted: 'وافقت',
        canceled: 'ألغيت',
        noMeetings: 'لا توجد اجتماعات مجدولة حتى الآن',
        finished: 'تم الانتهاء من',
    },
    programDropdown: {
        timeZone: 'المنطقة الزمنية:',
        with: 'مع',
        noProgram: 'لا يوجد برنامج',
    },
    notificationsDropdown: {
        title: 'الاشعارات',
        contactRequestReceived: 'أرسل لك طلب اتصال. تحقق من ذلك!',
        contactRequestAccepted: 'لقد قبلت طلب الاتصال الخاص بك. ابدأ الدردشة!',
        meetingRequestReceived: 'أرسل لك طلب اجتماع. تحقق من ذلك!',
        meetingRequestAccepted: 'قبلت طلب الاجتماع الخاص بك. تحقق من ذلك!',
        meetingRequestCanceled: 'إلغاء طلب اجتماع. تحقق من ذلك!',
        meetingStarting: 'سيبدأ الاجتماع قريباً. تحقق من ذلك!',
        newBoothPost: 'تم نشره في حائط منصة العرض الخاصة بك. تحقق من ذلك!',
        noNotifications: 'ليس لديك أي إشعارات حتى الآن.',
        seeAll: 'اظهار الكل',
        hideAll: 'إخفاء الكل',
        agendaItemUpdated: 'تم تحديث أدوات البرنامج المحفوظ بواسطة منظم الحدث',
        agendaItemDelted: 'تم حذف أدوات البرنامج المحفوظ بواسطة منظم الحدث',
        newMeetingRequest: 'لديك طلب اجتماع جديد',
        meetingAccepted: 'تم قبول طلب الاجتماع الخاص بك',
        meetingCancelled: 'تم إلغاء اجتماعك',
        meetingWillStartTextFirst: 'اجتماع مع',
        meetingWillStartTextSecond: 'سوف يبدأ قريباً، تحقق من ذلك!',
    },
    chatsDropdown: {
        title: 'الدردشات',
        newChatButton: 'دردشة جديدة',
        newChatStart: 'ابدأ محادثة جديدة',
        newChatProvideText: 'يرجى تقديم اسم للدردشة الخاصة بك. ستتمكن من تعديل الاسم بعد ذلك:',
        noChats: 'أنشئ محادثة واحدة على الأقل للتحدث إلى المشاركين في الحدث',
        member: 'عضو',
        members: 'الأعضاء',
        noOpenedChatText: 'انقر فوق الدردشة لمتابعة المحادثة.',
        noMessagesInChatTextFirst: 'اكسر حاجز الجمود.',
        noMessagesInChatTextSecond: 'كن الشخص الذي يبدأ هذه المحادثة.',
        newChatNameInput: 'اسم الدردشة (اختياري)',
        newChatAddMembers: 'أضف جهات اتصال ومشاركين آخرين إلى هذه الدردشة:',
        editChatAddMembers: 'يرجى البحث عن مستخدمين من هذا الحدث الذي تريد إضافته إلى الدردشة الجماعية.',
        newChatSearchInput: 'أبحث عن مستخدمين',
        newChatNoUsersMessage: 'لم يتم العثور على مستخدمين',
        newChatAddUserButton: 'إضافة',
        newChatRemoveUserButton: 'حذف',
        newChatCancelButton: 'أغلق',
        newChatCreateButton: 'إنشاء',
        membersLeaveButton: 'غادر',
        membersUpdateButton: 'تحديث',
        membersChatButton: 'الدردشة',
        chatSettingsButton: 'الإعدادات',
        newMessageInput: 'اكتب الرسالة هنا',
        newVideoCallTooltipText: 'انقر هنا لبدء محادثة فيديو',
        fullscreenButton: 'فتح شاشة عرض كاملة',
        minimizeButton: 'تصغير الشاشة',
    },
    myAccountDropdown: {
        title: 'حسابي',
        viewProfileButton: 'عرض الصفحة الشخصية',
        logoutButton: 'تسجيل الخروج',
    },
    myAccountPage: {
        platformProfileSectionTitle: 'الملف الشخصي لماي أونفينت ',
        changePasswordButton: 'تغيير كلمة المرور',
        deleteAccountButton: 'حذف حسابي ',
        platformLanguage: 'لغة النظام:',
        reviewPlatformGdprText: 'قم بمراجعة شروط أونفينت:',
        privacyPolicyLink: 'إشعار الخصوصية والسياسات',
        cookiesLink: 'ملفات تعريف الارتباط',
        termsAndConditionsLink: 'الشروط والأحكام',
        eventProfileSectionTitle: 'الملف الشخصي للحدث',
        eventPackage: 'باقات الحدث',
        reviewEventGdprText: 'مراجعة شروط الحدث:',
        unregisterFromEventButton: 'إلغاء التسجيل من الحدث',
        unregisterCurrentEventButton: 'إلغاء التسجيل من الحدث الحالي',
        unregisterCurrentEventConfirmation: 'الرجاء تأكيد رغبتك بإلغاء التسجيل من الحدث',
        enableParticipantProfile: 'قم بتمكين ملف تعريف المشارك',
        editUserDialogTitle: 'قم بتعديل معلومات المستخدم',
        editExhibitorDialogTitle: 'تحرير بيانات جهة اتصال الشركة',
        editParticipantDialogTitle: 'تعديل تفاصيل المشارك',
        firstName: 'الاسم الأول',
        lastName: 'الاسم الاخير',
        uploadImage: 'تحميل صورة',
        removeImage: 'إزالة صورة',
        deleteTitle: 'حذف حسابي ',
        deleteConfirm: 'تأكيد حذف حسابي',
        deleteError: 'لا يمكنك حذف حسابك. يرجى حذف جميع الفعاليات التي تم إنشاؤها لتتمكن من حذف حسابك.',
        deleteSuccess: 'لقد تم حذف حسابك.',
        email: 'البريد الإلكتروني',
        reviewEventPrivacyPolicy: 'راجع سياسة خصوصية الحدث لدينا:',
        title: 'اللقب',
        company: 'اسم الجهة',
        phone: 'رقم الهاتف',
        platformProfileSectionTitleTextSecond: 'الملف',
        reviewPlatformGdprTextFirst: 'راجع',
        reviewPlatformGdprTextSecond: 'الشروط',
    },
    changePassword: {
        title: 'تغيير كلمة المرور',
        oldPassword: 'كلمة المرور القديمة',
        oldPasswordError: 'كلمة المرور القديمة غير صحيحة!',
        inputPassword: 'كلمة المرور الجديدة',
        inputConfirmPassword: 'تأكيد كلمة المرور',
        changePasswordButton: 'تغيير كلمة المرور',
    },
    roomsDropdown: {
        title: 'قائمة الغرف',
        auditoriumButton: 'الصالة',
        showfloorButton: 'صالة العرض',
        archiveButton: 'الأرشيف',
        onDemandButton: 'ON-DEMAND', // new translation
    },
    sideMenu: {
        liveWall: 'حائط العرض المباشر',
        networking: 'شبكات التواصل',
        info: 'معلومات',
        videoWall: 'حائط عرض بالفيديو',
        booth: 'منصة العرض',
        boothWall: 'حائط منصة العرض',
        resources: 'المصادر',
        networkingTooltip: 'ليس لديك حق الوصول إلى جميع وظائف الشبكات.',
        polls: 'استطلاعات رأي',
    },
    wall: {
        sortBy: 'ترتيب حسب',
        popularity: 'الأكثر شهرة',
        time: '‫التوقيت',
        emptyWallText: 'كن أول من يضيف منشور هنا',
        newPostInput: 'ماذا يدور في عقلك؟',
        newCommentInput: 'تعليق',
        resourcesVideoDescription: 'نبذة عن الفيديو',
        resourcesLinks: 'الروابط',
        resourcesFiles: 'الملفات',
        whatIsOnYourMind: 'في ماذا تفكر؟',
    },
    networking: {
        searchInput: 'البحث حسب المستخدم',
        at: 'في',
        regenerateMatches: 'تجديد المباريات',
    },
    closedAuditoriumDialog: {
        title: 'معلومات القاعة',
        content: 'تم تقييد الوصول إلى القاعة.',
        contentPackage: 'لا يمكن للباقة الخاصة بك الوصول إلى أي قاعات.',
        button: 'نعم',
    },
    closedShowfloorDialog: {
        title: 'معلومات العرض',
        content: 'تم تقييد الوصول إلى مخطط الحدث.',
        contentPackage: 'لا يمكن للباقة الخاصة بك الوصول إلى مخطط الحدث', //New translation: Your access package doesn't have access to any showfloors.
        button: 'نعم',
    },
    auditorium: {
        willStart: 'ستبدأ الجلسة التالية في ...',
        noAccess: 'لا يمكن للباقة الخاصة بك الوصول إلى أي قاعات.',
        noVideos: 'لا توجد مقاطع فيديو مجدولة لتشغيلها في هذه القاعة.',
        stageTab: 'المسرح',
        stagesButton: 'المسارح',
        videoWallButton: 'حائط عرض بالفيديو',
        resourcesButton: 'المصادر',
        slotEndsTextFirst: 'ينتهي البرنامج في',
        slotEndsTextSecond: 'سيكون العرض التقديمي متاحاًعند الطلب في نهاية البرنامج.',
        slotTitleWith: 'مع',
        allRooms: 'كل الغرف',
        onDemandButton: 'ON-DEMAND', // new translation
    },
    auditoriumArchive: {
        title: 'أرشيف القاعة',
        titleEvent: 'أرشيف الحدث',
        with: 'مع',
        stage: 'المسرح',
        videoWall: 'حائط عرض بالفيديو',
        resources: 'المصادر',
        tooltipMinimize: 'تصغير',
        tooltipFullscreen: 'شاشة كاملة',
        tooltipPause: 'إيقاف مؤقت',
        tooltipPlay: 'ابدء ',
        tooltipUnmute: 'إلغاء كتم الصوت',
        tooltipMute: 'كتم الصوت',
        noVideosAvailable: 'All sessions finished', // new translation
    },
    videoPlayer: {
        videoNotPlayingError: 'هل تواجه مشكلة مع بث الفيديو؟ أعد تحميل الصفحة من فضلك.',
        videoEndedTextFirst: 'انتهى الفيديو. شكراً على المشاهدة!',
        videoEndedTextSecond: 'حائط الفيديو مفتوح لفقرة الأسئلة والأجوبة.',
        tooltipPlay: 'ابدء ',
        tooltipPause: 'إيقاف مؤقت',
        tooltipMinimize: 'تصغير',
        tooltipFullscreen: 'شاشة كاملة',
        tooltipMute: 'إلغاء كتم الصوت',
        tooltipUnmute: 'إعادة الصوت',
    },
    showfloor: {
        allExhibitorsButton: 'كل العارضين', //new translation: Showfloor menu
        featuredExhibitorsText: 'العارضون المميزون',
        boothLinksDialogTitle: 'روابط منصة العرض',
        boothLinksDialogOtherLinksTitle: 'آخرى:',
        boothFilesDialogTitle: 'ملفات منصة العرض',
        boothVideoDialogTitle: 'الفيديو',
        mobileTabChat: 'الدردشة',
        mobileTabFiles: 'الملفات',
        mobileTabLinks: 'الروابط',
        mobileTabVideo: 'الفيديو',
        noExhibitorsTextFirst: 'العارضون يعملون على الإنتهاء من تجهيز منصاتهم',
        noExhibitorsTextSecond: 'ابقَ مترقباً',
        filterBy: 'مصنف بواسطة',
        searchByTheUser: 'البحث عن طريق اسم المستخدم',
        showShowfloorsButton: 'ALL EXHIBIT HALLS', //new translation
        searchExhibitors: 'Search exhibitors', // //new translation: Search exhibitors
        showfloorsTitle: 'Exhibit Halls', //new translation: Showfloors
    },
    eventArchive: {
        title: 'أرشيف الفيديو',
        searchText: 'انقر للبحث',
        noVideosText: 'لم يتم العثور على مقاطع فيديو في هذا الحدث.',
        noArchivedEvents: 'لا يوجد حالياً أي أحداث مؤرشفة.',
    },
    exhibitorDashboard: {
        event: 'فعالية',
        title: 'إدارة منصة العرض',
        visitorsButton: 'زوار منصة العرض',
        goToBoothButton: 'خذني إلى منصة العرض',
        goToBoothButtonDisabled: 'منصة العرض الخاصة بك غير متاحة للمعاينة حتى الآن.',
        tabStyle: 'نمط منصة العرض',
        tabLogo: 'الشعار',
        tabFiles: 'الملفات',
        tabLinks: 'الروابط',
        tabAvatars: 'الصور الرمزية',
        tabVideo: 'الفيديو',
        boothStyleTab: {
            optionOne: 'الخيار 1 – طراز اسكندنافي',
            optionTwo: 'الخيار 2 – طراز عصري',
            optionThree: 'الخيار 3 – طراز ديناميكي',
            optionFour: 'الخيار4 - طراز مخصص',
        },
        logoTab: {
            title: 'الشعار',
            fileUploadButton: '‫اضغط  للتحميل',
            noFile: 'لم يتم اختيار ملف',
        },
        filesTab: {
            title: 'المستندات:',
            fileNameInput: 'اسم المستند (مرئي لجميع المشاركين)',
            chooseFileButton: 'اختر ملفًا',
            noDocAdded: 'لا توجد مستندات مُضافة',
            fileError: 'يرجى إضافة اسم المستند',
        },
        linksTab: {
            social: {
                title: 'روابط وسائل التواصل الاجتماعي',
                subtitle: ' (مرئية لجميع المشاركين)',
                facebookInput: 'أدخل رابط صفحتك على فيسبوك',
                linkedinInput: 'أدخل رابط صفحتك على لينكد إن',
                twitterInput: 'أدخل رابط صفحتك على تويتر',
            },
            other: {
                title: 'روابط أخرى',
                subtitle: ' (الصفحة الرئيسية أو روابط أخرى)',
                linkInput: 'أدخل رابط جديد',
                linkLabel: 'أدخل مسمى رابط جديد',
            },
        },
        avatarsTab: {
            mainRepresentatives: {
                title: 'ممثلي منصة العرض',
                subtitle: ' (فقط ممثل واحد إلزامي)',
                leftRepresentative: 'ممثل الطرف الأيسر',
                rightRepresentative: 'ممثل الطرف الأيمن',
                addDialog: {
                    title: 'أضف صورة رمزية',
                    contentFirst: 'اختر صورة رمزية من المعرض أدناه ، أو قم بتحميل الصورة الرمزية الخاصة بك.',
                    contentSecond: 'هل تحتاج إلى مساعدة في التصميم؟ تواصل معنا:',
                    female: 'أنثى',
                    male: 'ذكر',
                    uploadSectionTitle: 'قم بتحميل صورتك الرمزية الشخصية',
                    uploadButton: 'اختر ملفًا',
                    noFile: 'اختر ملفًا',
                    assignText: 'يرجى تحديد مشارك في الفعالية ليكون مرتبطًا بالممثل أعلاه.',
                    input: 'بحث حسب الاسم',
                    avatarLabels: {
                        femaleA: 'أنثى أ',
                        femaleB: 'أنثى ب',
                        femaleC: 'أنثى ج',
                        maleA: 'ذكر أ',
                        maleB: 'ذكر ب',
                        maleC: 'ذكر ج',
                    },
                },
            },
            additionalRepresentatives: {
                title: 'ممثلين إضافيين',
                subtitle: ' (اختياري ، سيتم عرضه مع صورة ملفه الشخصي)',
                titleScholar: 'Choose Co-Author', //new translation
                addDialog: {
                    title: 'اختر ممثل إضافي',
                    assignText: 'يرجى البدء في البحث عن مشارك في الحدث لإضافته/ها كممثل.',
                    assignError: 'تمت إضافة المستخدم بالفعل كممثل لمنصة العرض!',
                    chooseAvatarTextFirst: 'اختر صورة رمزية من المعرض أدناه ، أو قم بتحميل الصورة الرمزية الخاصة بك.',
                    chooseAvatarTextSecond: 'هل تحتاج إلى مساعدة في التصميم؟ تواصل معنا:',
                    uploadText: 'قم بتحميل صورتك الرمزية الشخصية',
                    chooseFileText: 'اختر ملفًا',
                    noFile: 'لم يتم اختيار ملف',
                },
            },
            author: {
                title: 'Presenting Author', // new translation
                subtitle: 'Add only an author that registered to the event. You can add 1 author.', // new translation
                mainRepresentative: 'Main representative', // new translation
            },
            coAuthors: {
                title: 'Co-Authors', // new translation
                subtitle: 'Add only co-authors that registered to the event. You can add up to 4 co-authors.', // new translation
            },
        },
        videoTab: {
            video: {
                title: 'فيديو منصة العرض',
                subtitle: ' (رابط فيديو يوتيوب فقط)',
                input: 'أدخل رابط يوتيوب الخاص بك',
            },
            image: {
                title: 'صورة',
                subtitle: '(يتم عرض الصورة في منصتك في حالة عدم إضافة رابط فيديو في القسم أعلاه)',
                uploadButton: '‫اضغط  للتحميل',
            },
        },
    },
    boothDashboard: {
        event: 'فعالية',
        title: 'منصة العرض الخاصة بك',
        manage: 'إدارة',
        goToBoothButton: 'خذني إلى منصة العرض',
        previewNotAvailable: 'منصتك غير متاحة للمعاينة حتى الآن.',
        statistics: 'الإحصائيات',
        usersNow: 'الحاضرون الآن ',
        usersAllTime: 'الحاضرين طوال الوقت ',
        linkClicks: 'عدد النقرات على الرابط',
        documentViews: 'مشاهدات المستند ',
        videoViews: 'تمت مشاهدة العرض التقديمي بالفيديو ',
        visitorsSectionTitle: 'زوار المنصة',
        noVisitors: 'لا يوجد أي زوار في منصتك حتى الآن.',
        visitorsSearch: 'أبحث عن مستخدمين',
        actionType: 'نوع الإجراء',
        showUsers: 'عرض المستخدمين',
        ofUsers: 'عدد المستخدمين',
    },
    polls: {
        polls: 'استطلاعات رأي',
        poll: 'استطلاع رأي',
        noPolls: 'لم يتم إضافة استطلاعات الرأي حتى الآن.',
        pollProgress: 'الاستطلاع قيد التقدم',
        pollClosed: 'الاستطلاع مغلق',
        pollVoted: 'صوت المشاركون',
    },
    onDemandVideos: {
        sectionTitle: 'ON-DEMAND', // new translation
        noOnDemandVideos: 'No on demand videos were added yet.', // new translation
    },
    meetings: {
        meeting: 'اجتماع ',
        meetings: 'اجتماع ',
        createDialog: {
            createTitle: 'طلب اجتماع مع',
            inputDate: 'التاريخ',
            inputStart: 'يبدأ',
            inputEnd: 'ينتهي',
            inputTimezone: 'الوحدة الزمنية',
            inputNote: 'ملاحظة: يمكن جدولة اجتماع واحد فقط في كل مرة مع نفس الشخص.',
            meetingError: 'تاريخ أو وقت الاجتماع غير صالح. يرجى إعادة المراجعة!',
            buttonDisabled: 'يمكنك تحديد موعد اجتماع بعد بدء المحادثة!',
        },
        banner: {
            pending: 'طلب اجتماع',
            accepted: 'اجتماع مجدول إلى',
            progress: 'الاجتماع قيد التقدم',
            cancelTitle: 'إلغاء الاجتماع؟',
            cancelDescription: 'هل أنت متأكد أنك تريد إلغاء هذا الاجتماع؟',
            startCall: 'بدء المكالمة',
            joinCall: 'انضم',
            pendingOwner: 'طلب الاجتماع معلق لـ',
            videoCallStarted: 'بدأ الاجتماع بالفيديو',
        },
        newMeeting: 'اجتماع جديد',
        requestMeeting: 'طلب اجتماع مع',
        startDate: 'تاريخ البدء',
        starts: 'يبدأ',
        ends: 'ينتهي',
        addMessageHere: 'أضف رسالتك هنا',
        note: 'ملاحظة: يمكنك طلب اجتماع واحد فقط مع شخص معين. بعد انتهاء الاجتماع (أو إلغاؤه) ، يمكنك إعادة جدولة الاجتماع التالي مرة أخرى',
        create: 'إنشاء',
        requested: 'طلب',
        confirmed: 'تم تأكيد',
        title: 'اجتماعاتك',
        meetingsInfo: 'يمكن جدولة الاجتماعات من المحادثات. الرجاء بدء محادثة مع المستخدم الذي تريد جدولة اجتماع معه.',
    },
    landingPage: {
        animatedSectionButton: 'ابدأ الرحلة',
        topSectionHeaderPrimary: 'الجيل القادم',
        topSectionHeaderSecondary: 'الفعاليات عبر الإنترنت',
        carouselPresentationText: 'اكتشف',
        carouselPresentationTextAccent: 'جديد ماي أونفينت',
        videoPresentationTextPrimary: 'ماي أونفينت - الفعاليات عبر الإنترنت التي',
        videoPresentationTextAccent: 'سيحبها المشاركون.',
        videoPresentationTextSecondary: 'إليكم السبب:',
        testimonialsTextPrimary: 'الفعاليات عبر الإنترنت منذ 2015 -',
        testimonialsTextAccent: 'كل شيء يتعلق بالناس',
        testimonialsTextSecondary: '...',
        cardsTextPrimary: 'ماي أونفينت يجلب ',
        cardsTextAccent: 'كل ما هو مُذهل',
        cardsTextSecondary: 'إلى...',
        contactTextPrimary: 'مهتم في تنظيم حدث عبر الإنترنت؟',
        contactTextAccent: 'ابقى على تواصل',
        contactTextSecondary: '',
        contactInputName: 'الاسم',
        contactInputEmail: 'البريد الإلكتروني',
        contactInputPhone: 'رقم الهاتف المتحرك (اختياري)',
        contactInputMessage: 'رسالة',
        contactButton: 'إرسال',
        carouselPresentationTextAccentTextFirst: 'جديد ',
        videoPresentationTextPrimaryTextSecond: 'الأحداث عبر الإنترنت التي ',
        cardsTextPrimaryTextSecond: 'تجلب',
    },
    loginPlatform: {
        title: 'تسجيل الدخول',
        description:
            'بعد تسجيل الدخول، ستتم إعادة توجيهك إلى آخر حدث قمت بالتسجيل فيه. يمكنك التبديل بسهولة بين الفعاليات باستخدام خيار "الفعاليات الخاصة بي" في قائمتك.',
        descriptionTextFirst: 'بعد تسجيل الدخول ستتم إعادة توجيهك إلى الأخير',
        descriptionTextSecond: 'قمت بالتسجيل في. يمكنك التبديل بسهولة بين',
        descriptionTextThird: 'باستخدام خيار "فعالياتي" في قائمتك.',
    },
    skipped: {
        startVideoChat: 'لقد قمت بإعداد اجتماع عبر الفيديو. من فضلك انقر هنا للإنضمام.',
    },
    hubDropdownTitle: 'قائمة المنصة',
    marketplace: {
        title: {
            default: 'السوق',
            uppercased: 'السوق',
        },
        label: 'المنتجات',
        addNew: 'اضف جديد',
        addNewProduct: 'أضف منتج جديد',
        editProduct: 'تحرير المنتج',
        noProducts: 'لا توجد منتجات بعد',
        publish: 'النشر',
        productTitle: 'عنوان المنتج',
        productDescription: 'وصف المنتج',
        productLink: 'رابط المنتج',
        productImage: 'صورة المنتج',
        uploadHint:
            'قم بتحميل الصورة التي سيتم عرضها كمعاينة المنتج، الدقة المقترحة 600 * 600 بكسل للحصول على جودة أفضل',
        descriptionHint: 'الوصف محدود حتى 300 رمز',
        uploadImage: 'تحميل الصورة لزيادة قيمة المنتج',
        uploaded: 'تم الرفع',
    },
    personalAgenda: {
        agenda: 'جدول الأعمال',
        myProgram: 'برنامجي',
        myMeetings: 'اجتماعاتي',
        addNewTask: {
            default: 'أضف مهمة جديدة',
            uppercased: 'أضف مهمة جديدة',
        },
        noProgram: 'لا يوجد برنامج متاح في الوقت الحاضر',
        noMeetings: 'لا توجد اجتماعات متاحة في الوقت الحاضر',
        title: 'العنوان',
        startDate: 'تاريخ البدء',
        starts: 'يبدأ',
        ends: 'ينتهي',
        create: 'إنشاء',
        schedule: 'جدول',
        personalAgenda: 'الأجندة الشخصية',
        timetableMenu: 'قائمة الجدول الزمني',
        gmt: 'بتوقيت غرينيتش',
    },
    program: {
        toAgenda: 'إلى جدول الأعمال',
        attending: 'حضور',
    },
    businessCard: {
        businessCard: {
            default: 'بطاقة العمل',
            uppercased: 'بطاقة العمل',
        },
        editText: 'تحرير النص',
        colors: 'الألوان',
        branding: 'العلامات التجارية',
        cardPreview: 'معاينة البطاقة',
        onceSaved:
            'بمجرد حفظ بطاقة العمل، ستكون دائماً متصلة بملف التعريف الخاص بك. وسيتيح لك الوصول إلى الشبكات والدردشة وخيارات مهمة أخرى. سيتم حذف البطاقة تلقائيًا عند حذف ملف التعريف الخاص بك',
        required: 'مطلوب',
        designation: 'المسمى الوظيفي',
        Mobile: 'رقم الهاتف المتحرك',
        Phone: 'رقم الهاتف',
        Website: 'الموقع الإلكتروني',
        companyName: 'اسم الشركة',
        honoric: 'أضف لقبك المفضل مثل السيد، السيدة، الشيخ، إلخ',
        fillUpData: 'املأ البيانات من حسابي',
        primaryColor: 'اللون الرئيسي',
        primaryColorDescription: 'هذا اللون هو اللون المميز، استخدم لون علامتك التجارية هنا',
        backgroundColor: 'لون الخلفية',
        backgroundColorDescription:
            'هذا اللون هو اللون الإضافي، قم بإضافته كخلفية. بشكل افتراضي يمكن أن يكون أبيض أو أسود',
        backgroundTextColor: 'نص الخلفية ولون الرموز',
        backgroundTextColorDescription:
            'هذا اللون للهواتف والبريد الإلكتروني وتفاصيل الموقع الإلكتروني، ويجب أن يكون متباينًا مع لون الخلفية. انتقل إلى معاينة البطاقة للتأكد من أن البطاقة قابلة للقراءة وتحتوي على مستوى تباين كافٍ',
        companyLogo: 'شعار الشركة',
        uploadMessage:
            'قم بتحميل الصورة التي سيتم عرضها على شكل شعار الشركة، الدقة المقترحة 320 * 200 بكسل لجودة أفضل، استخدام شعار بخلفية شفافة (يفضل أن تكون صيغة الملف png)',
        uploadImage: 'تحميل الصور',
        request: 'طلب',
        open: 'افتح',
        pending: 'قيد الانتظار',
    },
    businessWallet: {
        short: 'محفظة الأعمال',
        full: 'محفظة الأعمال',
        myContacts: 'جهات الاتصال الخاصة بي',
        requests: 'الطلبات',
        pendings: 'معلقة',
        searchByName: 'البحث عن طريق الإسم',
        chat: 'دردشة',
        openCard: 'فتح البطاقة',
        empty: 'لا أحد هنا حتى الآن',
        cancelRequest: 'إلغاء الطلب',
        approve: 'اعتمد',
        reject: 'رفض',
        myNotes: 'ملاحظاتي',
        addNewNote: 'أضف ملاحظة جديدة',
        delete: 'حذف',
        saveThoughts: 'احفظ أفكارك بصورة منظمة في مكان واحد',
    },
    videoConferences: {
        message: 'لقد قمت بإعداد اجتماع بالفيديو، الرجاء الضغط هنا للانضمام إليها',
        clickToStart: 'انقر هنا لبدء محادثة فيديو',
        fullScreen: 'فتح بنمط ملء الشاشة',
        minimizeScreen: 'تصغير الشاشة',
    },
    sessions: {
        sessionList: 'قائمة الجلسات',
        createNewSession: {
            uppercased: 'إنشاء جلسة جديدة',
            default: 'إنشاء جلسة جديدة',
        },
        sessionName: {
            uppercased: 'اسم الجلسة',
            default: 'اسم الجلسة',
        },
        sessionLink: 'رابط الجلسة',
        sessionType: 'نوع الجلسة',
        manage: 'إدارة',
        edit: 'تعديل',
        delete: 'حذف',
        live: {
            uppercased: 'مباشر',
            lowercased: 'مباشر',
            default: 'مباشر',
        },
        recorded: {
            uppercased: 'مسجل',
            lowercased: 'مسجل',
            default: 'مسجل',
        },
        editSession: 'تحرير الجلسة',
        uploadVideo: 'رفع فيديو',
        create: 'إنشاء',
        discard: 'تجاهل',
        speakers: 'المتحدثين',
        moderators: 'مدراء الجلسة',
        speakerName: 'اسم المتحدث',
        moderatorName: 'اسم مدير الجلسة',
        kick: 'بدء',
        screenSharing: 'مشاركة الشاشة',
        audio: 'الصوت',
        video: 'فيديو',
        startStream: 'بدء البث',
        stopStream: 'إيقاف البث',
        startRecording: 'ابدأ التسجيل',
        endRecording: 'إنهي التسجيل',
        sessionChat: 'محادثة الجلسة',
        typeMessageHere: 'اكتب الرسالة هنا',
        sessionLinks: 'روابط الجلسات',
        speakerUrl: 'رابط المتحدث',
        moderatorUrl: 'رابط مدير الجلسة',
        copyUrl: 'إنسخ الرابط',
        organizer: 'المنظم',
        moderator: 'مدير الجلسة',
        speaker: 'المتحدث',
        joinAsSpeaker: 'انضم كمتحدث',
        invitedAsSpeaker: 'لقد تمت دعوتك كمتحدث إلى العرض التوضيحي. أدخل اسمك (سيكون مرئيًا للمشاركين في الجلسة)',
        yourName: 'اسمك',
        enter: 'أدخل',
        waitForApproval: 'من فضلك انتظر الموافقة',
        deny: 'رفض',
        admit: 'الموافقة ',
        joinAsModeraotr: 'انضم كمدير جلسة',
        invitedAsModerator: 'لقد تمت دعوتك كمتحدث إلى العرض التوضيحي. أدخل اسمك (سيكون مرئيًا للمشاركين في الجلسة)',
        declined: 'عذراً،  لقد تم رفضك لسبب ما',
    },
    breadcrumbsNavigation: {
        mainLobby: 'البهو الرئيسي',
        village: 'القرية',
    },
    village: {
        clickToOpen: 'انقر لفتح',
    },
    gdpr: {},
};

export default ar;

import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import FullPageSpinner from '../../SmallLayoutComponents/FullPageSpinner';
import Spinner from '../../SmallLayoutComponents/Spinner';
import BackgroundImage from '../../Images/background.jpg';
import NotFound from '../NotFound';
import RegisterDescriptionSection from './RegisterDescriptionSection';
import LoginEvent from './LoginEvent';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as RectangleSmall} from '../../Images/svg/bg_title_small.svg';
import SocialNetworkButtons from '../../Components/SocialNetworkButtons';
import RegisterEventForm from './RegisterEventForm';
import RegisterDescriptionSectionMobile from './RegisterDescriptionSectionMobile';
import RegisterMenuLinks from '../../Components/RegisterMenuLinks';
import {getBackgroundImageUrl, preventDefaultDrag} from '../../Utils/utils';
import {Helmet} from 'react-helmet';

class RegisterEvent extends React.Component {
    state = {
        registerAsParticipant: null,
        registerAsExhibitor: null,
        registerAsScholar: null,
        brandingTranslation: null,
        isRegistrationDisabled: null,
        displaySocialButtons: true
    };

    componentDidMount() {
        this.setRegistrationData();
        this.props.onSetEventPublicMenu();
        window.scrollTo(0, 0);
        this.setLanguagesData();
        if (this.props.location.search.split('?')[2] === 'login=true') {
            this._handleSeeLogInForm();
        } else {
            this._handleSeeSignUpForm();
        }
    }

    componentDidUpdate(prevProps) {
        const {location, languages} = this.props;
        if (prevProps.location.search !== location.search) {
            this.setRegistrationData();
        }
        if (prevProps.languages.eventLanguage !== languages.eventLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const brandingTranslation = this.props.eventInfo.brandingTranslations.find(
            (translation) => translation.language === this.props.languages.eventLanguage
        );
        this.setState({
            brandingTranslation: brandingTranslation,
        });
    };

    setRegistrationData = () => {
        const {location, eventInfo} = this.props;
        const query = new URLSearchParams(location.search);
        const registerAsExhibitor = query.get('registerAsExhibitor') === 'true';
        const registerAsScholar = query.get('registerAsScholar') === 'true';
        const registerAsParticipant = !registerAsExhibitor && !registerAsScholar;
        this.setState({
            registerAsExhibitor: registerAsExhibitor,
            registerAsScholar: registerAsScholar,
            registerAsParticipant: registerAsParticipant,
        });
        let isRegistrationClosed = false;
        if (registerAsExhibitor) {
            isRegistrationClosed = eventInfo.restrictExhibitorRegistration;
        } else if (registerAsScholar) {
            isRegistrationClosed = eventInfo.restrictScholarRegistration;
        } else if (registerAsParticipant) {
            isRegistrationClosed = eventInfo.restrictParticipantRegistration;
        }
        this.setState({
            isRegistrationDisabled: isRegistrationClosed,
        });
        // if the user landed on the registration page with a packageId in the URL
        // set the preselectedPackageId in the state of the React App
        // LOOK AT THE RedirectOnLogin COMPONENT => implemented it there
    };

    getOverlayColor = () => {
        const {registerAsExhibitor, registerAsScholar, registerAsParticipant} = this.state;
        const {eventInfo} = this.props;
        let overlayColor = {};
        if (eventInfo.brandingData.overlayColorParticipantReg && registerAsParticipant) {
            overlayColor.background = eventInfo.brandingData.overlayColorParticipantReg;
        }
        if (eventInfo.brandingData.overlayColorExhibitorReg && registerAsExhibitor) {
            overlayColor.background = eventInfo.brandingData.overlayColorExhibitorReg;
        }
        if (eventInfo.brandingData.overlayColorScholarReg && registerAsScholar) {
            overlayColor.background = eventInfo.brandingData.overlayColorScholarReg;
        }
        return overlayColor;
    };

    _handleSeeLogInForm = () => {
        this.props.seeLogInForm();
    };

    _handleSeeSignUpForm = () => {
        this.props.seeSignUpForm();
    };

    showSocialButtons = () => {
        this.setState({
            displaySocialButtons: true
        })
    }

    hideSocialButtons = () => {
        this.setState({
            displaySocialButtons: false
        })
    }

    render() {
        let {
            registerAsExhibitor,
            registerAsScholar,
            registerAsParticipant,
            brandingTranslation,
            isRegistrationDisabled,
            displaySocialButtons
        } = this.state;
        let {
            eventInfo,
            loadingEvent,
            loadingUser,
            translation,
            defaultTranslation,
            registerTabs,
            isMobile,
        } = this.props;
        if (loadingEvent && !eventInfo) {
            return (
                <div onDragStart={preventDefaultDrag} className="register-participant-step-1-spinner-container">
                    <FullPageSpinner />
                </div>
            );
        }
        let overlayColor = this.getOverlayColor();

        // if the user is on a exhibitor registration page and the event doesn't support normal exhibitors, display 404
        // same for scholars
        if (
            (registerAsExhibitor && !eventInfo.companyExhibitorsType) ||
            (registerAsScholar && !eventInfo.scholarExhibitorsType)
        ) {
            return <NotFound />;
        }

        return (
            <div>
                <Helmet>
                    <title>{`${process.env.REACT_APP_PLATFORM_NAME} - ${
                        brandingTranslation?.homepageEventName
                            ? brandingTranslation?.homepageEventName
                            : eventInfo.title
                    }: ${
                        registerAsParticipant
                            ? translation?.participantRegistration.metaTitle ||
                              defaultTranslation?.participantRegistration.metaTitle
                            : registerAsExhibitor
                            ? translation?.exhibitorRegistration.metaTitle ||
                              defaultTranslation?.exhibitorRegistration.metaTitle
                            : translation?.register.metaTitleScholarRegistration ||
                              defaultTranslation?.register.metaTitleScholarRegistration
                    }`}</title>
                </Helmet>
                <div onDragStart={preventDefaultDrag} className="register-participant-step-1">
                    <div onDragStart={preventDefaultDrag} className="full-background-container p-relative d-flex">
                        {registerAsExhibitor && (
                            <>
                                {/* fix older events with background image type string instead of type object */}
                                {eventInfo.brandingData &&
                                eventInfo.brandingData.exhibitorsRegBackground &&
                                eventInfo.brandingData.exhibitorsRegBackground?.original ? (
                                    <img
                                        src={getBackgroundImageUrl(eventInfo, 'exhibitorsRegBackground')}
                                        className="full-background-img img-cover p-absolute w-100 h-100"
                                        alt="background"
                                    />
                                ) : (
                                    <img
                                        src={BackgroundImage}
                                        className="full-background-img img-cover p-absolute w-100 h-100"
                                        alt="background"
                                    />
                                )}
                            </>
                        )}
                        {registerAsScholar && (
                            <>
                                {eventInfo.brandingData &&
                                eventInfo.brandingData.scholarsRegBackground &&
                                eventInfo.brandingData.scholarsRegBackground?.original ? (
                                    <img
                                        src={getBackgroundImageUrl(eventInfo, 'scholarsRegBackground')}
                                        className="full-background-img img-cover p-absolute w-100 h-100"
                                        alt="background"
                                    />
                                ) : (
                                    <img
                                        src={BackgroundImage}
                                        className="full-background-img img-cover p-absolute w-100 h-100"
                                        alt="background"
                                    />
                                )}
                            </>
                        )}
                        {registerAsParticipant && (
                            <>
                                {eventInfo.brandingData &&
                                eventInfo.brandingData.participantsRegBackground &&
                                eventInfo.brandingData.participantsRegBackground?.original ? (
                                    <img
                                        src={getBackgroundImageUrl(eventInfo, 'participantsRegBackground')}
                                        className="full-background-img img-cover p-absolute w-100 h-100"
                                        alt="background"
                                    />
                                ) : (
                                    <img
                                        src={BackgroundImage}
                                        className="full-background-img img-cover p-absolute w-100 h-100"
                                        alt="background"
                                    />
                                )}
                            </>
                        )}

                        {/*<div*/}
                        {/*    onDragStart={preventDefaultDrag}*/}
                        {/*    className="full-background-overlay p-absolute w-100 h-100"*/}
                        {/*    style={overlayColor}*/}
                        {/*/>*/}
                        <div onDragStart={preventDefaultDrag} className="header-spacing-container">
                            <div onDragStart={preventDefaultDrag} className="w-100 d-flex justify-content-center">
                                {eventInfo ? (
                                    <div onDragStart={preventDefaultDrag} className="form-container d-flex">
                                        <RegisterDescriptionSection
                                            registerAsExhibitor={registerAsExhibitor}
                                            registerAsScholar={registerAsScholar}
                                            registerAsParticipant={registerAsParticipant}
                                            brandingTranslation={brandingTranslation}
                                            translation={translation}
                                            defaultTranslation={defaultTranslation}
                                            isOnTabRegister={registerTabs.seeSignUpForm}
                                        />
                                        <div
                                            className={`right-form ${
                                                registerTabs.seeSignUpForm ? 'is-sign-up' : 'is-log-in'
                                            }`}
                                        >
                                            <div
                                                className={`form-header ${
                                                    registerTabs.seeLogInForm ? 'log-in' : 'sign-up'
                                                }`}
                                            >
                                                {registerTabs.seeLogInForm && (
                                                    <p onDragStart={preventDefaultDrag} className="form-title">
                                                        {translation?.register.loginTitleForm ||
                                                            defaultTranslation?.register.loginTitleForm}
                                                    </p>
                                                )}
                                                {registerTabs.seeSignUpForm && (
                                                    <div
                                                        onDragStart={preventDefaultDrag}
                                                        className="form-title banner-text"
                                                    >
                                                        {translation?.register.signUpTitleText ||
                                                            defaultTranslation?.register.signUpTitleText}
                                                    </div>
                                                )}
                                                <div onDragStart={preventDefaultDrag} className="tabs-container">
                                                    <div>
                                                        <div
                                                            className={`tab ${
                                                                registerTabs.seeLogInForm ? 'active' : ''
                                                            }`}
                                                            onClick={this._handleSeeLogInForm}
                                                        >
                                                            <h3>
                                                                {translation?.login.loginButton ||
                                                                defaultTranslation?.login.loginButton}
                                                            </h3>
                                                        </div>
                                                        <div
                                                            className={`tab ${
                                                                registerTabs.seeSignUpForm ? 'active' : ''
                                                            }`}
                                                            onClick={this._handleSeeSignUpForm}
                                                        >
                                                            <h3>
                                                                {translation?.register.registerButton ||
                                                                    defaultTranslation?.register.registerButton}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {registerTabs.seeLogInForm && (
                                                <LoginEvent
                                                    classes={'login-tab'}
                                                    registerAsExhibitor={registerAsExhibitor}
                                                    registerAsScholar={registerAsScholar}
                                                    registerAsParticipant={registerAsParticipant}
                                                />
                                            )}
                                            {registerTabs.seeSignUpForm && (
                                                <div onDragStart={preventDefaultDrag} className="register-form-wrapper">
                                                    {isMobile && displaySocialButtons && (
                                                        <RegisterDescriptionSectionMobile
                                                            registerAsExhibitor={registerAsExhibitor}
                                                            registerAsScholar={registerAsScholar}
                                                            registerAsParticipant={registerAsParticipant}
                                                            brandingTranslation={brandingTranslation}
                                                            translation={translation}
                                                            defaultTranslation={defaultTranslation}
                                                            isOnTabRegister={true}
                                                        />
                                                    )}
                                                    {displaySocialButtons &&
                                                        <SocialNetworkButtons
                                                            registerAsExhibitor={registerAsExhibitor}
                                                            registerAsScholar={registerAsScholar}
                                                            registerAsParticipant={registerAsParticipant}
                                                        />
                                                    }
                                                    <RegisterEventForm
                                                        translation={translation}
                                                        defaultTranslation={defaultTranslation}
                                                        checkIsRegistrationClosed={isRegistrationDisabled}
                                                        showSocialButtons={this.showSocialButtons}
                                                        hideSocialButtons={this.hideSocialButtons}
                                                    />
                                                    {isMobile && <RegisterMenuLinks />}
                                                </div>
                                            )}
                                            {(loadingUser || (loadingEvent && eventInfo)) && <Spinner />}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        loadingUser: state.user.loading,
        loadingEvent: state.event.loading,
        eventInfo: state.event.eventInfo,
        eventId: state.event.eventId,
        registerTabs: state.user.registerTabs,
        isMobile: state.layout.isMobile,
        languages: state.languages,
        translation: state.languages.translations[state.languages.eventLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetEventPublicMenu: () => dispatch(actions.setEventPublicMenu()),
        seeLogInForm: () => dispatch(actions.seeLogInForm()),
        seeSignUpForm: () => dispatch(actions.seeSignUpForm()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterEvent);

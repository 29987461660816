import React from 'react';
import Spinner from '../SmallLayoutComponents/Spinner';
import LoginBackground from '../Images/background.jpg';
import {connect} from 'react-redux';
import * as actions from '../store/actions/index';
import SocialLoginButtons from '../Components/SocialNetworkButtons';
import {ReactComponent as RectangleGreen} from '../Images/svg/bck_small.svg';
import RegisterMenuLinks from '../Components/RegisterMenuLinks';
import LoginPlatformForm from './LoginPlatformForm';
import {preventDefaultDrag} from '../Utils/utils';

class LoginPlatform extends React.Component {
    componentDidMount() {
        this.props.onSetPlatformMenu();
    }

    // if we had an error from server on login attempt we clear that error
    componentWillUnmount() {
        this.props.onClearError();
    }

    render() {
        let {loadingUser, tab, isMobile} = this.props;

        return (
            <div onDragStart={preventDefaultDrag} className={`${tab ? 'login-tab' : ''}`}>
                <div onDragStart={preventDefaultDrag} className="login-page platform-login">
                    {tab ? (
                        <div onDragStart={preventDefaultDrag} className="login-container">
                            <div>
                                <div>
                                    {isMobile && (
                                        <p onDragStart={preventDefaultDrag} className="title-description">
                                            After log in you will get redirected to the last event you registered to.
                                            You can easily switch between events using the My Events option in your
                                            menu.
                                        </p>
                                    )}
                                    <SocialLoginButtons
                                        logInTab
                                        registerAsExhibitor={false}
                                        registerAsParticipant={false}
                                    />
                                </div>
                                <LoginPlatformForm />
                            </div>
                            {loadingUser && <Spinner />}
                        </div>
                    ) : (
                        <div onDragStart={preventDefaultDrag} className="full-background-container p-relative d-flex">
                            <img
                                src={LoginBackground}
                                className="full-background-img img-cover p-absolute w-100 h-100"
                                alt="background"
                            />
                            {/*<div*/}
                            {/*    onDragStart={preventDefaultDrag}*/}
                            {/*    className="full-background-overlay p-absolute w-100 h-100"*/}
                            {/*/>*/}
                            <div onDragStart={preventDefaultDrag} className="header-spacing-container">
                                <div
                                    onDragStart={preventDefaultDrag}
                                    className="w-100 h-100 d-flex justify-content-center align-items-center"
                                >
                                    <div onDragStart={preventDefaultDrag} className="form-container d-flex">
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className="form-header left-log-in-description is-sign-up"
                                        >
                                            <h1 className="form-title banner-text">
                                                <div>
                                                    <span>Already have an account?</span>
                                                </div>
                                            </h1>
                                        </div>
                                        <div>
                                            <div onDragStart={preventDefaultDrag} className="left-form">
                                                <div>
                                                    <p>
                                                        After log in you will get redirected to the last event you
                                                        registered to. You can easily switch between events using the
                                                        My Events option in your menu.
                                                    </p>
                                                </div>
                                            </div>
                                            <div onDragStart={preventDefaultDrag} className="right-form">
                                                <div onDragStart={preventDefaultDrag} className="register-form-wrapper">
                                                    {isMobile && (
                                                        <p
                                                            onDragStart={preventDefaultDrag}
                                                            className="title-description"
                                                        >
                                                            After log in you will get redirected to the last event you
                                                            registered to. You can easily switch between events using
                                                            the My Events option in your menu.
                                                        </p>
                                                    )}
                                                    <SocialLoginButtons
                                                        logInTab
                                                        registerAsExhibitor={false}
                                                        registerAsParticipant={false}
                                                        registerAsScholar={false}
                                                    />
                                                    <LoginPlatformForm />
                                                </div>
                                                {loadingUser && <Spinner />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        loadingUser: state.user.loading,
        isMobile: state.layout.isMobile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onClearError: () => dispatch(actions.clearError()),
        onSetPlatformMenu: () => dispatch(actions.setPlatformMenu()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPlatform);

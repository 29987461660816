import React, {useRef, useState} from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import {isMobileOnly} from 'react-device-detect';


const NextVideoTimerDetails = ({displayProgramTimeslotMatch}) => {

    const titleWithQuotes = `"${displayProgramTimeslotMatch?.title}"`;

    return (
        <span className={'upcoming-timeslot-details-container'}>
            <span className={"upcoming-timeslot-separator"}/>
            <span className="upcoming-timeslot-title-container">
                <LinesEllipsis
                    text={titleWithQuotes}
                    maxLine={`${isMobileOnly ? 3 : 2}`}
                    ellipsis='..."'
                    trimRight
                    basedOn='letters'
                />

            </span>
            <span className="upcoming-timeslot-speakers">
                {displayProgramTimeslotMatch?.speakers}
            </span>
        </span>
    );
};

export default NextVideoTimerDetails;
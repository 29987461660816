import React from 'react';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import * as actions from '../../store/actions';
import axios from '../../store/axios-instance';
import TextField from '../../Components/InputFields/TextField';
import SelectCountry from '../../Components/InputFields/SelectCountry';
import RadioField from '../../Components/InputFields/RadioField';
import SelectField from '../../Components/InputFields/SelectField';
import CheckboxField from '../../Components/InputFields/CheckboxField';
import Button from '@material-ui/core/Button';
import {preventDefaultDrag} from '../../Utils/utils';
import AccountSDGs from './AccountSDGs';
import {ReactComponent as SaveIcon} from '../../Images/svg/save.svg';
import cloneDeep from 'lodash/cloneDeep';
import {
    isLinkRule,
    isFacebookLinkRule,
    isLinkedinLinkRule,
    isTwitterLinkRule,
    isGoogleScholarLinkRule,
    isResearchGateLinkRule,
} from 'Utils/validationRules';

class EditScholarInfoForm extends React.Component {
    state = {
        user: {
            fields: [
                {
                    isEnabled: true,
                    name: 'affiliation',
                    type: 'text',
                    value: '',
                    label: 'Affiliation',
                    multiline: 0,
                    validators: [],
                    errorMessages: [],
                },
                {
                    isEnabled: true,
                    name: 'title',
                    type: 'text',
                    value: '',
                    label: 'Academic Title',
                    multiline: 0,
                    validators: [],
                    errorMessages: [],
                },
                {
                    isEnabled: true,
                    name: 'phone',
                    type: 'text',
                    value: '',
                    label: 'Mobile',
                    multiline: 0,
                    validators: ['matchRegexp:^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$'],
                    errorMessages: [
                        `${this.props.languages.translations[this.props.languages.eventLanguage].errors.phoneInvalid}`,
                    ],
                },
                {
                    isEnabled: true,
                    name: 'googleScholar',
                    type: 'text',
                    value: '',
                    label: 'Google Scholar profile',
                    multiline: 0,
                    validators: ['isGoogleScholarLink'],
                    errorMessages: [
                        `${
                            this.props.languages.translations[this.props.languages.eventLanguage].errors
                                .googleScholarLinkInvalid
                        }`,
                    ],
                },
                {
                    isEnabled: true,
                    name: 'researchGate',
                    type: 'text',
                    value: '',
                    label: 'Research Gate profile',
                    multiline: 0,
                    validators: ['isResearchGateLink'],
                    errorMessages: [
                        `${
                            this.props.languages.translations[this.props.languages.eventLanguage].errors
                                .researchGateLinkInvalid
                        }`,
                    ],
                },
                {
                    isEnabled: true,
                    name: 'facebook',
                    type: 'text',
                    value: '',
                    label: 'Facebook profile',
                    multiline: 0,
                    validators: ['isFacebookLink'],
                    errorMessages: [
                        `${
                            this.props.languages.translations[this.props.languages.eventLanguage].errors
                                .facebookLinkInvalid
                        }`,
                    ],
                },
                {
                    isEnabled: true,
                    name: 'linkedin',
                    type: 'text',
                    value: '',
                    label: 'Your Linkedin profile',
                    multiline: 0,
                    validators: ['isLinkedinLink'],
                    errorMessages: [
                        `${
                            this.props.languages.translations[this.props.languages.eventLanguage].errors
                                .linkedinLinkInvalid
                        }`,
                    ],
                },
                {
                    isEnabled: true,
                    name: 'twitter',
                    type: 'text',
                    value: '',
                    label: 'Your Twitter profile',
                    multiline: 0,
                    validators: ['isTwitterLink'],
                    errorMessages: [
                        `${
                            this.props.languages.translations[this.props.languages.eventLanguage].errors
                                .twitterLinkInvalid
                        }`,
                    ],
                },
                {
                    isEnabled: true,
                    name: 'country',
                    type: 'text',
                    value: '',
                    label: 'Country',
                    multiline: 0,
                    validators: [],
                    errorMessages: [],
                },
                {
                    isEnabled: true,
                    name: 'website',
                    type: 'text',
                    value: '',
                    label: 'Website',
                    multiline: 0,
                    validators: ['isLink'],
                    errorMessages: [
                        `${this.props.languages.translations[this.props.languages.eventLanguage].errors.linkInvalid}`,
                    ],
                },
                {
                    isEnabled: true,
                    name: 'sdgs',
                    type: 'text',
                    value: [],
                    label: 'Which SDGs are you interested in?',
                    multiline: 0,
                    validators: [],
                    errorMessages: [],
                },
            ],
            chatPreference: {
                name: 'chatPreference',
                type: 'radio',
                value: '',
                label: 'Who should we allow to contact you in this event via the Neural Network chat?',
                options: [
                    {label: 'Everybody', value: 'everybody'},
                    {label: 'Only Contacts', value: 'contacts'},
                    {label: 'Only High Matches (if Smart Matching is enabled for this event)', value: 'matches'},
                    {label: 'Only Contacts or High Matches', value: 'contactsOrMatches'},
                    {label: 'Nobody', value: 'nobody'},
                ],
                multiline: 0,
                validators: [],
                errorMessages: [],
            },
        },
        disabled: true,
    };

    componentDidMount() {
        this.addValidationRules();

        // update the required fields with the settings from the current event
        this.setExhibitorFields();
    }

    componentWillUnmount() {
        // remove rule when it is not needed
        this.removeValidationRules();
    }

    setExhibitorFields = () => {
        const {fields, chatPreference} = this.state.user;
        const {exhibitor, predefinedFields, customFields} = this.props;
        chatPreference.value = exhibitor.chatPreference;
        let updatedFields = [...fields];

        updatedFields.forEach((field) => {
            let eventInfoField = predefinedFields[field.name];
            field.label = eventInfoField.label;
            field.isEnabled = eventInfoField.isEnabled;
            field.value = exhibitor[field.name] ? exhibitor[field.name] : '';
            if (eventInfoField.isRequired) {
                field.validators.push(['required']);
                field.errorMessages.push([
                    this.props.languages.translations[this.props.languages.eventLanguage].errors.required,
                ]);
            }
        });

        customFields.forEach((extraField) => {
            let customField = exhibitor.customFields.find(
                (customField) => customField.customFieldId === extraField._id
            );
            let value = '';
            if (customField?.value && customField.value.length) {
                value = customField.value;
            } else if (customField?.values && customField.values.length) {
                value = customField.values;
            }

            const field = {
                isEnabled: true,
                name: extraField._id,
                type: extraField.type,
                value: value,
                label: extraField.label,
                multiline: 0,
                validators: [],
                errorMessages: [],
                options: extraField.options,
            };
            if (extraField.isRequired) {
                field.validators.push(['required']);
                field.errorMessages.push([
                    this.props.languages.translations[this.props.languages.eventLanguage].errors.required,
                ]);
            }
            updatedFields.push(field);
        });

        let enabledUpdatedFields = updatedFields.filter((field) => field.isEnabled);
        this.setState({
            user: {...this.state.user, fields: enabledUpdatedFields},
        }, this.addNoCommasValidation);
    };

    addNoCommasValidation = () => {
        const updatedUser = cloneDeep(this.state.user);
        const {translation, defaultTranslation} = this.props;
        updatedUser.fields.forEach(field => {
            // for the sdg field we send as values an array
            // an array contains ',' and we couldn't pass the validation
            // by adding !Array.isArray(field.value) we fixed this bug
            if(field.type === "text" && !Array.isArray(field.value)){
                field.validators.push('noCommasAllowed');
                const errorMessage = translation.errors.noCommasAllowed || defaultTranslation.errors.noCommasAllowed;
                field.errorMessages.push(errorMessage);
            }
        })
        this.setState({
            user: updatedUser
        })
    }

    handleFieldChange = (fieldName, fieldValue) => {
        const {fields, chatPreference} = this.state.user;
        let updatedFields = [...fields];

        if (fieldName === 'chatPreference') {
            chatPreference.value = fieldValue;
        } else {
            let updatedFieldIndex = updatedFields.findIndex((field) => field.name === fieldName);
            updatedFields[updatedFieldIndex].value = fieldValue;
        }
        this.setState({user: {...this.state.user, fields: updatedFields}}, () => {
            this.refs.form.isFormValid().then((isValid) => {
                this.setState({disabled: !isValid});
            });
        });
    };

    handleUpdateExhibitorContactPersonInformation = () => {
        const {fields, chatPreference} = this.state.user;
        const {exhibitor} = this.props;
        let user = {};
        user[chatPreference.name] = chatPreference.value;
        fields.forEach((field) => {
            user[field.name] = field.value;
        });

        axios({method: 'put', url: '/exhibitor/' + exhibitor._id, data: user})
            .then((response) => {
                const {eventId, userId} = this.props;
                this.setState({
                    disabled: true,
                });
                this.props.onGetUserEventProfile(eventId, userId);
                this.props.closeDialog();
            })
            .catch((error) => {});
    };

    addValidationRules = () => {
        ValidatorForm.addValidationRule('isFacebookLink', (value) => {
            let rule = isFacebookLinkRule;
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isLinkedinLink', (value) => {
            let rule = isLinkedinLinkRule;
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isTwitterLink', (value) => {
            let rule = isTwitterLinkRule;
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isLink', (value) => {
            let rule = isLinkRule
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                this.setState({newLinkError: true});
                return false;
            }
            this.setState({newLinkError: false});
            return true;
        });
        ValidatorForm.addValidationRule('isGoogleScholarLink', (value) => {
            let rule = isGoogleScholarLinkRule;
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isResearchGateLink', (value) => {
            let rule = isResearchGateLinkRule;
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });

        ValidatorForm.addValidationRule('noCommasAllowed', (value) => {
            let rule = new RegExp('^[^,]+$', 'i');
            let match = rule.test(value);

            if (value === undefined || value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
    };

    removeValidationRules = () => {
        ValidatorForm.removeValidationRule('isFacebookLink');
        ValidatorForm.removeValidationRule('isLinkedinLink');
        ValidatorForm.removeValidationRule('isTwitterLink');
        ValidatorForm.removeValidationRule('isLink');
        ValidatorForm.removeValidationRule('isGoogleScholarLink');
        ValidatorForm.removeValidationRule('isResearchGateLink');
        ValidatorForm.removeValidationRule('noCommasAllowed');
    };

    handleChangeCountry = (e) => {
        this.setState({
            user: {...this.state.user, country: e.target.value},
        });
    };

    handleSDGS = (sdgs) => {
        let updatedFields = [...this.state.user.fields];
        let updatedFieldIndex = updatedFields.findIndex((field) => field.name === 'sdgs');

        updatedFields[updatedFieldIndex].value = sdgs;
        this.setState({user: {...this.state.user, fields: updatedFields}}, () => {
            this.refs.form.isFormValid().then((isValid) => {
                this.setState({disabled: !isValid});
            });
        });
    };

    render() {
        let {fields, chatPreference} = this.state.user;
        const {translation} = this.props;
        return (
            <ValidatorForm ref="form" onSubmit={this.handleUpdateExhibitorContactPersonInformation}>
                <RadioField
                    field={chatPreference}
                    handleChange={this.handleFieldChange}
                    key={chatPreference.name}
                    withLabel
                />
                {fields.map((field) => {
                    if (field.name === 'country') {
                        return (
                            <SelectCountry
                                field={field}
                                handleChange={this.handleFieldChange}
                                key={field.name}
                            />
                        );
                    }
                    if (field.type === 'select') {
                        return (
                            <SelectField
                                field={field}
                                handleChange={this.handleFieldChange}
                                key={field.name}
                            />
                        );
                    }
                    if (field.type === 'radio') {
                        return (
                            <RadioField
                                field={field}
                                handleChange={this.handleFieldChange}
                                key={field.name}
                            />
                        );
                    }
                    if (field.type === 'checkbox') {
                        return (
                            <CheckboxField
                                field={field}
                                handleChange={this.handleFieldChange}
                                key={field.name}
                                fieldValues={field.value}
                            />
                        );
                    }
                    if (field.name === 'sdgs') {
                        return (
                            <div key={field.name}>
                                <AccountSDGs
                                    field={field}
                                    sdgsValues={field.value}
                                    handleSDGS={this.handleSDGS}
                                />
                                <TextValidator
                                    label={field.label}
                                    type="text"
                                    name={field.name}
                                    value={field.value}
                                    variant="outlined"
                                    onChange={this.handleFieldChange}
                                    validators={field.validators}
                                    errorMessages={field.errorMessages}
                                    multiline={field.multiline > 0}
                                    rows={field.multiline}
                                    fullWidth={true}
                                    margin="normal"
                                    className="field-container hidden sdg-label"
                                />
                            </div>
                        )
                    }
                    return (
                        <TextField
                            field={field}
                            handleChange={this.handleFieldChange}
                            key={field.name}
                        />
                    );
                })}
                <Button
                    type="submit"
                    variant="outlined"
                    classes={{
                        root: 'restyled-save-button button-save',
                        label: 'dialog-btn-uppercase',
                    }}
                    disabled={this.state.disabled}
                >
                    <SaveIcon/>
                    {translation?.generalText.save}
                </Button>
            </ValidatorForm>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        predefinedFields: state.event?.registrationFields?.data?.scholarPredefinedRegistrationFields,
        customFields: state.event?.registrationFields?.data?.scholarRegistrationFields,
        eventId: state.event.eventId,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        userId: state.user.data._id
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetUserEventProfile: (eventId, userId) => dispatch(actions.getUserEventProfile(eventId, userId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditScholarInfoForm);

import React, {PureComponent} from 'react';

class TimeIcon extends PureComponent{
    render() {
        return (
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.00008 0.667969C4.41675 0.667969 0.666748 4.41797 0.666748 9.0013C0.666748 13.5846 4.41675 17.3346 9.00008 17.3346C13.5834 17.3346 17.3334 13.5846 17.3334 9.0013C17.3334 4.41797 13.5834 0.667969 9.00008 0.667969ZM12.5001 12.5013L8.16675 9.83464V4.83464H9.41675V9.16797L13.1667 11.418L12.5001 12.5013Z" fill={this.props.primary}/>
            </svg>
        );
    }
}

export default TimeIcon;
import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import {
    EventAvailable as EventAvailableIcon,
    FiberManualRecord as FiberManualRecordIcon,
    Stop as StopIcon,
    PlayArrow as PlayArrowIcon,
} from '@material-ui/icons';

import {
    sessionStreamStart,
    sessionStreamStop,
    sessionStreamRecordStart,
    sessionStreamRecordStop,
} from '../../store/actions';

const StreamControls = ({
    session,
    sessionStreamStart,
    sessionStreamStop,
    sessionStreamRecordStart,
    sessionStreamRecordStop,
}) => {
    const handleStartRecording = useCallback(() => sessionStreamRecordStart(session._id), [
        session._id,
        sessionStreamRecordStart,
    ]);

    const handleEndRecording = useCallback(() => sessionStreamRecordStop(session._id), [
        session._id,
        sessionStreamRecordStop,
    ]);

    const handleStartStream = useCallback(() => sessionStreamStart(session._id), [session._id, sessionStreamStart]);

    const handleStopStream = useCallback(() => sessionStreamStop(session._id), [session._id, sessionStreamStop]);

    const canStartStream = !session.streamPipeId && session.records.length === 0;

    return (
        <div className="buttons_group">

            {/**/}
            {canStartStream && (
                <button type="button" className="button" onClick={handleStartStream}>
                    <PlayArrowIcon className="button_icon" />
                    <span>START STREAM</span>
                </button>
            )}

            {!!session.streamPipeId && (
                <React.Fragment>
                    <button type="button" className="button" onClick={handleStopStream}>
                        <EventAvailableIcon className="button_icon" />
                        <span>STOP STREAM</span>
                    </button>


                    {/* Commented out the start end recording from ETX */}
                    {/* at myOnvent you can only start and stop stream only once */}

                    {/*{!!session.recordPipeId && (*/}
                    {/*    <button type="button" className="button -colored" onClick={handleEndRecording}>*/}
                    {/*        <StopIcon className="button_icon" />*/}
                    {/*        <span>END RECORDING</span>*/}
                    {/*    </button>*/}
                    {/*)}*/}
                    {/*{!session.recordPipeId && (*/}
                    {/*    <button type="button" className="button -colored" onClick={handleStartRecording}>*/}
                    {/*        <FiberManualRecordIcon className="button_icon" />*/}
                    {/*        <span>START RECORDING</span>*/}
                    {/*    </button>*/}
                    {/*)}*/}
                </React.Fragment>
            )}
        </div>
    );
};

export default connect(
    (state) => ({
        session: state.sessions.sessionDashboard.session,
    }),
    {
        sessionStreamStart,
        sessionStreamStop,
        sessionStreamRecordStart,
        sessionStreamRecordStop,
    }
)(StreamControls);

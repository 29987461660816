import React from 'react';
import SocialLoginButtons from '../Components/SocialNetworkButtons';
import {connect} from 'react-redux';
import * as actions from '../store/actions/index';
import {withRouter} from 'react-router-dom';
import FullPageSpinner from '../SmallLayoutComponents/FullPageSpinner';
import Spinner from '../SmallLayoutComponents/Spinner';
import BackgroundImage from '../Images/background.jpg';
import RegistrationPlatformDescription from './RegistrationPlatformDescription';
import LoginPlatform from './LoginPlatform';
import RegisterPlatformForm from './RegisterPlatformForm';
import {preventDefaultDrag} from '../Utils/utils';

class RegisterPlatform extends React.Component {

    state = {
        displaySocialButtons: true
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.onSetPlatformMenu();
        if (this.props.location.search.split('?')[1] === 'login=true') {
            this._handleSeeLogInForm();
        } else {
            this._handleSeeSignUpForm();
        }
    }

    _handleSeeLogInForm = () => {
        this.props.seeLogInForm();
    };

    _handleSeeSignUpForm = () => {
        this.props.seeSignUpForm();
    };

    showSocialButtons = () => {
        this.setState({
            displaySocialButtons: true
        })
    }

    hideSocialButtons = () => {
        this.setState({
            displaySocialButtons: false
        })
    }

    render() {
        let {loadingUser, registerTabs, isMobile} = this.props;
        let {displaySocialButtons} = this.state;
        return (
            <div>
                <div onDragStart={preventDefaultDrag} className="register-participant-step-1">
                    <div onDragStart={preventDefaultDrag} className="full-background-container p-relative d-flex">
                        <img
                            src={BackgroundImage}
                            className="full-background-img img-cover p-absolute w-100 h-100"
                            alt="background"
                        />
                        <div onDragStart={preventDefaultDrag} className="header-spacing-container">
                            <div onDragStart={preventDefaultDrag} className="w-100 d-flex justify-content-center">
                                <div onDragStart={preventDefaultDrag} className="form-container d-flex">
                                    <RegistrationPlatformDescription isOnTabRegister={registerTabs.seeSignUpForm} />
                                    <div
                                        className={`right-form ${
                                            registerTabs.seeSignUpForm ? 'is-sign-up' : 'is-log-in'
                                        }`}
                                    >
                                        <div
                                            className={`form-header ${
                                                registerTabs.seeLogInForm ? 'log-in' : 'sign-up'
                                            }`}
                                        >
                                            {registerTabs.seeLogInForm && (
                                                <p onDragStart={preventDefaultDrag} className="form-title">
                                                    Already have an account ?
                                                </p>
                                            )}
                                            {registerTabs.seeSignUpForm && (
                                                <p onDragStart={preventDefaultDrag} className="form-title banner-text">
                                                    First time at Neural Network?
                                                </p>
                                            )}
                                            <div onDragStart={preventDefaultDrag} className="tabs-container">
                                                <div>
                                                    <div
                                                        className={`tab ${registerTabs.seeLogInForm ? 'active' : ''}`}
                                                        onClick={this._handleSeeLogInForm}
                                                    >
                                                        <h3>Log in</h3>
                                                    </div>
                                                    <div
                                                        className={`tab ${registerTabs.seeSignUpForm ? 'active' : ''}`}
                                                        onClick={this._handleSeeSignUpForm}
                                                    >
                                                        <h3>Sign up</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {registerTabs.seeLogInForm && <LoginPlatform tab />}
                                        {registerTabs.seeSignUpForm && (
                                            <div onDragStart={preventDefaultDrag} className="register-form-wrapper">
                                                {isMobile && displaySocialButtons && (
                                                    <p onDragStart={preventDefaultDrag} className="title-description">
                                                        Registering on the platform will allow you to set up your own
                                                        event or participate in events set up by others.
                                                    </p>
                                                )}
                                                {displaySocialButtons &&
                                                    <SocialLoginButtons
                                                        registerAsExhibitor={false}
                                                        registerAsParticipant={false}
                                                    />
                                                }
                                                <RegisterPlatformForm
                                                    showSocialButtons={this.showSocialButtons}
                                                    hideSocialButtons={this.hideSocialButtons}
                                                />
                                            </div>
                                        )}
                                        {loadingUser && <Spinner />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loadingUser: state.user.loading,
        registerTabs: state.user.registerTabs,
        isMobile: state.layout.isMobile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetPlatformMenu: () => dispatch(actions.setPlatformMenu()),
        seeLogInForm: () => dispatch(actions.seeLogInForm()),
        seeSignUpForm: () => dispatch(actions.seeSignUpForm()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterPlatform));

import React from 'react';
import {Link} from 'react-router-dom';
import SideMenu from '../../Components/SideMenu';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import NotificationPresentationStarts from '../../Components/NotificationPresentationStarts';
import NoVideoImage from '../../assets/no-video.png';
import Slider from 'react-slick';
import FullOverlay from '../../SmallLayoutComponents/FullOverlay';
import BoothVideo from '../../Dialogs/ExhibitorBooth/BoothVideo';
import '../../CSS/lobby.scss';
import InfoBoothIcon from '../../Images/svg/contour3.svg';
import PlayIcon from '../../Images/svg/play.svg';
import {getPrivateChatId, getVideoIdFromYoutubeUrl, preventDefaultDrag} from '../../Utils/utils';
import {disconnectFromNotifications} from '../../Api/socketApi';
import {getLobbyBackground} from '../../Utils/getBackgrounds';
import {getBuildingLobbyBackgroundUploaded} from '../../Utils/getBackgroundsUploaded';
import InformDialog from '../../Dialogs/InformDialog';
import BreadcrumbsNavigation from '../../Components/BreadcrumbsNavigation';
import NoBuildingAccess from './NoBuildingAccess';
import axios from '../../store/axios-instance';

class BuildingLobby extends React.Component {
    state = {
        showVideo: false,
        auditoriumElementStyle: {},
        showFloorElementStyle: {},
        sliderContainerStyle: {},
        lobbyVideoContainerStyle: {},
        infoBoothContainerStyle: {},
        bannerContainerStyle: {},
        showNoShowfloorDialog: false,
        showNoShowfloorAccessDialog: false,
        showNoAuditoriumDialog: false,
        showNoAuditoriumAccessDialog: false,
    };

    componentDidMount() {
        this.props.onSetEventProtectedMenu();
        this.handleSeeLiveWall();
        this.props.setHasVideoWall(false);
        this.setActiveElementsPositions();
        window.addEventListener('resize', this.setActiveElementsPositions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setActiveElementsPositions);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.event._id !== this.props.event._id) {
            this.handleSeeLiveWall();
        }
    }

    handleSeeLiveWall = () => {
        const wallData = {
            wallId: this.props.event.liveWallId,
            highlightUsers: [],
        };

        this.props.setActiveWall(wallData);
        this.props.seeLiveWall();
    };

    handleOpenLobbyVideo = () => {
        this.setState({showVideo: true});
    };

    handleOpenNoShowfloorDialog = () => {
        this.setState({showNoShowfloorDialog: true});
    };

    handleOpenNoShowfloorAccess = () => {
        this.setState({showNoShowfloorAccessDialog: true});
    };

    handleOpenNoAuditoriumDialog = () => {
        this.setState({showNoAuditoriumDialog: true});
    };

    handleOpenNoAuditoriumAccess = () => {
        this.setState({showNoAuditoriumAccessDialog: true});
    };

    closeDialog = () => {
        this.setState({
            showVideo: false,
            showNoShowfloorDialog: false,
            showNoShowfloorAccessDialog: false,
            showNoAuditoriumDialog: false,
            showNoAuditoriumAccessDialog: false,
        });
    };

    setActiveElementsPositions = () => {
        const realHeight = window.innerHeight - 70;

        let showfloorElementStyle = {
            width: (realHeight * 39.1) / 100,
            height: (realHeight * 33.2) / 100,
            left: (realHeight * 34.48) / 100,
            top: (-realHeight * 2.3) / 100,
        };

        let auditoriumElementStyle = {
            width: (realHeight * 39.1) / 100,
            height: (realHeight * 33.2) / 100,
            left: (-realHeight * 29.6) / 100,
            top: (-realHeight * 2.3) / 100,
        };

        let sliderContainerStyle = {
            width: (realHeight * 19.723) / 100,
            height: (realHeight * 10.9573) / 100,
            left: (realHeight * 2.3068) / 100,
            top: (-realHeight * 12.8027) / 100,
        };

        let lobbyVideoContainerStyle = {
            width: (realHeight * 23.06) / 100,
            height: (realHeight * 12.91) / 100,
            left: (-realHeight * 71.39) / 100,
            top: (-realHeight * 10.95) / 100,
        };

        let infoBoothContainerStyle = {
            backgroundImage: `url('${InfoBoothIcon}')`,
            width: (realHeight * 21.683) / 100,
            height: (realHeight * 38.06) / 100,
            left: (-realHeight * 66.32) / 100,
            top: (realHeight * 15.916) / 100,
        };

        let bannerContainerStyle = {
            width: (realHeight * 17.993) / 100,
            height: (realHeight * 33.79469) / 100,
            left: (-realHeight * 86.01534) / 100,
            top: (realHeight * 19.26182) / 100,
        };

        this.setState({
            auditoriumElementStyle: auditoriumElementStyle,
            showfloorElementStyle: showfloorElementStyle,
            sliderContainerStyle: sliderContainerStyle,
            lobbyVideoContainerStyle: lobbyVideoContainerStyle,
            infoBoothContainerStyle: infoBoothContainerStyle,
            bannerContainerStyle: bannerContainerStyle,
        });
    };

    handleLogoutUser = () => {
        // if user logged out we should also disconnect him from notifications
        disconnectFromNotifications(this.props.user._id);
        this.props.onLogoutUser();
    };

    handleOpenPrivateChatWithRepresentative = () => {
        const {eventId, eventRoles, user} = this.props;
        let userPrivateChats = user.privateChats;
        // we check if we have allready talked with an organizer representative
        let representativeChatId = null;
        this.props.event.representatives.forEach((representative) => {
            let privateChatId = getPrivateChatId(userPrivateChats, representative.user._id);
            if (privateChatId) {
                representativeChatId = privateChatId;
            }
        });
        // if we allready talked with one, we open the same private chat with him
        if (representativeChatId) {
            this.props.onOpenPrivateChat(representativeChatId);
        } else {
            // if we didn't talk with a representative, we make an API call to the server
            // to alocate us a random representative
            let data;
            if (eventRoles.isExhibitor) {
                data = {exhibitorId: eventRoles.exhibitorId};
            } else {
                data = {participantId: eventRoles.participantId};
            }

            if (!this.state.loading) {
                axios({method: 'post', url: `/event/${eventId}/chat-with-organizer-representative`, data: data})
                    .then((response) => {
                        const newOrganizerChatId = response.data.privateChatId;
                        this.props.getPrivateChatsAndOpenPrivateChat(newOrganizerChatId);
                    })
                    .catch(() => {});
            }
        }
    };

    render() {
        const {event, eventSlug, eventRoles, resourcesAccess, isLargeScreen, building} = this.props;

        if (
            event.hasAccessManagement &&
            eventRoles.isParticipant &&
            resourcesAccess !== null &&
            !resourcesAccess.buildings.includes(building._id)
        ) {
            return <NoBuildingAccess />;
        }

        const {
            showNoShowfloorDialog,
            showNoShowfloorAccessDialog,
            showNoAuditoriumDialog,
            showNoAuditoriumAccessDialog,
            showVideo,
        } = this.state;

        let sliderSettings = {
            dots: false,
            arrows: false,
            autoplay: true,
            draggable: false,
            pauseOnHover: false,
            swipe: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        let lobbyVideoId = null;

        if (building.lobbyVideoUrl) {
            lobbyVideoId = getVideoIdFromYoutubeUrl(building.lobbyVideoUrl);
        }

        let BuildingLobbyImage;
        if ('lobbyRender' in building && building.lobbyRender) {
            BuildingLobbyImage = getBuildingLobbyBackgroundUploaded(event.brandingData.filesUrl, building);
        } else {
            BuildingLobbyImage = getLobbyBackground();
        }

        let buildingLobbyRoomLink = '';
        if (building.type === 'conference') {
            buildingLobbyRoomLink = `/event/${eventSlug}/village/building/${building._id}/auditorium`;
        } else {
            buildingLobbyRoomLink = `/event/${eventSlug}/village/building/${building._id}/showfloor`;
        }

        return (
            <>
                <div
                    onDragStart={preventDefaultDrag}
                    className={`position-background ${!isLargeScreen ? 'lobby-mobile' : ''}`}
                >
                    <BreadcrumbsNavigation />
                    <SideMenu />
                    <div
                        className="lobby-page"
                        style={{
                            backgroundImage: `url('${BuildingLobbyImage}')`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >
                        <div onDragStart={preventDefaultDrag} className="centerOfPage">
                            <div onDragStart={preventDefaultDrag} className="relativeCenter">
                                <div
                                    className="clickable-element"
                                    id="showfloor"
                                    style={this.state.showfloorElementStyle}
                                >
                                    <div onDragStart={preventDefaultDrag} className="relativeCenter">
                                        <Link to={{pathname: buildingLobbyRoomLink}} />
                                    </div>
                                </div>
                                <div
                                    className="clickable-element"
                                    id="auditorium"
                                    style={this.state.auditoriumElementStyle}
                                >
                                    <div onDragStart={preventDefaultDrag} className="relativeCenter">
                                        <Link to={{pathname: buildingLobbyRoomLink}} />
                                    </div>
                                </div>
                                {eventRoles.isOrganizerRep ? null : !this.props.event.representatives.length ? (
                                    <div onDragStart={preventDefaultDrag} className="clickable-element" id="info-booth">
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            onClick={this.handleOpenPrivateChatWithRepresentative}
                                            className="w-100 h-100"
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className="clickable-element"
                                        id="info-booth"
                                        style={this.state.infoBoothContainerStyle}
                                    >
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            onClick={this.handleOpenPrivateChatWithRepresentative}
                                            className="w-100 h-100"
                                        />
                                    </div>
                                )}
                                <div
                                    className={`clickable-element ${!building.lobbyVideoUrl && 'no-hover'}`}
                                    id="show-lobby-video"
                                    style={this.state.lobbyVideoContainerStyle}
                                >
                                    {building.lobbyVideoUrl ? (
                                        <div
                                            className="image-preview-video"
                                            style={{
                                                backgroundImage: `url('${
                                                    'https://img.youtube.com/vi/' + lobbyVideoId + '/0.jpg'
                                                }')`,
                                            }}
                                            onClick={this.handleOpenLobbyVideo}
                                        >
                                            <div
                                                className="play-button"
                                                style={{backgroundImage: `url('${PlayIcon}')`}}
                                            ></div>
                                        </div>
                                    ) : (
                                        <div
                                            className="image-preview-video no-video"
                                            style={{backgroundImage: `url('${NoVideoImage}')`}}
                                        ></div>
                                    )}
                                </div>
                                <BoothVideo
                                    opened={showVideo}
                                    closeDialog={this.closeDialog}
                                    videoUrl={building.lobbyVideoUrl}
                                />
                                {building.lobbyLogos.length > 0 && (
                                    <div
                                        className="clickable-element"
                                        id="slider-logos"
                                        style={this.state.sliderContainerStyle}
                                    >
                                        <Slider {...sliderSettings}>
                                            {building.lobbyLogos.map((lobbyLogo) => {
                                                return (
                                                    <div onDragStart={preventDefaultDrag} key={lobbyLogo._id}>
                                                        <img
                                                            src={
                                                                this.props.event.brandingData.filesUrl + lobbyLogo.image
                                                            }
                                                            alt="Logo"
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </Slider>
                                    </div>
                                )}
                                <div
                                    className="clickable-element no-hover"
                                    id="banner"
                                    style={this.state.bannerContainerStyle}
                                >
                                    {building.lobbyBanner ? (
                                        <img
                                            draggable="false"
                                            src={this.props.event.brandingData.filesUrl + building.lobbyBanner}
                                            alt="Logo"
                                            className="lobby-banner-image"
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showNoShowfloorDialog && (
                    <InformDialog
                        open={showNoShowfloorDialog}
                        onClose={this.closeDialog}
                        dialogTitle={'Showfloor information'}
                        dialogDescription={'Access to the Showfloor has been restricted.'}
                        dialogButtonLabel={'OK'}
                    />
                )}
                {showNoShowfloorAccessDialog && (
                    <InformDialog
                        open={showNoShowfloorAccessDialog}
                        onClose={this.closeDialog}
                        dialogTitle={'Showfloor information'}
                        dialogDescription={"Your access package doesn't have access to any showfloors."}
                        dialogButtonLabel={'OK'}
                    />
                )}
                {showNoAuditoriumDialog && (
                    <InformDialog
                        open={showNoAuditoriumDialog}
                        onClose={this.closeDialog}
                        dialogTitle={'Auditorium information'}
                        dialogDescription={'Access to the Auditorium has been restricted.'}
                        dialogButtonLabel={'OK'}
                    />
                )}
                {showNoAuditoriumAccessDialog && (
                    <InformDialog
                        open={showNoAuditoriumAccessDialog}
                        onClose={this.closeDialog}
                        dialogTitle={'Auditorium information'}
                        dialogDescription={"Your access package doesn't have access to any auditoriums."}
                        dialogButtonLabel={'OK'}
                    />
                )}
                {!this.props.event.participantsLogin &&
                    !eventRoles.isOrganizer &&
                    !eventRoles.isOrganizerRep &&
                    !eventRoles.isExhibitor &&
                    !eventRoles.isExhibitorRep && (
                        <FullOverlay
                            title="Event closed now"
                            text={'You have successfully registered for this event. The event is currently closed.'}
                            buttonText="Log out"
                            handleClick={this.handleLogoutUser}
                        />
                    )}
                <NotificationPresentationStarts />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        loadingEvent: state.event.loading,
        event: state.event.data,
        user: state.user.data,
        eventRoles: state.user.eventRoles,
        resourcesAccess: state.user.resourcesAccess,
        isLargeScreen: state.layout.isLargeScreen,
        buildingId: state.building.buildingId,
        building: state.building.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setHasVideoWall: (booleanValue) => dispatch(actions.sideNavHasVideoWall(booleanValue)),
        onLogoutUser: () => dispatch(actions.logoutUser()),
        setActiveWall: (wallData) => dispatch(actions.setActiveWall(wallData)),
        seeLiveWall: () => dispatch(actions.sideNavSeeLiveWall()),
        onSetEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
        getPrivateChatsAndOpenPrivateChat: (privateChatId) => dispatch(actions.getPrivateChats(privateChatId)),
        onOpenPrivateChat: (privateChatId) => dispatch(actions.openPrivateChat(privateChatId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildingLobby);

import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import * as actions from '../store/actions';
import {getNotReadChatMessages, getPrivateChatId, preventDefaultDrag} from '../Utils/utils';
import {ReactComponent as ProgramFilledIcon} from '../Images/svg/ProgramFilled.svg';
import {ReactComponent as ProgramIcon} from '../Images/svg/checklist.svg';
import colors from '../CSS/_variables.module.scss';
import DetectOutsideClick from '../HOC/DetectOutsideClick';
import {ReactComponent as ChatsFilledIcon} from '../Images/svg/ChatsFilled.svg';
import {ReactComponent as ChatsIcon} from '../Images/svg/chat.svg';
import {ReactComponent as ContactsFilledIcon} from '../Images/svg/ContactsFilled.svg';
import {ReactComponent as ContactsIcon} from '../Images/svg/phone-book.svg';
import Contacts from './EventMenuDropdowns/Contacts';
import {Link, withRouter} from 'react-router-dom';
import {ReactComponent as ArchiveFilledIcon} from '../Images/svg/ArchiveFilled.svg';
import {ReactComponent as LogoWhite} from '../Images/svg/mo_logo_white.svg';
import ArchiveIcon from '../Images/svg/ArchiveIcon';
import Chats from './EventMenuDropdowns/Chats/Chats';
import Confirm from '../Dialogs/Confirm';
import axios from '../store/axios-instance';
import AdvancedProgram from './EventMenuDropdowns/AdvancedProgramComponents/AdvancedProgram';

class LeftSideMenu extends PureComponent {
    state = {
        openLeaveVideoMeetingDialog: false,
        activeItem: '',
    };

    componentDidMount() {
        const {isLargeScreen} = this.props;
        this.calculateNotReadChatMessages();

        if (isLargeScreen && document.querySelector('.left-sidebar')) {
            const leftSidebar = document.querySelector('.left-sidebar');
            document.documentElement.style.setProperty('--sidebar', `${leftSidebar.clientWidth}px`);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {user, isLargeScreen} = this.props;
        if (
            (prevProps.user && prevProps.user.privateChats !== user.privateChats) ||
            prevProps.user.groupChats !== user.groupChats
        ) {
            this.calculateNotReadChatMessages();
        }

        if (isLargeScreen && document.querySelector('.left-sidebar')) {
            const leftSidebar = document.querySelector('.left-sidebar');
            document.documentElement.style.setProperty('--sidebar', `${leftSidebar.clientWidth}px`);
        }
    }

    componentWillUnmount() {
        const {isLargeScreen} = this.props;
        if (isLargeScreen && document.querySelector('.left-sidebar')) {
            document.documentElement.style.removeProperty('--sidebar');
        }
    }

    handleEnterKey = (tab) => (e) => {
        const {eventSlug} = this.props;
        if (e.key === 'Enter') {
            if (tab === 'contacts') return this.props.seeContacts();
            if (tab === 'chats') return this._handleCloseChatsComponent();
            if (tab === 'program') return this.props.seeProgram();
            if (tab === 'archive') return this.props.history.push(`/event/${eventSlug}/event-archive`);
            if (tab === 'reception') return this.handleOpenPrivateChatWithRepresentative();
        }
    };

    _handleCloseChatsComponent = () => {
        const {videoConference, topNavigation, helpChat} = this.props;
        if (videoConference.isActive) {
            this.setState({
                openLeaveVideoMeetingDialog: true,
                activeItem: 'chats'
            });
        } else {
            if (!topNavigation.seeChatsDesktop || !helpChat.seeHelpOpen) {
               this.props.seeChats();
            }
            this.props.closeHelpChat();
        }
    };

    _closeDialog = () =>
        this.setState({
            openLeaveVideoMeetingDialog: false,
        });

    _handleConfirmCloseComponents = () => {
        const {activeItem} = this.state;
        const {chatsDesktop, helpChat, topNavigation, videoConference} = this.props;
        this.setState(
            {
                openLeaveVideoMeetingDialog: false,
            },
            () => {
                if (!chatsDesktop.seeChats || !helpChat.seeHelpOpen) {
                    this.props.seeChats();
                }
                if (activeItem === 'program') {
                    this.props.seeProgram();
                }
                if (activeItem === 'contacts') {
                    this.props.seeContacts();
                }
                if (activeItem === 'help') {
                    this.setState({
                        openLeaveVideoMeetingDialog: true,
                    }, () => {
                        this.handleOpenPrivateChatWithRepresentative();
                    });
                } else {
                    this.props.closeHelpChat();
                }
                if (activeItem === 'chats') {
                    this.props.closeVideoConference(videoConference);
                    this.props.closeHelpChat();
                }
            }
        );
    };

    calculateNotReadChatMessages = () => {
        let privateChats = [...this.props.user.privateChats];
        let groupChats = [...this.props.user.groupChats];
        let totalMessagesUnread = getNotReadChatMessages(privateChats, groupChats);
        this.props.onSetNotReadChatMessages(totalMessagesUnread);
    };

    handleOpenReprChat = () => {
        const {event, eventId, user, eventRoles} = this.props;
        let userPrivateChats = user.privateChats;
        // we check if we have allready talked with an organizer representative
        let representativeChatId = null;
        event.representatives.forEach((representative) => {
            let privateChatId = getPrivateChatId(userPrivateChats, representative.user._id);
            if (privateChatId) {
                representativeChatId = privateChatId;
            }
        });
        // if we allready talked with one, we open the same private chat with him
        if (representativeChatId) {
            this.props.seeHelpChat();
            this.props.onOpenPrivateChat(representativeChatId);
        } else {
            // if we didn't talk with a representative, we make an API call to the server
            // to alocate us a random representative
            let data;
            if (eventRoles.isExhibitor) {
                data = {exhibitorId: eventRoles.exhibitorId};
            } else {
                data = {participantId: eventRoles.participantId};
            }

            if (!this.state.loading) {
                axios({method: 'post', url: `/event/${eventId}/chat-with-organizer-representative`, data: data})
                    .then((response) => {
                        const newOrganizerChatId = response.data.privateChatId;
                        this.props.seeHelpChat();
                        this.props.getPrivateChatsAndOpenPrivateChat(newOrganizerChatId);
                    })
                    .catch(() => {});
            }
        }
    };

    handleOpenPrivateChatWithRepresentative = () => {
        const {helpChat, videoConference} = this.props;
        this.setState({
            activeItem: 'help',
        });
        if (this.state.openLeaveVideoMeetingDialog) {
            this.handleOpenReprChat();
            this.setState({
                openLeaveVideoMeetingDialog: false,
            });
        } else {
            if (videoConference.isActive) {
                this.setState({
                    openLeaveVideoMeetingDialog: true,
                });
            } else {
                if (helpChat.seeHelpOpen) {
                    this.props.closeHelpChat();
                    this.props.seeChats();
                } else {
                    this.handleOpenReprChat();
                }
            }
        }
    };

    _handleOpenProgram = () => {
        const {videoConference} = this.props;
        if (videoConference?.isActive) {
            this.setState({
                openLeaveVideoMeetingDialog: true,
                activeItem: 'program',
            });
        } else {
            this.props.seeProgram();
        }
    };

    _handleOpenContacts = () => {
        const {videoConference} = this.props;
        if (videoConference?.isActive) {
            this.setState({
                openLeaveVideoMeetingDialog: true,
                activeItem: 'contacts',
            });
        } else {
            this.props.seeContacts();
        }
    };

    startTooltipTimeout = () => {
        setTimeout(() => {
            this.props.onShowSmartTooltip(true);
        }, 3000);
    };

    stopTooltipTimeout = () => {
        this.props.onShowSmartTooltip(false);
    };

    stopDisplayingSmartTooltip = () => {
        const {eventSlug, user} = this.props;
        let dataToStorage = [];
        const setData = {
            event: eventSlug,
            userId: user?._id,
            smartTooltipDisplayed: true,
        };
        const smartTooltipData = localStorage.getItem('smartTooltipData');
        if (smartTooltipData !== null && JSON.parse(smartTooltipData).length > 0) {
            let spreadArray = JSON.parse(smartTooltipData);
            if (spreadArray.filter((tooltipData) => tooltipData.event === eventSlug && tooltipData.userId === user?._id)?.length === 0) {
                spreadArray.push(setData);
            }
            dataToStorage = spreadArray;
        } else {
            dataToStorage.push(setData);
        }
        localStorage.setItem('smartTooltipData', JSON.stringify(dataToStorage));

        this.stopTooltipTimeout();
    };

    render() {
        const {openLeaveVideoMeetingDialog} = this.state;
        const {
            event,
            eventSlug,
            topNavigation,
            notReadChatMessages,
            eventArchive,
            eventRoles,
            helpChat,
            location,
            translation,
            defaultTranslation,
            chatsDesktop
        } = this.props;

        let activeEventArchive = location.pathname.indexOf('/event-archive') > -1;

        if(window.innerWidth < 1025){
            return null
        }

        return (
            <>
                <div className="left-sidebar">
                    <div className="menu-items">
                        <div
                            tabIndex="0"
                            onDragStart={preventDefaultDrag}
                            id="program"
                            className={`button-link ${topNavigation.seeProgram ? 'active' : ''}`}
                            onKeyDown={this.handleEnterKey('program')}
                            onClick={this._handleOpenProgram}
                        >
                            <div onDragStart={preventDefaultDrag}>
                                {topNavigation.seeProgram ? (
                                    <ProgramFilledIcon />
                                ) : (
                                    <ProgramIcon fill={colors.primary} />
                                )}
                                <p onDragStart={preventDefaultDrag} className="button-link-label">
                                    {translation?.eventMenu.programButton}
                                </p>
                            </div>
                        </div>
                        <div
                            tabIndex="0"
                            id="chats"
                            onClick={this._handleCloseChatsComponent}
                            className={`button-link ${chatsDesktop.seeChats && !helpChat.seeHelpOpen ? 'active' : ''}`}
                            onKeyDown={this.handleEnterKey('chats')}
                        >
                            {chatsDesktop.seeChats && !helpChat.seeHelpOpen ? (
                                <span>
                                    <ChatsFilledIcon />
                                        {notReadChatMessages && (
                                            <div
                                                className={`flex-center ${
                                                    notReadChatMessages.toString().length >= 3 ? 'fs8' : 'fs11'
                                                }`}
                                            >
                                            <span>
                                                {notReadChatMessages.toString().length >= 3
                                                    ? '99+'
                                                    : notReadChatMessages}
                                            </span>
                                            </div>
                                        )}
                                </span>
                            ) : (
                                <span>
                                <ChatsIcon fill={colors.primary} />
                                    {notReadChatMessages && (
                                        <div
                                            className={`flex-center ${
                                                notReadChatMessages.toString().length >= 3 ? 'fs8' : 'fs11'
                                            }`}
                                        >
                                        <span>
                                            {notReadChatMessages.toString().length >= 3
                                                ? '99+'
                                                : notReadChatMessages}
                                        </span>
                                        </div>
                                    )}
                            </span>
                            )}
                            <p onDragStart={preventDefaultDrag} className="button-link-label">
                                {translation?.eventMenu.chatsButton}
                            </p>
                        </div>
                        <div
                            tabIndex="0"
                            id="contacts"
                            className={`button-link ${topNavigation.seeContacts ? 'active' : ''}`}
                            onKeyDown={this.handleEnterKey('contacts')}
                            onClick={this._handleOpenContacts}
                        >
                            <div onDragStart={preventDefaultDrag}>
                                {topNavigation.seeContacts ? (
                                    <ContactsFilledIcon />
                                ) : (
                                    <ContactsIcon
                                        stroke={`${
                                            topNavigation.seeContacts ? colors.secondary : colors.primary
                                        }`}
                                        fill={`${topNavigation.seeContacts ? colors.secondary : colors.primary}`}
                                    />
                                )}
                                <p onDragStart={preventDefaultDrag} className="button-link-label">
                                    {translation?.eventMenu.contactsButton}
                                </p>
                            </div>
                        </div>
                        {eventArchive && eventArchive.length > 0 && (
                            <Link tabIndex="-1" to={{pathname: `/event/${eventSlug}/event-archive`}}>
                                <div
                                    tabIndex="0"
                                    id="event-archive"
                                    className={`button-link ${activeEventArchive ? 'active' : ''}`}
                                    onKeyDown={this.handleEnterKey('archive')}
                                >
                                <div>
                                    {activeEventArchive ? (
                                        <ArchiveFilledIcon />
                                    ) : (
                                        <ArchiveIcon
                                            width="24"
                                            height="24"
                                            fillGrey={colors.dark}
                                            fillPrimary={colors.primary}
                                        />
                                    )}

                                    <p onDragStart={preventDefaultDrag} className="button-link-label">
                                        {translation?.eventMenu.archiveButton}
                                    </p>
                                </div>
                                </div>
                            </Link>

                        )}
                        {!eventRoles.isOrganizerRep && (event.representatives.length > 0) && (
                            <div
                                tabIndex="0"
                                onDragStart={preventDefaultDrag}
                                className={`button-link ${helpChat.seeHelpOpen ? 'active' : ''}`}
                                id="info-booth"
                                onKeyDown={this.handleEnterKey('reception')}
                                onClick={this.handleOpenPrivateChatWithRepresentative}
                            >
                                <div onDragStart={preventDefaultDrag}>
                                    <span>
                                        {helpChat.seeHelpOpen
                                        ?
                                            <ChatsFilledIcon />
                                        :
                                            <ChatsIcon fill={colors.primary} />
                                        }
                                        <div className="info-booth-wrapper">
                                            <span>?</span>
                                        </div>
                                    </span>
                                    <p onDragStart={preventDefaultDrag} className="button-link-label">
                                        {translation?.eventMenu.help || defaultTranslation?.eventMenu.help}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                    <a href="https://myonvent.com/" target="_blank" className="powered-by">
                        <LogoWhite/>
                    </a>
                </div>
                {topNavigation.seeProgram && (
                    <DetectOutsideClick
                        stopDisplayingSmartTooltip={this.stopDisplayingSmartTooltip}
                    >
                        <AdvancedProgram
                            startTooltipTimeout={this.startTooltipTimeout}
                            stopDisplayingSmartTooltip={this.stopDisplayingSmartTooltip}
                            stopTimeout={this.stopTooltipTimeout}
                        />
                    </DetectOutsideClick>
                )}
                {topNavigation.seeContacts && (
                    <DetectOutsideClick>
                        <Contacts />
                    </DetectOutsideClick>
                )}
                {chatsDesktop.seeChats && (
                    <Chats />
                )}
                <Confirm
                    open={openLeaveVideoMeetingDialog}
                    closeConfirm={this._closeDialog}
                    dialogTitle={
                        translation?.chatsDropdown.leaveVideoMeetingTitle ||
                        defaultTranslation?.chatsDropdown.leaveVideoMeetingTitle
                    }
                    dialogDescription={
                        translation?.chatsDropdown.leaveVideoMeetingDescription ||
                        defaultTranslation?.chatsDropdown.leaveVideoMeetingDescription
                    }
                    dialogConfirmButtonLabel={
                        translation?.generalText.leave || defaultTranslation?.generalText.leave
                    }
                    handleConfirm={this._handleConfirmCloseComponents}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        event: state.event.data,
        eventArchive: state.eventArchive.data,
        user: state.user.data,
        eventRoles: state.user.eventRoles,
        notReadChatMessages: state.user.notReadChatMessages,
        topNavigation: state.user.topNavigation,
        chatsDesktop: state.user.chatsDesktop,
        helpChat: state.user.helpChat,
        isLargeScreen: state.layout.isLargeScreen,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        videoConference: state.videoConference,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        seeContacts: () => dispatch(actions.topNavSeeContacts()),
        seeProgram: () => dispatch(actions.topNavSeeProgram()),
        seeChats: () => dispatch(actions.topNavSeeChatsMobile()),
        onSetNotReadChatMessages: (numberOfNotReadMessages) =>
            dispatch(actions.setNotReadChatMessages(numberOfNotReadMessages)),onUpdateEventExhibitor: (exhibitor) => dispatch(actions.updateEventExhibitor(exhibitor)),
        getPrivateChatsAndOpenPrivateChat: (privateChatId) => dispatch(actions.getPrivateChats(privateChatId)),
        onOpenPrivateChat: (privateChatId) => dispatch(actions.openPrivateChat(privateChatId)),
        seeHelpChat: () => dispatch(actions.seeHelpChat()),
        closeHelpChat: () => dispatch(actions.closeHelpChat()),
        closeVideoConference: (conference) => dispatch(actions.closeVideoConference(conference)),
        onShowSmartTooltip: (show) => dispatch(actions.showSmartTooltip(show)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftSideMenu));
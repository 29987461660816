import React, {PureComponent} from 'react';
import {
    formatDecimalHoursToHoursAndMinutes,
    getDayLabelString,
    getEventDate,
    preventDefaultDrag,
} from '../../../../Utils/utils';
import TimeIcon from '../../../../Images/svg/TimeIcon';
import variables from '../../../../CSS/_variables.module.scss';
import UpComingStatus from '../UpComingStatus';
import LiveStatus from '../LiveStatus';
import PastEventStatus from '../PastEventStatus';
import {ReactComponent as SponsoredIcon} from '../../../../Images/svg/sponsored.svg';
import {Tooltip} from '@material-ui/core';
import {ReactComponent as AddedIcon} from '../../../../Images/svg/added.svg';
import {ReactComponent as AddToAgendaIcon} from '../../../../Images/svg/add-to-agenda.svg';
import {ReactComponent as ArrowDownIcon} from '../../../../Images/svg/arrow_down.svg';
import {ReactComponent as ArrowUpIcon} from '../../../../Images/svg/arrow_up.svg';
import {convertToRaw} from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html';
import {Link} from 'react-router-dom';
import axios from '../../../../store/axios-instance';
import moment from 'moment';
import Confirm from '../../../../Dialogs/Confirm';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import button from '../../../Button/Button';
import './SingleSmartSuggestionStyles.scss';

class SingleSmartSuggestion extends PureComponent {
    state = {
        removeConfirmed: false,
        openRemoveSession: false,
        isRestricted: false,
    };

    smartTooltipRef = React.createRef();

    handleClickOutsidSmartTooltip = this.handleClickOutsidSmartTooltip.bind(this);

    componentDidMount() {
        const {event} = this.props;
        if (event.restrictAuditoriumAccess || event.hideAuditoriumDoor) {
            this.setState({
                isRestricted: true,
            });
        }
        document.addEventListener('mousedown', this.handleClickOutsidSmartTooltip);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.resizeList) {
            if (
                prevProps.suggestion !== this.props.suggestion &&
                prevProps.suggestion.isAddedToAgenda !== this.props.suggestion.isAddedToAgenda
            ) {
                const scrollToTop = true;
                this.props.resizeList(scrollToTop);
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsidSmartTooltip);
    }

    handleClickOutsidSmartTooltip(e) {
        if (
            this.smartTooltipRef &&
            this.smartTooltipRef?.current &&
            !this.smartTooltipRef?.current?.contains(e.target)
        ) {
            this.props.stopTooltip();
        }
    }

    getStatusSuggestion = () => {
        const {suggestion, translation, defaultTranslation} = this.props;
        if (suggestion.status === 'upcoming') {
            return <UpComingStatus translation={translation} defaultTranslation={defaultTranslation} />;
        } else if (suggestion.status === 'live') {
            return <LiveStatus translation={translation} defaultTranslation={defaultTranslation} />;
        } else if (suggestion.status === 'past event') {
            return <PastEventStatus translation={translation} defaultTranslation={defaultTranslation} />;
        }
    };

    handleTypeOfAuditorium = () => {
        const {event, suggestion} = this.props;
        if (suggestion?.status === 'past event') {
            return `/event/${event.slug}/auditorium-archive/${suggestion?.auditoriumId}/timeslot/${suggestion?.auditoriumProgramId}`;
        } else {
            let auditoriumIndex = this.getAuditoriumIndex(suggestion.auditoriumId);
            return `/event/${event.slug}/auditorium/${auditoriumIndex + 1}`;
        }
    };

    getAuditoriumIndex = (auditoriumId) => {
        const {event} = this.props;
        let index = '';
        event?.auditoriums.forEach((auditorium, auditoriumIndex) => {
            if (auditorium._id === auditoriumId) {
                index = auditoriumIndex;
            }
        });
        return index;
    };

    addTimeSlotToAgenda = (auditoriumId, timeslotId) => (e) => {
        const {eventId, suggestion, smartSuggestions, showTooltip} = this.props;
        e.preventDefault();
        let data = {};
        data.date = moment(suggestion.startTimestamp).format('YYYY-MM-DD');
        data.auditoriumId = auditoriumId;
        data.programTimeslotId = timeslotId;
        axios({
            method: 'post',
            url: `/event/${eventId}/add-timeslot-to-agenda`,
            data: data,
        })
            .then(() => {
                this.props.addSessionAgenda(timeslotId);
                if (smartSuggestions && showTooltip) {
                    this.props.hideTooltip();
                }
            })
            .catch(() => {});
    };

    removeTimeSlotFromAgenda = (timeslotId) => (e) => {
        e.preventDefault();
        const {eventId, agenda, smartSuggestions, showTooltip} = this.props;
        let data = {};
        data.programTimeslotId = timeslotId;
        axios({
            method: 'post',
            url: `/event/${eventId}/remove-timeslot-from-agenda`,
            data: data,
        })
            .then(() => {
                if (agenda) {
                    this.setState(
                        {
                            openRemoveSession: false,
                            removeConfirmed: true,
                        },
                        () => {
                            setTimeout(() => {
                                this.props.removeSessionAgenda(timeslotId);
                            }, 750);
                        }
                    );
                } else {
                    this.setState(
                        {
                            openRemoveSession: false,
                        },
                        () => {
                            this.props.removeSessionAgenda(timeslotId);
                            if (smartSuggestions && showTooltip) {
                                this.props.hideTooltip();
                            }
                        }
                    );
                }
            })
            .catch(() => {});
    };

    removeSession = (e) => {
        e.preventDefault();
        this.setState({
            openRemoveSession: true,
        });
    };

    cancelRemoveSession = (e) => {
        e.preventDefault();
        this.setState({
            openRemoveSession: false,
        });
    };

    getSDGSImages = () => {
        const {suggestion} = this.props;
        return suggestion?.sdgs?.map((sdg) => {
            return (
                <div key={sdg._id}>
                    <img src={`${process.env.REACT_APP_SDGS_FOLDER}${sdg.logo}`} alt={sdg.title} />
                </div>
            );
        });
    };

    addSessionToCalendar = (e) => {
        e.preventDefault();
        const {suggestion} = this.props;
        const eventCalendarData = {
            title: suggestion.title,
            description: suggestion.description,
            location: window.location.origin,
            start: suggestion.startTimestamp,
            end: suggestion.endTimestamp,
        };
        const calendarEventType = 'session';
        this.props.showAddToExternalCalendarDialog(eventCalendarData, calendarEventType);
    };
    setLinksNewTab = () => {
        var links = document.getElementsByTagName('a');
        var len = links.length;
        for (var i = 0; i < len; i++) {
            links[i].target = '_blank';
        }
    };
    render() {
        const {openRemoveSession, removeConfirmed, isRestricted} = this.state;
        const {
            suggestion,
            event,
            expanded,
            toggleDescription,
            suggestionTooltipId,
            smartSuggestions,
            showTooltip,
            isLargeScreen,
            isRtlLanguage,
            languages,
            translation,
            defaultTranslation,
            agenda,
            showAddToExternalCalendarButton,
        } = this.props;

        let checkEmptyDescription = convertToRaw(stateFromHTML(suggestion?.description))
            .blocks.map((obj) => obj.text)
            .join('');

        let auditoriumType;
        if (suggestion?.auditoriumProgramId) {
            auditoriumType = this.handleTypeOfAuditorium();
        }
        this.setLinksNewTab();
        return (
            <div
                // to={{pathname: auditoriumType}}
                className={`linked-session ${removeConfirmed ? 'remove-session-item' : ''} ${
                    isRestricted ? 'restricted' : ''
                } ${suggestion?.sdgs?.length > 0 ? 'has-sdgs' : ''}
                `}
            >
                <>
                    <div
                        onDragStart={preventDefaultDrag}
                        className={`single-session-item ${
                            expanded?.includes(suggestion._id) || expanded === suggestion._id ? 'expanded' : ''
                        }`}
                    >
                        <div>
                            <div>
                                <div className="program-time-container">
                                    <TimeIcon primary={variables.primary} />
                                    <span>
                                        {getEventDate(suggestion.startTimestamp, suggestion.endTimestamp, languages) +
                                            ' - ' +
                                            getDayLabelString(suggestion.startTimestamp, languages)}
                                    </span>
                                </div>
                                {isLargeScreen && (
                                    <>
                                        <div className="status-wrapper">{this.getStatusSuggestion()}</div>
                                        {suggestion?.isSponsored && (
                                            <div className="sponsored">
                                                <Tooltip
                                                    id="sponsored"
                                                    placement="top"
                                                    arrow
                                                    tabIndex="0"
                                                    title={
                                                        translation?.program?.sponsored ||
                                                        defaultTranslation?.program?.sponsored
                                                    }
                                                >
                                                    <span>
                                                        <SponsoredIcon />
                                                    </span>
                                                </Tooltip>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div>
                                {showAddToExternalCalendarButton && (
                                    <div
                                        className="add-to-external-calendar-button"
                                        onClick={this.addSessionToCalendar}
                                    >
                                        <InsertInvitationIcon />
                                    </div>
                                )}
                                {isLargeScreen &&
                                    checkEmptyDescription !== '' &&
                                    checkEmptyDescription !== 'undefined' && (
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            onClick={toggleDescription}
                                            className="expand"
                                        >
                                            {expanded?.includes(suggestion._id) || expanded === suggestion._id ? (
                                                <ArrowUpIcon />
                                            ) : (
                                                <ArrowDownIcon />
                                            )}
                                        </div>
                                    )}
                                {suggestion?.isAddedToAgenda ? (
                                    <button
                                        onDragStart={preventDefaultDrag}
                                        className="is-added"
                                        onClick={this.removeSession}
                                    >
                                        <span>{translation?.program?.added || defaultTranslation?.program?.added}</span>
                                        <AddedIcon />
                                    </button>
                                ) : (
                                    <>
                                        {agenda ? (
                                            <button
                                                onDragStart={preventDefaultDrag}
                                                className="is-added"
                                                onClick={this.removeSession}
                                            >
                                                <span>
                                                    {translation?.program?.added || defaultTranslation?.program?.added}
                                                </span>
                                                <AddedIcon />
                                            </button>
                                        ) : (
                                            <>
                                                {smartSuggestions &&
                                                showTooltip &&
                                                suggestionTooltipId === suggestion._id ? (
                                                    <Tooltip
                                                        id="smart-tooltip"
                                                        placement={isRtlLanguage ? 'bottom-start' : 'bottom-end'}
                                                        open
                                                        arrow
                                                        PopperProps={{
                                                            disablePortal: true,
                                                            popperOptions: {
                                                                positionFixed: false,
                                                                modifiers: {
                                                                    preventOverflow: {
                                                                        enabled: true,
                                                                        boundariesElement: 'window', // where "window" is the boundary
                                                                    },
                                                                },
                                                            },
                                                        }}
                                                        title={
                                                            translation?.program?.smartTooltip ||
                                                            defaultTranslation?.program?.smartTooltip
                                                        }
                                                    >
                                                        <button
                                                            ref={this.smartTooltipRef}
                                                            onDragStart={preventDefaultDrag}
                                                            className="add-to-agenda"
                                                            onClick={this.addTimeSlotToAgenda(
                                                                suggestion.auditoriumId,
                                                                suggestion._id
                                                            )}
                                                        >
                                                            <span>
                                                                {isLargeScreen
                                                                    ? translation?.program?.addToCalendar ||
                                                                      defaultTranslation?.program?.addToCalendar
                                                                    : translation?.generalText.add ||
                                                                      defaultTranslation?.generalText.add}
                                                            </span>
                                                            <AddToAgendaIcon />
                                                        </button>
                                                    </Tooltip>
                                                ) : (
                                                    <button
                                                        onDragStart={preventDefaultDrag}
                                                        className="add-to-agenda"
                                                        onClick={this.addTimeSlotToAgenda(
                                                            suggestion.auditoriumId,
                                                            suggestion._id
                                                        )}
                                                    >
                                                        <span>
                                                            {isLargeScreen
                                                                ? translation?.program?.addToCalendar ||
                                                                  defaultTranslation?.program?.addToCalendar
                                                                : translation?.generalText.add ||
                                                                  defaultTranslation?.generalText.add}
                                                        </span>
                                                        <AddToAgendaIcon />
                                                    </button>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        {!isLargeScreen && (
                            <div className="mobile-wrapper">
                                <div>
                                    <div className="status-wrapper">{this.getStatusSuggestion()}</div>
                                    {suggestion?.isSponsored && (
                                        <div className="sponsored">
                                            <Tooltip
                                                id="sponsored"
                                                placement="top"
                                                arrow
                                                tabIndex="0"
                                                title={
                                                    translation?.program?.sponsored ||
                                                    defaultTranslation?.program?.sponsored
                                                }
                                            >
                                                <span>
                                                    <SponsoredIcon />
                                                </span>
                                            </Tooltip>
                                        </div>
                                    )}
                                </div>
                                {checkEmptyDescription !== '' && checkEmptyDescription !== 'undefined' && (
                                    <div
                                        onDragStart={preventDefaultDrag}
                                        onClick={toggleDescription}
                                        className="expand"
                                    >
                                        {expanded?.includes(suggestion._id) || expanded === suggestion._id ? (
                                            <ArrowUpIcon />
                                        ) : (
                                            <ArrowDownIcon />
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                        <p className="time-timeslot">
                            {suggestion.start + ' - ' + suggestion.end} GMT {event.timezoneValue >= 0 ? '+' : ''}
                            {formatDecimalHoursToHoursAndMinutes(event.timezoneValue)}
                        </p>

                        <p className="title titlea-a-helper">
                            <Link to={{pathname: auditoriumType}}>{suggestion.title}</Link>
                        </p>
                        {suggestion?.speakers !== '' && (
                            <div className="speakers-wrapper">
                                <p>{suggestion?.speakers}</p>
                            </div>
                        )}
                        {checkEmptyDescription !== '' && checkEmptyDescription !== 'undefined' && (
                            <p
                                className="description link-helper"
                                onDragStart={preventDefaultDrag}
                                dangerouslySetInnerHTML={{
                                    __html: suggestion?.description,
                                }}
                            />
                        )}
                        {suggestion?.sdgs?.length > 0 ? (
                            <div className="sdgs-wrapper">{this.getSDGSImages()}</div>
                        ) : null}
                    </div>
                    {openRemoveSession && (
                        <Confirm
                            classList={['remove-session']}
                            open={openRemoveSession}
                            closeConfirm={this.cancelRemoveSession}
                            dialogTitle={
                                translation?.program?.removeItemFromAgenda ||
                                defaultTranslation?.program?.removeItemFromAgenda
                            }
                            dialogDescription={
                                translation?.program?.removeItemDescription ||
                                defaultTranslation?.program?.removeItemDescription
                            }
                            dialogConfirmButtonLabel={
                                translation?.generalText.remove || defaultTranslation?.generalText.remove
                            }
                            dialogCancelButtonLabel={
                                translation?.generalText.cancel || defaultTranslation?.generalText.cancel
                            }
                            handleConfirm={this.removeTimeSlotFromAgenda(suggestion._id)}
                        />
                    )}
                </>
            </div>
        );
    }
}

export default SingleSmartSuggestion;

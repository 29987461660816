import MovieIcon from '@material-ui/icons/Movie';
import React from 'react';
import {isIOS} from 'react-device-detect';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import colors from '../../../CSS/_variables.module.scss';
import '../../../CSS/rooms.scss';
import InformDialog from '../../../Dialogs/InformDialog';
import {ReactComponent as ArchiveIcon} from '../../../Images/svg/archive_icon.svg';
import {ReactComponent as AuditoriumIcon} from '../../../Images/svg/auditorium_icon.svg';
import {ReactComponent as ShowFloorIcon} from '../../../Images/svg/showfloor_icon_rooms.svg';
import {checkIfEventHasEnded, preventDefaultDrag} from '../../../Utils/utils';
import * as actions from '../../../store/actions/index';
import IconContainer from '../../IconContainer/IconContainer';
import AuditoriumLink from './AuditoriumLink';
import ShowfloorLink from './ShowfloorLink';

class Rooms extends React.Component {
    state = {
        showVideo: false,
        auditoriumElementStyle: {},
        showFloorElementStyle: {},
        sliderContainerStyle: {},
        lobbyVideoContainerStyle: {},
        infoBoothContainerStyle: {},
        bannerContainerStyle: {},
        showNoShowfloorDialog: false,
        showNoShowfloorAccessDialog: false,
        showNoAuditoriumDialog: false,
        showNoAuditoriumAccessDialog: false,
        showNoBuildingDialog: false,
        showNoBuildingAccessDialog: false,
    };

    componentDidMount() {
        const {eventId, topNavigation} = this.props;
        this.props.onHasOnDemandVideos(eventId);
        if (topNavigation.seeRooms) {
            document.body.classList.add('menu-slow-index');
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('menu-slow-index');
    }

    handleOpenNoShowfloorDialog = () => this.setState({showNoShowfloorDialog: true});
    handleOpenNoShowfloorAccess = () => this.setState({showNoShowfloorAccessDialog: true});
    handleOpenNoAuditoriumDialog = () => this.setState({showNoAuditoriumDialog: true});
    handleOpenNoAuditoriumAccess = () => this.setState({showNoAuditoriumAccessDialog: true});

    closeDialog = () =>
        this.setState({
            showVideo: false,
            showNoShowfloorDialog: false,
            showNoShowfloorAccessDialog: false,
            showNoAuditoriumDialog: false,
            showNoAuditoriumAccessDialog: false,
            showNoBuildingDialog: false,
            showNoBuildingAccessDialog: false,
        });

    checkIfEventHasEnded = () => {
        // check if event has ended
        // if yes, redirect to the video archive page
        const {event} = this.props;
        if (event) {
            return checkIfEventHasEnded(event, event.timezoneValue);
        }
    };

    redirectToBuilding = (building) => {
        const {event, eventSlug, history} = this.props;
        this.props.onSetBuildingId(building._id);

        if (building.type === 'conference') {
            const buildingAuditoriumProgramEnded = checkIfEventHasEnded(building, event.timezoneValue);
            if (buildingAuditoriumProgramEnded) {
                history.push(`/event/${eventSlug}/village/building/${building._id}/auditorium-archive`);
            } else {
                history.push(`/event/${eventSlug}/village/building/${building._id}/auditorium`);
            }
        } else {
            history.push(`/event/${eventSlug}/village/building/${building._id}/showfloor`);
        }
    };

    handleRedirectToBuilding = (building) => (e) => {
        const {event, eventRoles, resourcesAccess} = this.props;

        if (building.restrictedAccess) {
            this.setState({showNoBuildingDialog: true});
        } else {
            if (event.hasAccessManagement && eventRoles.isParticipant && resourcesAccess) {
                // check if the user has access to the current building
                const hasAccessToBuilding = resourcesAccess.buildings.includes(building._id);
                if (hasAccessToBuilding) {
                    this.redirectToBuilding(building);
                } else {
                    this.setState({showNoBuildingAccessDialog: true});
                }
            } else {
                this.redirectToBuilding(building);
            }
        }
    };

    render() {
        const {
            showNoShowfloorDialog,
            showNoShowfloorAccessDialog,
            showNoAuditoriumDialog,
            showNoAuditoriumAccessDialog,
            showNoBuildingDialog,
            showNoBuildingAccessDialog,
        } = this.state;
        const {event, eventSlug, eventRoles, resourcesAccess, hasOnDemandVideos, location, eventArchive, translation} =
            this.props;
        const eventHasEnded = this.checkIfEventHasEnded();
        const activeAuditoriumArchive = location.pathname.indexOf(`/${eventSlug}/auditorium-archive`) > -1;
        const activeEventArchive = location.pathname.indexOf(`/${eventSlug}/event-archive`) > -1;
        let hasAccessToAuditoriums = true;
        let hasAccessToShowfloor = true;
        if (event.hasAccessManagement && eventRoles.isParticipant && resourcesAccess) {
            if (!resourcesAccess.auditoriums.length) {
                hasAccessToAuditoriums = false;
            }
            if (!resourcesAccess.showfloors.length) {
                hasAccessToShowfloor = false;
            }
        } else if (
            (event.exhibitorHasAccessManagement || event.scholarHasAccessManagement) &&
            eventRoles.isExhibitor &&
            resourcesAccess
        ) {
            if (!resourcesAccess.auditoriums.length) {
                hasAccessToAuditoriums = false;
            }
            if (!resourcesAccess.showfloors.length) {
                hasAccessToShowfloor = false;
            }
        }

        return (
            <>
                <div
                    onDragStart={preventDefaultDrag}
                    id="rooms-dropdown"
                    className={isIOS ? 'dropdown-component rooms-dropdown is-ios' : 'dropdown-component rooms-dropdown'}
                >
                    <div onDragStart={preventDefaultDrag} className="page-title">
                        <h3>{event.hasVillage ? 'Hub menu' : translation?.eventMenu.roomsButton}</h3>
                    </div>
                    {event.hasVillage ? (
                        <div onDragStart={preventDefaultDrag} className="rooms-container">
                            {event.buildings?.map((building) => {
                                return (
                                    <div
                                        onDragStart={preventDefaultDrag}
                                        onClick={this.handleRedirectToBuilding(building)}
                                        key={building._id}
                                    >
                                        <IconContainer>
                                            {building.type === 'conference' ? (
                                                <AuditoriumIcon fill={colors.greyVariant} />
                                            ) : (
                                                <ShowFloorIcon fill={colors.greyVariant} />
                                            )}
                                        </IconContainer>
                                        <p>{building.name}</p>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div onDragStart={preventDefaultDrag} className="rooms-container">
                            {!event.hideAuditoriumDoor && (
                                <AuditoriumLink
                                    translation={translation}
                                    hasAccessToAuditoriums={hasAccessToAuditoriums}
                                    eventHasEnded={eventHasEnded}
                                    activeAuditoriumArchive={activeAuditoriumArchive}
                                    showNoAuditoriumDialog={showNoAuditoriumDialog}
                                    handleOpenNoAuditoriumDialog={this.handleOpenNoAuditoriumDialog}
                                    showNoAuditoriumAccessDialog={showNoAuditoriumAccessDialog}
                                    handleOpenNoAuditoriumAccess={this.handleOpenNoAuditoriumAccess}
                                    closeDialog={this.closeDialog}
                                />
                            )}
                            {!event.hideShowfloorDoor && (
                                <ShowfloorLink
                                    translation={translation}
                                    hasAccessToShowfloor={hasAccessToShowfloor}
                                    showNoShowfloorDialog={showNoShowfloorDialog}
                                    handleOpenNoShowfloorDialog={this.handleOpenNoShowfloorDialog}
                                    showNoShowfloorAccessDialog={showNoShowfloorAccessDialog}
                                    handleOpenNoShowfloorAccess={this.handleOpenNoShowfloorAccess}
                                    closeDialog={this.closeDialog}
                                />
                            )}
                            {!event.hideAuditoriumDoor && hasOnDemandVideos && !eventHasEnded && (
                                <div>
                                    <Link
                                        className={`${activeAuditoriumArchive ? 'active' : ''}`}
                                        to={{pathname: `/event/${eventSlug}/auditorium-archive`}}
                                    >
                                        <MovieIcon />
                                        <p>{translation?.roomsDropdown.onDemandButton}</p>
                                    </Link>
                                </div>
                            )}
                            {eventArchive && eventArchive.length > 0 && (
                                <div>
                                    <Link
                                        className={`archive-link ${activeEventArchive ? 'active' : ''}`}
                                        to={{pathname: `/event/${eventSlug}/event-archive`}}
                                    >
                                        {activeEventArchive ? (
                                            <ArchiveIcon fill={colors.primary} />
                                        ) : (
                                            <ArchiveIcon fill={colors.greyVariant} />
                                        )}
                                        <p>{translation?.roomsDropdown.archiveButton}</p>
                                    </Link>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {showNoBuildingDialog && (
                    <InformDialog
                        open={showNoBuildingDialog}
                        onClose={this.closeDialog}
                        dialogTitle={'Building information'}
                        dialogDescription={'Access to the Building has been restricted.'}
                        dialogButtonLabel={'OK'}
                    />
                )}
                {showNoBuildingAccessDialog && (
                    <InformDialog
                        open={showNoBuildingAccessDialog}
                        onClose={this.closeDialog}
                        dialogTitle={'Building information'}
                        dialogDescription={"Your access package doesn't have access to the Building."}
                        dialogButtonLabel={'OK'}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        event: state.event.data,
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        loggedIn: state.user.loggedIn,
        eventArchive: state.eventArchive.data,
        eventRoles: state.user.eventRoles,
        resourcesAccess: state.user.resourcesAccess,
        topNavigation: state.user.topNavigation,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        hasOnDemandVideos: state.onDemandVideos.hasOnDemandVideos,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogoutUser: () => dispatch(actions.logoutUser()),
        onSetBuildingId: (buildingId) => dispatch(actions.setBuildingId(buildingId)),
        onHasOnDemandVideos: (eventId) => dispatch(actions.hasOnDemandVideos(eventId)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Rooms));

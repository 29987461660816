import React, {PureComponent} from 'react';
import Spinner from '../../../../SmallLayoutComponents/Spinner';
import {connect} from 'react-redux';
import {ReactComponent as EmptyIcon} from '../../../../Images/svg/empty-sessions.svg';
import SmartSuggestionsList from './VirtualizedSuggestions/SmartSuggestionsList';
import * as actions from '../../../../store/actions';

class SmartSuggestions extends PureComponent {
    state = {
        expandedItems: [],
    };

    componentDidMount() {
        const {isLargeScreen} = this.props;
        if (!isLargeScreen) {
            const tabs = document.querySelector('.tabs-wrapper');
            const title = document.querySelector('.smart-suggestions > p');
            let elementsHeight = tabs.clientHeight + title?.clientHeight;
            document.documentElement.style.setProperty('--smart-height', `${elementsHeight}px`);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isLargeScreen} = this.props;
        if (!isLargeScreen) {
            const tabs = document.querySelector('.tabs-wrapper');
            const title = document.querySelector('.smart-suggestions > p');
            let elementsHeight = tabs.clientHeight + title?.clientHeight;
            document.documentElement.style.setProperty('--smart-height', `${elementsHeight}px`);
        }
    }

    componentWillUnmount() {
        const {isLargeScreen} = this.props;
        if (!isLargeScreen) {
            document.documentElement.style.removeProperty('--smart-height');
        }
    }

    toggleExpandedSuggestion = (id) => (e) => {
        const {expandedItems} = this.state;
        let items = [...expandedItems];
        if (items.includes(id)) {
            items = items.filter((item) => item !== id);
        } else {
            items.push(id);
        }
        this.setState({
            expandedItems: items
        });
    };

    addSessionToAgenda = (sessionId) => {
        this.props.addSessionAgenda(sessionId);
        this.createExternalCalendarData(sessionId);
    }

    createExternalCalendarData = (sessionId) => {
        const {suggestions} = this.props;
        const timeslotData = suggestions.find(suggestion => suggestion._id === sessionId);
        const eventCalendarData = {
            title: timeslotData.title,
            description: timeslotData.description,
            location: window.location.origin,
            start: timeslotData.startTimestamp,
            end: timeslotData.endTimestamp
        };
        const calendarEventType = 'session';
        this.props.showAddToExternalCalendarDialog(eventCalendarData, calendarEventType)
    }

    render() {
        const {expandedItems} = this.state;
        const {
            event,
            eventId,
            suggestions,
            suggestionsLoading,
            showProgramTooltip,
            hideTooltip,
            stopTooltip,
            isLargeScreen,
            isRtlLanguage,
            languages,
            translation,
            defaultTranslation
        } = this.props;

        const notAddedSuggestion = suggestions.find((element) => !element.isAddedToAgenda)?._id;

        return (
            <div className="program-body smart-suggestions">
                {suggestionsLoading && <Spinner/>}
                {suggestions?.length > 0
                ?
                    <>
                        <p>
                            {translation?.program?.smartListTitle ||
                            defaultTranslation?.program?.smartListTitle}
                        </p>
                        <div className="smart-wrapper">
                            <SmartSuggestionsList
                                suggestions={suggestions}
                                event={event}
                                eventId={eventId}
                                expanded={expandedItems}
                                isLargeScreen={isLargeScreen}
                                languages={languages}
                                translation={translation}
                                defaultTranslation={defaultTranslation}
                                isRtlLanguage={isRtlLanguage}
                                toggleDescription={this.toggleExpandedSuggestion}
                                addSessionAgenda={this.addSessionToAgenda}
                                removeSessionFromAgenda={this.props.removeSessionAgenda}
                                suggestionTooltip={notAddedSuggestion}
                                showProgramTooltip={showProgramTooltip}
                                hideProgramTooltip={hideTooltip}
                                stopTooltipTime={stopTooltip}
                            />
                        </div>
                    </>
                :
                    <>
                        <p>
                            {translation?.program?.smartListTitle ||
                            defaultTranslation?.program?.smartListTitle}
                        </p>
                        <div className="smart-wrapper empty-data">
                            <div>
                                <EmptyIcon/>
                                <p>
                                    {translation?.program?.emptySessions ||
                                    defaultTranslation?.program?.emptySessions}
                                </p>
                            </div>
                        </div>
                    </>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        suggestions: state.event.suggestions,
        suggestionsLoading: state.event.suggestionsLoading,
        showProgramTooltip: state.layout.showProgramTooltip,
        isLargeScreen: state.layout.isLargeScreen,
        isRtlLanguage: state.languages.isRtlLanguage,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addSessionAgenda: (sessionId) => dispatch(actions.addSessionToAgenda(sessionId)),
        removeSessionAgenda: (sessionId) => dispatch(actions.removeSessionFromAgenda(sessionId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartSuggestions);
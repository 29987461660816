import React from 'react';
import {AutoSizer, List, CellMeasurer, CellMeasurerCache} from 'react-virtualized';
import {Scrollbars} from 'react-custom-scrollbars';
import {preventDefaultDrag} from '../../../../../Utils/utils';
import 'react-virtualized/styles.css';
import SingleSmartSuggestion from '../SingleSmartSuggestion';
import isEqual from 'lodash/isEqual';

class SmartSuggestionsList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.cache = new CellMeasurerCache({
            // Define a CellMeasurerCache --> Put the height and width you think are the best
            defaultHeight: 100,
            fixedWidth: true,
        });
    }

    componentDidMount = () => {
        this.list.Grid._scrollingContainer = this.scroll.view;
    };

    componentDidUpdate = (prevProps) => {
        if (!isEqual(prevProps.suggestions, this.props.suggestions)) {
            const scrollToTop = true;
            this.resizeList(scrollToTop);
        }
    };

    resizeList = (scrollToTop = false) => {
        this.cache.clearAll();
        if (this.list) {
            this.list.recomputeRowHeights();
            if (scrollToTop) {
                this.list.scrollToPosition();
            }
        }
    };

    handleScroll = (e) => {
        this.list.Grid._onScroll(e);
    };

    renderThumb = ({style, ...props}) => {
        const thumbStyle = {
            backgroundColor: `#777081`,
            left: 0,
            border: `1px solid #FFFFFF`,
            boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.25)`,
            borderRadius: `8px`
        };
        return (
            <div
                onDragStart={preventDefaultDrag}
                className="grey-scroll"
                style={{...style, ...thumbStyle}}
                {...props}
            />
        );
    };

    renderView = ({style, ...props}) => {
        let viewStyle = {};
        if (this.props.isRtlLanguage) {
            viewStyle = {...viewStyle, marginLeft: style.marginRight, marginRight: 0};
        }
        return <div onDragStart={preventDefaultDrag} className="box" style={{...style, ...viewStyle}} {...props} />;
    };

    toggleExpandedDescription = (index, id) => (e)=> {
        e.preventDefault();
        const {toggleDescription} = this.props;
        toggleDescription(id)(e);
        // clear cache only for expanded item (blocks the list from scrolling to top)
        this.cache.clear(index);
    };

    rowRenderer = ({
        key, // Unique key within array of rows
        index, // Index of row within collection
        parent,
        style, // Style object to be applied to row (to position it)
    }) => {
        const {
            event,
            eventId,
            suggestions,
            expanded,
            addSessionAgenda,
            removeSessionFromAgenda,
            suggestionTooltip,
            showProgramTooltip,
            hideProgramTooltip,
            stopTooltipTime,
            isLargeScreen,
            isRtlLanguage,
            languages,
            translation,
            defaultTranslation
        } = this.props;
        const suggestion = suggestions[index];
        return (
            <CellMeasurer cache={this.cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
                <div
                    className="Row"
                    key={key}
                    style={{
                        ...style,
                    }}
                >
                    <SingleSmartSuggestion
                        key={key}
                        suggestion={suggestion}
                        isAddedToAgenda={suggestion.isAddedToAgenda}
                        expanded={expanded}
                        toggleDescription={this.toggleExpandedDescription(index, suggestion._id)}
                        addSessionAgenda={addSessionAgenda}
                        removeSessionAgenda={removeSessionFromAgenda}
                        event={event}
                        eventId={eventId}
                        isLargeScreen={isLargeScreen}
                        isRtlLanguage={isRtlLanguage}
                        languages={languages}
                        translation={translation}
                        defaultTranslation={defaultTranslation}
                        resizeList={this.resizeList}
                        suggestionTooltipId={suggestionTooltip}
                        showTooltip={showProgramTooltip}
                        hideTooltip={hideProgramTooltip}
                        stopTooltip={stopTooltipTime}
                        smartSuggestions
                    />
                </div>
            </CellMeasurer>
        );
    };

    render() {
        const {suggestions} = this.props;
        return (
            <AutoSizer>
                {({height, width}) => (
                    <Scrollbars
                        ref={(node) => (this.scroll = node)}
                        onScroll={this.handleScroll}
                        style={{height, width}}
                        className="scrollbar"
                        renderThumbVertical={this.renderThumb}
                        renderView={this.renderView}
                    >
                        <List
                            tabIndex={-1}
                            width={width}
                            height={height}
                            rowGetter={({index}) => suggestions[index]}
                            rowCount={suggestions.length}
                            rowHeight={this.cache.rowHeight}
                            rowRenderer={this.rowRenderer}
                            headerHeight={20}
                            style={{overflowX: 'visible', overflowY: 'visible'}}
                            ref={(node) => (this.list = node)}
                        />
                    </Scrollbars>
                )}
            </AutoSizer>
        );
    }
}
export default SmartSuggestionsList;

import React from 'react';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import * as actions from '../../../store/actions/index';
import ColoredScrollbars from '../../../SmallLayoutComponents/ColoredScrollbars';
import VideoMeetingBanner from './VideoMeetingBanner';
import Button from '@material-ui/core/Button';
import colors from '../../../CSS/_variables.module.scss';
import {ReactComponent as MeetingsInfoIcon} from '../../../Images/svg/counter.svg';
import {preventDefaultDrag} from '../../../Utils/utils';
import {isIOS} from 'react-device-detect';
import {Tooltip} from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import {debounce} from 'lodash';

class CreateGroupChat extends React.Component {
    state = {
        search: '',
        groupChatName: '',
        users: [],
        participants: [],
        filterUsers: [],
        videoBannerShow: false,
    };

    componentDidMount() {
        this.setAvailableChatUsers();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.activeChatRoomId !== prevProps.activeChatRoomId) {
            this.newMessageRef.current.focus();
        }
        if(!isEqual(prevProps.allEventUsers, this.props.allEventUsers)){
            // this is needed because if a participant changed the "chat preference"
            // we want this update to be seen in real time
            this.setAvailableChatUsers();
        }
    }

    setAvailableChatUsers = () => {
        const {allEventUsers, userContacts} = this.props;
        // in users we save all the users that can be found in the group chat to be added
        // it contains all the event users
        // + all the user contacts (as long as they are not already in the event users array)
        // so that they will not be duplicated
        let users = [...allEventUsers, ...this.mapContactsAsEventUsers(userContacts)];
        users = this.removeUserDuplicates(users);
        this.setState(
            {users: users},
            ()=>{this.filterUsers()}
        );
    }

    // because for event users the userId is stored in [user].user
    // and for contacts is stored in [user].user._id
    mapContactsAsEventUsers = (contacts) => {
        return contacts.map((user) => { return {...user.user, user:user.user?._id}});
    }

    removeUserDuplicates = (users) => {
        return users.reduce((acc, current) => {
            const x = acc.find(item => item.user === current.user);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
    }

    handleAddUserToGroupChat = (userId) => this.setState({participants: [...this.state.participants, userId]});
    
    _showInfoBanner = () =>
        this.setState({
            videoBannerShow: true,
        });

    _closeVideoBanner = () =>
        this.setState({
            videoBannerShow: false,
        });

    handleInputFocus = () => {
        const eventPage = document.getElementsByClassName('event-page');
        if (eventPage && !isIOS) {
            eventPage[0].classList.add('hide');
        }
    };

    handleInputBlur = () => {
        const eventPage = document.getElementsByClassName('event-page hide');
        if (eventPage.length !== 0 && !isIOS) {
            eventPage[0].classList.remove('hide');
        }
    };

    handleKeyDown = (e) => {
        const {search} = this.state;
        if (e.key === 'Enter' && search.trim()) {
            e.target.blur();
        }
    };

    handleRemoveUserFromGroupChat = (userId) =>
        this.setState({
            participants: this.state.participants.filter(function (participant) {
                return participant !== userId;
            }),
        });

    handleCreateGroupChat = () => {
        let groupChatData = {
            name: this.state.groupChatName,
            event: this.props.event._id,
            users: this.state.participants,
        };

        this.props.onCreateGroupChat(groupChatData);
        this.props.handleCloseCreateGroupChat();
    };

    onSearchChange = (e) => {
        this.setState(
            {
                search: e.target.value,
            },
            () => {
                if (this.state.search.trim().length >= 2) {
                    this.debouncedFilterUsers();
                } else {
                    this.setState({filterUsers: []});
                }
            }
        );
    }

    debouncedFilterUsers = debounce(() => {
        this.filterUsers()
    }, 500)

    filterUsers = () => {
        const {search} = this.state;

        // if the search string is smaller than 2 characters, we don't want to display any users
        if(search.trim().length < 2){
            this.setState({filterUsers: []});

            // we use return to stop the function from execturing further
            return;
        }

        let filterUsers = this.state.users.filter((obj) => {
            let fullName = `${obj.first} ${obj.last}`;
            if (fullName.toLowerCase().includes(this.state.search.toLowerCase())) {
                return obj;
            }
            return null;
        });

        // hide yourself
        filterUsers = filterUsers.filter((obj) => obj.user !== this.props.user._id);

        this.setState({filterUsers: filterUsers});
    };

    checkIfIsContact = (userId) => {
        const {user} = this.props;
        let isContact = user?.contacts?.find((contact) => contact.user._id === userId);
        return isContact !== undefined;
    };

    checkIfIsAMatch = (userId) => {
        const {user} = this.props;
        let isAMatch = user?.matchingUsers?.find((match) => match.matchingLevel === 'high' && match.user === userId);
        return isAMatch !== undefined;
    };

    checkIfChatButtonIsDisabled = (userData) => {
        const {translation, defaultTranslation} = this.props;
        let chatPreference = {
            disabledChat: false,
            tooltipText: '',
        };
        let isAMatch = this.checkIfIsAMatch(userData.user);
        let isContact = this.checkIfIsContact(userData.user);
        let acceptHighMatches = translation?.networking.acceptHighMatches || defaultTranslation?.networking.acceptHighMatches;
        let acceptContacts = translation?.networking.acceptContacts || defaultTranslation?.networking.acceptContacts;
        let acceptContactsOrHighMatches = translation?.networking.acceptContactsOrHighMatches || defaultTranslation?.networking.acceptContactsOrHighMatches;
        let denyEverybody = translation?.networking.denyEverybody || defaultTranslation?.networking.denyEverybody;
        if (userData.chatPreference === 'matches') {
            if (!isAMatch) {
                chatPreference.tooltipText = acceptHighMatches;
                chatPreference.disabledChat = true;
            }
        } else if (userData.chatPreference === 'contacts') {
            if (!isContact) {
                chatPreference.tooltipText = acceptContacts;
                chatPreference.disabledChat = true;
            }
        } else if (userData.chatPreference === 'contactsOrMatches') {
            if (!isAMatch && !isContact) {
                chatPreference.tooltipText = acceptContactsOrHighMatches;
                chatPreference.disabledChat = true;
            }
        } else if (userData.chatPreference === 'nobody') {
            chatPreference.tooltipText = denyEverybody;
            chatPreference.disabledChat = true;
        }
        return chatPreference;
    };

    render() {
        const {videoBannerShow, filterUsers} = this.state;
        const {translation, defaultTranslation} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} className="chat-page">
                <div>
                    <VideoMeetingBanner
                        videoBannerShow={videoBannerShow}
                        translation={translation}
                        defaultTranslation={defaultTranslation}
                        closeBanner={this._closeVideoBanner}
                    />
                    <div onDragStart={preventDefaultDrag} className="new-chat-wrapper">
                        <div>
                            <div onDragStart={preventDefaultDrag} className="input-container">
                                <p onDragStart={preventDefaultDrag} className="d-none">
                                    {translation?.chatsDropdown.newChatProvideText}
                                </p>
                                <TextField
                                    autoFocus
                                    margin="normal"
                                    id="name"
                                    label={translation?.chatsDropdown.newChatNameInput}
                                    onChange={(e) => this.setState({groupChatName: e.target.value})}
                                    value={this.state.groupChatName}
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    onFocus={this.handleInputFocus}
                                    onBlur={this.handleInputBlur}
                                />
                                <p>{translation?.chatsDropdown.newChatAddMembers}</p>
                                <TextField
                                    id="search"
                                    label={translation?.chatsDropdown.newChatSearchInput}
                                    type="search"
                                    margin="normal"
                                    className="m-bottom"
                                    value={this.state.search}
                                    onChange={this.onSearchChange}
                                    onFocus={this.handleInputFocus}
                                    onBlur={this.handleInputBlur}
                                    onKeyDown={this.handleKeyDown}
                                    variant="outlined"
                                />
                            </div>
                            {this.props.event ? (
                                <div onDragStart={preventDefaultDrag} className="members-chat-wrapper">
                                    <ColoredScrollbars>
                                        <ul className="members-list-event">
                                            {filterUsers.map((participant, participantIndex) => {
                                                let participantUser = participant;
                                                if (typeof participant.user === 'object') {
                                                    participantUser = participant.user;
                                                }
                                                let chatPreference = this.checkIfChatButtonIsDisabled(participantUser);
                                                return (
                                                    <React.Fragment key={participantIndex}>
                                                        <li>
                                                            <div
                                                                className="user-avatar"
                                                                style={{
                                                                    backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${participantUser.avatarSmall}')`,
                                                                }}
                                                            />
                                                            <div
                                                                onDragStart={preventDefaultDrag}
                                                                className="chat-member-details"
                                                            >
                                                                <p>
                                                                    {participantUser.first} {participantUser.last}
                                                                </p>
                                                                {participantUser.company !== '' ? (
                                                                    <span>{participantUser.company}</span>
                                                                ) : null}
                                                            </div>
                                                            {this.state.participants.includes(participant.user) ? (
                                                                <button
                                                                    className="remove-member"
                                                                    onClick={() =>
                                                                        this.handleRemoveUserFromGroupChat(participant.user)
                                                                    }
                                                                >
                                                                    {translation?.generalText.remove}
                                                                </button>
                                                            ) :
                                                                chatPreference.disabledChat
                                                                ?
                                                                    <Tooltip
                                                                        id="chat-preference"
                                                                        title={chatPreference.tooltipText}
                                                                        enterTouchDelay={1}
                                                                    >
                                                                        <div className="add-member">
                                                                            {translation?.generalText.add}
                                                                        </div>
                                                                    </Tooltip>
                                                                :
                                                                <button
                                                                    className="add-member"
                                                                    onClick={() =>
                                                                        this.handleAddUserToGroupChat(participant.user)
                                                                    }
                                                                >
                                                                    {translation?.generalText.add}
                                                                </button>
                                                            }
                                                        </li>
                                                        <div
                                                            onDragStart={preventDefaultDrag}
                                                            className="user-wrapper-separator"
                                                        />
                                                    </React.Fragment>
                                                );
                                            })}

                                            {this.state.search.length > 2 && !filterUsers.length && (
                                                <li>{translation?.chatsDropdown.newChatNoUsersMessage}</li>
                                            )}
                                        </ul>
                                    </ColoredScrollbars>
                                </div>
                            ) : null}
                        </div>
                        <div onDragStart={preventDefaultDrag} className="new-chat-buttons-container create-group">
                            <div>
                                <MeetingsInfoIcon
                                    fill={colors.primary}
                                    className="info-button"
                                    onClick={this._showInfoBanner}
                                />
                                <Button
                                    type="button"
                                    classes={{label: 'dialog-btn-uppercase'}}
                                    onClick={this.props.handleCloseCreateGroupChat}
                                >
                                    {translation?.chatsDropdown.newChatCancelButton}
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                    type="button"
                                    color="secondary"
                                    classes={{label: 'dialog-btn-uppercase'}}
                                    onClick={this.handleCreateGroupChat}
                                    disabled={!this.state.participants.length}
                                >
                                    {translation?.chatsDropdown.newChatCreateButton}
                                </Button>
                                &nbsp;&nbsp;
                            </div>
                            <div onDragStart={preventDefaultDrag} className="video-meetings-info-wrapper">
                                <div>
                                    <MeetingsInfoIcon fill={colors.primary} />
                                    <p>
                                        {translation?.meetings.banner.videoCallStartedInfo ||
                                            defaultTranslation?.meetings.banner.videoCallStartedInfo}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        event: state.event.data,
        allEventUsers: state.eventUsers.allEventUsers,
        userContacts: state.user.data?.contacts,
        eventOwner: state.event.data.owner,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCreateGroupChat: (groupChatData) => dispatch(actions.createGroupChat(groupChatData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupChat);

import React from 'react';
import '../../CSS/wall.scss';
import connect from 'react-redux/es/connect/connect';
import {withRouter} from 'react-router-dom';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ColoredScrollbars from '../../SmallLayoutComponents/ColoredScrollbars';
import {linkify, preventDefaultDrag} from '../../Utils/utils';
import {convertToRaw} from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html';
import './ResourcesStyles.scss'

class Resources extends React.Component {
    render() {
        const {currentTimeslot, eventId, translation, defaultTranslation} = this.props;
        let checkEmptyDescription = convertToRaw(stateFromHTML(currentTimeslot.description))
            .blocks.map((obj) => obj.text)
            .join('');

        return (
            <div onDragStart={preventDefaultDrag} className="wall-container">
                <ColoredScrollbars>
                    <div onDragStart={preventDefaultDrag} className="resources-container">
                        {'description' in currentTimeslot && currentTimeslot.description.length ? (
                            <div onDragStart={preventDefaultDrag} className="description-container">
                                {/* react-wysiwyg empty description state double check */}
                                {checkEmptyDescription !== '' && (
                                    <>
                                        <p onDragStart={preventDefaultDrag} className="title">
                                            {translation?.wall.resourcesVideoDescription ||
                                                defaultTranslation?.wall.resourcesVideoDescription}
                                            :
                                        </p>
                                        <p
                                            className="description-text-wall link-helper"
                                            onDragStart={preventDefaultDrag}
                                            dangerouslySetInnerHTML={{
                                                __html: currentTimeslot?.description,
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                        ) : null}
                        {'links' in currentTimeslot && currentTimeslot.links.length ? (
                            <div onDragStart={preventDefaultDrag} className="links-container">
                                <p onDragStart={preventDefaultDrag} className="title">
                                    {translation?.wall.resourcesLinks || defaultTranslation?.wall.resourcesLinks}
                                </p>
                                <ul>
                                    {currentTimeslot.links.map((link, index) => (
                                        <li key={link._id}>
                                            <a href={link.link} target="_blank" rel="noopener noreferrer">
                                                <span
                                                    onDragStart={preventDefaultDrag}
                                                    draggable="false"
                                                    className="link"
                                                >
                                                    {link.displayName}
                                                </span>
                                                <AttachmentIcon />
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : null}
                        {'documents' in currentTimeslot && currentTimeslot.documents.length ? (
                            <div onDragStart={preventDefaultDrag} className="documents-container">
                                <p onDragStart={preventDefaultDrag} className="title">
                                    {translation?.wall.resourcesFiles || defaultTranslation?.wall.resourcesFiles}
                                </p>
                                <ul>
                                    {currentTimeslot.documents.map((document, index) => (
                                        <li key={index}>
                                            <a
                                                href={`${process.env.REACT_APP_EVENT_FOLDER}${eventId}/${document.file}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <span
                                                    onDragStart={preventDefaultDrag}
                                                    draggable="false"
                                                    className="link"
                                                >
                                                    {document.displayName}
                                                </span>
                                                <CloudDownloadIcon />
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : null}
                    </div>
                </ColoredScrollbars>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default withRouter(connect(mapStateToProps)(Resources));

import React from 'react';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as Arrow} from '../../Images/svg/showfloor-arrow-image.svg';
import {preventDefaultDrag} from '../../Utils/utils';

class AuditoriumNavigationMobile extends React.Component {
    getPreviousAuditoriumIndex = () => {
        const {auditoriums, auditoriumRoomIndex} = this.props;
        let previousAuditoriumIndex = auditoriumRoomIndex - 1;
        if (auditoriumRoomIndex === 0) {
            previousAuditoriumIndex = auditoriums.length - 1;
        }
        return previousAuditoriumIndex;
    };

    getNextAuditoriumIndex = () => {
        const {auditoriums, auditoriumRoomIndex} = this.props;
        let nextAuditoriumIndex = auditoriumRoomIndex + 1;
        if (auditoriumRoomIndex === auditoriums.length - 1) {
            nextAuditoriumIndex = 0;
        }
        return nextAuditoriumIndex;
    };

    render() {
        const {timeSlot, setActiveAuditoriumIndex, auditoriums, auditoriumRoomIndex, timezoneName} = this.props;

        let formatedTimezoneName = timezoneName.split(')');
        formatedTimezoneName = formatedTimezoneName[0];
        formatedTimezoneName = formatedTimezoneName.substring(1);

        return (
            <div
                onDragStart={preventDefaultDrag}
                className={'auditoriums-navigation-mobile ' + (timeSlot?.video ? 'has-booth-wall' : '')}
            >
                <div
                    onDragStart={preventDefaultDrag}
                    className="button previous"
                    onClick={setActiveAuditoriumIndex(this.getPreviousAuditoriumIndex())}
                >
                    <div onDragStart={preventDefaultDrag} className="button-container">
                        <div onDragStart={preventDefaultDrag} className="details">
                            <Arrow stroke={colors.primary} />
                        </div>
                    </div>
                </div>
                <div onDragStart={preventDefaultDrag} className="auditorium-title-container">
                    <p onDragStart={preventDefaultDrag} className="name">
                        {auditoriums[auditoriumRoomIndex].name}
                    </p>
                    <p>
                        <span>{formatedTimezoneName}</span>
                        <span>
                            {auditoriumRoomIndex + 1}/{auditoriums.length} STAGES
                        </span>
                    </p>
                </div>
                <div
                    onDragStart={preventDefaultDrag}
                    className="button next"
                    onClick={setActiveAuditoriumIndex(this.getNextAuditoriumIndex())}
                >
                    <div onDragStart={preventDefaultDrag} className="button-container">
                        <div onDragStart={preventDefaultDrag} className="details">
                            <Arrow stroke={colors.primary} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AuditoriumNavigationMobile;

import React from 'react';
import Wall from '../../HOC/Wall/Wall';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import AuditoriumBackgroundImage from '../../SmallLayoutComponents/AuditoriumBackgroundImage';
import Resources from '../../HOC/Wall/Resources';
import {checkIfEventHasEnded, preventDefaultDrag} from '../../Utils/utils';
import AuditoriumBanners from '../Auditorium/AuditoriumBanners';
import {AuditorumPollsOutput} from '../../Components/AuditorumPollsOutput';
import BackToLive from '../../Components/Buttons/BackToLive/BackToLive.js';
import AuditoriumVideoPlayer from '../Auditorium/AuditoriumVideoPlayer';
import AuditoriumTabs from '../Auditorium/AuditoriumTabs';
import AuditoriumSideButtons from '../Auditorium/AuditoriumSideButtons/AuditoriumSideButtons';
import AuditoriumNetworking from '../Auditorium/AuditoriumNetworking';
import {connectUserToTimeslot, disconnectUserFromTimeslot, handlePollsUpdated} from '../../Api/socketApi';
import axios from '../../store/axios-instance';
import '../../CSS/auditorium.scss';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as BackIcon} from '../../Images/svg/back.svg';
import {ReactComponent as GreaterArrow} from '../../Images/svg/greater-arrow.svg';
import LeftSideMenu from '../../Components/LeftSideMenu';
import OnDemandVideosSection from '../../HOC/VirtualizedOnDemandVideos/OnDemandVideosSection';

class Auditorium extends React.Component {
    state = {
        auditoriumRoomIndex: 0,
        auditoriumRoomId: 0,
        auditoriumId: null,
        timeSlotId: null,
        timeSlot: null,
        timeSlotIndex: null,
        currentTab: 'wall',
        hasPolls: false,
        auditorium: null,
    };

    componentDidMount() {
        const {isLargeScreen} = this.props;
        const {auditoriumId, timeslotId} = this.props.match.params;
        this.checkIfAuditoriumAndTimeslotExist();

        if (!isLargeScreen) {
            this.setState({currentTab: 'video'});
        }

        handlePollsUpdated(({hasPolls}) => {
            this.setState({hasPolls});
            if (this.state.currentTab === 'polls' && !hasPolls) {
                if (!isLargeScreen) {
                    this.setState({currentTab: 'video'});
                } else {
                    this.setState({
                        currentTab: 'wall',
                    });
                }
            }
        });
        this.props.onSetEventProtectedMenu();
        this.loadCurrentTimeSlot(timeslotId);
        let auditorium = this.getAuditoriumBasedOnId(auditoriumId);
        if (auditorium) {
            this.setState({
                auditorium: auditorium[0],
            });
        }
        window.addEventListener('beforeunload', this.beforeUnload);

        this.props.onCloseTopNav();
    }

    componentDidUpdate(prevProps, prevState) {
        const {timeslotId, auditoriumId} = this.props.match.params;
        if (this.state.timeSlotId !== timeslotId) {
            this.setState({
                timeSlotId: timeslotId,
                auditoriumId: auditoriumId,
            });
            this.loadCurrentTimeSlot(timeslotId);
            this.props.onCloseTopNav();
        }
        if (prevState.auditoriumId !== this.state.auditoriumId) {
            let auditorium = this.getAuditoriumBasedOnId(auditoriumId);
            if (auditorium) {
                this.setState({
                    auditorium: auditorium[0],
                });
            }
        }
        if (prevState.timeSlotId !== this.state.timeSlotId) {
            if (prevState?.timeSlotId) {
                this.disconnectFromTimeslotSocket(prevState?.timeSlotId);
            }
            this.connectToTimeslotSocket();
        }

        if (prevProps.event.hideAuditoriumNetworking !== this.props.event.hideAuditoriumNetworking) {
            if (this.props.event.hideAuditoriumNetworking && this.state.currentTab === 'networking') {
                // if is on tablet/mobile, we handle the automatic tabChange from the AuditoriumTabs component
                if (this.props.isLargeScreen) {
                    this.handleChangeTab('wall')();
                }
            }
        }
    }

    componentWillUnmount() {
        this.beforeUnload();
        window.removeEventListener('beforeunload', this.beforeUnload);
    }

    checkIfAuditoriumAndTimeslotExist = async () => {
        const {auditoriumId, timeslotId} = this.props.match.params;
        const {event} = this.props;

        if (event && event.auditoriums?.length > 0) {
            const currentAuditorium = await event.auditoriums?.find((auditorium) => auditorium._id === auditoriumId);
            if (currentAuditorium) {
                let currentTimeslotExist = {};
                await currentAuditorium.dailyProgram?.map((program) => {
                    const carrier = program.program?.find((timeslot) => timeslot._id === timeslotId);
                    if (carrier) {
                        return (currentTimeslotExist = carrier);
                    }
                });
                if (Object.keys(currentTimeslotExist).length > 0) {
                    this.setState({
                        auditoriumId: auditoriumId,
                        timeSlotId: timeslotId,
                    });
                } else {
                    window.location.href = `/event/${event.slug}/auditorium-archive/`;
                }
            } else {
                window.location.href = `${process.env.REACT_APP_AIFG_WP_LINK}`;
            }
        }
    };

    getAuditoriumBasedOnId = (auditoriumId) => {
        return this.props.event.auditoriums.filter((auditorium) => auditorium._id === auditoriumId);
    };

    beforeUnload = () => {
        this.props.closeSidebar();
        if (this.state.timeSlot?._id) {
            this.disconnectFromTimeslotSocket(this.state.timeSlot._id);
        }
    };

    getAuditoriumTimeslotId = (id) => {
        this.setState({auditoriumRoomId: id});
    };

    connectToTimeslotSocket = () => {
        const {eventRoles} = this.props;
        // if the user isOrganizer and doesn't have the participant profile enabled, don't connect him to the socketTimeslot
        if (eventRoles.isOrganizer && !eventRoles.isParticipant) {
            return null;
        }

        const {timeSlotId, auditoriumId} = this.state;
        if (timeSlotId && auditoriumId) {
            const {user, event} = this.props;
            const userId = user._id;
            const eventId = event._id;
            connectUserToTimeslot(userId, timeSlotId, auditoriumId, eventId);
        }
    };

    disconnectFromTimeslotSocket = (timeSlotId) => {
        const {eventRoles} = this.props;
        // if the user isOrganizer and doesn't have the participant profile enabled, don't connect him to the socketTimeslot
        if (eventRoles.isOrganizer && !eventRoles.isParticipant) {
            return null;
        }

        const {user} = this.props;
        const userId = user._id;
        disconnectUserFromTimeslot(userId, timeSlotId);
    };

    loadCurrentTimeSlot = (timeslotId) => {
        if (this.props.event !== null) {
            let allAuditoriumsProgram = [];

            this.props.event.auditoriums.forEach((auditorium) => {
                auditorium.dailyProgram.forEach((dayProgram) => {
                    allAuditoriumsProgram = allAuditoriumsProgram.concat(dayProgram.program);
                });
            });

            let timeSlot = allAuditoriumsProgram.find((timeSlot) => timeSlot._id === timeslotId);
            let timeSlotIndex = allAuditoriumsProgram.findIndex((timeSlot) => timeSlot._id === timeslotId);

            this.setState(
                {
                    timeSlot: timeSlot,
                    timeSlotIndex: timeSlotIndex,
                },
                async () => {
                    // Check if current timeslot has active polls before handlePollsUpdated socket
                    this.setState({hasPolls: false});
                    if (timeSlot !== null) {
                        const response = await axios.get(
                            `/polls/event/${this.props.event._id}/auditoriums/${this.props.match.params.auditoriumId}/program/${timeSlot._id}`
                        );
                        let hasPolls = response.data.polls.map((poll) => poll.hidden).includes(false);
                        this.setState({hasPolls});
                    }
                }
            );

            this.setVideoWallData(timeSlot);
        }
    };

    setVideoWallData = (timeSlot) => {
        // we set up the sideMenu to have videoWall
        this.props.setHasVideoWall(true);

        // we set up the current time slot video Id
        const activeVideoWallId = timeSlot.videoWall;

        let highlightUsers = [];
        timeSlot.speakers.forEach((speaker) => {
            highlightUsers.push(speaker.user._id);
        });

        // we set up the current wall Id to be the video Wall Id
        const wallData = {
            wallId: activeVideoWallId,
            highlightUsers: highlightUsers,
        };
        this.props.setTimeSlotVideoWall(wallData);
        this.props.setActiveWall(wallData);

        // we open the sideBar to the videoWall
        this.props.seeVideoWall();
    };

    handleChangeTab = (tabName) => (e) => {
        this.setState({
            currentTab: tabName,
        });
    };

    pollsUpdated = (polls) => {
        // here we always get the latest polls for the current timeslot
    };

    renderTimeslotTitle = () => {
        const {timeSlot} = this.state;
        const {event, translation} = this.props;
        let eventHasEnded = checkIfEventHasEnded(event, event.timezoneValue);

        return (
            <div
                onDragStart={preventDefaultDrag}
                className={`slot-title ${timeSlot ? '' : 'd-none'} ${!eventHasEnded ? 'more-right-spacing' : ''}`}
            >
                <div>
                    <p onDragStart={preventDefaultDrag} className={'title '}>
                        <span>
                            {timeSlot.speakersExtended.length ? (
                                <>
                                    {timeSlot.speakersExtended.map((speaker, index) => {
                                        return (
                                            <span onDragStart={preventDefaultDrag} draggable="false" key={speaker._id}>
                                                {speaker.fullName}
                                                {index === timeSlot.speakersExtended.length - 1 ? (
                                                    <span> {translation?.auditoriumArchive.with} </span>
                                                ) : (
                                                    ', '
                                                )}
                                            </span>
                                        );
                                    })}
                                </>
                            ) : null}
                            {timeSlot.title}
                        </span>
                    </p>
                </div>
            </div>
        );
    };

    render() {
        const {event, eventId, isLargeScreen, translation, defaultTranslation, isRtlLanguage} = this.props;
        const {timeSlot, currentTab, hasPolls, auditorium} = this.state;
        const hideVideo = !isLargeScreen && currentTab !== 'video';
        const sideContainerHidden = currentTab === '';
        const displaySideBar = isLargeScreen || (!isLargeScreen && currentTab !== '' && currentTab !== 'video');

        let eventHasEnded = checkIfEventHasEnded(event, event.timezoneValue);

        if (!timeSlot) {
            return null;
        }

        return (
            <div onDragStart={preventDefaultDrag} className="position-background left-sidebar-show">
                <LeftSideMenu />
                <div
                    id="js-auditorium-page"
                    className={`auditorium-page mobile-page-container on-demand-video-page ${
                        currentTab !== 'video ' ? 'wall-tab-active ' : ''
                    } ${!eventHasEnded ? 'has-live-button' : ''}`}
                >
                    {/*SHOW BACKGROUND IMAGE ONLY ON LARGE SCREENS*/}
                    {isLargeScreen && (
                        <AuditoriumBackgroundImage eventTitle={event.title} brandingData={event.brandingData} />
                    )}

                    {!isLargeScreen && (
                        <div onDragStart={preventDefaultDrag} className="page-title">
                            {translation?.auditoriumArchive.title}
                        </div>
                    )}
                    {isLargeScreen && this.renderTimeslotTitle()}
                    {!isLargeScreen && (
                        <AuditoriumTabs
                            timeSlot={timeSlot}
                            hasVideoWall={true}
                            hasPolls={hasPolls}
                            currentTab={currentTab}
                            handleChangeTab={this.handleChangeTab}
                        />
                    )}
                    <div onDragStart={preventDefaultDrag} className={`centerOfPage ${hideVideo ? 'hide' : ''}`}>
                        <div onDragStart={preventDefaultDrag} className="relativeCenter">
                            {isLargeScreen && (
                                <AuditoriumBanners
                                    classes={'left-banner'}
                                    filesUrl={
                                        auditorium?.stageBanners?.length > 0
                                            ? `${process.env.REACT_APP_EVENT_FOLDER}${eventId}/`
                                            : event.brandingData.filesUrl
                                    }
                                    lobbyBanner={event.brandingData.lobbyBanner}
                                    stageBanners={auditorium?.stageBanners}
                                />
                            )}
                            <AuditoriumVideoPlayer
                                key={timeSlot._id}
                                timeSlot={timeSlot}
                                videoStreamingUrl={timeSlot.video}
                                showFastForwardButtons={true}
                                showPlayPauseButtons={true}
                                greyVideoProgressBar
                                isOnDemandVideo={true}
                                autoplayFix
                            />
                            {!isLargeScreen && this.renderTimeslotTitle()}
                            {isLargeScreen && !timeSlot && (
                                <AuditoriumBanners
                                    classes={'right-banner'}
                                    filesUrl={
                                        auditorium?.stageBanners?.length > 0
                                            ? `${process.env.REACT_APP_EVENT_FOLDER}${eventId}/`
                                            : event.brandingData.filesUrl
                                    }
                                    lobbyBanner={event.brandingData.lobbyBanner}
                                    stageBanners={auditorium?.stageBanners}
                                />
                            )}
                        </div>
                    </div>
                    {displaySideBar && (
                        <div
                            onDragStart={preventDefaultDrag}
                            className={`booth-wall-container ${sideContainerHidden ? 'hidden' : ''}`}
                        >
                            <div onDragStart={preventDefaultDrag} className="booth-wall-header">
                                {timeSlot ? (
                                    <>
                                        <div
                                            onClick={this.handleChangeTab('wall')}
                                            className={`tab wall-tab ${currentTab === 'wall' ? 'active' : ''}`}
                                        >
                                            <span>{translation?.auditorium.videoWallButton}</span>
                                        </div>
                                        {!event.hideAuditoriumNetworking && (
                                            <div
                                                onClick={this.handleChangeTab('networking')}
                                                className={`tab networking-tab ${
                                                    currentTab === 'networking' ? 'active' : ''
                                                }`}
                                            >
                                                <span>
                                                    {translation?.sideMenu.audience ||
                                                        defaultTranslation?.sideMenu.audience}
                                                </span>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <span>{translation?.auditorium.onDemandButton}</span>
                                )}
                                {!timeSlot && currentTab === 'onDemand' ? (
                                    <div
                                        className="close-dropdown auditorium-sidebar-close-dropdown"
                                        onClick={this.handleChangeTab('')}
                                    >
                                        <GreaterArrow fill={colors.whiteVariant} />
                                    </div>
                                ) : (
                                    <AuditoriumSideButtons
                                        timeSlot={timeSlot}
                                        translation={translation}
                                        defaultTranslation={defaultTranslation}
                                        handleChangeTab={this.handleChangeTab}
                                        hasOnDemandVideos={true}
                                        currentTab={currentTab}
                                        hasPolls={hasPolls}
                                    />
                                )}
                                {!eventHasEnded && (
                                    <BackToLive
                                        translation={translation}
                                        defaultTranslation={defaultTranslation}
                                        linkTo={`/event/${event.slug}/auditorium`}
                                    />
                                )}
                            </div>
                            {currentTab !== 'wall' && currentTab !== 'networking' && timeSlot && (
                                <div onDragStart={preventDefaultDrag} className="booth-wall-subheader">
                                    <BackIcon fill={colors.grey} onClick={this.handleChangeTab('wall')} />
                                    <span>
                                        {currentTab === 'polls' &&
                                            (translation?.polls?.polls || defaultTranslation?.polls?.polls)}
                                        {currentTab === 'resources' &&
                                            (translation?.auditorium?.resourcesButton ||
                                                defaultTranslation?.auditorium?.resourcesButton)}
                                        {currentTab === 'onDemand' &&
                                            (translation?.auditorium?.onDemandButton ||
                                                defaultTranslation?.auditorium?.onDemandButton)}
                                    </span>
                                </div>
                            )}
                            {currentTab === 'wall' && timeSlot && <Wall isVideoWall />}
                            {currentTab === 'networking' && (
                                <AuditoriumNetworking
                                    timeSlotId={timeSlot?._id}
                                    auditoriumId={this.state.auditoriumId}
                                />
                            )}
                            {currentTab === 'polls' && timeSlot && (
                                <AuditorumPollsOutput
                                    auditoriumId={this.state.auditoriumId}
                                    programId={timeSlot._id}
                                    pollsUpdate={this.pollsUpdated}
                                    translation={translation}
                                    isRtlLanguage={isRtlLanguage}
                                />
                            )}
                            {currentTab === 'resources' && timeSlot && <Resources currentTimeslot={timeSlot} />}
                            {currentTab === 'onDemand' && <OnDemandVideosSection />}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        user: state.user.data,
        eventRoles: state.user.eventRoles,
        isLargeScreen: state.layout.isLargeScreen,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        isRtlLanguage: state.languages.isRtlLanguage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeSidebar: () => dispatch(actions.sideNavClose()),
        setHasVideoWall: (booleanValue) => dispatch(actions.sideNavHasVideoWall(booleanValue)),
        setTimeSlotVideoWall: (wallData) => dispatch(actions.setTimeSlotVideoWall(wallData)),
        setActiveWall: (wallData) => dispatch(actions.setActiveWall(wallData)),
        seeVideoWall: () => dispatch(actions.sideNavSeeVideoWall()),
        onSetEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
        onCloseTopNav: () => dispatch(actions.topNavClose()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auditorium);

import React, {PureComponent} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions';
import Spinner from '../../../../SmallLayoutComponents/Spinner';
import {preventDefaultDrag} from '../../../../Utils/utils';
import {ReactComponent as EmptyIcon} from '../../../../Images/svg/empty-sessions.svg';
import SingleSmartSuggestion from '../SmartSuggestions/SingleSmartSuggestion';
import {ReactComponent as GetAppIcon} from '../../../../Images/svg/download.svg';
import colors from '../../../../CSS/_variables.module.scss';
import ProgrammeSearch from './ProgrammeSearch';

class Programme extends PureComponent {
    scrollContainer = React.createRef();

    state = {
        expanded: null,
    };

    toggleExpandedSession = (id) => (e) => {
        e.preventDefault();
        this.setState(prevState => ({
            expanded: prevState.expanded === id ? null : id
        }));
    };

    addSessionToAgenda = (sessionId) => {
        this.props.addSessionAgenda(sessionId);
        this.createExternalCalendarData(sessionId);
    }

    createExternalCalendarData = (sessionId) => {
        const {myProgramme} = this.props;
        const timeslotData = myProgramme.find(programme => programme._id === sessionId);
        const eventCalendarData = {
            title: timeslotData.title,
            description: timeslotData.description,
            location: window.location.origin,
            start: timeslotData.startTimestamp,
            end: timeslotData.endTimestamp
        };
        const calendarEventType = 'session';
        this.props.showAddToExternalCalendarDialog(eventCalendarData, calendarEventType)
    }

    render() {
        const {expanded} = this.state;
        const {
            event,
            eventId,
            myProgrammeLoading,
            myProgramme,
            programmeSearch,
            isLargeScreen,
            languages,
            translation,
            defaultTranslation
        } = this.props;
        return (
            <div className="programme">
                {myProgrammeLoading && <Spinner/>}
                <div className="programme-wrapper">
                    <div
                        className="scroll-left-container"
                        ref={this.scrollContainer}
                    >
                        <div>
                            <ProgrammeSearch
                                scrollContainerRef={this.scrollContainer}
                                isProgramEmpty={myProgramme?.length === 0}/>
                            <div onDragStart={preventDefaultDrag} className="programme-wrapper-title">
                                <p>
                                    {translation?.program?.searchWrapperTitle ||
                                    defaultTranslation?.program?.searchWrapperTitle}
                                </p>
                                {event.brandingData.programFile && (
                                    <DownloadProgramButton
                                        eventId={event._id}
                                        programFile={event.brandingData.programFile}
                                        translation={translation}
                                        defaultTranslation={defaultTranslation}
                                    />
                                )}
                            </div>
                            { myProgramme?.length > 0
                                ?
                                <>
                                    {myProgramme?.map((session) => {
                                        return <SingleSmartSuggestion
                                            key={session._id}
                                            suggestion={session}
                                            isAddedToAgenda={session.isAddedToAgenda}
                                            event={event}
                                            eventId={eventId}
                                            expanded={expanded}
                                            toggleDescription={this.toggleExpandedSession(session._id)}
                                            isLargeScreen={isLargeScreen}
                                            languages={languages}
                                            translation={translation}
                                            defaultTranslation={defaultTranslation}
                                            addSessionAgenda={this.addSessionToAgenda}
                                            removeSessionAgenda={this.props.removeSessionAgenda}/>
                                    })}
                                </>
                                :
                                <div className="empty-data">
                                    <div>
                                        <EmptyIcon/>
                                        <p>
                                            {programmeSearch
                                            ?
                                                translation?.program?.emptySearch ||
                                                defaultTranslation?.program?.emptySearch
                                            :
                                                translation?.program?.emptyProgramme ||
                                                defaultTranslation?.program?.emptyProgramme
                                            }
                                        </p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        myProgramme: state.event.myProgramme,
        myProgrammeLoading: state.event.myProgrammeLoading,
        programmeSearch: state.event.programmeSearch,
        isLargeScreen: state.layout.isLargeScreen,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addSessionAgenda: (sessionId) => dispatch(actions.addSessionToAgenda(sessionId)),
        removeSessionAgenda: (sessionId) => dispatch(actions.removeSessionFromAgenda(sessionId)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Programme));

const DownloadProgramButton = ({eventId, programFile, className, translation, defaultTranslation}) => {
    return (
        <div className={`download-program ${className}`}>
            <a
                href={`${process.env.REACT_APP_EVENT_FOLDER}${eventId}/${programFile}`}
                target="_blank"
                rel="noreferrer"
                className="download-program-link"
            >
                <GetAppIcon tabIndex="0" className="button" fill={colors.secondary} />
                <span>
                    {translation?.generalText.download ||
                    defaultTranslation?.generalText.download}
                </span>
            </a>
        </div>
    );
};
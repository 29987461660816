import React from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import * as actions from '../../../store/actions';
import BackIcon from '@material-ui/icons/NavigateBeforeOutlined';
import MasterAdminNavigation from '../MasterAdminNavigation';
import '../../../CSS/adminDashboard.scss';
import Spinner from "../../../SmallLayoutComponents/Spinner";
import {preventDefaultDrag} from '../../../Utils/utils';
import axios from '../../../store/axios-instance';
import moment from 'moment';

class SingleEventStatistics extends React.Component {
    componentDidMount() {
        this.props.onSetAdminMenu();
        this.getEventStatistics();
    }

    state = {
        event: '',
        statistics: '',
        loading: false,
        eventHasEnded: false
    };

    getEventStatistics = () => {
        const {eventId} = this.props.match.params;
        this.setState({loading: true})
        axios({method: 'get', url: `/master-admin/event-statistics/${eventId}`})
            .then((response) => {
                let eventHasEnded = false;
                const now = moment();
                let eventEnd = moment(response.data.statistics?.eventEnd);
                if (now > eventEnd) {
                    eventHasEnded = true;
                }
                this.setState({event: response.data.event, statistics: response.data.statistics, loading: false, eventHasEnded: eventHasEnded});
            })
            .catch((error) => {
                this.setState({loading: false});
            });
    };

    render() {
        const {event, statistics, loading, eventHasEnded} = this.state;
        return (
            <div onDragStart={preventDefaultDrag} className="superadmin-dashboard">
                <MasterAdminNavigation/>
                {loading && <Spinner/>}
                {!loading &&
                <section className="top-section">
                    <div onDragStart={preventDefaultDrag} className="container">
                        <h1>
                            <Link to={{pathname: `/dashboard/organizers/all-events`}}>
                                <BackIcon/>
                            </Link>
                            {event.name ? `${event.name} - Event Statistics` : 'Unable to load event data!'}
                        </h1>
                        {event && (
                            <div onDragStart={preventDefaultDrag} className="organizer-details">
                                <p> Event Start Date: {event?.eventStartDate} </p>
                                <p> Event End Date: {event?.eventEndDate} </p>
                                <p> Event First Timeslot Start: {moment(statistics?.eventStart).format('YYYY-MM-DD HH:mm:ss')} </p>
                                <p> Event Last Timeslot End: {moment(statistics?.eventEnd).format('YYYY-MM-DD HH:mm:ss')} </p>
                                <p> Participants 24h before event start: {statistics?.participants24hBefore} </p>
                                <p> Participants 1h before event start: {statistics?.participants1hBefore} </p>
                                <p> Participants event start: {statistics?.participantsStart} </p>
                                <p> Participants event end: {eventHasEnded ? statistics?.participantsEnd : 'Not available!'} </p>
                                <p> Exhibitors event end: {eventHasEnded ? statistics?.exhibitorsEnd : 'Not available!'} </p>
                                <p> Posts event start: {statistics?.postsStart} </p>
                                <p> Posts event end: {eventHasEnded ? statistics?.postsEnd : 'Not available!'} </p>
                                <p> Comments event start: {statistics?.commentsStart} </p>
                                <p> Comments event end: {eventHasEnded ? statistics?.commentsEnd : 'Not available!'} </p>
                                <p> Likes event start: {statistics?.likesStart} </p>
                                <p> Likes event end: {eventHasEnded ? statistics?.likesEnd : 'Not available!'} </p>
                            </div>
                        )}
                    </div>
                </section>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetAdminMenu: () => dispatch(actions.setAdminMenu()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleEventStatistics);

const de = {
    generalText: {
        upload: 'Hochladen',
        save: 'Speichern',
        add: 'Hinzufügen',
        select: 'Auswählen',
        remove: 'Entfernen',
        close: 'SCHLIEßEN',
        update: 'AKTUALISIEREN',
        cancel: 'ABBRECHEN',
        confirm: 'Bestätigen',
        download: 'Herunterladen',
        refresh: 'Aktualisieren',
        manage: 'Verwalten',
        back: 'Zurück',
        submit: 'Einreichen',
        send: 'Senden',
        eventMO: 'onvent',
        eventETX: 'ereignis',
        eventsMO: 'onvents',
        eventsETX: 'ereignisse',
        eventUpperMO: 'Onvent',
        eventUpperETX: 'Ereignis',
        hide: 'HIDE', //new translation: HIDE
    },
    time: {
        justNow: 'Nicht registriert',
        minute: 'Anmeldung nicht möglich',
        minutes: 'Onvent ist nicht verfügbar',
        hour: 'Das von Ihnen gesuchte Ereignis ist nicht verfügbar. Es wurde entweder vom Organisator geschlossen oder von einem Admin deaktiviert.',
        hours: 'TAGUNGEN',
        day: 'Treffen mit',
        days: 'Ausstehend',
        month: 'Akzeptiert',
        months: 'Annulliert',
        yesterday: 'Noch keine Treffen geplant',
        today: 'Heute',
        tomorrow: 'Morgen',
        monday: 'Montag',
        tuesday: 'Dienstag',
        wednesday: 'Mittwoch',
        thursday: 'Donnerstag',
        friday: 'Freitag',
        saturday: 'Samstag',
        sunday: 'Sonntag',
        mondayShort: 'Mo.',
        tuesdayShort: 'Di.',
        wednesdayShort: 'Mi.',
        thursdayShort: 'Do.',
        fridayShort: 'Fr.',
        saturdayShort: 'Sa.',
        sundayShort: 'So.',
        january: 'Januar',
        february: 'Februar',
        march: 'März',
        april: 'April',
        may: 'Mai',
        june: 'Juni',
        july: 'Juli',
        august: 'August',
        september: 'September',
        october: 'Oktober',
        november: 'November',
        december: 'Dezember',
        januaryShort: 'Jan.',
        februaryShort: 'Feb.',
        marchShort: 'März',
        aprilShort: 'Apr.',
        mayShort: 'Mai',
        juneShort: 'Jun.',
        julyShort: 'Jul.',
        augustShort: 'Aug.',
        septemberShort: 'Sept.',
        octoberShort: 'Okt.',
        novemberShort: 'Nov.',
        decemberShort: 'Dez.',
    },
    errors: {
        noFile: 'Keine Datei ausgewählt',
        required: 'Feld erforderlich',
        emailNotValid: 'E-Mail nicht gültig',
        passwordMinLength: 'Das Passwort muss mindestens 8 Zeichen haben.',
        passwordMismatch: 'Passwort-Fehlanpassung',
        passwordIsSame: 'Your new password must be different', //new translation
        fileToLarge: 'Datei zu groß. 2Mb maximale Dateigröße.',
        fileToLargeTextFirst: 'Datei zu groß.',
        fileToLargeTextSecond: 'Maximale Dateigröße.',
        agreeToGDPR: 'Bitte stimmen Sie der Datenschutzerklärung zu',
        noInvitationCode: 'Bitte geben Sie Ihren Einladungscode ein',
        invitationCodeInvalid: 'Der Einladungscode ist ungültig.',
        imageFileNotSupported:
            'Der Dateityp wird nicht unterstützt. Bitte verwenden Sie eine der folgenden Möglichkeiten:',
        phoneInvalid: 'Bitte geben Sie eine gültige Telefonnummer ein. Zum Beispiel: +447517663928',
        linkInvalid: 'Bitte geben Sie einen gültigen Link ein (https://site.com)',
        facebookLinkInvalid: 'Bitte geben Sie einen gültigen Link ein (https://facebook.com/)',
        linkedinLinkInvalid: 'Bitte geben Sie einen gültigen Link ein (https://linkedin.com/)',
        twitterLinkInvalid: 'Bitte geben Sie einen gültigen Link ein (https://twitter.com/)',
        youtubeLinkInvalid: 'Bitte geben Sie einen gültigen Link ein (https://www.youtube.com/)',
        connectionProblemTextFirst: 'Es scheint ein Verbindungsproblem zu geben. Bitte',
        connectionProblemButton: 'KLICK',
        connectionProblemTextSecond: 'oder aktualisieren Sie die Seite.',
        fileTooLarge: 'Datei zu groß. Maximal 2 MB Dateigröße.',
        fileTooLargeTextFirst: 'Datei zu groß.',
        fileTooLargeTextSecond: 'Maximale Dateigröße.',
    },
    footer: {
        cookiesText:
            'Diese Website verwendet wichtige Cookies, die für ihre Funktion erforderlich sind. Es werden auch Cookies von Drittanbietern verwendet, die für das Funktionieren einiger Eigenschaften unserer Website erforderlich sind. Wenn Sie mehr erfahren oder Ihre Zustimmung zu einigen Cookies widerrufen möchten, lesen Sie bitte unsere',
        cookiesLink: 'Cookie-Richtlinie',
        cookiesButton: 'ANNEHMEN',
        privacyPolicy: 'Datenschutzerklärung',
        cookies: 'Cookies',
        termsAndConditions: 'Geschäftsbedingungen ',
        socialLinksTitle: 'Folge uns:',
    },
    menu: {
        logIn: 'ANMELDEN',
        logOut: 'ABMELDEN',
        scholarRegistration: 'Poster / Study Registration', // new translation
        exhibitorRegistration: 'Aussteller sein', // new translation modification (Be Exhibitor => Exhibitor Registration)
        participantRegistration: 'REGISTRIEREN', // new translation modification (Register => Participant Registration)
        createEvent: 'Veranstaltung organisieren',
        launchText: 'Start September 2020 - offen für frühzeitigen Zugang',
    },
    homepage: {
        organizedBy: 'ORGANISIERT VON',
        date: 'DATUM',
        location: 'DOMAIN & LAGE',
        price: 'PREIS',
        tabIntro: 'EINFÜHRUNG',
        tabIntroProgramButton: 'PROGRAMM',
        tabProgram: 'PROGRAMM',
        tabProgramIntroButton: 'EINFÜHRUNG',
        tabPartners: 'PARTNER',
        tabExhibitors: 'AUSSTELLER',
        previousEventsSectionTitle: 'FRÜHERE VERANSTALTUNGEN',
        tabSocialMedia: 'SOZIALE MEDIEN',
    },
    socialButtons: {
        linkedinButton: 'Mit LinkedIn anmelden ',
        facebookButton: 'Mit Facebook anmelden',
        googleButton: 'Mit Googleanmelden',
        twitterButton: 'Mit Twitter anmelden',
    },
    login: {
        orText: 'oder',
        inputEmail: 'E-Mail',
        inputPassword: 'Kennwort',
        rememberMeButton: 'Erinnern Sie mich daran',
        forgotPasswordButton: 'Passwort vergessen?',
        loginButton: 'ANMELDEN',
        noAccountText: 'Sie haben noch keinen Konto?',
        registerButton: 'Hier registrieren',
        recoverPasswordDialogTitle: 'PASSWORT WIEDERHERSTELLEN',
        recoverPasswordDialogInputEmail: 'E-Mail',
        recoverPasswordDialogCloseButton: 'SCHLIEßEN',
        recoverPasswordDialogRecoverButton: 'WIEDERHERSTELLEN',
        errorNotRegistered: 'Nicht registriert',
        errorUnableToLogin: 'Anmeldung unmöglich',
    },
    register: {
        formTitle: 'Onvent ist nicht verfügbar',
        inputFirstName: 'Vorname',
        inputLastName: 'Nachname',
        inputEmail: 'E-Mail',
        inputPassword: 'Kennwort',
        inputConfirmPassword: 'Passwort bestätigen',
        uploadImageText: 'Profilbild hochladen (2MB):',
        editImageText: 'Profilbild bearbeiten (2 MB):',
        gdprTextFirst: 'Indem Sie fortfahren, geben Sie ihre Zustimmung zu',
        gdprTextTerms: 'den Nutzungsbedingungen',
        gdprTextSecond: 'und bestätigen Sie, dass durchlesen unserer',
        gdprTextCookies: 'Cookie',
        gdprTextThird: 'und',
        gdprTextPrivacy: 'Datenschutzerklärung',
        gdprTextAgree: 'Bitte stimmen Sie der Datenschutzerklärung zu',
        registerButton: 'SICH ANMELDEN',
        haveAccountText: 'Sie haben schon ein Konto?',
        haveAccountLoginButton: 'Hier einloggen',
        errorAlreadyHaveAccount: 'Bereits registriert.',
        formTitleTextFirst: 'Oder erstellen Sie ihr',
        formTitleTextSecond: 'Profil',
        invitationCode: 'Einladungscode',
    },
    participantRegistration: {
        choosePackageTitle: 'WÄHLEN SIE DAS ZUGRIFFSPAKET',
        noPackageText: 'Bitte wählen Sie ein Paket',
        gdprTextFirst: 'Indem Sie fortfahren, geben Sie ihre Zustimmung zu',
        gdprTextPrivacy: 'Datenschutzerklärung',
        gdprTextSecond: 'und',
        gdprTextTerms: 'Veranstaltungsbedingungen',
        gdprTextAgree: 'Bitte stimmen Sie der Datenschutzerklärung zu',
        registerButtonFree: 'Vollständige Registrierung',
        registerButtonPay: 'Weiter zur Zahlung',
    },
    exhibitorRegistration: {
        choosePackageTitle: 'WÄHLEN SIE DAS ZUGRIFFSPAKET',
        gdprTextFirst: 'Indem Sie fortfahren, geben Sie ihre Zustimmung zu',
        gdprTextPrivacy: 'Datenschutzbestimmungen für Veranstaltungen',
        gdprTextSecond: 'und   ',
        gdprTextTerms: 'Veranstaltungsbedingungen',
        gdprTextAgree: 'Bitte stimmen Sie der Datenschutzerklärung zu',
        registerButtonFree: 'Vollständige Registrierung',
        registerButtonPay: 'Weiter zur Zahlung',
    },
    stripePayment: {
        cardNumber: 'Kartennummer',
        expirationDate: 'Ablaufdatum (MM / JJ)',
        cvc: 'CVC',
        disclaimerText:
            'Dieser Zahlungsvorgang wird Ende-zu-Ende verschlüsselt. Sicherheit und Datenschutz werden von Stripe Services garantiert.',
        disclaimerTextLink: 'Klick hier um mehr zu erfahren.',
        cancelButton: 'ABBRECHEN',
        payButton: 'Zahlen',
    },
    recoverPassword: {
        recoverTitle: 'PASSWORT WIEDERHERSTELLEN',
        cancelButton: 'SCHLIEßEN',
        recoverButton: 'WIEDERHERSTELLEN',
        recoverTextSend: 'E-Mail für Passwort-Wiederherstellung erfolgreich gesendet.',
    },
    unsubscribe: {
        unsubscribeTitle: 'Abmelden?',
        unsubscribeHomepage: 'Zur Homepage gehen',
    },
    notAvailable: {
        notAvailableTitle: 'Onvent ist nicht verfügbar',
        notAvailableSubtitle:
            'Das von Ihnen gesuchte Onvent ist nicht verfügbar. Es wurde entweder vom Vernastalter geschlossen oder von einem Verwalter deaktiviert.',
        notAvailableTitleTextSecond: 'ist nicht verfügbar',
        notAvailableSubtitleTextFirst: 'Das',
        notAvailableSubtitleTextSecond:
            'Sie suchen ist nicht verfügbar. Es wurde entweder vom Veranstalter geschlossen oder von einem Administrator deaktiviert',
        closedTitle: 'jetzt geschlossen',
        closedSubtitleTextFirst: 'Sie haben sich  erfolgreich dafür registriert',
        closedSubtitleTextSecond: 'ist derzeit geschlossen',
    },
    notFound: {
        notFoundDescription: 'Ups ... Keine Veranstaltung hier zu finden!',
        notFoundHomepage: 'Zur Homepage gehen',
    },
    eventMenu: {
        organizerButton: 'VERANSTALTER(IN)',
        exhibitorButton: 'AUSSTELLER',
        boothButton: 'STAND',
        boothsButton: 'STÄNDE',
        homeButton: 'STARTSEITE',
        contactsButton: 'KONTAKTE',
        meetingsButton: 'TREFFENS',
        chatsButton: 'CHATS',
        programButton: 'PROGRAMM',
        archiveButton: 'ARCHIVIEREN',
        myAccountButton: 'MEIN KONTO',
        lobbyButton: 'Lobby',
        roomsButton: 'RÄUME',
        connectionProblemTextFirst: 'Neue Updates sind verfügbar. Bitte klicken Sie',
        connectionProblemButton: 'HIER',
        connectionProblemTextSecond: 'oder aktualisieren Sie die Seite.',
        sessionProblemTextFirst: 'Ihre Sitzung ist abgelaufen. Bitte klicke',
        sessionProblemButton: 'HIER',
        sessionProblemTextSecond: 'um sich erneut anzumelden.',
        backButton: 'ZURÜCK',
        hubButton: 'ZENTRUM',
    },
    myEventsDropdown: {
        title: 'MEINE VERANSTALTUNGEN',
        createButtonDesktop: 'Verantsaltung erstellen',
        createButtonMobile: 'SCHAFFEN',
    },
    myBoothsDropdown: {
        title: 'MEINE STÄNDE',
    },
    contactsDropdown: {
        title: 'IHRE ANSPRECHPARTNER',
        requestsTitle: 'Kontakt-Anfragen',
        rejectButton: 'ABLEHNEN',
        acceptButton: 'ANNEHMEN',
        pendingButton: 'ausstehend',
        addButton: 'Hinzufügen',
        noContacts:
            'Sie haben noch keine Kontakte. Bitte besuchen Sie den Netzwerkbereich, um mit der Erstellung Ihrer Kontaktliste zu beginnen.',
    },
    meetingsDropdown: {
        meetingWith: 'Treffen mit',
        pending: 'Ausstehend',
        accepted: 'Akzeptiert',
        canceled: 'Annulliert',
        noMeetings: 'Noch kein Treffen geplant',
        finished: 'Fertig',
    },
    programDropdown: {
        timeZone: 'Zeitzone:',
        with: 'mit ',
        noProgram: '',
    },
    notificationsDropdown: {
        title: 'Benachrichtigungen',
        contactRequestReceived: 'hat Ihnen eine Kontaktanfrage gesendet. Schau es dir an!',
        contactRequestAccepted: 'hat Ihre Kontaktanfrage angenommen. Fangen Sie an zu chatten!',
        meetingRequestReceived: 'Eine Treffensanfrage Ihnen geschickt wurde. Überprüfen Sie!',
        meetingRequestAccepted: 'Ihre Treffensanfrage akzeptiert wurde. Überprüfen Sie!',
        meetingRequestCanceled: 'Eine Treffensanfrage annuliert wurde. Überprüfen Sie!',
        meetingStarting: 'Ein Treffen wird bald beginnen. Überprüfen Sie!',
        newBoothPost: 'hat in Ihrer Standwand gepostet. Schau es dir an!',
        noNotifications: 'Sie haben noch keine Benachrichtigungen.',
        seeAll: 'ALLE SEHEN',
        hideAll: 'ALLE AUSBLENDEN',
        agendaItemUpdated: 'Das gespeicherte Programmelement wurde vom Veranstalter aktualisiert',
        agendaItemDelted: 'Das gespeicherte Programmelement wurde vom Veranstalter gelöscht',
        newMeetingRequest: 'Sie haben eine neue Terminanfrage',
        meetingAccepted: 'Ihre Besprechungsanfrage wurde angenommen',
        meetingCancelled: 'Ihr Termin wurde abgesagt',
        meetingWillStartTextFirst: 'Ein Treffen mit',
        meetingWillStartTextSecond: 'wird bald beginnen. Schau es Dir an!',
    },
    chatsDropdown: {
        title: 'CHATS',
        newChatButton: 'Neuer Chat',
        newChatStart: 'Starten Sie einen neuen Chat',
        newChatProvideText:
            'Bitte geben Sie einen Namen für Ihren Chat an. Sie können den Namen anschließend bearbeiten:',
        noChats: 'Erstellen Sie mindestens einen Chat, um mit Veranstaltungsteilnehmern zu sprechen',
        member: 'Mitglied',
        members: 'Mitglieder',
        noOpenedChatText: 'Klicken Sie auf einen Chat, um das Gespräch fortzusetzen.',
        noMessagesInChatTextFirst: 'Das Eis brechen.',
        noMessagesInChatTextSecond: 'Sei derjenige, der dieses Gespräch beginnt.',
        newChatNameInput: 'Chat-Name (optional)',
        newChatAddMembers: 'Fügen Sie Kontakte und andere Teilnehmer zu diesem Chat hinzu:',
        editChatAddMembers:
            'Bitte suchen Sie Benutzer aus diesem Ereignis, die Sie dem Gruppenchat hinzufügen möchten.',
        newChatSearchInput: 'Benutzer suchen',
        newChatNoUsersMessage: 'Keine Benutzer gefunden',
        newChatAddUserButton: 'Hinzufügen',
        newChatRemoveUserButton: 'Entfernen',
        newChatCancelButton: 'SCHLIEßEN',
        newChatCreateButton: 'SCHAFFEN',
        membersLeaveButton: 'VERLASSEN',
        membersUpdateButton: 'AKTUALISIEREN',
        membersChatButton: 'Chat',
        chatSettingsButton: 'Einstellungen',
        newMessageInput: 'Nachricht hier eingeben',
        newVideoCallTooltipText: 'Klicken Sie hier, um einen Video-Chat zu starten',
        fullscreenButton: 'Öffnen Sie den Vollbildmodus',
        minimizeButton: 'Bildschirm minimieren',
    },
    myAccountDropdown: {
        title: 'MEIN KONTO',
        viewProfileButton: 'Profil anzeigen',
        logoutButton: 'ABMELDEN',
    },
    myAccountPage: {
        platformProfileSectionTitle: 'Mein Onvent-Profil',
        changePasswordButton: 'PASSWORT ÄNDERN',
        deleteAccountButton: 'MEIN KONTO LÖSCHEN',
        platformLanguage: 'Plattformsprache:',
        reviewPlatformGdprText: 'Überprüfen Sie unsere Onvent-Bedingungen:',
        privacyPolicyLink: 'Datenschutzerklärung / -richtlinie',
        cookiesLink: 'Cookies',
        termsAndConditionsLink: 'Geschäftsbedingungen ',
        eventProfileSectionTitle: 'Veranstaltungsprofil',
        eventPackage: 'Veranstaltungspaket',
        reviewEventGdprText: 'Überprüfen Sie unsere Veranstaltungsbedingungen:',
        unregisterFromEventButton: 'Abmeldung von einer Veranstaltung',
        unregisterCurrentEventButton: 'Abmeldung von der aktuellen Veranstaltung',
        unregisterCurrentEventConfirmation:
            'Bitte bestätigen Sie, dass Sie sich von der Veranstaltung abmelden möchten',
        enableParticipantProfile: 'Teilnehmerprofil aktivieren',
        editUserDialogTitle: 'Benutzerdetails bearbeiten',
        editExhibitorDialogTitle: 'Angaben zur Kontaktperson des Unternehmens bearbeiten',
        editParticipantDialogTitle: 'Teilnehmer-Details bearbeiten',
        firstName: 'Vorname',
        lastName: 'Nachname',
        uploadImage: 'Bild hochladen',
        removeImage: 'Bild entfernen',
        deleteTitle: 'MEIN KONTO LÖSCHEN',
        deleteConfirm: 'Bestätigen Sie das Löschen ihres Kontos',
        deleteError:
            'Sie können Ihr Konto nicht löschen. Bitte löschen Sie alle Ereignisse, die erstellt wurden, um Ihr Konto löschen zu können.',
        deleteSuccess: 'Ihr Konto wurde gelöscht.',
        email: 'E-Mail',
        reviewEventPrivacyPolicy: 'Lesen Sie unsere Veranstaltungs-Datenschutzbestimmungen:',
        title: 'Titel',
        company: 'Unternehmen',
        phone: 'Telefon',
        platformProfileSectionTitleTextSecond: 'Profil',
        reviewPlatformGdprTextFirst: 'Überprüfen Sie unsere',
        reviewPlatformGdprTextSecond: 'Bedingungen',
    },
    changePassword: {
        title: 'PASSWORT ÄNDERN',
        oldPassword: 'Altes Passwort',
        oldPasswordError: 'Altes Passwort stimmt nicht überein!',
        inputPassword: 'Neues Passwort',
        inputConfirmPassword: 'Passwort bestätigen',
        changePasswordButton: 'PASSWORT ÄNDERN',
    },
    roomsDropdown: {
        title: 'RÄUME MENÜ',
        auditoriumButton: 'Auditorium',
        showfloorButton: 'Ausstellungsbereich',
        archiveButton: 'ARCHIVIEREN',
        onDemandButton: 'ON-DEMAND', // new translation
    },
    sideMenu: {
        liveWall: 'Live-Wand',
        networking: 'Vernetzung',
        info: 'INFO',
        videoWall: 'Videowand',
        booth: 'STAND',
        boothWall: 'Standwand',
        resources: 'RESSOURCEN',
        networkingTooltip: 'Sie haben leider keinen Zugriff auf alle Netzwerkfunktionen.',
        polls: 'UMFRAGE',
    },
    wall: {
        sortBy: 'Sortieren nach:',
        popularity: 'BELIEBTHEIT',
        time: 'ZEIT',
        emptyWallText: 'Sei der Erste, der hier einen Beitrag hinzufügt',
        newPostInput: 'Was haben Sie auf dem Herzen?',
        newCommentInput: 'Kommentar',
        resourcesVideoDescription: 'VIDEOBESCHREIBUNG',
        resourcesLinks: 'Links',
        resourcesFiles: 'Dateien',
        whatIsOnYourMind: 'Was haben Sie auf dem Herzen?',
    },
    networking: {
        searchInput: 'Suche nach Benutzer',
        at: 'bei',
        regenerateMatches: 'Übereinstimmungen neu generieren',
    },
    closedAuditoriumDialog: {
        title: 'Informationen zum Auditorium',
        content: 'Der Zugang zum Auditorium wurde eingeschränkt.',
        contentPackage: 'Ihr Zugangspaket hat keinen Zugang zu Auditorien.',
        button: 'OK',
    },
    closedShowfloorDialog: {
        title: 'Präsentationsbühnen-Information',
        content: 'Der Zugang zur Präsentationsbühne wurde eingeschränkt.',
        contentPackage: 'Ihr Zugangspaket hat keinen Zugang zur Präsentationsbühne', //New translation: Your access package doesn't have access to any showfloors.
        button: 'OK',
    },
    auditorium: {
        willStart: 'Die nächste Sitzung beginnt in ...',
        noAccess: 'Ihr Zugangspaket hat keinen Zugang zu diesem Auditorium.',
        noVideos: 'In diesem Auditorium sind keine Videos zum Abspielen geplant',
        stageTab: 'BÜHNE',
        stagesButton: 'BÜHNEN',
        videoWallButton: 'Videowand',
        resourcesButton: 'RESSOURCEN',
        slotEndsTextFirst: 'Diese Sendung endet in',
        slotEndsTextSecond: 'Die Präsentation wird am Ende des Programms auf Anfrage verfügbar sein.',
        slotTitleWith: 'mit',
        allRooms: 'ALLE RAUME',
        onDemandButton: 'ON-DEMAND', // new translation
    },
    auditoriumArchive: {
        title: 'Auditorium Archiv',
        titleEvent: 'Veranstaltungsarchiv',
        with: 'mit',
        stage: 'BÜHNE',
        videoWall: 'Videowand',
        resources: 'RESSOURCEN',
        tooltipMinimize: 'Minimieren',
        tooltipFullscreen: 'Vollbildschirm',
        tooltipPause: 'Pause',
        tooltipPlay: 'Abspielen',
        tooltipUnmute: 'Stummschaltung aufheben',
        tooltipMute: 'Stummschaltung',
        noVideosAvailable: 'All sessions finished', // new translation
    },
    videoPlayer: {
        videoNotPlayingError: 'Haben Sie Probleme mit dem Videostream?',
        videoEndedTextFirst: 'Video ist beendet. Danke fürs Zuschauen!',
        videoEndedTextSecond: 'Die Videowand ist offen für Fragen und Antworten.',
        tooltipPlay: 'Abspielen',
        tooltipPause: 'Pause',
        tooltipMinimize: 'Minimieren',
        tooltipFullscreen: 'Vollbildschirm',
        tooltipMute: 'Stummschaltung',
        tooltipUnmute: 'Stummschaltung aufheben',
    },
    showfloor: {
        allExhibitorsButton: 'ALLE AUSSTELLER', //new translation: Showfloor menu
        featuredExhibitorsText: 'DARGESTELLTE AUSSTELLER',
        boothLinksDialogTitle: 'STAND-LINKS',
        boothLinksDialogOtherLinksTitle: 'Anderes:',
        boothFilesDialogTitle: 'STANDDATEIEN',
        boothVideoDialogTitle: 'VIDEO',
        mobileTabChat: 'Chat',
        mobileTabFiles: 'Dateien',
        mobileTabLinks: 'Links',
        mobileTabVideo: 'VIDEO',
        noExhibitorsTextFirst: 'DIE AUSSTELLER ARBEITEN AN IHREN STÄNDEN',
        noExhibitorsTextSecond: 'BLEIB DRAN',
        filterBy: 'Filtern nach',
        searchByTheUser: 'Suche nach Benutzer',
        showShowfloorsButton: 'ALL EXHIBIT HALLS', //new translation
        searchExhibitors: 'Search exhibitors', // //new translation: Search exhibitors
        showfloorsTitle: 'Exhibit Halls', //new translation: Showfloors
    },
    eventArchive: {
        title: 'Video-Archiv',
        searchText: 'Tippen Sie auf, um zu suchen',
        noVideosText: 'In dieser Veranstaltung wurden keine Videos gefunden.',
        noArchivedEvents: 'Derzeit sind keine archivierten Veranstaltungen vorhanden.',
    },
    exhibitorDashboard: {
        event: 'Veranstaltung',
        title: 'Verwalten Sie Ihren Stand',
        visitorsButton: 'Standbesucher',
        goToBoothButton: 'Bringen Sie mich zum Stand',
        goToBoothButtonDisabled: 'Ihr Stand ist noch nicht zur Vorschau verfügbar.',
        tabStyle: 'Stand-Stil',
        tabLogo: 'Logo',
        tabFiles: 'Dateien',
        tabLinks: 'Links',
        tabAvatars: 'Avatare',
        tabVideo: 'Video',
        boothStyleTab: {
            optionOne: 'Option 1 - Skandinavisch',
            optionTwo: 'Option 2 - Modern',
            optionThree: 'Option 3 - Dynamisch',
            optionFour: 'Option 4 - Benutzerdefiniert',
        },
        logoTab: {
            title: 'Logo',
            fileUploadButton: 'Zum Hochladen hier klicken',
            noFile: 'Keine Datei ausgewählt',
        },
        filesTab: {
            title: 'Dokumente:',
            fileNameInput: 'Dokumentname (für alle Teilnehmer sichtbar)',
            chooseFileButton: 'Datei auswählen',
            noDocAdded: 'Keine hinzugefügten Dokumente',
            fileError: 'Bitte fügen Sie einen Dokumentnamen hinzu',
        },
        linksTab: {
            social: {
                title: 'Soziale Medien Links',
                subtitle: '(sichtbar für alle Teilnehmer)',
                facebookInput: 'Fügen Sie Ihren Facebook-Link ein',
                linkedinInput: 'Fügen Sie Ihren LinkedIn-Link ein',
                twitterInput: 'Fügen Sie Ihren Twitter-Link ein',
            },
            other: {
                title: 'Andere Links',
                subtitle: '(Homepage oder andere Links)',
                linkInput: 'Neuen Link einfügen',
                linkLabel: 'Neues Link-Label einfügen',
            },
        },
        avatarsTab: {
            mainRepresentatives: {
                title: 'Standvertreter',
                subtitle: '(nur ein Vertreter obligatorisch)',
                leftRepresentative: 'Linker Vertreter',
                rightRepresentative: 'Rechter Vertreter',
                addDialog: {
                    title: 'Avatar hinzufügen',
                    contentFirst:
                        'Wählen Sie einen Avatar aus der folgenden Galerie oder laden Sie Ihren persönlichen Avatar hoch.',
                    contentSecond: 'Benötigen Sie Designhilfe? Kontakt aufnehmen:',
                    female: 'Weiblich',
                    male: 'Männlich',
                    uploadSectionTitle: 'Laden Sie Ihren persönlichen Avatar hoch',
                    uploadButton: 'Datei auswählen:',
                    noFile: 'Datei auswählen:',
                    assignText:
                        'Bitte weisen Sie einen Veranstaltungsteilnehmer zu, der mit dem oben genannten Vertreter in Verbindung steht.',
                    input: 'Hier nach Namen suchen',
                    avatarLabels: {
                        femaleA: 'Frau A',
                        femaleB: 'Frau B',
                        femaleC: 'Frau C',
                        maleA: 'Mann A',
                        maleB: 'Mann B',
                        maleC: 'Mann C',
                    },
                },
            },
            additionalRepresentatives: {
                title: 'Zusätzliche Vertreter',
                subtitle: '(Optional, wird mit ihrem Profilbild angezeigt)',
                addDialog: {
                    title: 'Wählen Sie einen zusätzlichen Vertreter',
                    titleScholar: 'Choose Co-Author', //new translation
                    assignText:
                        'Bitte suchen Sie nach einem Veranstaltungsteilnehmer, um ihn/sie als Vertreter hinzuzufügen.',
                    assignError: 'Benutzer ist bereits als Standvertreter hinzugefügt!',
                    chooseAvatarTextFirst:
                        'Wählen Sie einen Avatar aus der folgenden Galerie oder laden Sie Ihren persönlichen Avatar hoch.',
                    chooseAvatarTextSecond: 'Benötigen Sie Designhilfe? Kontakt aufnehmen:',
                    uploadText: 'Laden Sie Ihren persönlichen Avatar hoch',
                    chooseFileText: 'Datei auswählen:',
                    noFile: 'Keine Datei ausgewählt',
                },
            },
            author: {
                title: 'Presenting Author', // new translation
                subtitle: 'Add only an author that registered to the event. You can add 1 author.', // new translation
                mainRepresentative: 'Main representative', // new translation
            },
            coAuthors: {
                title: 'Co-Authors', // new translation
                subtitle: 'Add only co-authors that registered to the event. You can add up to 4 co-authors.', // new translation
            },
        },
        videoTab: {
            video: {
                title: 'Standvideo',
                subtitle: '(Nur Youtube-Videolink)',
                input: 'Youtube-URL eingeben',
            },
            image: {
                title: 'Bild',
                subtitle:
                    '(Das Bild wird in Ihrem Stand angezeigt, falls Sie im obigen Abschnitt keinen Videolink hinzugefügt haben.)',
                uploadButton: 'Zum Hochladen hier klicken',
            },
        },
    },
    boothDashboard: {
        event: 'Veranstaltung',
        title: 'Ihr Messestand',
        manage: 'Verwalten',
        goToBoothButton: 'Bringen Sie mich zum Stand',
        previewNotAvailable: 'Ihr Stand ist noch nicht zur Vorschau verfügbar.',
        statistics: 'Statistiken',
        usersNow: 'Derzeitige Teilnehmer ',
        usersAllTime: 'Ganzzeitige Teilnehmer',
        linkClicks: 'Linkklicks',
        documentViews: 'Dokumentansichten',
        videoViews: 'Videopräsentation gesehen',
        visitorsSectionTitle: 'Standbesucher',
        noVisitors: 'Es sind noch keine Besucher an Ihrem Stand.',
        visitorsSearch: 'Benutzer suchen',
        actionType: 'AKTIONSTYP',
        showUsers: 'BENUTZER ANZEIGEN',
        ofUsers: 'ANZAHL DER BENUTZER',
    },
    polls: {
        polls: 'UMFRAGEN',
        poll: 'UMFRAGE',
        noPolls: 'Es wurden noch keine Umfragen hinzugefügt.',
        pollProgress: 'Umfrage läuft',
        pollClosed: 'Umfrage geschlossen',
        pollVoted: 'Teilnehmer stimmten ab',
    },
    onDemandVideos: {
        sectionTitle: 'ON-DEMAND', // new translation
        noOnDemandVideos: 'No on demand videos were added yet.', // new translation
    },
    meetings: {
        meeting: 'TREFFENS',
        meetings: 'TREFFENS',
        createDialog: {
            createTitle: 'Um ein Treffen ersuchen  mit',
            inputDate: 'Datum',
            inputStart: 'Beginnt',
            inputEnd: 'Endet',
            inputTimezone: 'Zeitzone',
            inputNote: 'Hinweis: Es kann jeweils nur ein Treffen mit derselben Person angesetzt werden.',
            meetingError: 'Ungültiges Datum oder Uhrzeit der Besprechung. Bitte erneut prüfen!',
            buttonDisabled: 'Sie können eine Besprechung planen, nachdem Sie das Gespräch begonnen haben!',
        },
        banner: {
            pending: 'Treffensanfrage',
            accepted: 'Treffen geplant für',
            progress: 'Laufendes Treffen',
            cancelTitle: 'Treffen annulieren?',
            cancelDescription: 'Wollen Sie wirklich  dieses Treffen annulieren?',
            startCall: 'ANRUF STARTEN',
            joinCall: 'ANMELDEN',
            pendingOwner: 'Ausstehende Besprechungsanfrage für',
            videoCallStarted: 'Die Videobesprechung hat begonnen',
        },
        newMeeting: 'NEUES TREFFEN',
        requestMeeting: 'Treffen mit anfordern',
        startDate: 'Anfangsdatum',
        starts: 'Startet',
        ends: 'Endet',
        addMessageHere: 'Fügen Sie hier Ihre Nachricht hinzu',
        note: 'Hinweis: Sie können nur einen Termin mit einer bestimmten Person beantragen. Nachdem der Termin beendet (oder abgesagt) wurde, können Sie den nächsten Termin erneut planen',
        create: 'ERSTELLEN',
        requested: 'Beantragt',
        confirmed: 'Bestätigt',
        title: 'Ihre Besprechungen',
        meetingsInfo:
            'Besprechungen können über Chats geplant werden. Bitte starten Sie einen Chat mit dem Benutzer, mit dem Sie eine Besprechung planen möchten.',
    },
    landingPage: {
        animatedSectionButton: 'REISE STARTEN',
        topSectionHeaderPrimary: 'Nächste Generation',
        topSectionHeaderSecondary: 'Online-Veranstaltungen',
        carouselPresentationText: 'Auschecken',
        carouselPresentationTextAccent: 'NEU Neural Network',
        videoPresentationTextPrimary: 'Mein Onvent - Online-Veranstaltungen die Ihre ',
        videoPresentationTextAccent: 'Teilnehmer lieben werden.',
        videoPresentationTextSecondary: 'Hier ist warum:',
        testimonialsTextPrimary: 'Online-Veranstaltungen seit 2015 -',
        testimonialsTextAccent: 'Es geht nur um die Menschen',
        testimonialsTextSecondary: '…',
        cardsTextPrimary: 'Neural Network bringt',
        cardsTextAccent: 'das WOW',
        cardsTextSecondary: 'zu…',
        contactTextPrimary: 'Interessiert eine Online-Veranstaltung organisieren?',
        contactTextAccent: 'Kontakt aufnehmen',
        contactTextSecondary: '',
        contactInputName: 'Name',
        contactInputEmail: 'E-Mail',
        contactInputPhone: 'Handynummer (optional)',
        contactInputMessage: 'Mitteilung',
        contactButton: 'SENDEN',
        carouselPresentationTextAccentTextFirst: 'NEU',
        videoPresentationTextPrimaryTextSecond: 'Online-Ereignisse, die Ihr',
        cardsTextPrimaryTextSecond: 'bringt',
    },
    loginPlatform: {
        title: 'ANMELDEN',
        description:
            'Nach dem Anmelden werden Sie zu der letzten Veranstaltung weitergeleitet, bei der Sie sich registriert haben. Mit der Option Meine Veranstaltungen in Ihrem Menü können Sie problemlos zwischen Veranstaltungen wechseln.',
        descriptionTextFirst: 'Nach dem Anmelden werden Sie  weitergeleitet zum letzten',
        descriptionTextSecond: 'zu dem Sie sich bei registriert  haben. Sie können leicht wechseln zwischen ',
        descriptionTextThird: 'mit der Alternative Meine Ereignisse in Ihrem Menü.',
    },
    skipped: {
        startVideoChat: 'Ich habe eine Videokonferenz angesetzt. Bitte klicken Sie hier, um teilzunehmen.',
    },
    hubDropdownTitle: 'ZENTRUM MENÜ',
    marketplace: {
        title: {
            default: 'Marktplatz',
            uppercased: 'MARKTPLATZ',
        },
        label: 'PRODUKTE',
        addNew: 'NEUE HINZUFÜGEN',
        addNewProduct: 'Neues Produkt hinzufügen',
        editProduct: 'Produkt bearbeiten',
        noProducts: 'Noch keine Produkte',
        publish: 'VERÖFFENTLICHEN',
        productTitle: 'Produktname',
        productDescription: 'Produktbeschreibung',
        productLink: 'Produktlink',
        productImage: 'PRODUKTBILD',
        uploadHint:
            'Laden Sie ein Bild hoch, das als Produktvorschau angezeigt wird. Vorgeschlagene Auflösung von 600 * 600 px für eine beste Bildqualität',
        descriptionHint: 'Die Beschreibung ist auf 300 Zeichen begrenzt',
        uploadImage: 'LADEN SIE EIN BILD HOCH, UM DEN WERT DES PRODUKTS ZU ERHÖHEN',
        uploaded: 'Hochgeladen',
    },
    personalAgenda: {
        agenda: 'TAGESORDNUNG',
        myProgram: 'MEIN PROGRAMM',
        myMeetings: 'MEINE TERMINE',
        addNewTask: {
            default: 'Neue Aufgabe hinzufügen',
            uppercased: 'NEUE AUFGABE HINZUFÜGEN',
        },
        noProgram: 'Derzeit sind keine Programmeinträge verfügbar',
        noMeetings: 'Derzeit sind keine Termine verfügbar',
        title: 'Titel',
        startDate: 'Anfangsdatum',
        starts: 'Startet',
        ends: 'Endet',
        create: 'ERSTELLEN',
        schedule: 'ZEITPLAN',
        personalAgenda: 'PERSÖNLICHE TAGESORDNUNG',
        timetableMenu: 'ZEITPLAN MENÜ',
        gmt: 'mittlere Greenwich-Zeit',
    },
    program: {
        toAgenda: 'ZU AGENDA',
        attending: 'TEILNAHME',
    },
    businessCard: {
        businessCard: {
            default: 'Visitenkarte',
            uppercased: 'VISITENKARTE',
        },
        editText: 'TEXT BEARBEITEN',
        colors: 'FARBEN',
        branding: 'MARKENBILDUNG',
        cardPreview: 'KARTENVORSCHAU',
        onceSaved:
            'Nach dem Speichern wird die Visitenkarte immer mit Ihrem Profil verbunden. Sie erhalten Zugriff auf Netzwerk, Chat und weitere wichtige Funktionen. Wenn Sie Ihr Profil löschen, wird die Karte wird automatisch gelöscht.',
        required: 'erforderlich',
        designation: 'Bezeichnung',
        Mobile: 'Handy',
        Phone: 'Telefon',
        Website: 'Webseite',
        companyName: 'Name der Firma',
        honoric: 'Fügen Sie Ihren bevorzugten Titel wie Herr, Frau, Ser, Scheich usw. Hinzu',
        fillUpData: 'Füllen Sie die Daten von meinem Konto aus',
        primaryColor: 'PRIMÄRFARBE',
        primaryColorDescription: 'Diese Farbe ist die Akzentfarbe. Verwenden Sie hier Ihre Markenfarbe',
        backgroundColor: 'HINTERGRUNDFARBE',
        backgroundColorDescription:
            'Diese Farbe ist die zusätzliche Farbe. Fügen Sie es als Hintergrund hinzu. Standardmäßig kann es weiß oder schwarz sein',
        backgroundTextColor: 'HINTERGRUNDTEXT & IKON FARBE',
        backgroundTextColorDescription:
            'Diese Farbe für die Telefone, E-Mail- und Webseiten-Details muss im Kontrast zur Hintergrundfarbe stehen. Gehen Sie zur Kartenvorschau, um sicherzustellen, dass die Karte lesbar ist und einen ausreichenden Kontrast aufweist',
        companyLogo: 'FIRMENLOGO',
        uploadMessage:
            'Laden Sie ein Bild hoch, das als Firmenlogo angezeigt wird. Vorgeschlagene Auflösung von 320 * 200 px für eine bessere Bildqualität. Verwenden Sie ein Logo mit transparentem Hintergrund (das PNG-Dateiformat ist vorzuziehen).',
        uploadImage: 'BILD HOCHLADEN',
        request: 'ANFRAGE',
        open: 'ÖFFNEN',
        pending: 'UNERLEDIGT ',
    },
    businessWallet: {
        short: 'B Brieftasche',
        full: 'BUSINESS BRIEFTASCHE',
        myContacts: 'MEINE KONTAKTE',
        requests: 'ANFRAGEN',
        pendings: 'UNERLEDIGTES',
        searchByName: 'Suche mit Name',
        chat: 'Chat',
        openCard: 'OPEN CARD',
        empty: 'Noch niemand hier',
        cancelRequest: 'ANFRAGE ABBRECHEN',
        approve: 'GENEHMIGEN',
        reject: 'ABLEHNEN',
        myNotes: 'MEINE NOTIZEN',
        addNewNote: 'Neue Notiz hinzufügen',
        delete: 'LÖSCHEN',
        saveThoughts: 'Speichern Sie Ihre Ideen gut organisiert an einem Platz',
    },
    videoConferences: {
        message: 'Ich habe einen Video-Besprechungsraum eingerichtet. Bitte klicken Sie hier, um daran teilzunehmen',
        clickToStart: 'Klicken Sie hier, um einen Video-Chat zu starten',
        fullScreen: 'VOLLBILDSCHIRM ÖFFNEN',
        minimizeScreen: 'BILDSCHIRM MINIMIEREN',
    },
    sessions: {
        sessionList: 'SITZUNGSLISTE',
        createNewSession: {
            uppercased: 'NEUE SITZUNG ERSTELLEN',
            default: 'Neue Sitzung erstellen',
        },
        sessionName: {
            uppercased: 'SITZUNGSNAME',
            default: 'Sitzungsname',
        },
        sessionLink: 'SITZUNGS-LINK',
        sessionType: 'SITZUNGSTYP',
        manage: 'VERWALTEN',
        edit: 'BEARBEITEN',
        delete: 'LÖSCHEN',
        live: {
            uppercased: 'LIVE ',
            lowercased: 'live',
            default: 'Live',
        },
        recorded: {
            uppercased: 'AUFGEZEICHNET',
            lowercased: 'aufgezeichnet',
            default: 'Aufgezeichnet',
        },
        editSession: 'Sitzung bearbeiten',
        uploadVideo: 'VIDEO HOCHLADEN',
        create: 'ERSTELLEN',
        discard: 'VERWERFEN',
        speakers: 'LAUTSPRECHER',
        moderators: 'MODERATOREN',
        speakerName: 'LAUTSPRECHERNAME',
        moderatorName: 'MODERATORNAME',
        kick: 'ANTRETEN',
        screenSharing: 'BILDSCHIRM TEILEN',
        audio: 'AUDIO',
        video: 'VIDEO',
        startStream: 'STREAM STARTEN',
        stopStream: ' STREAM STOPPEN',
        startRecording: 'AUFNAHME STARTEN',
        endRecording: 'AUFNAHME BEENDEN ',
        sessionChat: 'CHAT-SITZUNG ',
        typeMessageHere: 'Geben Sie hier eine Nachricht ein',
        sessionLinks: 'SITZUNG-LINKS',
        speakerUrl: 'Sprecher-URL',
        moderatorUrl: 'Moderator-URL',
        copyUrl: 'URL kopieren',
        organizer: 'VERANSTALTER',
        moderator: 'MODERATOR',
        speaker: 'LAUTSPRECHER',
        joinAsSpeaker: 'Als Redner anschließen',
        invitedAsSpeaker:
            'Sie wurden als Redner zur AIM Sitzungs-Demo eingeladen. Geben Sie Ihren Namen ein (er ist für die Teilnehmer der Sitzung sichtbar).',
        yourName: 'Dein Name',
        enter: 'EINGEBEN',
        waitForApproval: 'Bitte warten Sie auf die Genehmigung',
        deny: 'VERWEIGERN',
        admit: 'EINGESTEHEN',
        joinAsModeraotr: 'Treten Sie als Moderator bei',
        invitedAsModerator:
            'Sie wurden als Moderator zur AIM Sitzungs-Demo eingeladen. Geben Sie Ihren Namen ein (er ist für die Teilnehmer der Sitzung sichtbar).',
        declined: 'Hoppla, du wurdest irgendwie abgelehnt',
    },
    breadcrumbsNavigation: {
        mainLobby: 'Hauptlobby',
        village: 'Dorf',
    },
    village: {
        clickToOpen: 'Klicken Sie zum Öffnen',
    },
    gdpr: {},
};

export default de;

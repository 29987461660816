const fr = {
    generalText: {
        upload: 'Télécharger',
        save: 'Sauvegarder',
        add: 'Ajouter',
        remove: 'Supprimer',
        close: 'FERMER',
        update: 'MISE À JOUR',
        cancel: 'ANNULER',
        confirm: 'Confirmer',
        download: 'Télécharger',
        refresh: 'Rafraîchir',
        back: 'Retour',
        submit: 'Soumettre',
        send: 'Envoyer',
        leave: 'Quitter',
        step1: 'Étape 1',
        step2: 'Étape 2',
        or: 'ou',
        delete: 'Supprimer',
        please: "S'il vous plaît",
        before: 'avant',
        approve: 'Accepter',
        decline: 'Refuser',
        start: 'Commencer',
        stop: 'Arrêter',
        resetFilter: 'Réinitialiser Filtre',
        apply: 'Appliquer',
        filters: 'Filtres',
        unsavedChangesTitle: 'Changement non enregistrés',
        unsavedChangesText: 'Vous avez des changements non enregistrés. Voulez-vous les enregistrer ?',
        selectAll: 'Tout sélectionner',
        unselectAll: 'Tout déselectionner',
    },
    time: {
        justNow: 'à l’instant',
        minute: 'minute',
        minutes: 'minutes',
        hour: 'heure',
        hours: 'heures',
        day: 'jour',
        days: 'jours',
        month: 'mois',
        months: 'mois',
        yesterday: 'hier',
        today: "aujourd'hui",
        tomorrow: 'demain',
        monday: 'lundi',
        tuesday: 'mardi',
        wednesday: 'Mercredi',
        thursday: 'Jeudi',
        friday: 'Vendredi',
        saturday: 'samedi',
        sunday: 'dimanche',
        january: 'janvier',
        february: 'février',
        march: 'Mars',
        april: 'avril',
        may: 'Mai',
        june: 'juin',
        july: 'juillet',
        august: 'août',
        september: 'septembre',
        october: 'octobre',
        november: 'novembre',
        december: 'décembre',
        januaryShort: 'jan',
        februaryShort: 'fév',
        marchShort: 'Mar',
        aprilShort: 'avr',
        mayShort: 'Mai',
        juneShort: 'jui',
        julyShort: 'jui',
        augustShort: 'aoû',
        septemberShort: 'sep',
        octoberShort: 'oct',
        novemberShort: 'nov',
        decemberShort: 'déc',
    },
    errors: {
        noFile: 'Aucun dossier choisi',
        required: 'Champ obligatoire',
        emailNotValid: 'Courriel non valide',
        passwordMinLength: 'Le mot de passe doit comporter au moins 8 caractères',
        passwordMismatch: 'Erreur de mot de passe',
        passwordIsSame: 'Your new password must be different',
        noInvitationCode: "Veuillez entrer votre code d'invitation",
        imageFileNotSupported:
            "Type de fichier non pris en charge. Veuillez utiliser l'un des types de fichiers suivants :",
        phoneInvalid: 'Veuillez entrer un numéro de téléphone valide. Par exemple +447517663928',
        linkInvalid: 'Veuillez entrer un lien valide (https://site.com)',
        facebookLinkInvalid: 'Veuillez entrer un lien valide (https://facebook.com/)',
        linkedinLinkInvalid: 'Veuillez entrer un lien valide (https://linkedin.com/)',
        twitterLinkInvalid: 'Veuillez entrer un lien valide (https://twitter.com/)',
        youtubeLinkInvalid: 'Veuillez entrer un lien valide (https://www.youtube.com/)',
        googleScholarLinkInvalid: 'Veuillez entrer un lien valide (https://scholar.google.com/)',
        researchGateLinkInvalid: 'Veuillez entrer un lien valide (https://www.researchgate.net/)',
        connectionProblemTextFirst: 'De nouvelles mises à jour sont disponibles. Veuillez cliquer',
        connectionProblemButton: 'CLIQUEZ',
        connectionProblemTextSecond: 'ou rafraîchir la page.',
        fileTooLarge: 'Fichier trop large. 2Mb taille maximale du fichier',
        fileTooLargeTextFirst: 'Fichier trop large.',
        fileTooLargeTextSecond: 'taille maximale du fichier.',
        maxWallInputLength: 'Vous avez atteint le nombre maximum de 1200 caractères.',
    },
    footer: {
        cookiesText:
            'Ce site web utilise des cookies essentiels,qui sont nécessaires à son fonctionnement elle utilise également des cookies de tiers,  qui sont nécessaires au fonctionnement de certaines fonctionnalités de notre site web. Si vous souhaitez en savoir plus ou retirer votre consentement à certains cookies, veuillez vous référer à notre',
        cookiesLink: 'Paramètrer les Cookies',
        cookiesButton: 'ACCEPTER',
        privacyPolicy: 'Politique de confidentialité',
        cookies: 'Cookies',
        termsAndConditions: 'Conditions générales',
        socialLinksTitle: 'Suivez-nous :',
    },
    menu: {
        logIn: 'CONNEXION',
        logOut: 'DÉCONNEXION',
        scholarRegistration: 'Poster / Study Registration',
        exhibitorRegistration: 'Etre exposant',
        participantRegistration: "S'INSCRIRE",
        createEvent: "Organiser l'événement",
    },
    homepage: {
        organizedBy: 'ORGANISÉ PAR',
        date: 'DATE',
        location: 'DOMAINE ET LIEU',
        price: 'PRIX',
        tabIntro: 'INTRO',
        tabProgram: 'PROGRAMME',
        tabSponsors: 'SPONSORS',
        tabPartners: 'PARTENAIRES',
        tabExhibitors: 'EXPOSANTS',
        previousEventsSectionTitle: 'ÉVÉNEMENTS PRÉCÉDENTS',
    },
    socialButtons: {
        linkedinButton: 'Connectez-vous avec LinkedIn',
        loginTabLinkedinButton: 'avec LinkedIn',
        facebookButton: 'Connectez-vous avec Facebook',
        loginTabFacebookButton: 'avec Facebook',
        googleButton: 'Connectez-vous avec Google',
        loginTabGoogleButton: 'avec Google',
        twitterButton: 'Connectez-vous avec Twitter',
        loginTabTwitterButton: 'avec Twitter',
        socialButtonsLogInTitle: 'Connectez-vous avec votre profile de réseau social',
        socialButtonsSignUpTitle: '',
    },
    login: {
        inputEmail: 'Courriel',
        inputPassword: 'Mot de passe',
        rememberMeButton: 'Se souvenir de moi',
        forgotPasswordButton: 'Mot de passe oublié ?',
        loginButton: 'CONNEXION',
        loginTitleLowerCase: 'connexion',
        recoverPasswordDialogTitle: 'RÉCUPÉRER LE MOT DE PASSE',
        recoverPasswordDialogRecoverButton: 'RÉCUPÉRER',
        errorIncorrectDates: 'Email ou mot de passe incorrect',
        orLogInWithEmail: 'ou connecter avec email',
        logInAsParticipantSecondText: 'en tant que participant',
        logInAsExhibitorSecondText: "en tant qu'exhibitant",
        logInAsPosterSecondText: "en tant qu'étude / affiche",
        errorAccountNotFound: 'Nous ne trouvons pas votre compte Neural Network',
    },
    register: {
        inputFirstName: 'Prénom',
        inputLastName: 'Nom de famille',
        inputEmail: 'Courriel',
        inputPassword: 'Mot de passe',
        inputConfirmPassword: 'Confirmer le mot de passe',
        gdprTextFirst: 'En procédant ainsi, vous acceptez notre',
        gdprTextTerms: "Conditions d'utilisation",
        gdprTextSecond: 'et confirmez que vous avez lu notre',
        gdprTextCookies: 'Cookie',
        gdprTextThird: 'et',
        gdprTextPrivacy: 'Politique de confidentialité',
        gdprTextAgree: 'Veuillez accepter la politique de confidentialité',
        registerButton: "S'inscrire",
        signUpTitleLowerCase: "s'inscrire",
        invitationCode: "Code d'invitation",
        registerNotAllowed: 'Inscription fermée',
        logInTitleFirst: 'Rejoindre',
        logInTitleSecond: 'événement',
        signUpTitleTextFirst: 'Première fois à',
        signUpTitleTextSecond: 'Neural Network',
        orFillInTheForm: 'ou remplissez le formulaire ci-dessous',
        uploadProfilePicture: 'Télécharger photo de profile',
        editProfilePicture: 'Modifier photo de profile',
        errorAlreadyHaveMOAccount: 'Vous avez déjà un compte Neural Network.',
        step1From2Text: 'Étape 1/2',
        step2From2Text: 'Étape 2/2',
        metaTitleScholarRegistration: 'Inscription pour Étude / Affiche',
    },
    participantRegistration: {
        metaTitle: 'Inscription pour participant',
        choosePackageTitle: "CHOISIR LE PACKAGE D'ACCÈS",
        noPackageText: 'Veuillez sélectionner un paquet',
        gdprTextFirst: 'En procédant ainsi, vous acceptez notre',
        gdprTextPrivacy: 'Politique de confidentialité',
        gdprTextSecond: 'et',
        gdprTextTerms: "Conditions de l'événement",
        gdprTextAgree: 'Veuillez accepter la politique de confidentialité',
        registerButtonFree: 'Inscription complète',
        registerButtonPay: 'Procéder au paiement',
    },
    exhibitorRegistration: {
        metaTitle: 'Inscription pour exhibitant',
        choosePackageTitle: "CHOISIR LE PACKAGE D'ACCÈS",
        gdprTextFirst: 'En procédant ainsi, vous acceptez notre',
        gdprTextPrivacy: "Paramètrer de confidentialité L'événements",
        gdprTextSecond: 'et',
        gdprTextTerms: "Conditions de l'événement",
        gdprTextAgree: 'Veuillez accepter la politique de confidentialité',
        registerButtonFree: 'Inscription complète',
        registerButtonPay: 'Procéder au paiement',
    },
    stripePayment: {
        cardNumber: 'Numéro de la carte',
        expirationDate: "Date d'expiration  (MM/YY)",
        cvc: 'CVC',
        disclaimerText:
            'Cette opération de paiement est cryptée de bout en bout. La sécurité et la confidentialité sont garanties par Stripe Services.',
        disclaimerTextLink: 'Cliquez ici pour en savoir plus.',
        cancelButton: 'ANNULER',
        payButton: 'Payer',
    },
    recoverPassword: {
        recoverTextSend: 'Le courriel de récupération du mot de passe a été envoyé avec succès.',
    },
    unsubscribe: {
        unsubscribeTitle: 'Se désinscrire ?',
        unsubscribeHomepage: "Aller sur la page d'accueil",
    },
    notAvailable: {
        title: "Cet événement n'est pas disponible",
        description:
            "L'événement que vous recherchez n'est pas disponible. Il a soit été fermé par l'organisateur, soit désactivé par un administrateur",
        fullOverlayEvents: 'Voir vos autres événements',
        fullOverlayNoEvents: 'Aucun autre événement trouvé',
    },
    notFound: {
        notFoundDescription: 'Oups... Aucun événement à trouver ici !',
        notFoundHomepage: "Aller sur la page d'accueil",
    },
    eventMenu: {
        organizerButton: 'ORGANISATEUR',
        coOrganizerButton: 'GÉRER',
        exhibitorButton: 'EXPOSANT',
        posterStudyButton: 'ÉTUDE / AFFICHE',
        boothButton: 'BOOTH',
        boothsButton: 'BOOTHS',
        homeButton: 'ACCUEIL',
        contactsButton: 'CONTACTS',
        meetingsButton: 'RÉUNIONS',
        chatsButton: 'CHATS',
        programButton: 'PROGRAMME',
        archiveButton: 'ARCHIVE',
        myAccountButton: 'MON COMPTE',
        lobbyButton: 'Lobby',
        roomsButton: 'SALLES',
        connectionProblemTextFirst: 'Il semble y avoir un problème de connexion. Veuillez cliquer sur',
        connectionProblemButton: 'ici',
        connectionProblemTextSecond: 'ou rafraîchir la page.',
        sessionProblemTextFirst: 'Votre session est terminée. Veuillez cliquer sur',
        sessionProblemButton: 'ici',
        sessionProblemTextSecond: 'pour se reconnecter.',
    },
    myEventsDropdown: {
        title: 'MES ÉVÉNEMENTS',
        createButtonDesktop: 'Créer un événement',
        createButtonMobile: 'CREER',
    },
    myBoothsDropdown: {
        title: 'MES STANDS',
        emptyStudyTitle: "STAND D'ÉTUDE / AFFICHE",
    },
    contactsDropdown: {
        title: 'VOS CONTACTS',
        requestsTitle: 'Demandes de contact',
        rejectButton: 'REJETER',
        acceptButton: 'ACCEPTER',
        pendingButton: 'En attente',
        addButton: 'Ajouter',
        noContacts:
            'Vous n\'avez pas encore de contacts. Veuillez consulter la section "Mise en réseau" pour commencer à établir votre liste de contacts.',
    },
    meetingsDropdown: {
        meetingWith: 'Réunion avec',
        pending: 'En attente',
        accepted: 'Accepté',
        canceled: 'Annulé',
        noMeetings: "Aucune réunion prévue pour l'instant",
        finished: 'Finie',
    },
    programDropdown: {
        with: 'avec',
        noProgram: 'pas de programme',
    },
    notificationsDropdown: {
        title: 'Notifications',
        contactRequestReceived: 'vous a envoyé une demande de contact. Consultez le site !',
        contactRequestAccepted: 'a accepté votre demande de contact. Commencez à discuter !',
        meetingRequestReceived: 'vous a envoyé une demande de rendez-vous. Consultez le site !',
        meetingRequestAccepted: 'a accepté votre demande de réunion. Vérifiez !',
        meetingRequestCanceled: 'a annulé une demande de réunion. Regardez ça !',
        meetingStarting: 'Une réunion va bientôt commencer. Regardez ça !',
        newBoothPost: 'a affiché sur le mur de votre stand. Vérifiez !',
        noNotifications: "Vous n'avez pas encore de notifications.",
        seeAll: 'VOIR TOUT',
        hideAll: 'CACHER TOUT',
        meetingWillStartTextFirst: 'Une rencontre avec',
        meetingWillStartTextSecond: 'va bientôt commencer. Vérifiez-le!',
    },
    chatsDropdown: {
        title: 'CHATS',
        newChatProvideText: 'Veuillez donner un nom pour votre chat. Vous pourrez modifier le nom par la suite :',
        noChats: "Créer au moins un chat pour parler aux participants à l'événement",
        members: 'Membres',
        noOpenedChatText: 'Cliquez sur un chat pour poursuivre la conversation.',
        noMessagesInChatTextFirst: 'Brisez la glace.',
        noMessagesInChatTextSecond: 'Soyez celui qui commcence cette conversation.',
        newChatNameInput: 'Nom du chat (facultatif)',
        newChatAddMembers: "Ajoutez des contacts et d'autres participants à ce chat :",
        editChatAddMembers:
            'Veuillez rechercher les utilisateurs de cet événement que vous souhaitez ajouter à la discussion de groupe.',
        newChatSearchInput: 'Rechercher des utilisateurs',
        newChatNoUsersMessage: 'Aucun utilisateur trouvé',
        newChatRemoveUserButton: 'Supprimer',
        newChatCancelButton: 'FERMER',
        newChatCreateButton: 'CREER',
        membersLeaveButton: 'QUITTER',
        membersUpdateButton: 'MISE À JOUR',
        membersChatButton: 'Chat',
        chatSettingsButton: 'Paramètres',
        newMessageInput: 'Tapez votre message ici',
        newVideoCallTooltipText: 'Cliquez ici pour démarrer un chat vidéo',
        leaveVideoMeetingTitle: 'Quitter la conférence vidéo',
        leaveVideoMeetingDescription: 'Vous allez quitter la conférence vidéo. Êtes vous sur de vouloir quitter?',
    },
    myAccountDropdown: {
        title: 'MON COMPTE',
        viewProfileButton: 'Voir profil',
        logoutButton: 'DÉCONNEXION',
    },
    myAccountPage: {
        changePasswordButton: 'CHANGER DE MOT DE PASSE',
        deleteAccountButton: 'SUPPRIMER MON COMPTE',
        platformLanguage: 'Choisissez votre langue :',
        privacyPolicyLink: 'Politique de confidentialité',
        cookiesLink: 'Cookies',
        termsAndConditionsLink: 'Conditions générales',
        eventProfileSectionTitle: 'Récapitulatif de mon profil',
        eventPackage: "Paquet d'événements",
        unregisterFromEventButton: "SE DÉSINSCRIRE DE L'ÉVÉNEMENT",
        unregisterCurrentEventButton: "Se désinscrire de l'événement en cours",
        unregisterCurrentEventConfirmation: "Veuillez confirmer que vous souhaitez vous désinscrire de l'événement",
        enableParticipantProfile: 'Activer le profil du participant',
        editUserDialogTitle: "Modifier les détails de l'utilisateur",
        editExhibitorDialogTitle: 'Modifier les coordonnées de la personne de contact de la société',
        editScholarDialogTitle: "Modifier les coordonnées de la personne de contact de l'Étude / Affiche",
        editParticipantDialogTitle: 'Modifier les détails du participant',
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        uploadImage: "Télécharger l'image",
        removeImage: "Supprimer l'image",
        deleteTitle: 'SUPPRIMER MON COMPTE',
        deleteConfirm: 'Confirmer la suppression de mon compte',
        deleteError:
            'Vous ne pouvez pas supprimer votre compte. Veuillez supprimer tous les événements créés pour pouvoir supprimer votre compte.',
        deleteSuccess: 'Votre compte a été supprimé.',
        reviewEventPrivacyPolicy: 'Consultez notre politique de confidentialité :',
        title: 'Titre',
        company: 'Entreprise',
        phone: 'Téléphone',
        platformProfileSectionTitleTextSecond: 'profil',
        reviewPlatformConditions: 'Consultez les conditions de notre platforme',
        reviewEventConditions: 'Consultez les conditions de cet événement',
    },
    changePassword: {
        title: 'CHANGER DE MOT DE PASSE',
        oldPassword: 'Ancien mot de passe',
        oldPasswordError: "L'ancien mot de passe ne correspond pas !",
        inputPassword: 'Nouveau mot de passe',
        inputConfirmPassword: 'Confirmer le mot de passe',
    },
    roomsDropdown: {
        title: 'MENU DES SALLES',
        auditoriumButton: 'Auditorium',
        archiveButton: 'ARCHIVE',
        onDemandButton: 'SUR DEMANDE',
    },
    sideMenu: {
        liveWall: 'Mur en direct',
        networking: 'Mise en réseau',
        audience: 'AUDITOIRE',
        info: 'INFO',
        videoWall: 'Mur vidéo',
        booth: 'BOOTH',
        boothWall: 'Mur des conversation',
        networkingTooltip: "Vous n'avez pas accès à toutes les fonctionnalités de mise en réseau.",
        attendees: 'Participants',
    },
    wall: {
        sortBy: 'Trier par :',
        popularity: 'POPULARITÉ',
        time: 'TEMPS',
        emptyWallText: 'Soyez le premier à ajouter un poste ici',
        newPostInput: 'À quoi pensez-vous ?',
        newCommentInput: 'Commentaire',
        resourcesVideoDescription: 'VIDÉO DESCRIPTION',
        resourcesLinks: 'Liens',
        resourcesFiles: 'Fichiers',
        pinTitle: 'Épinglé',
        unPinTitle: 'Désépinglé',
        deleteDialogTitle: 'Supprimer le message?',
        pinDialogDescription:
            "Il y a déjà un message épinglé. Qu'un seul message peut être épinglé à la fois. En continuant, le message précédent sera automatiquement désépinglé.",
        edited: 'Édité',
        escapeToCancel: 'échap. pour annuler',
        enterToSave: 'entrée pour sauver',
        editingState: 'Modification du message',
        bannerModeration: 'Les messages et commentaires sur ce mur sont modérés pour garantir un contenu de qualité',
        pendingApproval: 'En attente de validation',
        approvePost: 'Valider la publication',
        approvePostComment: 'Valider le commentaire',
        commentsPendingApproval: 'Commentaire en attente de validation',
    },
    't tell what’s on your mind or briefly introduce yourself"': 'Partagez un commentaire ou presentez-vous brièvement',
    networking: {
        searchInput: 'Recherche par utilisateur',
        emptyNetworkingSearchResults: "La personne que vous recherchez ne s'est pas inscrite à cet événement en ligne",
        emptyAuditoriumNetworkingSearchResults:
            "La personne que vous recherchez n'a pas encore assisté à cette présentation",
        alphabetical: 'abc',
        online: 'en ligne',
        matching: 'connexions',
        matchingAlgo:
            "Algorithme de connexion en action. Veuillez patienter une minute pendant que nous trouvons d'autres profils qui vous correspond le mieux.",
        searchFilters: 'Filtres de recherche',
        applyFilterTooltip: 'Pour appliquer des filtres, sélectionnez au moins 1 option.',
        chat: 'Chat',
        regenerateMatches: 'régénérer les correspondances',
    },
    closedAuditoriumDialog: {
        title: "Informations sur l'auditorium",
        contentPackage: "Votre package d'accès n'a pas accès aux auditoriums.",
    },
    closedShowfloorDialog: {
        title: 'Informations sur le showfloor',
        contentPackage: "Votre package d'accès n'a pas accès au showfloor.",
        button: 'OK',
    },
    auditorium: {
        willStart: 'La prochaine séance débutera dans...',
        noAccess: "Votre package d'accès n'a pas accès à cet auditorium.",
        noVideos: "Aucune vidéo n'est prévue dans cet auditorium.",
        stageTab: 'STAGE',
        videoWallButton: 'Mur vidéo',
        resourcesButton: 'RESSOURCES',
        slotEndsTextFirst: 'Ce programme se termine par',
        slotEndsTextSecondUpdated: 'Cette presentation sera disponible sur demande. ',
        slotTitleWith: 'avec',
        allAuditoriums: 'Tout les auditorium',
        onDemandButton: 'SUR DEMANDE',
        hide: 'Cacher',
        leavePopupTitle: "Quitter l'auditorium?",
        leavePopupDescription: "En quittant l'auditorium, vous quitterez la session",
        leaveSession: "Quitter l'auditorium",
        cancelDescription: 'En quittant, vous ne serez plus conférencier. Voulez-vous continuer?',
        settings: 'Paramètres',
        minimize: 'Minimiser',
        microphone: 'Microphone',
        camera: 'Camera',
    },
    auditoriumArchive: {
        title: "Archives de l'auditorium",
        titleEvent: 'Archives des événements',
        with: 'avec',
        noVideosAvailable: 'Toutes les séances sont terminées',
    },
    videoPlayer: {
        videoNotPlayingError: "Des problèmes avec le flux vidéo ? Rechargez s'il vous plaît.",
        videoEndedTextFirst: "La vidéo est terminée. Merci d'avoir regardé !",
        videoEndedTextSecond: 'Le mur vidéo est ouvert aux questions et réponses.',
        tooltipPlay: 'Jouer',
        tooltipPause: 'Pause',
        tooltipMinimize: 'Réduire',
        tooltipFullscreen: 'Plein écran',
        tooltipMute: 'Mute',
        tooltipUnmute: 'non-mute',
        videoStreamNotStarted: 'Bientôt en direct. Restez connectés!',
    },
    showfloor: {
        allExhibitorsButton: 'TOUS LES EXPOSANTS',
        featuredExhibitorsText: 'EXPOSANTS VEDETTES',
        boothLinksDialogTitle: 'LIENS',
        boothFilesDialogTitle: 'FICHIERS',
        boothVideoDialogTitle: 'VIDÉO',
        mobileTabChat: 'Chat',
        mobileTabPoster: 'AFFICHE',
        mobileTabFiles: 'Fichiers',
        mobileTabLinks: 'Liens',
        mobileTabVideo: 'VIDÉO',
        noExhibitorsTextFirst: 'LES EXPOSANTS TRAVAILLENT SUR LEURS STANDS',
        noPresentersTextFirst: 'Les exposants travaillent sur leurs stands',
        noExhibitorsTextSecond: 'RESTEZ CONNECTÉS',
        showShowfloorsButton: 'ALL EXHIBIT HALLS',
        showShowfloorsPosterButton: 'TOUTES LES ÉTUDES / AFFICHES',
        showShowfloorsExhibitorButton: 'TOUS LES EXPOSANTS',
        searchExhibitors: 'Rechercher exposants',
        searchPosters: 'Rechercher par titre ou auteur',
        searchExhibitorsMultiple: "Rechercher exposants dans cette salle d'exposition",
        searchPostersMultiple: "Rechercher par titre ou auteur dans cette salle d'exposition",
        showfloorsTitle: 'Exhibit Halls',
        collapseList: 'Réduire la liste',
        seeFullList: 'Voir liste entière',
        emptyExhibitorsResults: "Cet exposant n'est pas disponible",
        emptyPostersResults: "Cette étude / affiche n'est pas disponible",
        posters: 'affiches',
        allPostersStudiesTitle: 'Toutes les Études / Affiches',
        authors: 'auteurs',
        titleNotAvailable: "Ce titre n'est pas disponible",
        abstractNotAvailable: "Ce résumé n'est pas disponible",
        authorsNotAvailable: "Cet auteur n'est pas disponible",
        openPosterFile: 'Ouvrir affiche',
        fileIsNotAvailable: "Ce fichier n'est pas disponible",
        posterBooth: {
            study: 'Étude / Affiche',
            authors: 'Auteurs',
            highlights: 'Résumé',
            scrollInfoText: 'DÉFILER VERS LE BAS POUR LIRE LE RÉSUMÉ',
        },
    },
    't we\'ll invite them next time"': [
        'nous les inviterons la prochaine fois',
        'nous les inviterons la prochaine fois',
    ],
    eventArchive: {
        title: 'Archives vidéo',
        searchText: 'Appuyez sur pour rechercher',
        noArchivedEvents: "Il n'y a actuellement aucun événement archivé.",
    },
    exhibitorDashboard: {
        event: 'Événement',
        title: 'Gérer votre stand',
        visitorsButton: 'Visiteurs du stand',
        goToBoothPreview: 'Aperçu du stand',
        previewButton: 'Aperçu',
        tabStyle: 'Style de la cabine',
        tabLogo: 'Logo',
        tabStudyDetails: 'Étude / Affiche',
        tabFiles: 'Fichiers',
        tabStudyFile: 'Fichier',
        tabLinks: 'Liens',
        tabAvatars: 'Avatars',
        tabStudyAuthors: 'Auteurs',
        tabVideo: 'Vidéo',
        boothStyleTab: {
            optionOne: 'Option 1 - Scandinave',
            optionTwo: 'Option 2 - Moderne',
            optionThree: 'Option 3 - Dynamique',
            optionFour: 'Option 4 - Personnalisé',
            optionScholarOne: 'Option 1 - Einstein',
            optionScholarTwo: 'Option 2 - Curie',
            optionScholarThree: 'Option 3 - da Vinci',
        },
        logoTab: {
            fileUploadButton: 'Cliquez ici pour télécharger',
            noFile: 'Aucun dossier choisi',
        },
        posterStudyTab: {
            posterStudyTitle: 'Titre',
            studyTitleInputLabel: "Titre de l'Étude / Affiche",
            highlightAbstractTitle: 'Résumé',
            studyHighlightsInputLabel: 'Résumé de la recherche',
            highlightAbstractError: 'Vous avez atteint le nombre maximum caractères! (500)',
            authorNameLabel: "Nom de l'auteur",
            authorAffiliationLabel: "Affiliation de l'auteur",
        },
        filesTab: {
            title: 'Documents :',
            fileNameInput: 'Nom du document (visible par tous les participants)',
            chooseFileButton: 'Choisissez un fichier',
            noDocAdded: 'Aucun document ajouté',
            fileError: 'Veuillez ajouter un nom de document',
        },
        fileStudyTab: {
            title: 'Télécharger fichier pdf',
            description: '',
            uploadPdfInputLabel: 'Télécharger fichier PDF',
            uploadNewPdfInputLabel: 'Télécharger un nouveau fichier PDF',
            uploadedFile: 'Téléchargé',
            dragContainerTitle: 'Simplement faites glisser le fichier içi',
            dragContainerNewTitle: 'Simplement faites glisser le nouveau fichier içi',
        },
        linksTab: {
            social: {
                title: 'Liens vers les médias sociaux',
                subtitle: '(visible par tous les participants)',
                facebookInput: 'Insérez votre lien Facebook',
                linkedinInput: 'Insérez votre lien LinkedIn',
                twitterInput: 'Insérez votre lien Twitter',
                googleScholarInput: 'Insérer votre lien Google Scholar',
                researchGateInput: 'Insérer votre lien Research Gate',
            },
            other: {
                linkInput: 'Insérer un nouveau lien',
                linkLabel: 'Insérer une nouvelle étiquette de lien',
            },
        },
        linksStudyTab: {
            authorProfiles: "Profils d'auteurs",
            additionalLinks: 'Liens supplémentairs',
        },
        avatarsTab: {
            mainRepresentatives: {
                title: 'Représentants des stands',
                subtitle: '(un seul représentant obligatoire)',
                leftRepresentative: 'Représentant de gauche',
                rightRepresentative: 'Représentant de droite',
                addDialog: {
                    title: 'Ajouter un avatar',
                    titleScholar: 'Ajouter un auteur',
                    editAvatarsTitle: 'Editer un avatar',
                    editAuthorTitle: 'Editer un auteur',
                    contentLine1: 'Choisissez un avatar dans la galerie ci-dessous',
                    contentLine2: 'Téléchargé votre propre avatar personnalizé',
                    contentLine3: "Besoin d'aide? Entrez en contact avec",
                    noFile: 'Choisissez le fichier :',
                    assignButtonText: 'Désigner',
                    assignText:
                        "Veuillez désigner un participant à l'événement qui sera associé au représentant ci-dessus.",
                    assignErrorBooth: "L'utilisateur est déjà ajouté comme représentant de Booth",
                    assignErrorAdditional: "L'utilisateur est déjà ajouté comme représentant supplémentaire",
                    input: 'Recherche par nom ici',
                },
            },
            additionalRepresentatives: {
                title: 'Représentants supplémentaires',
                subtitle: '(optionnel, sera affiché avec leur photo de profil)',
                addDialog: {
                    title: 'Choisir un représentant supplémentaire',
                    titleScholar: 'Choose Co-Author',
                    assignText:
                        "Veuillez commencer à chercher un participant à l'événement pour l'ajouter en tant que représentant.",
                    assignError: "L'utilisateur est déjà ajouté comme représentant de Booth !",
                },
            },
            author: {
                title: 'Presenting Author',
                subtitle: 'Add only an author that registered to the event. You can add 1 author.',
                mainRepresentative: 'Main representative',
            },
            coAuthors: {
                title: 'Coauteurs',
                singularTitle: 'Coauteur',
                subtitle: 'Add only co-authors that registered to the event. You can add up to 4 co-authors.',
            },
        },
        videoTab: {
            video: {
                title: 'Vidéo du stand',
                subtitle: '(Lien vidéo Youtube uniquement)',
                input: "Entrez l'URL Youtube",
            },
            image: {
                subtitle:
                    "(L'image est affichée dans votre stand au cas où vous n'auriez pas ajouté un lien vidéo dans la section ci-dessus)",
            },
        },
    },
    boothDashboard: {
        event: 'Événement',
        title: 'Votre stand',
        manage: 'Gérer',
        statistics: 'Statistiques',
    },
    polls: {
        polls: 'sondage',
        noPolls: "Aucun sondage n'a encore été ajouté.",
        pollProgress: 'Sondage en cours',
        pollClosed: 'Sondage fermé',
        pollVoted: 'participants voted',
    },
    onDemandVideos: {
        sectionTitle: 'SUR DEMANDE',
        noOnDemandVideos: "Aucune vidéo sur demande n'a été ajoutée",
    },
    meetings: {
        meeting: 'RÉUNION',
        createDialog: {
            createTitle: 'Demande de réunion avec',
            inputDate: 'Date',
            inputStart: 'Départs',
            inputEnd: 'Fin',
            inputTimezone: 'Fuseau horaire',
            inputNote: 'Note : Une seule réunion peut être programmée à la fois avec la même personne.',
            meetingError: 'Date ou heure de réunion non valide. Veuillez revérifier!',
            buttonDisabled: 'Vous pouvez planifier une réunion après avoir commencé la conversation!',
        },
        banner: {
            pending: 'Demande de réunion',
            accepted: 'Réunion prévue pour',
            progress: 'Réunion en cours',
            cancelTitle: 'Annuler une réunion ?',
            cancelDescription: 'Vous êtes sûr de vouloir annuler cette réunion ?',
            startCall: "COMMENCER L'APPEL",
            joinCall: 'REJOIGNEZ',
            pendingOwner: 'Demande de réunion en attente pour',
            videoCallStarted: 'La conférence vidéo a commencée',
            videoCallStartedInfo: 'La conférence vidéo fonctionne le mieux avec 10 utilisateurs ou moins',
        },
        title: 'Vos rendez-vous',
        meetingsInfo:
            "Des réunions peuvent être programmées à partir de chats. Veuillez démarrer un chat avec l'utilisateur avec lequel vous souhaitez programmer une réunion.",
    },
    socialMediaShare: {
        event: 'Événement',
        shareLabel: 'Partager',
        dialogTitleGeneral: 'Partager avec votre réseau',
        dialogTitlePost: 'Partager votre publication',
        linkedinLabel: 'LinkedIn',
        facebookLabel: 'Facebook',
        twitterLabel: 'Twitter',
        emailLabel: 'Email',
        clipboardLabel: 'Presse-papiers',
        clipboardSnackbarMessage: 'Lien copié dans le Presse-papiers',
        linkedinButtonTitle: 'Partager sur LinkedIn',
        facebookButtonTitle: 'Partager sur Facebook',
        twitterButtonTitle: 'Partager sur Twitter',
        emailButtonTitle: 'Partager par email',
        clipboardButtonTitle: 'Copier dans le Presse-papiers',
        imAttending: 'Je participe dans le',
        interestedClick: 'Intéressé à participer? Cliquez ici:',
    },
    hubDropdownTitle: 'MENU DU HUB',
    marketplace: {
        title: {
            default: 'Boutique',
            uppercased: 'Boutique',
        },
        label: 'DES PRODUITS',
        addNew: 'AJOUTER NOUVEAU',
        addNewProduct: 'Ajouter un nouveau produit',
        editProduct: 'Modifier le produit',
        noProducts: 'Pas encore de produits',
        publish: 'PUBLIER',
        productTitle: 'Titre du produit',
        productDescription: 'Description du produit',
        productLink: 'Lien produit',
        productImage: 'IMAGE DU PRODUIT',
        uploadHint:
            "Téléchargez une image qui sera affichée comme aperçu du produit. Résolution suggérée de 600 * 600 px pour une meilleure qualité d'image",
        descriptionHint: 'La description est limitée à 300 symboles',
        uploadImage: 'TÉLÉCHARGER UNE IMAGE POUR AUGMENTER LA VALEUR DU PRODUIT',
        uploaded: 'Téléchargé',
    },
    videoConferences: {
        fullScreen: 'OUVRIR PLEIN ÉCRAN',
        minimizeScreen: "MINIMISER L'ÉCRAN",
        startedVideoChat: 'a commencé(e) un appel vidéo',
        timeAgo: 'il y a',
        videoCallStarted: "L'appel vidéo a commencé",
    },
    sessions: {
        edit: 'ÉDITER',
        speakers: 'CONFÉRENCIER',
        speakerSingular: 'conférencier',
        moderators: 'MODÉRATEURS',
        moderatorSingular: 'modérateur',
        video: 'VIDÉO',
        startStream: 'COMMENCER LE STREAM',
        stopStream: 'STOP STREAM',
        sessionChat: 'CHAT DE SÉANCE',
        speakerUrl: 'URL du conférencier',
        moderatorUrl: 'URL du modérateur',
        copyUrl: 'copier le lien',
        noSpeakersJoined: "Aucun conférencier n'a encore rejoint",
        allFilter: 'tout',
        waitingRoom: "Salle d'attente",
        isReady: 'Est Prêt(e)',
        invitationAccepted: "L'invitation a été acceptée",
        requestPending: 'La demande est en attente',
        gettingReady: 'En préparation',
        inviteToSpeak: 'Inviter à parler',
        fromAuditorium: "depuis l'Auditorium",
        mic: 'Microphone',
        screen: 'Écran',
        rec: 'rec',
        sessionHasEnded: 'La séance est terminée',
        sessionLinks: 'LIENS DE SESSION',
        sessionSettings: 'Paramètres',
        noAvailableChat:
            'Aucune discussion disponible. Pour commencer une discussion, veuillez allouer cette séance à un créneau horaire.',
        startLiveSessionStreamTitle: 'Commencer une séance en directe',
        startLiveSessionDescription:
            "L'enregistrement de la séance commence automatiquement quand vouz commencez la séance.",
        stopLiveSessionStreamTitle: 'Arreter la séance en directe.',
        stopLiveSessionStreamDescription:
            "L'enregistrement de la séance se termine automatiquement quand vous arrêtez la séance. L'enregistrement sera bientôt disponible.",
        thumbnailImage: 'Vignette',
        uploadThumbnail: 'Télécharger une vignette',
        clickToUpload: 'Cliquez ici pour télécharger',
        requestMic: 'DEMANDER LE MICROPHONE',
        acceptInvitationTitle: 'Vous êtes invité(e) ',
        acceptInvitationSubTitle: "Le modérateur vous a invité(e) à parler. Êtes vous d'accord?",
        acceptInvitationButton: "Accepter l'invitation",
        requestPendingTitle: 'Votre demande est en attente',
        requestPendingSubTitle: 'Veuillez attendre que le modérateur vous invites à la séance',
        cancelRequest: 'Annuler la demande',
        approvedTitle: 'Veuillez vérifier votre microphone et votre vidéo et cliquez "Je suis prêt."',
        approvedSubTitle:
            "Cette séance est en cours d'enregistrement. En rejoignant cette séance, vous acceptez de partager vos données personnelles.",
        connectedToTheBackstage: "Vous diffusez maintenant en direct et êtes connecté aux coulisses de l'auditorium.",
        removedFromTheSession: 'Vous avez été expulsé de la séance, veuillez contacter un modérateur.',
        imReady: 'Je suis prêt',
        isTryingToRegister: "essaie de s'inscrire en tant que",
        hasLeftTheSession: 'a quitté la session',
        stopVideo: 'Arreter la vidéo',
        startVideo: 'Commencer la vidéo',
        disabledScreenSharing: "Désactivez votre partage d'écran avant de commencer votre vidéo",
        disabledByModerator: 'Désactivé par le modérateur',
        disabledUntilReady: "Désactivé jusqu'à prêt",
        stopShareScreen: "Arreter votre partage d'écran",
        shareScreen: 'Partager votre écran',
        disabledVideoSharing: "Désactivez votre vidéo avant de commencer votre partage d'écran",
        anotherUserIsScreenSharing: "Quelqu'un d'autre partage déjà son écran",
        removeRoleDescription: 'Êtes-vous sûr de vouloir supprimer cela?',
        declinedInvitation: "a refusé l'invitation d'être conferencier",
    },
    gdpr: {
        disclaimerTextFirst:
            "Ce document a été traduit par un logiciel d'intelligence artificielle. En cas d'incohérence, veuillez référer à la version originale du document",
        disclaimerTextSecond: 'IÇI',
        metaTitlePrivacyPolicy: 'Politique de confidentialité',
        metaTitleCookiesPolicy: 'Parametrer les cookies',
        metaTitleTerms: 'Termes et conditions',
        updateCookieConsent: 'Mettre à jour le consentement des cookies',
        changeYourConsent: 'Changer votre consentement',
        withdrawYourConsent: 'Retirer votre consetement',
        withdrawSnackbarText: 'Votre consetement au cookies a été retiré',
    },
    infoMessages: {
        imageExplain420x200: "Rapport d'image recommandé 1,8:1 (orienté paysage, par exemple 420 x 200 pixels)",
        imageExplainTB230x320:
            "Rapport d'image recommandé 1:1,4 (orienté portrait, par exemple 230 x 320 pixels) avec fond transparent",
        imageExplainTB230x875:
            "Rapport d'image recommandé 1:3,8 (orienté portrait, par exemple 230 x 875 pixels) avec fond transparent",
    },
    matching: {
        matchingQuestionnaireTitle: 'Questionnaire de connexion',
        matchingQuestionnaireIntro:
            "Connectez-vous à d'autres profils qui vous correspondent! Les meilleures connexions mènent toujours à des résultats incroyables - remplissez le questionnaire pour commencer.",
        matchingQuestionnaireIntroSkip:
            'En sautant le questionnaire, nous ne pourrons pas vous connecter avec d\'autres profils qui vous correspondent. Vous pouvez toujours remplir le questionnaire plus tard dans la section "Mise en réseau". Toutes les réponses sont modifiables dans Mon Compte.',
        doItLaterBtn: 'REMPLIR PLUS TARD',
        nextBtn: 'Prochain',
        backBtn: 'Retour',
        finishBtn: 'Terminer',
        seeMatchesBtn: 'VOIR TOUTES VOS CONNEXIONS',
        tryMatchingBtn: 'ESSAYER CONNEXION INTELLIGENTE',
        optionalQuestion: 'Optionnelle',
        requiredQuestion: 'Obligatoire',
        singleSelect: 'Séléction unique',
        multiSelect: 'Séléction multiple',
        mediumMatch: 'Connexion moyenne',
        highMatch: 'Connexion forte',
        matchLabel: 'Connexion',
        matchesLabel: 'Connexions',
        infoTooltipJump: 'Cliquez ici pour voir vos connexions',
        infoTooltipClickToSee1: 'Cliquez sur',
        infoTooltipClickToSee2: 'pour voir avec qui vous devez vous connecter!',
        loadingAlgoText:
            "L'algorithme de connexion est en marche. Patientez une minute lorsque nous trouvons les profils qui vous correspondent le mieux!",
        matchingDetailsFormTitle: 'Détails de connexions intelligentes',
        tryMatchingBanner: 'Établissez une connexion importante, essayez notre fonction Connexion Intelligente!',
        matchingBannerClosed:
            'Vous pouvez toujours remplir le questionnaire Connexion Intelligente plus tard dans la section "Mise en réseau".',
        resultsTitle: 'Voici les profils qui vous correspondent le mieux',
        resultsNoteLabel: 'NOTE:',
        resultsNoteText: 'Vous pouvez trouver toutes vos connexions dans la section "Mise en réseau".',
        resultsNoteMyAccount: 'Toutes réponses peuvent être modifier dans le menu Mon Compte',
        btwResultsResponsive: 'Vous pouvez trouver toutes vos connexions dans la section "Mise en réseau".',
        noMoreMatches:
            'Oh non! Nous ne trouvons pas de connexions pour vous en ce moment. Revenez voir cette section quand plus de participants ont terminé le questionnaire.',
        noResultsTitle: "Félicitations! Nous cherchons à l'instant les profils qui vous correspondent le mieux.",
        noResults:
            "Merci d'avoir terminé le questionnaire de Connexion Intelligente! Nous cherchons à l'instant les profils qui vous correspondent le mieux.",
        clickStartChat: "Cliquez 'chat' pour commencer une conversation.",
        closeMatchingTitle: 'Fermer le questionnaire Connexion Intelligente?',
        closeMatchingTextA:
            'Si vous fermez le questionnaire avant de le remplir, nous ne garderons aucun détail et les résultats de la Connexio Intelligente ne vous seront pas accessibles.',
        closeMatchingTextB:
            "Votre profil ne fera pas partie de la Connexion Intelligente et vous n'aurez pas accés au profils qui vous correspondent le mieux.",
        matchingTooltip: "Cliquez sur 'Connexions' pour voir les profils qui vous correspondent le mieux!",
        myAccountMatchingSectionTitle: 'Détails de connexions intelligentes',
        matchingRequiredError: 'Vous devez répondre à toutes les questions Obligatoires',
        rejectMatchingTitle: 'Rejeter participation de Connexion Intelligente?',
        rejectMatchingText:
            "Vos réponses seront effacées et vous ne serez pas mis en correspondance avec d'autres profils. Vous pouvez reprendre le questionnaire de Connexion Intelligente à tout moment.",
        rejectMatchingBtn: 'REJETER PARTICIPATION',
    },
    welcomeScreen: {
        goToEvent: "Aller à l'événement ",
    },
};

export default fr;
